import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import ModalContentLokasi from '../../master/modals/ModalContentLokasi'
import { AuthContext } from "../../../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import TambahLokasi from './TambahLokasi';


const ListLokasi = () => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    let refreshToken = null;

    const navigate = useNavigate()

    const handleIsModal = (item, event) => {
        // console.log(event.target.id.split('-')[1]);
        // console.log(item.id);
        // console.log(isModal);
        console.log(item);
        if (event.target.id.split('-')[1] == item.id || item === "tambah-data") {
            // console.log('aaaa');
            isModal ? setIsModal(false) : setIsModal(true);
            setItemData(item);
        }
    }

    function changeModal() {
        var newModalId = "#newModal"; // Replace with the ID of the new modal you want to open
        var button = document.querySelector('[data-toggle="modal"]');
        button.setAttribute('data-target', newModalId);
    }

    const handleNilaiBalikan = (nilaiBalikan) => {
        setIsLoading(nilaiBalikan);
    }

    const setModalFalse = () => {
        isModal ? setIsModal(false) : setIsModal(true);
    }

    // useEffect(() => {        
    //     setTokenJwt(user.accessToken);     
    // }, []);

    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl+'/api/igahp/lokasi/all', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);

            try {
                fetch(apiUrl+'/api/public/certif/ref/hapusDataBidang', {
                    method: 'POST',
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                        // alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }

    let sequenceNumber = 1;


    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Data Lokasi Perumahan</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                        <div className="col-12">
                            <ul className="nav nav-pills">
                                {/* <li className="nav-item col-md-auto">
                                        <a className="nav-link active" href="#revenue-chart" data-toggle="tab">List Data Bidang</a>
                                    </li>
                                    <li className="nav-item col-md-auto">
                                        <a className="nav-link" href="#sales-chart" data-toggle="tab">Detail Data Bidang</a>
                                    </li> */}
                            </ul>
                            <div className="tab-content p-2">
                                <div className="chart tab-pane active" id="revenue-chart">



                                    {/* Main content */}
                                    {/* card-header */}

                                    {isLoading ?
                                        <p>Loading Data...</p> :

                                        <div className="card">
                                            <div className="card-header">
                                                <div className="row">
                                                    <div className="col-12">
                                                        {/* <label className="form-control-label mt-2" htmlFor="exampleCheck1">Data ditemukan: <a href="#">3</a></label> */}

                                                        {/* <button type="button" id="tambahModal" data-toggle="modal"
                                                        data-target="#ModalContentLokasi"
                                                        className="btn btn-success float-right"
                                                        onClick={(event) => {
                                                            handleIsModal("tambah-data", event)
                                                        }}

                                                    >
                                                        <i className="fas fa-plus" />
                                                        Tambah Data
                                                    </button> */}

                                                        {
                                                            <ModalContentLokasi isModal={isModal} sendData={itemData}
                                                                penampungBalikan={handleNilaiBalikan}
                                                                setModalFalse={setModalFalse}
                                                            />
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                            {/* /.card-header */}
                                            <div className="card-body">
                                                <table id="example1" className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>NO</th>
                                                            <th>NAMA PERUMAHAN</th>
                                                            <th>LOGO</th>
                                                            <th>AKSI</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {data.map((item) => (

                                                            <tr key={item.id}>
                                                                {/* <td>{item.id}</td> */}
                                                                {/* <td>{item.id}</td> */}
                                                                <td>{sequenceNumber++}</td>
                                                                <td>{item.nama_perumahan}</td>

                                                                <td>
                                                                    <div className="product-img">
                                                                        {/* <a href={`http://localhost:8080/api/public/certif/images/${item.logo}`} target="_blank">
                                                                                <img src={`http://localhost:8080/api/public/certif/images/${item.logo}`} alt="Product Image" className="img-size-50" />
                                                                            </a> */}
                                                                    </div>
                                                                </td>

                                                                <td>
                                                                    <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                                        // id={`button-${item.id}`} 
                                                                        onClick={(event) => {
                                                                            // handleIsModal(item, event)
                                                                            navigate(`/igahp/InfoLokasi/${item.id}`);
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-eye" />
                                                                    </button>

                                                                    <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                                        // id={`button-${item.id}`} 
                                                                        onClick={(event) => {
                                                                            // handleIsModal(item, event)
                                                                            navigate(`/igahp/TambahLokasi/${item.id}`);
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-edit" id={`i-${item.id}`} />

                                                                        {/* <TambahLokasi/> */}
                                                                    </button>


                                                                    <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                                        <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                                    </button>
                                                                </td>

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    }


                                    {/* modal-edit*/}
                                    {/* <div className="modal fade" id="modal-xl" style={{ display: 'none' }} aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h4 className="modal-title">Extra Large Modal</h4>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true">×</span>
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="card-body">
                                                            <div className="form-group">
                                                                <label htmlFor="Name">Nama</label>
                                                                <input type="exampleInputName" className="form-control" id="exampleInputName" placeholder="Enter email" />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputNik">KTP</label>
                                                                <input type="nik" className="form-control" id="exampleInputNIK" placeholder={3275012444003901} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputKelamin">Pilih Jenis Kelamin</label>
                                                                <div className="row">
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="radio1" />
                                                                                <label className="form-check-label">Pria</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-3">
                                                                        <div className="form-group">
                                                                            <div className="form-check">
                                                                                <input className="form-check-input" type="radio" name="radio1" />
                                                                                <label className="form-check-label">Wanita</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputN0Handphone">No Handphone</label>
                                                                <input type="nik" className="form-control" id="exampleInputNoHandphone" placeholder={"081219121266"} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputInstansi">Instansi</label>
                                                                <input type="nik" className="form-control" id="exampleInputInstansi" placeholder={"081219121266"} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputJabatan">Jabatan</label>
                                                                <input type="nik" className="form-control" id="exampleInputJabatan" placeholder={"081219121266"} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampJurusanProgram">Jurusan Program</label>
                                                                <input type="nik" className="form-control" id="exampleJurusanProgram" placeholder={"081219121266"} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleInputStrata">Strata</label>
                                                                <input type="nik" className="form-control" id="exampleInputStrata" placeholder={"081219121266"} />
                                                            </div>
                                                            <div className="form-group">
                                                                <label htmlFor="exampleWilayahKerja">Wilayah Kerja</label>
                                                                <input type="nik" className="form-control" id="exampleWilayahKerja" placeholder={"081219121266"} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer justify-content-end">
                                                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                                        <button type="button" className="btn btn-primary">Simpan</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    {/* /.modal-edit */}




                                </div>
                                <div className="chart tab-pane" id="sales-chart" style={{ position: 'relative', height: 300 }}>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



        </>
    )
}

export default ListLokasi
