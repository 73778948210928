import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';

import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';

const TaBghHasilPenilaian = () => {

    const { user, setUser } = useContext(AuthContext);

    let refreshToken = null;


    const navigate = useNavigate()


    useEffect(() => {

        // const script2 = document.createElement('script');
        // script2.src = "/js/toast.js";
        // script2.async = true;
        // document.body.appendChild(script2);


        // Fetch options from API and update state

        // return () => { script2.remove(); }
    }, []);




    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <br />
                        <h4 className="text-center display-4">Data Pengembang</h4>
                        <br />
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <form action="simple-results.html">
                                    <div className="input-group">
                                        <input type="search" className="form-control form-control-lg" placeholder="Cari Nama Pengembang" />
                                        <div className="input-group-append">
                                            <button type="submit" className="btn btn-lg btn-default">
                                                <i className="fa fa-search" />
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-8 offset-md-2">
                                <form action="simple-results.html">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-block btn-dark btn-sm">Bersubsidi</button>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-block btn-outline-dark btn-sm">Konvensional</button>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-block btn-outline-dark btn-sm">Terjual</button>
                                        </div>
                                        <div className="col-md-3">
                                            <button type="button" className="btn btn-block btn-outline-dark btn-sm">Tersisa</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <section className="content">
                    <div className="container-fluid">
                        {/* /.row */}
                    </div>{/* /.container-fluid */}
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                {/* left column */}
                                <div className="col-md-6">
                                    {/* general form elements */}
                                    <div className="card card-gray">
                                        <div className="card-header">
                                            <h3 className="card-title">KONVENSIONAL</h3>
                                        </div>
                                        <br />
                                        {/* /.card-header */}
                                        {/* form start */}
                                        <div className="col-12 ml-2">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-gradient-primary"><i className="far fa-list-alt" /></span>
                                                <div className="info-box-content">
                                                    <h4 className="info-box-text">Jumlah Unit</h4>
                                                    <h2 className="info-box-number">410</h2>
                                                </div>
                                                {/* /.info-box-content */}
                                            </div>
                                            {/* /.info-box */}
                                        </div>
                                        <br />
                                        <div className="card-body">
                                            <div className="position-relative shadow rounded">
                                                <span className="bg-blue rounded p-1 text-white position-absolute">Rumah
                                                    Tapak</span>
                                                <img className="round" src="image/Rumah contoh.png" alt="foto contoh rumah perumahanASRI MEGA LAND 1" style={{ width: '100%' }} />
                                                <div className="row ml-0">
                                                    <div className="image-preview"><img src="image/Rumah contoh1.png" alt="Product Image" /></div>
                                                    <div className="image-preview"><img src="image/Rumah contoh2.png" alt="Product Image" /></div>
                                                    <div className="image-preview"><img src="image/Rumah contoh3.png" alt="Product Image" /></div>
                                                    <div className="image-preview"><img src="image/Rumah contoh4.png" alt="Product Image" /></div>
                                                </div>
                                                <br />
                                                <div className="row p-3">
                                                    <div className="col-md-12">
                                                        <div className="mb-1">
                                                            <h4 className="m-0"><b>ASRI MEGA LAND 1</b></h4>
                                                        </div>
                                                        <h5 className>PT. Purimega Saranaland</h5>
                                                        <h5 className>APERSI</h5>
                                                        <h6 className>JAWA BARAT, KAB KUNINGAN, Cigugur, Winduherang</h6>
                                                        <div className="row text-xs mt-1">
                                                            <div className="col-md-12" style={{ fontSize: 15 }}>
                                                                <span className="bg-yellow-600 rounded text-black p-1" style={{ backgroundColor: 'rgb(227, 227, 227)' }}>46 Unit Terjual</span>
                                                                <span className="bg-gray-800 rounded text-black p-1" style={{ backgroundColor: 'rgb(227, 227, 227)' }}>44 Unit Tersisa</span>
                                                                <span className="bg-teal-600 rounded text-black p-1" style={{ backgroundColor: 'rgb(227, 227, 227)' }}>90 Unit Total</span>
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/*/.col (left) */}
                                {/* right column */}
                                <div className="col-md-6">
                                    {/* Form Element sizes */}
                                    <div className="card card-gray">
                                        <div className="card-header">
                                            <h3 className="card-title">RUMAH HIJAU</h3>
                                        </div>
                                        <br />
                                        <div className="col-12 ml-2">
                                            <div className="info-box">
                                                <span className="info-box-icon bg-gradient-green"><i className="far fa-list-alt" /></span>
                                                <div className="info-box-content">
                                                    <h4 className="info-box-text">Jumlah Unit</h4>
                                                    <h2 className="info-box-number">410</h2>
                                                </div>
                                                {/* /.info-box-content */}
                                            </div>
                                            {/* /.info-box */}
                                        </div>
                                        <br />
                                        <div className="card-body">
                                            <div className="position-relative shadow rounded">
                                                <span className="bg-green rounded p-1 text-white position-absolute">Rumah
                                                    Tapak Hijau</span>
                                                <img className="round" src="image/Rumah contoh.png" alt="foto contoh rumah perumahanASRI MEGA LAND 1" style={{ width: '100%' }} />
                                                <div className="row ml-0">
                                                    <div className="image-preview"><img src="image/Rumah contoh1.png" alt="Product Image" /></div>
                                                    <div className="image-preview"><img src="image/Rumah contoh2.png" alt="Product Image" /></div>
                                                    <div className="image-preview"><img src="image/Rumah contoh3.png" alt="Product Image" /></div>
                                                    <div className="image-preview"><img src="image/Rumah contoh4.png" alt="Product Image" /></div>
                                                </div>
                                                <br />
                                                <div className="row p-3">
                                                    <div className="col-md-12">
                                                        <div className="mb-1">
                                                            <h4 className="m-0"><b>TAMAN ANGGREK KUNINGAN 1</b></h4>
                                                        </div>
                                                        <h5 className>PT. Purimega Saranaland</h5>
                                                        <h5 className>APERSI</h5>
                                                        <h6 className>JAWA BARAT, KAB KUNINGAN, Cigugur, Winduherang</h6>
                                                        <div className="row text-xs mt-1">
                                                            <div className="col-md-12" style={{ fontSize: 15 }}>
                                                                <span className="bg-yellow-600 rounded text-black p-1" style={{ backgroundColor: 'rgb(227, 227, 227)' }}>46 Unit Terjual</span>
                                                                <span className="bg-gray-800 rounded text-black p-1" style={{ backgroundColor: 'rgb(227, 227, 227)' }}>44 Unit Tersisa</span>
                                                                <span className="bg-teal-600 rounded text-black p-1" style={{ backgroundColor: 'rgb(227, 227, 227)' }}>90 Unit Total</span>
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                    {/* /.card */}
                                    {/* /.card */}
                                </div>
                                {/*/.col (right) */}
                                {/* /.col */}
                            </div>
                        </div>
                        {/* /.card-body */}
                    </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer />

        </>
    )
}

export default TaBghHasilPenilaian
