import React from 'react'
import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import { useState } from 'react';

const Aplikasi = () => {



  const [imageSource, setImageSource] = useState("images/aplikasi/image_phone_aplikasi_1.svg");


  const [isActivePenilaian, setIsActivePenilaian] = useState(false);
  const handlePenilaianOtomatisClick = () => {
    if (isActivePenilaian) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    }
    setIsActivePenilaian(!isActivePenilaian);
    setIsActiveUnggah(false);
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveUnggah, setIsActiveUnggah] = useState(false);
  const handleUnggahOtomatisClick = () => {
    if (isActiveUnggah) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_2.svg";
      setImageSource(newImageSource);
    }
    setIsActiveUnggah(!isActiveUnggah);
    setIsActivePenilaian(false)
    setIsActiveTerbit(false)
    setIsActivePenugasan(false);
  };


  const [isActiveTerbit, setIsActiveTerbit] = useState(false);
  const handleTerbitOtomatisClick = () => {
    if (isActiveTerbit) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_3.svg";
      setImageSource(newImageSource);
    }
    setIsActiveTerbit(!isActiveTerbit)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
    setIsActivePenugasan(false);
  };


  const [isActivePenugasan, setIsActivePenugasan] = useState(false);
  const handlePenugasanOtomatisClick = () => {
    if (isActivePenugasan) {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_1.svg";
      setImageSource(newImageSource);
    } else {
      const newImageSource = "images/aplikasi/image_phone_aplikasi_4.svg";
      setImageSource(newImageSource);
    }
    setIsActivePenugasan(!isActivePenugasan)
    setIsActiveTerbit(false)
    setIsActiveUnggah(false);
    setIsActivePenilaian(false)
  };


  require("./../../css/common.css");

  return (

    <>
      <NavBarAtas page="3" showButton={true} />


      <div>
        <section className="wrapper-hero-image-aplikasi">
          <div className="wrapper-hero-text-aplikasi">
            <div className="one-green-h3-semi-bold white-text">Sertifikasi Hijau Dengan Aplikasi OneGreen</div>
            <div className="one-green-m-regular white-text">Lakukan sertifikasi hijau untuk rumah subsidi dan komersil
              dengan aplikasi One Green secara mudah, canggih, dan efektif.</div>
          </div>
          <div className="wrapper-background-hero-image-aplikasi">
            <img src="images/aplikasi/background_green_vector_aplikasi.svg" id="background-hero-image-aplikasi" />
            <img src="images/aplikasi/image_hero_aplikasi.png" id="hero-image-aplikasi" />
          </div>
          <img src="images/aplikasi/ic_green_leaf_aplikasi.svg" id="green-leaf-aplikasi" width={120} height={120} />
        </section>
        <section className="wrapper-keunggulan-aplikasi">
          <div className="keunggulan-aplikasi">

            {/* <img src="images/aplikasi/image_phone_aplikasi_1.svg " id="image-keunggulan-aplikasi" /> */}
            <img src={imageSource} id="image-keunggulan-aplikasi" />


            <div className="wrapper-deskripsi-keunggulan-aplikasi">
              <div className="one-green-h3-semi-bold">Keunggulan Aplikasi One Green</div>
              <div className="wrapper-list-keunggulan-aplikasi">

                <div className={`list-keunggulan-aplikasi-item ${isActivePenilaian ? 'active' : ''}`} id="penilaian-otomatis" onClick={handlePenilaianOtomatisClick}>
                  <div className="list-keunggulan-aplikasi-title">Penilaian otomatis sertifikasi hijau</div>
                  <div className="list-keunggulan-aplikasi-description">
                    Anda dapat melakukan penilaian terhadap Rumah Anda sesuai dengan Surat Edaran Direktur Jenderal
                    Cipta Karya No: 03/SE/DC/2023 tentang Petunjuk Teknis Penilaian Kinerja Bangunan Gedung
                    Hijau untuk Klas Bangunan 1a
                  </div>
                  <img src={imageSource} id="image-keunggulan-aplikasi-mobile" />
                  <div className="divider-neutral-30"></div>
                  <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" width={16} height={16} />

                </div>

                <div className={`list-keunggulan-aplikasi-item ${isActiveUnggah ? 'active' : ''}`} id="unggah-dokumen" onClick={handleUnggahOtomatisClick}>
                  <div className="list-keunggulan-aplikasi-title">Unggah dokumen lebih mudah</div>
                  <div className="list-keunggulan-aplikasi-description">
                    Anda dapat mengunggah dokumen penilaian hanya melalui genggaman
                  </div>
                  <img src={imageSource} id="image-keunggulan-aplikasi-mobile" />
                  <div className="divider-neutral-30" />
                  <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" width={16} height={16} />
                </div>

                <div className={`list-keunggulan-aplikasi-item ${isActiveTerbit ? 'active' : ''}`} id="penerbitan-dokumen" onClick={handleTerbitOtomatisClick}>
                  <div className="list-keunggulan-aplikasi-title">Menerbitkan dokumen penilaian lebih efisien</div>
                  <div className="list-keunggulan-aplikasi-description">
                    Anda dapat menerbitkan dokumen penilaian lebih efisien dan membantu untuk pengajuan
                    Sertifikasi Rumah Hijau
                  </div>
                  <div className="divider-neutral-30" />
                  <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" width={16} height={16} />
                </div>

                <div className={`list-keunggulan-aplikasi-item ${isActivePenugasan ? 'active' : ''}`} id="penugasan-tenaga-ahli" onClick={handlePenugasanOtomatisClick}>
                  <div className="list-keunggulan-aplikasi-title">Penugasan untuk tenaga ahli</div>
                  <div className="list-keunggulan-aplikasi-description">
                    Tenaga Ahli Sertifikasi Rumah Hijau dapat menerima penugasan dari Pengembang Perumahan yang
                    sedang mengajukan Sertifikasi Rumah Hijau
                  </div>
                  <img src={imageSource} id="image-keunggulan-aplikasi-mobile" />
                  <div className="divider-neutral-30" />
                  <img src="images/aplikasi/ic_chevron_down.svg" id="chevron-aplikasi" width={16} height={16} />
                </div>


              </div>
            </div>
          </div>
        </section>
        <section className="wrapper-download-aplikasi">
          <div className="wrapper-qr-code-aplikasi">
            <div className="one-green-h3-semi-bold white-text">Download Aplikasi</div>
            <div className="one-green-m-regular white-text">Scan QR dibawah ini untuk mendownload aplikasi One Green</div>
            <img src="one_green_mobile_qr.png" width={120} height={120} id="one-green-mobile-qr" />
            <div className="download-panduan-penggunaan">
              <div className="one-green-s-semibold green-main-text">Download panduan penggunaan</div>
              <img src="images/aplikasi/ic_download.svg" width={18} height={18} />
            </div>
          </div>
          <div className="background-green-bottom-right-rounded" />
          <div className="wrapper-image-contoh-aplikasi">
            <img src="images/aplikasi/image_aplikasi_detail_rumah.png" width={265} />
            <img src="images/aplikasi/image_aplikasi_penilaian.png" width={265} />
          </div>
        </section>
      </div>
      <FooterFront />

    </>
  )
}

export default Aplikasi
