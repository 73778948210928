import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
// import ModalContentLokasi from '../../master/modals/ModalContentLokasi'
import { AuthContext } from "../../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
// import { refreshTokenRequest } from '../../refreshTokenRequest';
// import TambahLokasi from './TambahLokasi';
import { refreshTokenRequest } from '../refreshTokenRequest';
import Footer from '../Footer';


const PengembangListLokasiPerorangan = () => {


    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const { loginContext } = useContext(AuthContext);

    const ITEMS_PER_PAGE = 6; // Jumlah item per halaman

    const [currentPage, setCurrentPage] = useState(1);

    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = data.slice(startIndex, endIndex);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    let refreshToken = null;

    const navigate = useNavigate()

    const handleIsModal = (item, event) => {
        // console.log(event.target.id.split('-')[1]);
        // console.log(item.id);
        // console.log(isModal);
        console.log(item);
        if (event.target.id.split('-')[1] == item.id || item === "tambah-data") {
            // console.log('aaaa');
            isModal ? setIsModal(false) : setIsModal(true);
            setItemData(item);
        }
    }

    function changeModal() {
        var newModalId = "#newModal"; // Replace with the ID of the new modal you want to open
        var button = document.querySelector('[data-toggle="modal"]');
        button.setAttribute('data-target', newModalId);
    }

    const handleNilaiBalikan = (nilaiBalikan) => {
        setIsLoading(nilaiBalikan);
    }

    const setModalFalse = () => {
        isModal ? setIsModal(false) : setIsModal(true);
    }

    // useEffect(() => {        
    //     setTokenJwt(user.accessToken);     
    // }, []);

    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            console.log('user id ' + user.id);
            const response = await axios.get(apiUrl + '/api/igahp/pengembang/lokasi/userid/' + user.id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            // console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);

            try {
                fetch(apiUrl + '/api/public/certif/ref/hapusDataBidang', {
                    method: 'POST',
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                        // alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }


    const handleAddLokasi = () => {
        user['perumahan_state'] = 'add';
        loginContext(user); // isi context

        navigate(`/igahp/pengembang/PengembangLokasiTambah`);
    };

    let sequenceNumber = 1;

    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">INFO<b> PERUMAHAN</b></h1>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="card card-solid">
                        <div className="card-body pb-0">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Card</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Table</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        {!isLoading ? (
                                            <>
                                                <div className="tab-content">

                                                    <div className="active tab-pane" id="activity">
                                                        <div className="row">




                                                            {data.map((item) => (
                                                                

                                                                <div key={item.id} className="col-12 col-md-6 col-xl-3 mb-4">
                                                                    <div className="card h-100">
                                                                        <div className="card-body">
                                                                            <div className="bg-label-primary rounded-3 text-center mb-3 pt-0">
                                                                                {/* <img className="img-fluid w-60" src="dist/img/kavling/IMG_1129_TENGAH.JPG" alt="Card girl image" /> */}

                                                                                <img src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                                                                                    alt="gambar lokasi"
                                                                                    style={{ width: '100%', height: '320px' }}
                                                                                    className="img-fluid w-60" />

                                                                            </div>
                                                                            <h4 className="mb-2 pb-1">{item.nama_perumahan}</h4>
                                                                            <h6 className="mb-3 text-muted">{item.kecamatan}</h6>
                                                                            <br />
                                                                            <div className="row mb-4">
                                                                                {/* <div className="col-6">
                                                                            <div className="d-flex">
                                                                                <div className="avatar flex-shrink-0 me-2">
                                                                                    <span className="avatar-initial rounded bg-label-primary"></span>
                                                                                </div>
                                                                                <div>
                                                                                    <h6 className="mb-0 text-muted">Total</h6>
                                                                                    <h6 className="mb-0 text">{item.totalUnitRumah !== null ? item.totalUnitRumah  + ' ' : "- "} Unit </h6>
                                                                                </div>
                                                                            </div>
                                                                        </div> */}
                                                                                <div className="col-6">
                                                                                    <div className="d-flex">
                                                                                        <div className="avatar flex-shrink-0 me-2">
                                                                                            <span className="avatar-initial rounded bg-label-primary">
                                                                                            </span></div>
                                                                                        <div>
                                                                                            <h6 className="mb-0 text-muted">Jenis Rumah</h6>
                                                                                            <h6 className="mb-0 text">{item.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mb-4">
                                                                                
                                                                                <div className="col-6">
                                                                                    <div className="d-flex">
                                                                                        <div className="avatar flex-shrink-0 me-2">
                                                                                            <span className="avatar-initial rounded bg-label-primary">
                                                                                            </span></div>
                                                                                        <div>
                                                                                            <h6 className="mb-0 text-muted">ID Rumah</h6>
                                                                                            <h6 className="mb-0 text">{item.idLokasiString}</h6>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            {/* <a href="detail_perumahan.html">
                                                                        <button type="button" className="btn btn-success btn-block">
                                                                        Lihat Detail</button>
                                                                    </a> */}


                                                                            <button type="button" className="btn btn-success btn-block"

                                                                                onClick={(event) => {

                                                                                    user['perumahan_id'] = item.id;
                                                                                    user['perumahan_nama'] = item.nama_perumahan;
                                                                                    user['status_verifikasi'] = item.verified;
                                                                                    // user['perumahan_state'] = 'add';
                                                                                    loginContext(user); // isi context

                                                                                    // navigate(`/igahp/pengembang/PengembangLokasiProfile/${item.id}`);
                                                                                    navigate(`/igahp/perorangan/rumahinfo`);
                                                                                }}
                                                                            >
                                                                                <i className="fas fa-eye mr-2" />
                                                                                Lihat Detail
                                                                            </button>


                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ))}


                                                        </div>

                                                        <nav aria-label="Page navigation">
                                                            <ul className="pagination">
                                                                {Array.from({ length: totalPages }).map((_, index) => (
                                                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                        <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                                            {index + 1}
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            </ul>
                                                        </nav>

                                                    </div>


                                                    {/* /.tab-pane */}
                                                    <div className="tab-pane" id="timeline">
                                                        <div className="row">

                                                            {isLoading ?
                                                                <p>Loading Data...</p> :

                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <div className="card-info">
                                                                            <button type="submit" onClick={handleAddLokasi} className="btn btn-primary">Tambah Lokasi</button>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12">

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* /.card-header */}
                                                                    <div className="card-body">
                                                                        <table id="example1" className="table table-bordered table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>NO</th>
                                                                                    <th>NAMA PERUMAHAN</th>
                                                                                    <th>KECAMATAN</th>
                                                                                    <th>JENIS</th>
                                                                                    <th>VERIFIED</th>
                                                                                    <th>AKSI</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                {data.map((item) => (
                                                                                    <tr key={item.id}>
                                                                                        {/* <td>{item.id}</td> */}
                                                                                        <td>{sequenceNumber++}</td>
                                                                                        <td>{item.nama_perumahan}</td>
                                                                                        <td>{item.kecamatan}</td>
                                                                                        <td>{item.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'}</td>
                                                                                        <td>{item.verified === true ? 'Verified' : 'Belum Verified'}</td>
                                                                                        <td>
                                                                                            <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                                                                onClick={(event) => {
                                                                                                    user['perumahan_id'] = item.id;
                                                                                                    user['perumahan_nama'] = item.nama_perumahan;
                                                                                                    user['status_verifikasi'] = item.verified;
                                                                                                    // user['perumahan_state'] = 'add';
                                                                                                    loginContext(user); // isi context                                                                                                    
                                                                                                    navigate(`/igahp/perorangan/rumahinfo`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-eye" />
                                                                                            </button>

                                                                                            <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                                                                // id={`button-${item.id}`} 
                                                                                                onClick={(event) => {
                                                                                                    user['perumahan_id'] = item.id;
                                                                                                    user['perumahan_nama'] = item.nama_perumahan;
                                                                                                    user['perumahan_state'] = 'edit';
                                                                                                    loginContext(user); // isi context

                                                                                                    // navigate(`/igahp/pengembang/PengembangLokasiProfile/${item.id}`);
                                                                                                    navigate(`/igahp/pengembang/PengembangLokasiTambah`);
                                                                                                }}
                                                                                            >
                                                                                                <i className="fas fa-edit" id={`i-${item.id}`} />

                                                                                                {/* <TambahLokasi/> */}
                                                                                            </button>


                                                                                            <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                                                                <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                                                            </button>
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}

                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            }





                                                        </div>
                                                    </div>
                                                    {/* /.tab-pane */}
                                                    {/* <div className="tab-pane" id="settings">
                                                
                                            </div> */}
                                                    {/* /.tab-pane */}
                                                </div>
                                            </>

                                        ) : (
                                            <>
                                                <p>Loading ...</p>
                                            </>
                                        )}

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </div>
            {/* /.content-wrapper */}



            {/* <Footer /> */}
        </>
    )
}

export default PengembangListLokasiPerorangan
