import React, { useState, useEffect } from 'react';
import axios from 'axios';

const LocationSelect = (props) => {

    const onVillageChange = props.onVillageChange;

    // const LocationSelect = ({ onVillageChange }) => {
    const [provinces, setProvinces] = useState([]);
    const [cities, setCities] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [villages, setVillages] = useState([]);
    const [selectedVillage, setSelectedVillage] = useState('');


    const [provSelectedOption, setProvSelectedOption] = useState(''); // Set the initial default value
    const [kotaSelectedOption, setKotaSelectedOption] = useState(''); // Set the initial default value
    const [kecSelectedOption, setKecSelectedOption] = useState(''); // Set the initial default value
    const [kelSelectedOption, setKelSelectedOption] = useState(''); // Set the initial default value

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    useEffect(() => {
        fetchProvinces();
    }, []);

    useEffect(() => {
        intialSelected();
    }, [props.villageCode]);


    const intialSelected = async () => {
        try {
            const hasil = props.villageCode;
            console.log(hasil);
            if (hasil) {

                setProvSelectedOption(hasil.substring(0, 2));

                fetchCities(hasil.substring(0, 2));
                setKotaSelectedOption(hasil.substring(0, 4));

                fetchDistricts(hasil.substring(0, 4));
                setKecSelectedOption(hasil.substring(0, 6));

                fetchVillages(hasil.substring(0, 6))
                setKelSelectedOption(hasil);

            }

        } catch (error) {
            console.error(error);
        }
    }

    const fetchProvinces = async () => {
        try {
            const response = await axios.get(apiUrl+'/api/public/getProvinsi');
            setProvinces(response.data);
        } catch (error) {
            console.error('Error fetching provinces:', error);
        }
    };

    const fetchCities = async (provinceId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKabKota/${provinceId}`);
            setCities(response.data);
        } catch (error) {
            console.error('Error fetching cities:', error);
        }
    };

    const fetchDistricts = async (cityId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKecamatan/${cityId}`);
            setDistricts(response.data);
        } catch (error) {
            console.error('Error fetching districts:', error);
        }
    };

    const fetchVillages = async (districtId) => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/getKelurahan/${districtId}`);
            setVillages(response.data);
        } catch (error) {
            console.error('Error fetching villages:', error);
        }
    };

    const handleProvinceChange = (e) => {
        const selectedProvinceId = e.target.value;

        fetchCities(selectedProvinceId);
        setDistricts([]);
        setVillages([]);
        setProvSelectedOption(selectedProvinceId);

    };

    const handleCityChange = (e) => {
        const selectedCityId = e.target.value;
        fetchDistricts(selectedCityId);
        setVillages([]);
        setKotaSelectedOption(selectedCityId);

    };

    const handleDistrictChange = (e) => {
        const selectedDistrictId = e.target.value;
        fetchVillages(selectedDistrictId);
        setKecSelectedOption(selectedDistrictId);
    };

    const handleVillageChange = (e) => {
        const selectedVillageId = e.target.value;
        setSelectedVillage(selectedVillageId);
        onVillageChange(selectedVillageId);
        setKelSelectedOption(selectedVillageId);
    };


    return (

        <div >
            {/* <label>Provinsi:</label> */}

            <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '20px' }}>

                <select onChange={handleProvinceChange} className="form-control " value={provSelectedOption}  >
                    <option>Pilih Provinsi</option>
                    {provinces.map((province) => (
                        <option key={province.kode} value={province.kode}>{province.nama_wilayah}</option>
                    ))}
                </select>

                {/* <label>Kota/Kabupaten:</label> */}
                <select onChange={handleCityChange} disabled={cities.length === 0} className="form-control" value={kotaSelectedOption} >
                    <option value="">Pilih Kota/Kabupaten</option>
                    {cities.map((city) => (
                        <option key={city.kode} value={city.kode}>{city.nama_wilayah}</option>
                    ))}
                </select>

                {/* <label>Kecamatan:</label> */}
                <select onChange={handleDistrictChange} disabled={districts.length === 0} className="form-control" value={kecSelectedOption}>
                    <option value="">Pilih Kecamatan</option>
                    {districts.map((district) => (
                        <option key={district.kode} value={district.kode}>{district.nama_wilayah.toUpperCase()}</option>
                    ))}
                </select>


                {/* <label>Kelurahan:</label> */}
                <select onChange={handleVillageChange} disabled={villages.length === 0} className="form-control" value={kelSelectedOption}>
                    <option value="">Pilih Kelurahan</option>
                    {villages.map((village) => (
                        <option key={village.kode} value={village.kode}>{village.nama_wilayah.toUpperCase()}</option>
                    ))}
                </select>

            </div>
        </div>
    );
};

export default LocationSelect;
