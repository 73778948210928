import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';


const ModalDataRumah = ({ onClose, namaPengembang, perumahanId, tipeRumahId }) => {

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);


    const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('');


    const handleClose = () => {
        onClose();
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });


    // const [namaPengembang, setNamaPengembang] = useState("PT. JAYA INDAH");
    const [typeRumah, setTypeRumah] = useState(30);
    // const [namaPerumahan, setNamaPerumahan] = useState("Dahlia Residence");
    const [hargaRumah, setHargaRumah] = useState("186.000.000,00-");

    const [lokasiProvinsi, setLokasiProvinsi] = useState("Provinsi");
    const [lokasiKotaKabupaten, setLokasiKotaKabupaten] = useState("Kota/Kabupaten");
    const [lokasiKecamatan, setLokasiKecamatan] = useState("Kecamatan");
    const [lokasiKelurahan, setLokasiKelurahan] = useState("Kelurahan");

    const [optionTipeRumah, setOptionTipeRumah] = useState([]);
    const [selectedOptionTipeRumah, setSelectedOptionTipeRumah] = useState('');

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [optionsPerumahan, setOptionsPerumahan] = useState([]);

    const [isTokenExpired, setIsTokenExpired] = useState(false);


    let refreshToken = null;

    const navigate = useNavigate();

    const { user, setUser } = useContext(AuthContext);





    useEffect(() => {

        (async () => {

            await fetchOptionsFromApiPerumahan()
                .then(data => setOptionsPerumahan(data))
                .catch(error => console.error('Error fetching options:', error));
            setSelectedOptionPerumahan(perumahanId);

            const response = await axios.get(`${apiUrl}/api/public/getTipeRumahById2/${tipeRumahId}`);
                        setHargaRumah(response.data.harga);

        })();

    }, []);


    useEffect(() => {

        (async () => {


            setOptionTipeRumah('');
            setHargaRumah(null);

            if (selectedOptionPerumahan) {
                await fetchOptionsFromApiTipeRumah()
                    .then(data => setOptionTipeRumah(data))
                    .catch(error => console.error('Error fetching options:', error));
                setSelectedOptionTipeRumah(tipeRumahId);

            }


        })();

    }, [selectedOptionPerumahan]);


    // useEffect(() => {
    //     (async () => {
    //         if (selectedOptionTipeRumah) {
    //             const response = axios.get(`${apiUrl}/api/public/getTipeRumahById2/${selectedOptionTipeRumah}`);
    //             setHargaRumah(response.data.harga);
    //         }
            
    //     })();

    // }, [selectedOptionTipeRumah]);

    const fetchOptionsFromApiPerumahan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        // var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

        var idPengembang = user.pengembang_id;

        try {
            const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };




    const fetchOptionsFromApiTipeRumah = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/tipeRumahByPerumahanId/${selectedOptionPerumahan}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const handleOptionChangePerumahan = event => {
        setSelectedOptionPerumahan(event.target.value);

    };


    const handleOptionChangeTipeRumah = async (event) => {
        setSelectedOptionTipeRumah(event.target.value);

        if (event.target.value) {
            try {
                const response = await axios.get(`${apiUrl}/api/public/getTipeRumahById2/${event.target.value}`);
                setHargaRumah(response.data.harga);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setHargaRumah(0)
        }
        
    };


    const handleSubmitDataRumah = async (e) => {
        e.preventDefault();
        // Perform submit actions here, such as sending data to backend
        // console.log("Form submitted");
        // alert('data rumah submit, perumahan_id = ' + selectedOptionPerumahan + ', type rumah id = ' + selectedOptionTipeRumah)


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        const data = {
            id: user.minat_id,
            perumahanId: selectedOptionPerumahan,
            tipeRumahId : selectedOptionTipeRumah
            
        };


        axios.post(apiUrl + '/api/igahp/pengembang/updateMinatUnit', data,
            {

                headers: {

                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json' // You can adjust this based on your API requirements
                }
            })
            .then(response => {
                console.log('API Response:', response.data);
                // Handle success response here

                alert('Update berhasil..');
                // fetchData();
            })
            .catch(error => {
                console.error('API Error:', error);
                alert('Update Gagal..');
                // Handle error here
            });

    };




    return (
        <>


            <div className="modal fade" id="ModalDataRumah" tabIndex={-1} role="dialog"
                aria-labelledby="ModalDataRumahLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">

                            <h5 className="modal-title">Update Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>


                        </div>
                        <div className="modal-body">
                            <div className="form-group">
                                <h6 className="m-0">Data Minat Rumah </h6>
                                <p className="text-muted mb-0"><small>Spesifikasi rumah yang diminati</small></p>
                            </div>
                            <form onSubmit={handleSubmitDataRumah}>

                                <div className="row">

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Pilih Perumahan</label>
                                            <select className="form-control" value={selectedOptionPerumahan} onChange={handleOptionChangePerumahan}>
                                                <option value="">Select an option</option>
                                                {optionsPerumahan.map(option2 => (
                                                    <option key={option2.id} value={option2.id}>
                                                        {option2.nama_perumahan}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="namaPengembang" className="text-gray-dark">Nama Pengembang</label>
                                            <input type="text" value={namaPengembang} className="form-control" id="namaPengembang" 
                                            placeholder="Masukan nama pengembang" 
                                            disabled
                                            />
                                        </div>
                                    </div>





                                </div>

                                <div className="row">


                                    {optionTipeRumah ? (
                                        <>

                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Tipe Rumah</label><br></br>
                                                    <select
                                                        className="form-control"
                                                        value={selectedOptionTipeRumah}
                                                        onChange={handleOptionChangeTipeRumah}
                                                        required
                                                    >
                                                        <option value="">Pilih Tipe</option>
                                                        {optionTipeRumah.map(option3 => (
                                                            <option key={option3.id} value={option3.id}>
                                                                {option3.nama}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>

                                        </>
                                    ) : (
                                        <>

                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label>Tipe Rumah</label><br></br>
                                                    <select
                                                        className="form-control"
                                                        value={selectedOptionTipeRumah}
                                                        onChange={handleOptionChangeTipeRumah}
                                                        required
                                                    >
                                                        <option value="">Pilih Tipe</option>

                                                    </select>
                                                </div>
                                            </div>

                                        </>
                                    )}




                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Harga Rumah</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    {/* <span className="input-group-text">Rp.</span> */}
                                                </div>

                                                <input
                                                    id="hargaRumah"
                                                    type="text"
                                                    placeholder="Rp XX.XXX.XXX.XXX,-"
                                                    value={formatter.format(hargaRumah)}
                                                    className="form-control"
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </div>


                                    {/* <div className="col-sm-6">
                                        <div className="form-group"/>
                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Harga Rumah</label>
                                        <input
                                            id="hargaRumah"
                                            type="text"
                                            placeholder="Rp XX.XXX.XXX.XXX,-"
                                            value={formatter.format(hargaRumah)}
                                            className="form-border-rad-12 one-green-m-regular neutral-90-text"
                                            disabled
                                        />
                                    </div> */}


                                </div>




                                <div className="modal-footer justify-content-end align-content-between">
                                    <button type="submit" className="btn btn-success">Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalDataRumah
