
import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import BrowserDetection from './BrowserDetection';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';
import PerumahanDetail from './PerumahanDetail';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom'; // assuming you're using React Router
import { coilState } from '../../coilState';
import { useRecoilState } from 'recoil';
import * as ReactDOMClient from "react-dom/client";
import Carousel from "react-simply-carousel";

// import Carousel from 'react-bootstrap/Carousel';

// import { MDBCarousel, MDBCarouselItem } from 'mdb-react-ui-kit';

const Mylanding = () => {

  const [coilData, setCoilData] = useRecoilState(coilState);
  const navigate = useNavigate();

  // const { user, setUser } = useContext(AuthContext);
  const [user, setUser] = useState({});


  const { loginContext } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const [dataSusun, setDataSusun] = useState([]);

  const [itemData, setItemData] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const [email, setEmail] = useState('');
  const [question, setQuestion] = useState('');



  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  const handleButtonClick = async () => {

    try {
      // Assuming you have an API endpoint and the appropriate method (POST, PUT, etc.)
      // const response = await fetch('http://localhost:8080/api/public/kirimEmailPertanyaan', {
      const response = await fetch('https://onegreen-be-cms.indonesiahijau.id/api/public/kirimEmailPertanyaan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          from: email,
          isi: question,
          subject: 'contact us'
        }),
      });

      // Handle the response accordingly (e.g., show success message, error handling)
      if (response.ok) {
        console.log('Request successful');
        alert('Pesan telah terkirim, harap tunggu balasan dari admin');
        // You can add further actions here upon successful API request
      } else {
        console.error('Error in API request');
        alert('Error in API request');
        // Handle error cases here
      }
    } catch (error) {
      console.error('Error:', error);
      alert('Error');
      // Handle other errors such as network issues
    } finally {

    }
  };



  useEffect(() => {


    if (isLoading || isTokenExpired) {
      fetchDataTapak();
      fetchDataRusun();
    }


  }, []);

  const fetchDataTapak = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanTapak', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setData(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };


  const fetchDataRusun = async () => {

    try {

      const response = await axios.get(apiUrl + '/api/public/getAllPerumahanSusun', {

      });

      // const response = await axios.get(apiUrl + '/api/public/getAllLokasi', {

      // });


      // await Delay(5000);

      // console.log(response.data);
      setDataSusun(response.data);

      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      // navigate('/login');
    } finally {
      setIsLoading(false)
    }
  };

  const goToPerumahanDetail = (id) => {

    user['perumahan_id'] = id;
    loginContext(user); // isi context

  };

  const [activeSlide, setActiveSlide] = useState(0);



  require("./../../css/common.css");



  return (

    <>
      <BrowserDetection />
      <div>


        <NavBarAtas page="0" showButton={true} />
        <section className="section-home-content">
          <div className="wrapper-home-content">
            <div className="igahp-hero-title-bold green-pressed-text">INDONESIA GREEN AFFORDABLE HOUSING PROGRAM</div>
          </div>
          <div className="wrapper-house-information">
            <div className="igahp-l-regular black-text">IGAHP Menyediakan perumahan terjangkau yang baik bagi lingkungan dan untuk mencapai target perubahan iklim dengan mengurangi emisi karbon.</div>
            <div className="wrapper-button-house-information">
              <div className="btn-green-solid-pressed igahp-m-medium">Lihat Panduan</div>
              <div className="btn-green-border-pressed igahp-m-medium">Baca Selengkapnya</div>
            </div>
          </div>
        </section>

        <section className="section-hero-image-landing">
          {/* <img src="image_hero_landing.png" /> */}
          <video autoPlay muted loop>
            <source src="/video-hero-image.mp4" type="video/mp4" />
          </video>
        </section>
        <section className="section-green-house-objective">
          <div className="igahp-h2-semi-bold black-text" id="green-house-objective-title">Rumah Hijau dirancang untuk mengurangi dampak negatif pada lingkungan dan meningkatkan kualitas hidup penghuninya.</div>
          <div className="igahp-l-regular black-text" id="green-house-objective-description">Merujuk laporan Climate Transparency 2021, <b>bangunan gedung di Indonesia mengeluarkan emisi karbon sebesar 4,6%</b>, emisi langsung yang berasal dari pembakaran alat penghangat, memasak, dan lain-lain serta 24,5% emisi tidak langsung dari aktivitas jaringan listrik untuk peralatan rumah tangga. Secara umum, Indonesia masih menjadi <b>salah satu negara penghasil emisi karbon terbesar dunia,</b> yakni menempati <b>peringkat ke-6 dengan total emisi sebesar 691,97 juta ton CO2.</b></div>
          <img src="/ic_tree.svg" id="green-house-objective-tree" />
        </section>
        <section className="wrapper-igahp-partner">
          <div className='igahp-h3-semi-bold black-text'>Bekerjasama dengan</div>
          <div className="igahp-partner">
             {/* <img src="logo_world_bank.png" height={50} /> */}
             <img src="/logo_pupr.png" height={50} />
            <img src="/logo_adb.png" height={50} />
            {/* <img src="logo_himbara.png" height={50}/> */}
          </div>
        </section>

        <section className="wrapper-green-house">
          <div className="wrapper-image-green-house">
            <img src="/image_green_house.png" id="image_green_house" alt />
          </div>
          <div className="wrapper-description-green-house">
            <div className="description-green-house-text">
              {/* <div className="igahp-h1-bold black-text" id="description-green-house-title">Apa itu Rumah Hijau</div> */}
              <p className="igahp-l-regular black-text" id="description-green-house-desc">Upaya pemerintah dalam implementasi penurunan Net Zero Carbon dengan menciptakan perumahan berlabel Green Housing melalui Program Indonesia Green Affordable Housing Program (IGAHP)</p>
              {/* <p className="igahp-m-regular neutral-90-text" id="description-green-house-desc">Rumah Hijau juga menekankan daya guna kembali sumber daya alam
                yang dapat diperbarui, mengelola dan
                memilah sampah rumah tangga secara mandiri, serta adanya ruang terbuka hijau di tempat tinggal.</p> */}
              {/* <div className="border-left-bottom-green" /> */}
              <div className="btn-green-solid-pressed igahp-m-medium">Lihat Katalog</div>
            </div>
            <div className="trees-illustration-image">
              <img src="/ic_tree.svg" alt />
            </div>
          </div>
        </section>

        {/* <section className="importance-green-house">
          <div className="importance-green-house-title">
            <div className="igahp-h3-semi-bold black-text" id="importance-green-house-text-title">Keunggulan Rumah Hijau</div>
            {/* <div className="selected-line">
              <div className="left-line" />
              <div className="mid-line" />
              <div className="right-line" />
            </div>
          </div>
          <div className="importance-green-house-description">
            <ul>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_material_ramah_lingkungan.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Material Ramah Lingkungan</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Material bangunan yang digunakan ramah lingkungan dan buatan lokal
                  </p>
                </div>
              </li>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_ruang_terbuka_hijau.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Ruang Terbuka Hijau</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Terdapat area hijau &gt;20% dari luas tanah sehingga dapat menyehatkan sirkulasi udara</p>
                </div>
              </li>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_rumah_sehat.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Rumah Sehat</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Terdapat sirkulasi udara yang baik, pencahayaan
                    matahari yang cukup dan menggunakan saniter hemat air</p>
                </div>
              </li>
              <li>
                <div className="importance-green-house-item">
                  <img src="ic_recycle.svg" alt width="48" height="48" />
                  <p className="igahp-m-medium black-text" id="importance-green-house-item-title">Gaya Hidup Ramah Lingkungan</p>
                  <p className="igahp-m-regular neutral-90-text" id="importance-green-house-item-description">Adanya pemilahan dan pengelolaan sampah dan
                    limbah rumah tangga yang baik</p>
                </div>
              </li>
            </ul>
          </div>
        </section> */}
        <section className="green-house-certificated">
          <div className="green-house-certificated-content">
            <div className="green-house-certificated-title">
              Minat Perumahan Bersertifikat Hijau?
            </div>
            <div className="igahp-l-regular black-text">Perumahan bersertifikasi hijau sesuai dengan Skema IGAHP</div>
            <button className="btn-green-solid-pressed" disabled>
              <div className="igahp-m-semi-bold white-text">Lihat lebih banyak</div>
              <img src="/ic_arrow_right_white.svg" alt="" />
            </button>
          </div>
          <div className="green-house-list">

            {(!isLoading) && data ? (

              <>

                {data.map((item, index) => (
                  <div key={item.id}>

                    <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>
                      <div className="list-grid-house"

                        style={{
                          background: "white",
                          border: "10px solid white"
                        }}

                      >
                        {/* <div className="wrapper-house-type">
                          <p className="igahp-xs-medium white-text">Rumah Tapak</p>
                        </div> */}
                        <div className="house-photo">
                          <img
                            src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                            alt="gambar lokasi"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            className="img-fluid w-60"
                          />
                        </div>
                        <div className="total-house">
                          <p className="subsidi-text">{item.totalUnitSubsidiHijau} Unit Subsidi Hijau</p>
                        </div>
                        <div className="wrapper-house-detail">
                          <p className="igahp-m-semi-bold black-text">{item.nama_perumahan}</p>
                          <p className="igahp-xs-regular neutral-80-text">{item.kecamatan}</p>
                        </div>
                        <div className="wrapper-developer">
                          <img src="/ic_logo_puri_mega_sarana_land.png" alt width={28} height={28} />
                          <p className="igahp-s-regular neutral-90-text">{item.namaPengembang}</p>
                        </div>
                        <div className="wrapper-house-id">
                          {item.idLokasiString}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}

              </>



            ) : (
              // Render alternative content if dataExist is false
              <div>Loading...</div>
            )}


          </div>
          {/* <div className='green-house-certificated-background'>
            <img src="bg_green_house_certificated.svg" id="bg-green-house-certificated" />
          </div>*/}
        </section>
        <section className="flat-house-certificated">
          <div className="green-house-list">

            {(!isLoading) && dataSusun ? (

              <>

                {dataSusun.map((item, index) => (
                  <div key={item.id}>

                    <Link to={`/perumahandetail`} onClick={() => goToPerumahanDetail(item.id)}>
                      <div className="list-grid-house"

                        style={{
                          background: "white",
                          border: "10px solid white"
                        }}

                      >
                        {/* <div className="wrapper-house-type">
                          <p className="igahp-xs-medium white-text">Rumah Tapak</p>
                        </div> */}
                        <div className="house-photo">
                          <img
                            src={`${apiUrl}/api/public/displayimage/${item.foto_tengah}`}
                            alt="gambar lokasi"
                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                            className="img-fluid w-60"
                          />
                        </div>
                        <div className="total-house">
                          <p className="subsidi-text">{item.totalUnitSubsidiHijau} Unit Subsidi Hijau</p>
                        </div>
                        <div className="wrapper-house-detail">
                          <p className="igahp-m-semi-bold black-text">{item.nama_perumahan}</p>
                          <p className="igahp-xs-regular neutral-80-text">{item.kecamatan}</p>
                        </div>
                        <div className="wrapper-developer">
                          <img src="/ic_logo_puri_mega_sarana_land.png" alt width={28} height={28} />
                          <p className="igahp-s-regular neutral-90-text">{item.namaPengembang}</p>
                        </div>
                        <div className="wrapper-house-id">
                          {item.idLokasiString}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}

              </>



            ) : (
              // Render alternative content if dataExist is false
              <div>Loading...</div>
            )}


          </div>
          <div className="green-house-certificated-content">
            <div className="green-house-certificated-title">
              Minat Rumah Susun Bersertifikat Hijau?
            </div>
            <div className="igahp-l-regular black-text">Rumah Susun bersertifikasi hijau sesuai dengan Skema IGAHP</div>
            <button className="btn-green-solid-pressed" disabled>
              <div className="igahp-m-semi-bold white-text">Lihat lebih banyak</div>
              <img src="/ic_arrow_right_white.svg" alt="" />
            </button>
          </div>
        </section>
        {/* <section className="green-house-objective">
          <img src="image_objective_new.svg" id="image_objective" alt />
        </section> */}
        <section className="wrapper-accepted-certificate">
          <div className="importance-green-house-title">
            <div className="igahp-h3-semi-bold black-text centered-align-text">Sertifikasi Rumah Hijau</div>
            {/* <div className="selected-line">
              <div className="left-line" />
              <div className="mid-line" />
              <div className="right-line" />
            </div> */}
          </div>
          <div className="list-accepted-certificate">
            <div className="accepted-certificate-item">
              <img src="/ic_bgh.png" height="80" />
              <p className="igahp-l-medium neutral-60-text centered-align-text">BGH Klas 1a</p>
            </div>
            <div className="accepted-certificate-item">
              <img src="/ic_edge.png" height="80" />
              <p className="igahp-l-medium neutral-60-text centered-align-text">Edge</p>
            </div>
            <div className="accepted-certificate-item">
              <img src="/ic_greenship.png" height="80" />
              <p className="igahp-l-medium neutral-60-text centered-align-text">Greenship</p>
            </div>
          </div>
        </section>
        {/* Section QR App Dihide Dulu
        <section className="mobile-app-qr">
          <div className="mockup-image-mobile">
            <img src="image_mockup_mobile.png" alt />
          </div>
          <div className="image-qr-download-mobile">
            <div className="image-qr-download-title">
              <div className="igahp-h1-bold black-text">DOWNLOAD APLIKASI ONE GREEN MOBILE</div>
              <div className="selected-line">
                <div className="left-line" />
                <div className="mid-line" />
                <div className="right-line" />
              </div>
            </div>
            <div className="image-qr-download-content">
              <div className="scan-qr-text">
                <p className="igahp-m-regular black-text">Silakan scan QR code dengan menggunakan <i>smartphone </i>
                  <b>Android</b> Anda untuk mendownload aplikasi <b>One Green</b> mobile.
                </p>
                <div className="wrapper-compatible-android">
                  <p className="igahp-m-regular neutral-60-text">Kompatibel pada operasi sistem :</p>
                  <img src="ic_android.svg" alt />
                </div>
              </div>
              <div className="image-qr-download">
                <img src="one_green_mobile_qr.png" alt />
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="wrapper-green-house-testimoni">
          <p className="igahp-h3-semi-bold black-text" id="green-house-testimoni-title">Kata Mereka Tentang Rumah Hijau</p>
          <div className="wrapper-testimoni-list">
            <div className="wrapper-testimoni-item">
              <div className="wrapper-user-testimoni">
                <img src="ic_user_avatar_pak_haryo.png" width="64" height="64" />
                <div className="wrapper-user-info">
                  <div className="igahp-subheading-semi-bold black-text">Haryo Beki Martoyoedo</div>
                  <div className="igahp-xs-regular neutral-60-text">Direktur PPP, DJPI - Kementerian PUPR</div>
                </div>
              </div>
              <div className="wrapper-testimoni-content">
                <img src="ic_double_quotes.svg" height="14" width="14" />
                <div className="igahp-m-regular neutral-90-text">Sekarang kita sudah masuk agenda global bahwa kita harus mengusung konsep <i>green</i>. Ini salah satu inisiasi pemerintah dan Kementerian PUPR untuk menyediakan rumah terjangkau dan ramah lingkungan.</div>
                <img src="ic_double_quotes.svg" height="14" width="14" />
              </div>
              {/* <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" />
            </div>
            <div className="wrapper-testimoni-item">
              <div className="wrapper-user-testimoni">
                <img src="ic_user_avatar_pak_cokro.png" width="64" height="64" />
                <div className="wrapper-user-info">
                  <div className="igahp-subheading-semi-bold black-text">Cokro Mardianto</div>
                  <div className="igahp-xs-regular neutral-60-text">Dirut PT Purimega Saranaland</div>
                </div>
              </div>
              <div className="wrapper-testimoni-content">
                <img src="ic_double_quotes.svg" height="14" width="14" />
                <div className="igahp-m-regular neutral-90-text">Kami bersyukur hingga akhirnya kita mendapat sertifikat Madya. Rumah subsidi hijau yang kita bangun ini memiliki kualitas yang berbeda dengan rumah subsidi lainnya.</div>
                <img src="ic_double_quotes.svg" height="14" width="14" />
              </div>
              {/* <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" />
            </div>
            <div className="wrapper-testimoni-item">
              <div className="wrapper-user-testimoni">
                <img src="ic_user_avatar_pak_putu.png" width="64" height="64" />
                <div className="wrapper-user-info">
                  <div className="igahp-subheading-semi-bold black-text">I Putu Bagiasna</div>
                  <div className="igahp-xs-regular neutral-60-text">Kepala Dinas DPKPP</div>
                </div>
              </div>
              <div className="wrapper-testimoni-content">
                <img src="ic_double_quotes.svg" height="14" width="14" />
                <div className="igahp-m-regular neutral-90-text">Kami berharap pembangunan perumahan subsidi tersebut dapat membantu mengurangi angka <i>backlog</i> rumah yang masih cukup tinggi di Kabupaten Kuningan.</div>
                <img src="ic_double_quotes.svg" height="14" width="14" />
              </div>
              {/* <img src="ic_green_leaf_testimoni.svg" id="green_leaf_testimoni" width="48" height="48" />
            </div>
          </div>
        </section> */}
        <section className="wrapper-contact-us">
          <div className="wrapper-contact-us-content">
            <div className="wrapper-contact-us-left">
              <p className="igahp-h1-medium white-text" id="contact-us-title">Hubungi kami untuk mengetahui IGAHP</p>
            </div>
            <div className="wrapper-contact-us-right">


              <div className="wrapper-contact-us-form-email">
                <img src="/ic_message_contact_us.svg" id="ic-message-contact-us" />
                <input
                  type="text"
                  placeholder="Masukkan email Anda"
                  className="form-rad-12 igahp-m-medium"
                  id="form-contact-us-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>


              <textarea
                placeholder="Masukkan pertanyaan"
                className="form-rad-12 igahp-m-medium"
                id="form-contact-us-question"
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
              />
              <button className="btn-green-solid-pressed" id="btn-send-contact-us" type="button" onClick={handleButtonClick} disabled>
                <img src="/ic_send.svg" alt="Send" />
                <div className="igahp-m-semi-bold white-text">Kirim Pertanyaan</div>
              </button>

            </div>
          </div>
          {/* <img src="bg_top_right_contact_us.svg" id="bg-top-right-contact-us" /> */}
          <img src="/ic_green_leaf_testimoni.svg" id="ic-bottom-left-contact-us" width="200" />
        </section>
        <FooterFront />
      </div>

    </>



  )
}

export default Mylanding
