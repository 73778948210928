import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import SearchBar from "../../items/SearchBar";
import FilterButton from "../../items/FilterButton";
import RumahCardItems from "../../items/RumahCardItems";
import PaginationItems from "../../items/PaginationItems";
//import "../../../style.css";

function RumahHijauPage() {
  require("../../../style.css");
  return (
    <>
      <Navbar />
      <SearchBar/>
      <FilterButton/>
      <RumahCardItems/>
      <RumahCardItems/>
      <RumahCardItems/>
      <RumahCardItems/>
      <PaginationItems/>
      <Footer />
    </>
  );
}

export default RumahHijauPage;
