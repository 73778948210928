import React, { useEffect } from 'react'
import { useState } from 'react';
import { useRef } from 'react';

const DigitalSiteplan = () => {

    // useEffect(() => {
    //     const script = document.createElement("script");
    //     script.src = "/js/mysiteplan.js";
    //     script.async = true;
    //     document.body.appendChild(script);

    //     return () => {
    //         script.remove();
    //     };
    // }, []);


    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    let openFrame = () => {
        let iframe = document.createElement("iframe");
        iframe.src = `${apiUrl}/web/rumahTapak`;
        iframe.frameBorder = "0";
        iframe.id = "iframe";
        iframe.style.position = "absolute";
        iframe.style.zIndex = "999";
        iframe.style.height = "100%";
        iframe.style.width = "100%";
        iframe.style.top = "0";
        iframe.style.backgroundColor = "white";
        iframe.style.border = "none";
        document.body.prepend(iframe);
        document.body.style.overflow = "hidden";
    };


    return (
        <></>
    )
}

export default DigitalSiteplan
