import React from 'react'
import { useEffect } from 'react';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import axios from 'axios';
// import KantorPemasaran from './childs/KantorPemasaran'
// import MenuPerumahanDetail from './MenuPerumahanDetail';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import MyTimeline from '../shared/MyTimeline';
// import ModalPengajuan from './childs/ModalPengajuan';
// import Timeline from 'react-timeline-horizontal';
// import 'react-timeline-horizontal/dist/style.css'; // Import the timeline styles
// import ModalProfile from './childs/ModalProfile';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import ModalInfoPenghuni from './modals/ModalInfoPenghuni';



const PenghuniProfile = () => {

    // const { id } = useParams();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const [dataProfile, setDataProfile] = useState({});
    const [dataFile, setDataFile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [dataStatus, setDataStatus] = useState();
    const [data, setData] = useState({});

    const [isModalOpen, setModalOpen] = useState(false);


    // Function to open the modal
    const handleModalOpen = () => {
        console.log('Modal Open')
        setModalOpen(true);
    };

    // Function to close the modal
    const handleModalClose = () => {
        console.log('Modal Close')
        setModalOpen(false);
        fetchData();

    };

    useEffect(() => {
        // const populateMap = async () => {
        //     const map = L.map('map');
        //     map.setView([51.505, -0.09], 13);

        //     L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //         maxZoom: 19,
        //         attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        //     }).addTo(map);

        //     const marker = L.marker([51.5, -0.09]).addTo(map);

        //     const circle = L.circle([51.5, -0.09], {
        //         color: 'red',
        //         fillColor: '#f03',
        //         fillOpacity: 0.5,
        //         radius: 500,
        //     }).addTo(map);

        //     const success = (pos) => {
        //         const lat = pos.coords.latitude;
        //         const lng = pos.coords.longitude;
        //         const accuracy = pos.coords.accuracy;

        //         const userMarker = L.marker([lat, lng]).addTo(map);
        //         const userCircle = L.circle([lat, lng], { radius: accuracy }).addTo(map);

        //         map.fitBounds(userCircle.getBounds());
        //     };

        //     const error = (err) => {
        //         if (err.code === 1) {
        //             alert('Please allow geolocation access');
        //         } else {
        //             alert('Cannot get current location');
        //         }
        //     };

        //     navigator.geolocation.watchPosition(success, error);
        // };

        // populateMap();

        // Cleanup function (optional)
        return () => {
            // Perform any cleanup here, e.g., remove event listeners or timers
        };
    }, []); // Empty dependency array ensures the useEffect runs only once on mount



    useEffect(() => {
        // console.log('masuk ke pengembang lokasi profile...')
        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            (async () => {
                await fetchData();
                // await fetchDataStatusPengajuan();

                setIsLoading(false);
                setIsTokenExpired(false);
                // await populateMap();
            })();
        }

    }, [isLoading, isTokenExpired]);


    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const populateMap = async () => {

        const map = L.map('map');
        map.setView([51.505, -0.09], 13);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);

        const marker = L.marker([51.5, -0.09]).addTo(map);

        const circle = L.circle([51.5, -0.09], {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: 500
        }).addTo(map);

        const success = (pos) => {
            const lat = pos.coords.latitude;
            const lng = pos.coords.longitude;
            const accuracy = pos.coords.accuracy;

            const userMarker = L.marker([lat, lng]).addTo(map);
            const userCircle = L.circle([lat, lng], { radius: accuracy }).addTo(map);

            map.fitBounds(userCircle.getBounds());
        };

        const error = (err) => {
            if (err.code === 1) {
                alert('Please allow geolocation access');
            } else {
                alert('Cannot get current location');
            }
        };

        navigator.geolocation.watchPosition(success, error);

    }

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            // console.log('user id ' + user.id);
            // console.log('pengembang id ' + user.pengembang_id);
            var pengembangId = '';
            const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + user.pengembang_id, {
                // const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + '1', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            // console.log(response.data);
            setData(response.data);
            // setNamaDirektur(response.data.namaDirektur);


            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };




    const handleNilaiBalikan = (nilaiBalikan) => {
        // setIsLoading(nilaiBalikan);
    };

    const [itemData, setItemData] = useState(null);


    const handleIsModal = (item, event) => {

        isModal ? setIsModal(false) : setIsModal(true);
        setItemData(dataProfile);

    }



    return (
        <>



            <>{data ? (

                <>
                    {/* Content Wrapper. Contains page content */}
                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-4 mt-2">
                                    <div className="col-sm-6">
                                        <h1 className="text-muted fw-light">INFO<b> PENGHUNI</b></h1>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Dashboard</li>
                                        </ol>
                                    </div>
                                    {/* /.content-card-body */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        {/* /.content-header */}
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-sm-12 col-lg-8">
                                        <div className="card mb-4">
                                            <div className="card-header align-items-center border-0 mt-1 mr-2">
                                                <div className="card-title">
                                                    <h6 className="m-0">Info Penghuni Rumah</h6>
                                                    <p className="text-muted mb-0"><small>Isi Data Penghuni Rumah</small></p>
                                                </div>

                                                <div className="card-tools">
                                                    <button type="button" id="tModal"
                                                        data-toggle="modal"
                                                        data-target="#ModalInfoPenghuni"
                                                        className="btn btn-success"
                                                        onClick={handleModalOpen}
                                                    >Update Data
                                                    </button>

                                                    {isModalOpen && <ModalInfoPenghuni onClose={handleModalClose} />}
                                                </div>


                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="table-responsive">
                                                            <table className="table border-bottom border-right border-left">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Nama</td>
                                                                        <td>:</td>
                                                                        <td>{data.pengembang_nama}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Usia (Tahun)</td>
                                                                        <td>:</td>
                                                                        <td>{data.umur}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Jenis Kelamin</td>
                                                                        <td>:</td>
                                                                        <td>{data.jk}</td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="table-responsive">
                                                            <table className="table border-bottom border-right border-left">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>NIK</td>
                                                                        <td>:</td>
                                                                        <td>{data.nik}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>NO. KK</td>
                                                                        <td>:</td>
                                                                        <td>{data.kk}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Pekerjaan</td>
                                                                        <td>:</td>
                                                                        <td>{data.namaPekerjaan}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="m-0">Pemilik Tanah </h6>
                                                            <p className="text-muted mb-0"><small>Isi Data Pemilik Tanah</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <table className="table border-bottom border-right border-left">
                                                        <tbody>
                                                            <tr>
                                                                <td>Nama Lengkap</td>
                                                                <td>:</td>
                                                                <td>{data.namaPemilik}</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Status Pemilikan Tanah</td>
                                                                <td>:</td>
                                                                <td>{data.statusPemilikTanah}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <br />
                                                <div className="d-flex justify-content-between align-items-center mb-3">
                                                    <div className="d-flex align-items-center">
                                                        <div className="d-flex flex-column">
                                                            <h6 className="m-0">Lokasi</h6>
                                                            <p className="text-muted mb-0"><small>Lokasi Rumah</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="table-responsive">
                                                            <table className="table border-bottom border-right border-left">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Provinsi</td>
                                                                        <td>:</td>
                                                                        <td>{data.provinsi}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Kab/Kota</td>
                                                                        <td>:</td>
                                                                        <td>{data.kabupaten}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="table-responsive">
                                                            <table className="table border-bottom border-right border-left">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>Kecamatan</td>
                                                                        <td>:</td>
                                                                        <td>{data.kecamatan}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>Kelurahan</td>
                                                                        <td>:</td>
                                                                        <td>{data.kelurahan}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <h6 className="m-0">Dokumen</h6>
                                                <p className="text-muted mb-0"><small>Kelengkapan Dokumen Perizinan</small></p>
                                            </div>
                                            <div className="card-body">
                                                <ul className="mailbox-attachments">
                                                    <li>
                                                        <span className="mailbox-attachment-icon"><i className="far fa-file-pdf" /></span>
                                                        <div className="mailbox-attachment-info">
                                                            <a href={`${apiUrl}/api/public/displaypdf/${data.doc1}`} target="_blank"
                                                                className="mailbox-attachment-name" >
                                                                <i className="fas fa-paperclip" />
                                                                Doc1.pdf</a>
                                                            <span className="mailbox-attachment-size clearfix mt-1">
                                                                <span>1,245 KB</span>
                                                                <a href={`${apiUrl}/api/public/displaypdf/${data.doc1}`} target="_blank" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
                                                            </span>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <span className="mailbox-attachment-icon"><i className="far fa-file-word" /></span>
                                                        <div className="mailbox-attachment-info">
                                                            <a href={`${apiUrl}/api/public/displaypdf/${data.doc2}`} target="_blank" className="mailbox-attachment-name" ><i className="fas fa-paperclip" />
                                                                Doc2.pdf</a>
                                                            <span className="mailbox-attachment-size clearfix mt-1">
                                                                <span>1,245 KB</span>
                                                                <a href={`${apiUrl}/api/public/displaypdf/${data.doc2}`} target="_blank" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
                                                            </span>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card mb-4">
                                            <div className="card-header">
                                                <h6 className="m-0">Alamat</h6>
                                                <p className="text-muted mb-0"><small>Alamat Lengkap </small></p>
                                            </div>
                                            <div className="card-body">
                                                <div className="d-flex justify-content-start align-items-center mb-4">
                                                    <span className="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center"><i className="fas fa-map-marker-alt mr-2" /></span>
                                                    <h6 className="text-body text-nowrap mb-0">{data.alamat}</h6>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    {/* <h6>Contact info</h6> */}
                                                </div>
                                                <p className=" mb-1">Kode Pos : {data.kodePos}</p>
                                                {/* <p class=" mb-0">Mobile: +1 (609) 972-22-22</p> */}
                                            </div>
                                        </div>
                                        {/* <div className="card mb-4">
                                            <div className="card-header">
                                                <h6 className="m-0">Maps</h6>
                                                <p className="text-muted mb-0"><small>Titik Koordinat Rumah</small></p>
                                            </div>
                                            <div className="card-body">
                                                <div className="card mb-4">
                                                    <div id="map" style={{ height: 180 }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </section>
                        {/* /.content */}



                    </div>
                    {/* /.content-wrapper */}
                </>

            ) : (

                <>
                    <p>Loading ...</p>
                </>
            )}


            </>



        </>
    )
}

export default PenghuniProfile
