import { Link,useNavigate } from 'react-router-dom';



const Delay = (ms) => new Promise (
    (resolved) => setTimeout(resolved,ms,true)
)

const apiUrl = process.env.REACT_APP_API_ENDPOINT;

export const LoginToServer = async (username, password, csrfToken) => {
    // await Delay(300);
    // if (username==="admin") {
    //     return { username : "emunajat", role : "admin", nik : "1234567890123456"}
    // } else {
    //     return { username : "emunajat", role : "asesi" , nik : "1234567890123456"}
    // }
     
    
    // const aa = 'http://localhost:8080/api/auth/signin';
    
      try {
      
        const response = await fetch(apiUrl + '/api/auth/signin', {
          method: 'POST',
          credentials : 'include', //getcookies
          headers: {
            'Content-Type': 'application/json',
            'X-XSRF-TOKEN': csrfToken                        
          },
          body: JSON.stringify({ username , password }),
        });
    
        if (response.ok) {
          // Successful login
          const data = await response.json();
          // Perform necessary actions after successful login
          data.csrfToken = csrfToken;

          console.log('Api respon login :', data);

          // if (data.status==='0') {
          //     alert('Status user anda masih non aktif, segera aktifkan..')
          //     return;
          // }
          return data;
        } else {
          // Login failed
          const error = await response.json();
          // console.error('Login error:', error);
          // console.log('gagal login dalam if else..')
          // console.log(csrfToken)
          return error.message;
        }
      } catch (error) {
        console.error('Login error dalam catch error :', error);
      }
      
}