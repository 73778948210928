import React from 'react'
import Footer from '../Footer'
import { useEffect, useState, useRef } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ChartComponentApi from '../igahp_tabgh/graph/ChartComponentApi';
import ChartComponent from '../igahp_tabgh/graph/ChartComponentMultiProduk';
import ChartComponentMultiProdukApi from '../igahp_tabgh/graph/ChartComponentMultiProdukApi';
import ChartComponentPie2Produk from '../igahp_tabgh/graph/ChartComponentPie2Produk';

import Chart from 'chart.js/auto';

const PengembangAdminDashboardPerorangan = () => {

    const chartRef = useRef(null);

    const chartRefPie = useRef(null);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const { user, setUser } = useContext(AuthContext);

    const [optionsPerumahan, setOptionsPerumahan] = useState([]);

    const [isTokenExpired, setIsTokenExpired] = useState(false);

    // const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('-');

    const [chartData, setChartData] = useState(null);
    const [pieData, setPieData] = useState(null);

    const [dashData, setDashData] = useState(null);

    const [bankData, setBankData] = useState(null);

    const navigate = useNavigate()

    let refreshToken = null;

    const [totalBankPengembang, setTotalBankPengembang] = useState(0);

    const [perumahanId, setPerumahanId] = useState('-');
    const [tahun, setTahun] = useState(new Date().getFullYear());;


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/aktifmenu.js";
        script.async = true;
        document.body.appendChild(script);

        // const script2 = document.createElement('script');
        // script2.src = "/js/content.js";
        // script2.async = true;
        // document.body.appendChild(script2);


        // fetchOptionsFromApiPerumahan()
        //     .then(data => setOptionsPerumahan(data))
        //     .catch(error => console.error('Error fetching options:', error));

        // fetchDataChart();
        // fetchDataDashboard();
        // fetchDataPie();

        return () => {

            script.remove();
            // script2.remove();
        }
    }, []);

    const [myChart, setMyChart] = useState(null);

    const [myPieChart, setMyPieChart] = useState(null);

    useEffect(() => {


        // ------------------------------------------------------------- line chart ----------------------------------------------------------------
        const ctx = chartRef.current.getContext('2d');
        if (myChart) {
            myChart.destroy();
        }
        var salesGraphChartOptions = {
            maintainAspectRatio: false,
            responsive: true,
            legend: {
                display: false
            },
            scales: {
                // x: {
                //     gridLines: {
                //         display: false,
                //         color: '#efefef',
                //         drawBorder: false
                //     }
                // },
                y: {
                    // ticks: {
                    //     stepSize: 1,
                    //     fontColor: '#efefef',
                    //     callback: function (value, index, values) {
                    //         if (Math.floor(value) === value) {
                    //             return value; // Return the integer if it's already an integer
                    //         }
                    //     }
                    // },
                    ticks: {
                        precision: 0,
                    },
                    // gridLines: {
                    //     display: true,
                    //     color: '#efefef',
                    //     drawBorder: false
                    // }
                }
            }
        }
        const newChart = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: salesGraphChartOptions
        });
        setMyChart(newChart);



        return () => {
            // Cleanup function to remove the script or other resources
            // script.remove();

            // Destroy the chart when the component unmounts
            if (myChart) {
                myChart.destroy();
            }

        }
    }, [chartData]); // Include chartData in the dependency array if it's dynamic


    useEffect(() => {

        // ------------------------------------------------------ pie chart ----------------------------------------------------------------
        const ctx2 = chartRefPie.current.getContext('2d');
        if (myPieChart) {
            myPieChart.destroy();
        }
        var pieOptions = {
            maintainAspectRatio: false,
            responsive: true,
        }

        const newChart = new Chart(ctx2, {
            type: 'pie',
            data: pieData,
            options: pieOptions
        });
        setMyPieChart(newChart);

        return () => {

            if (myPieChart) {
                myPieChart.destroy();
            }
        }
    }, [pieData]); // Include chartData in the dependency array if it's dynamic


    const fetchDataChart = async () => {


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }

        try {
            // const token = 'YOUR_ACCESS_TOKEN'; // Replace 'YOUR_ACCESS_TOKEN' with your actual access token
            const response = await fetch(apiUrl + '/api/igahp/dashboardPengembangGraphTargetRealisasi/' + user.pengembang_id + '/' +
                perumahanId + '/' + tahun, {

                headers: {

                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json' // You can adjust this based on your API requirements
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                setChartData(data);
                return data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }


    };


    const fetchDataPie = async () => {


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }

        try {
            // const token = 'YOUR_ACCESS_TOKEN'; // Replace 'YOUR_ACCESS_TOKEN' with your actual access token
            const response = await fetch(apiUrl + '/api/igahp/piedata/' + user.pengembang_id + '/' +
                perumahanId + '/' + tahun, {
                headers: {

                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json' // You can adjust this based on your API requirements
                }
            });

            if (response.ok) {
                const data = await response.json();
                // console.log(data);
                setPieData(data);
                return data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }


    };



    const fetchDataDashboard = async () => {


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/getAllBanksByPengembangId/' + user.pengembang_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            // console.log('..data banks....')
            // console.log(response.data);
            setBankData(response.data);

            if (Array.isArray(response.data)) {
                const totalRows = response.data.length;
                setTotalBankPengembang(totalRows);
                // console.log('Total rows:', totalRows);
                // setBankData(response.data);
            } else {
                console.error('API response is not an array');
            }

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }



        try {
            // const token = 'YOUR_ACCESS_TOKEN'; // Replace 'YOUR_ACCESS_TOKEN' with your actual access token
            const response = await fetch(apiUrl + '/api/igahp/dashboardPengembang/' + user.pengembang_id + '/'
                + perumahanId + '/' + tahun, {
                headers: {

                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json' // You can adjust this based on your API requirements
                }
            });

            if (response.ok) {
                const data = await response.json();
                // console.log(data);
                setDashData(data);
                return data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }




    };


    useEffect(() => {
        // fetchDataDashboard();
        // fetchDataPie();
        // fetchDataChart();
    }, [tahun, perumahanId]); // useEffect will only run if selectedValue changes




    const handleOptionChangePerumahan = event => {
        setPerumahanId(event.target.value);
    };


    const handleTahun = event => {
        setTahun(event.target.value);
    }

    const fetchOptionsFromApiPerumahan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        // var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

        var idPengembang = user.pengembang_id;

        try {
            const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            console.log(response.data)
            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };



    return (
        <>

            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2 mt-2">
                            <div className="col-sm-6">
                                <h2 className="text-muted fw-light">DASHBOARD <b>PENGEMBANG PERORANGAN</b></h2>
                            </div>

                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                            {/* /.content-card-body */}
                            {/* /.content-card-body */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-outline card-success">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="box box-primary">
                                                    <div className="box-body">
                                                        <div className="row">
                                                            <div className="col-sm-3">

                                                                {/* <div className="form-group">
                                                                    <label>Nama Perumahan </label>
                                                                    <select className="custom-select form-control" id="selectTahun">
                                                                        <option selected>Pilih</option>
                                                                        <option>Permata Indah Permai</option>
                                                                        Karunia Indah Permai
                                                                        <option>Harapan Indah Permai</option>
                                                                        <option>Green Indah Permai</option>
                                                                    </select>                                                                    
                                                                </div> */}


                                                                <div className="form-group">
                                                                    <div className="mb-3">
                                                                        <label>Pilih Perumahan</label>
                                                                        <select className="form-control" value={perumahanId} onChange={handleOptionChangePerumahan}>
                                                                            <option value='-' >Pilih</option>
                                                                            {optionsPerumahan.map(option2 => (
                                                                                <option key={option2.id} value={option2.id}>
                                                                                    {option2.nama_perumahan}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div className="col-sm-3">
                                                                <div className="form-group">
                                                                    <label>Pilih Tahun</label>
                                                                    <div className="col-sm-0">
                                                                        <div className="form-group">
                                                                            <select className="custom-select form-control" id="selectTahun"
                                                                                value={tahun}
                                                                                onChange={handleTahun}>

                                                                                {/* <option>Pilih</option>
                                                                                <option selected>2024</option>
                                                                                <option>2023</option>
                                                                                <option>2022</option>
                                                                                <option>2021</option>
                                                                                <option>2020</option> */}

                                                                                <option value="-" disabled>Pilih</option>
                                                                                <option value={new Date().getFullYear()}> {new Date().getFullYear()} </option>
                                                                                <option value={new Date().getFullYear() - 1}> {new Date().getFullYear() - 1} </option>
                                                                                <option value={new Date().getFullYear() - 2}> {new Date().getFullYear() - 2} </option>
                                                                                <option value={new Date().getFullYear() - 3}> {new Date().getFullYear() - 3} </option>


                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <br />
                <div className="card-header ui-sortable-handle" style={{ cursor: 'move' }}>
                    <div className="card-title">
                        <h4 className="text-muted fw-light">Data <b>Supply</b></h4>
                    </div>
                    <div className="card-tools">
                        <ul className="nav nav-pills ml-auto">
                            <li className="nav-item"><a className="nav-link active btn-default" href="#reguler-konven" data-toggle="tab">Subsidi
                                Hijau</a></li>
                            <li className="nav-item"><a className="nav-link btn-default" href="#reguler-syariah" data-toggle="tab">
                                Komersil Hijau</a></li>
                        </ul>
                    </div>
                </div>
                <div className="card-body">
                    <div className="tab-content">
                        <div className="active tab-pane" id="reguler-konven">
                            {/* row card baris 1 */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Rencana Pembangunan</p>
                                            {/* <h1 className="text"><b>{dashData.data.supplyTotalDiajukan}</b></h1> */}

                                            {dashData ? (
                                                <h1 className="text"><b>{dashData.data.supplyTotalDiajukan}</b></h1>
                                            ) : (
                                                <p>Loading...</p>
                                            )}

                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Tahap Pembangunan</p>
                                            {/* <h1 className="text"><b>80</b></h1> */}
                                            {dashData ? (
                                                <h1 className="text"><b>{dashData.data.supplyTahapPembangunan}</b></h1>
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Sisa Pembangunan</p>
                                            {/* <h1 className="text"><b>20</b></h1> */}
                                            {dashData ? (
                                                <h1 className="text"><b>{dashData.data.supplyBelumDiajukan}</b></h1>
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Rumah Subsidi Hijau</p>
                                            {/* <h1 className="text"><b>10</b></h1> */}
                                            {dashData ? (
                                                <h1 className="text"><b>{dashData.data.supplyRumahSubidiHijau}</b></h1>
                                            ) : (
                                                <p>Loading...</p>
                                            )}
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>
                            {/* /.post statistik */}
                            <div className="row">

                                <section className="col-lg-6 connectedSortable">
                                    <div className="card card-success card-outline">

                                        <div className="card-header">
                                            <h3 className="card-title">Monitoring Pembangunan</h3>
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fas fa-minus" />
                                                </button>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="tab-content p-0">
                                                <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 300 }}>


                                                    {/* <div className="chart tab-pane" id="sales-chart" style={{ position: 'relative', height: 300 }}> */}
                                                    <canvas ref={chartRef} id="sales-chart-canvas" height={300} style={{ height: 300 }} />
                                                    {/* </div> */}


                                                    {/* {(chartData && chartRef) ? (
                                                         <ChartComponentMultiProdukApi tipeChart='line' data={chartData} />                                                        
                                                    ) : (
                                                        <p>Loading...</p>
                                                    )} */}


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className="col-lg-6 connectedSortable">
                                    <div className="card card-success card-outline">

                                        <div className="card-header">
                                            <h3 className="card-title">Progres Pembangunan</h3>
                                            <div className="card-tools">
                                                <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                    <i className="fas fa-minus" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="card-body">


                                            <div className="tab-content p-0">
                                                {/* Morris chart - Sales */}
                                                <div className="chart tab-pane active" id="revenue-chart" style={{ position: 'relative', height: 300 }}>

                                                    <canvas ref={chartRefPie} id="sales-chart-canvas" height={300} style={{ height: 300 }} />

                                                    {/* {pieData ? (                                                        
                                                        <ChartComponentPie2Produk tipeChart='pie' data={pieData} />
                                                    ) : (
                                                        <p>Loading...</p>
                                                    )} */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                                {/* Main content */}
                            </div>
                            <br />
                            <div className="row mb-2 mt-1">
                                <div className="col-sm-6">
                                    <h4 className="text-muted fw-light">Data <b>Demand</b></h4>
                                </div>

                                {/* /.content-card-body */}
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Jumlah Peminat</p>
                                            <h1 className="text"><b>0</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Jumlah Terkonfirmasi</p>
                                            <h1 className="text"><b>0</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>
                            <br />
                            <div className="row mb-2 mt-1">
                                <div className="col-sm-6">
                                    <h4 className="text-muted fw-light">Data <b>Transaksi</b></h4>
                                </div>

                                {/* /.content-card-body */}
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Proses Pengajuan KPR</p>
                                            <h1 className="text"><b>0</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Proses Akad Kredit</p>
                                            <h1 className="text"><b>0</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Serah Terima Unit</p>
                                            <h1 className="text"><b>0</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row mb-2 mt-1">
                                <div className="col-sm-6">
                                    <h4 className="text-muted fw-light">Data <b>Bank Penyalur</b></h4>
                                </div>

                                {/* /.content-card-body */}
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-success">
                                        <div className="inner">
                                            <p>Total Bank</p>
                                            <h1 className="text"><b>{totalBankPengembang}</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Bank Penyalur</h3>
                                        </div>
                                        <div className="card-body p-0">
                                            {/* <div className="table-responsive"> */}


                                                {bankData ? (
                                                    <>
                                                        <table id="example1" className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    {/* <th>ID</th> */}
                                                                    <th></th>
                                                                    {/* <th>KODE</th> */}
                                                                    <th>NAMA</th>
                                                                <th style={{ textAlign: 'right' }}>JUMLAH DEBITUR</th>

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {bankData.map((item) => (
                                                                    <tr key={item.id}>
                                                                        {/* <td>{item.id}</td> */}
                                                                        <td>
                                                                            <img
                                                                                src={`${apiUrl}/api/public/displayimage/${item.logo}`}
                                                                                alt="gambar lokasi"
                                                                                style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }}
                                                                                className="img-fluid"
                                                                            />
                                                                        </td>

                                                                        {/* <td>{item.kodeBank}</td> */}
                                                                        <td>{item.namaBank}</td>
                                                                        {/* <td>0</td> */}
                                                                        <td style={{ textAlign: 'right' }}>0</td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </>
                                                    ) : (
                                                        <p>Loading...</p>
                                                    )
                                                }


                                            {/* </div> */}
                                        </div>
                                        {/* <div className="card-footer clearfix">
                                            <p>Total</p> <div>0</div>
                                            <a href="javascript:void(0)" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div> */}

                                        <div className="card-footer clearfix">
                                            <p style={{ float: 'left' }}>Total Debitur</p>
                                            <div style={{ float: 'right' }}>0</div>
                                        </div>


                                    </div>
                                </div>
                                {/* ./col */}
                            </div>



                            {/* /.content-header */}
                        </div>
                        {/* /.tab-pane */}
                        <div className="tab-pane" id="reguler-syariah">
                            {/* row card baris 2 */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Rencana Pembangunan</p>
                                            <h1 className="text"><b>150</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Tahap Pembangunan</p>
                                            <h1 className="text"><b>40</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Sisa Belum Diajukan</p>
                                            <h1 className="text"><b>40</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Rumah Subsidi Hijau</p>
                                            <h1 className="text"><b>70</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>
                            {/* /.post statistik */}
                            <div className="row">
                                {/* Left col */}
                                <section className="col-lg-6 connectedSortable">
                                    {/* Custom tabs (Charts with tabs)*/}
                                    <div className="card card-info card-outline">
                                        <div className="card-header border-0">
                                            <h3 className="card-title">
                                                Monitoring
                                            </h3>
                                        </div>
                                        <div className="card-body">
                                            <canvas className="chart" id="line-chart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                        </div>
                                        {/* /.d-flex */}
                                    </div>
                                </section>
                                <section className="col-lg-6 connectedSortable">
                                    {/* Custom tabs (Charts with tabs)*/}
                                    <div className="card card-info card-outline">
                                        <div className="card-header border-0">
                                            <h3 className="card-title">
                                                Perbandingan</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="chartjs-size-monitor">
                                                <div className="chartjs-size-monitor-expand">
                                                    <div className />
                                                </div>
                                                <div className="chartjs-size-monitor-shrink">
                                                    <div className />
                                                </div>
                                            </div>
                                            <canvas id="pieChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%', display: 'block', width: 388 }} width={388} height={250} className="chartjs-render-monitor" />
                                        </div>
                                        {/* /.d-flex */}
                                    </div>
                                </section>
                                {/* Main content */}
                            </div>
                            <br />
                            <div className="row mb-2 mt-1">
                                <div className="col-sm-6">
                                    <h4 className="text-muted fw-light">Data <b>Demand</b></h4>
                                </div>

                                {/* /.content-card-body */}
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Total Calon Debitur</p>
                                            <h1 className="text"><b>200</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Total Debitur</p>
                                            <h1 className="text"><b>80</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>
                            <br />
                            <div className="row mb-2 mt-1">
                                <div className="col-sm-6">
                                    <h4 className="text-muted fw-light">Data  <b>Transaksi</b></h4>
                                </div>{/* /.col */}
                                {/* /.content-card-body */}
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Proses Pengajuan KPR</p>
                                            <h1 className="text"><b>20</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Proses Akad Kredit</p>
                                            <h1 className="text"><b>10</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Serah Terima</p>
                                            <h1 className="text"><b>200</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="row mb-2 mt-1">
                                <div className="col-sm-6">
                                    <h4 className="text-muted fw-light">Data  <b>Bank</b></h4>
                                </div>{/* /.col */}
                                {/* /.content-card-body */}
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                            <div className="row">
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="small-box bg-info">
                                        <div className="inner">
                                            <p>Total Bank</p>
                                            <h1 className="text"><b>10</b></h1>
                                        </div>
                                        <div className="icon">
                                            <i className="fas fa-users" />
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                                <div className="col-md">
                                    {/* small box */}
                                    <div className="card">
                                        <div className="card-header border-transparent">
                                            <h3 className="card-title">Bank Penyalur</h3>
                                        </div>
                                        <div className="card-body p-0">
                                            <div className="table-responsive">
                                                <table className="table m-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Logo</th>
                                                            <th>Nama</th>
                                                            <th>Popularity</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="product-img">
                                                                    <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                                                                </div>
                                                            </td>
                                                            <td>Bank BTN</td>
                                                            <td>
                                                                <div className="sparkbar" data-color="#00a65a" data-height={20}>4</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="product-img">
                                                                    <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                                                                </div>
                                                            </td>
                                                            <td>Bank BRI</td>
                                                            <td>
                                                                <div className="sparkbar" data-color="#00a65a" data-height={20}>6</div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="product-img">
                                                                    <img src="dist/img/default-150x150.png" alt="Product Image" className="img-size-50" />
                                                                </div>
                                                            </td>
                                                            <td>Bank BTN</td>
                                                            <td>
                                                                <div className="sparkbar" data-color="#00a65a" data-height={20}>7</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <div className="card-footer clearfix">
                                            <a href="javascript:void(0)" className="btn btn-sm btn-secondary float-right">View All</a>
                                        </div>
                                    </div>
                                </div>
                                {/* ./col */}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {/* <div class="card-header">
    <h3 class="card-title">TABEL REALISASI</h3>
  </div> */}
                            {/* /card */}
                            {/* /.card-header */}
                            <div className="card-body">
                                <div className="tab-content">
                                    <div className="active tab-pane" id="reguler-konven">
                                        {/* row card baris 1 */}
                                        {/* /.post */}
                                    </div>
                                    {/* /.tab-pane */}
                                    <div className="tab-pane" id="reguler-syariah">
                                        {/* The timeline */}
                                        {/* <div class="timeline timeline-inverse"> */}
                                        {/* timeline time label */}
                                        {/* <div class="time-label">
            <span class="bg-danger">
              10 Feb. 2014
            </span>
          </div> */}
                                        {/* row card baris 3 */}
                                        <div className="row">
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>10.000</h2>
                                                        <p>Data Awal</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-users" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>7.000</h2>
                                                        <p>Data Diajukan</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-users" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>3.000</h2>
                                                        <p>Data Realisasi</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-users" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>4.000</h2>
                                                        <p>Data Retur</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-users" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>100</h2>
                                                        <p>Outstanding</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-users" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                        </div>
                                        {/* /.post */}
                                        {/* row card baris 4 */}
                                        <div className="row">
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>Rp 10.000</h2>
                                                        <p>Jumlah Rupiah Awal</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-donate" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>Rp 750.000</h2>
                                                        <p>Jumlah Rupiah, Diajukan</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-donate" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>RP 300.000</h2>
                                                        <p>Jumlah Rupiah Realisasi</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-donate" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>RP 450.000</h2>
                                                        <p>Jumlah Rupiah Retur</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-donate" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                            <div className="col-md">
                                                {/* small box */}
                                                <div className="small-box bg-info">
                                                    <div className="inner">
                                                        <h2>RP 100.000</h2>
                                                        <p>Jumlah Rupiah Oustanding</p>
                                                    </div>
                                                    <div className="icon">
                                                        <i className="fas fa-donate" />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* ./col */}
                                        </div>
                                        {/* /.post */}
                                        {/* </div> */}
                                    </div>
                                    {/* /.tab-pane */}
                                    <div className="tab-pane" id="settings">
                                        <form className="form-horizontal">
                                            <div className="form-group row">
                                                <label htmlFor="inputName" className="col-sm-2 col-form-label">Name</label>
                                                <div className="col-sm-10">
                                                    <input type="email" className="form-control" id="inputName" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail" className="col-sm-2 col-form-label">Email</label>
                                                <div className="col-sm-10">
                                                    <input type="email" className="form-control" id="inputEmail" placeholder="Email" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputName2" className="col-sm-2 col-form-label">Name</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="inputName2" placeholder="Name" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputExperience" className="col-sm-2 col-form-label">Experience</label>
                                                <div className="col-sm-10">
                                                    <textarea className="form-control" id="inputExperience" placeholder="Experience" defaultValue={""} />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputSkills" className="col-sm-2 col-form-label">Skills</label>
                                                <div className="col-sm-10">
                                                    <input type="text" className="form-control" id="inputSkills" placeholder="Skills" />
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="offset-sm-2 col-sm-10">
                                                    <div className="checkbox">
                                                        <label>
                                                            <input type="checkbox" /> I agree to the <a href="#">terms and conditions</a>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <div className="offset-sm-2 col-sm-10">
                                                    <button type="submit" className="btn btn-danger">Submit</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /.tab-pane */}
                                </div>

                            </div>
                        </div>

                    </div>
                    {/* /.col */}
                </div>
                {/* /.row */}

            </div>


        </>
    )
}

export default PengembangAdminDashboardPerorangan
