import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import axios from 'axios';
import KantorPemasaran from './childs/KantorPemasaran'
import MenuPerumahanDetail from './MenuPerumahanDetail';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import MyTimeline from '../shared/MyTimeline';
import ModalPengajuan from './childs/ModalPengajuan';
// import Timeline from 'react-timeline-horizontal';
// import 'react-timeline-horizontal/dist/style.css'; // Import the timeline styles
import ModalProfile from './childs/ModalProfile';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { OnegreenContext } from '../../OnegreenContext';



const PengembangProfileNew = () => {

    // const { id } = useParams();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const [dataProfile, setDataProfile] = useState({});
    const [dataFile, setDataFile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const [dataStatus, setDataStatus] = useState();
    const [data, setData] = useState({});

    const [isModalOpen, setModalOpen] = useState(false);

    const { setProfileData } = useContext(OnegreenContext);
    const handleProfileUpdate = (newProfileData) => {
        setProfileData(newProfileData);
    };


    // Function to open the modal
    const handleModalOpen = () => {
        console.log('Modal Open')
        setModalOpen(true);
    };

    // Function to close the modal
    const handleModalClose = () => {
        console.log('Modal Close')
        setModalOpen(false);
        fetchData();

    };

    useEffect(() => {
        // const populateMap = async () => {
        //     const map = L.map('map');
        //     map.setView([51.505, -0.09], 13);

        //     L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //         maxZoom: 19,
        //         attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        //     }).addTo(map);

        //     const marker = L.marker([51.5, -0.09]).addTo(map);

        //     const circle = L.circle([51.5, -0.09], {
        //         color: 'red',
        //         fillColor: '#f03',
        //         fillOpacity: 0.5,
        //         radius: 500,
        //     }).addTo(map);

        //     const success = (pos) => {
        //         const lat = pos.coords.latitude;
        //         const lng = pos.coords.longitude;
        //         const accuracy = pos.coords.accuracy;

        //         const userMarker = L.marker([lat, lng]).addTo(map);
        //         const userCircle = L.circle([lat, lng], { radius: accuracy }).addTo(map);

        //         map.fitBounds(userCircle.getBounds());
        //     };

        //     const error = (err) => {
        //         if (err.code === 1) {
        //             alert('Please allow geolocation access');
        //         } else {
        //             alert('Cannot get current location');
        //         }
        //     };

        //     navigator.geolocation.watchPosition(success, error);
        // };

        // populateMap();

        // Cleanup function (optional)
        return () => {
            // Perform any cleanup here, e.g., remove event listeners or timers
        };
    }, []); // Empty dependency array ensures the useEffect runs only once on mount



    useEffect(() => {
        // console.log('masuk ke pengembang lokasi profile...')
        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            (async () => {
                await fetchData();
                // await fetchDataStatusPengajuan();

                setIsLoading(false);
                setIsTokenExpired(false);
                // await populateMap();
            })();
        }

    }, [isLoading, isTokenExpired]);


    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const populateMap = async () => {

        const map = L.map('map');
        map.setView([51.505, -0.09], 13);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);

        const marker = L.marker([51.5, -0.09]).addTo(map);

        const circle = L.circle([51.5, -0.09], {
            color: 'red',
            fillColor: '#f03',
            fillOpacity: 0.5,
            radius: 500
        }).addTo(map);

        const success = (pos) => {
            const lat = pos.coords.latitude;
            const lng = pos.coords.longitude;
            const accuracy = pos.coords.accuracy;

            const userMarker = L.marker([lat, lng]).addTo(map);
            const userCircle = L.circle([lat, lng], { radius: accuracy }).addTo(map);

            map.fitBounds(userCircle.getBounds());
        };

        const error = (err) => {
            if (err.code === 1) {
                alert('Please allow geolocation access');
            } else {
                alert('Cannot get current location');
            }
        };

        navigator.geolocation.watchPosition(success, error);

    }

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            // console.log('user id  photo' + user.photo);
            // console.log('pengembang id ' + user.pengembang_id);
            var pengembangId = '';
            const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + user.pengembang_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            // console.log(response.data);
            setData(response.data);
            // setNamaDirektur(response.data.namaDirektur);


            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };




    const handleNilaiBalikan = (nilaiBalikan) => {
        // setIsLoading(nilaiBalikan);
    };

    const [itemData, setItemData] = useState(null);


    const handleIsModal = (item, event) => {

        isModal ? setIsModal(false) : setIsModal(true);
        setItemData(dataProfile);

    }

    const fileInputRef = useRef(null);
    const [imagePreview, setImagePreview] = useState('/dist/img/logo_pengembang/pengembang_001.jpg');

    const [error, setError] = useState('');

    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    // useEffect(() => {
    //     if (user.pengembang_photo) {
    //         setImagePreview(apiUrl + '/api/public/displayimage/' + user.pengembang_photo);

    //     } else {
    //         setImagePreview('/dist/img/logo_pengembang/pengembang_default.jpg');
    //     }
    // }, [user.pengembang_photo]);


    useEffect(() => {
        if (user.pengembang_photo) {
            setImagePreview(apiUrl + '/api/public/displayimage/' + user.pengembang_photo);

        } else {
            setImagePreview('/dist/img/logo_pengembang/pengembang_default.jpg');
        }
    }, []);

    
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) { // 1MB size limit
                setError('File size exceeds 1MB');
                return;
            }
            setError(''); // Clear any previous errors
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
                uploadImage(file); // Upload image if size is OK
            };
            reader.readAsDataURL(file);
        }
    };


    const uploadImage = async (file) => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        const formData = new FormData();
        formData.append('id', user.pengembang_id);
        formData.append('photo', file);


        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }




        const sUrl = apiUrl + '/api/igahp/uploadPhotoProfilePengembang';

        console.log(sUrl);
        try {
            await fetch(sUrl, {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {

                    if (data) {

                        user['pengembang_photo'] = file.name;
                        setError('');

                        // if (data.status === 'Gagal') {

                        //     Toast.fire({
                        //         icon: 'error',
                        //         title: data.status
                        //     });
                        // } else {
                        //     Toast.fire({
                        //         icon: 'success',
                        //         title: data.status
                        //     });
                        // }


                        var tempData = data;
                        alert(tempData.status);

                        handleProfileUpdate({ photoProfilePengembang: data.photoProfilePengembang });

                    }


                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    // setIsButtonProcess(false);
                    // alert(error);

                    setError('Error uploading image. Please try again-1.');
                    // Toast.fire({
                    //     icon: 'error',
                    //     title: error
                    // });
                });

        } catch (error) {
            // console.error('An error occurred:', error);
            // setIsButtonProcess(false);
            setError('Error uploading image. Please try again-2.');
        }

    };


    return (
        <>



            <>

                {/* Content Wrapper. Contains page content */}
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2 mt-2">
                                <div className="col-sm-6">
                                    <h1 className="text-muted fw-light">PROFIL <b>PENGEMBANG</b></h1>
                                </div>{/* /.col */}
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Dashboard</li>
                                    </ol>
                                </div>
                                {/* /.content-card-body */}
                            </div>{/* /.row */}
                        </div>{/* /.container-fluid */}
                    </div>




                    {/* /.content-header */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">


                                <div className="col-sm-12 col-lg-8">
                                    <div className="card mb-4">

                                        <div className="card-body">
                                            <div className="d-flex align-items-start align-items-sm-center">
                                                <img
                                                    src={imagePreview}
                                                    alt="image"
                                                    className="img-thumbnail"
                                                    height={180}
                                                    width={180}
                                                    onClick={handleImageClick}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <input
                                                    type="file"
                                                    ref={fileInputRef}
                                                    style={{ display: 'none' }}
                                                    onChange={handleFileChange}
                                                />
                                                {error && <p style={{ color: 'red' }}>{error}</p>}

                                                <div className="button-wrapper ml-4">
                                                    <label htmlFor="upload" className="btn btn-success me-2 mb-4" tabIndex={0}>
                                                        <span className="d-none d-sm-block">Unggah logo</span>
                                                        <i className="fas fa-upload d-block d-sm-none" />
                                                        <input
                                                            type="file"
                                                            id="upload"
                                                            onChange={handleFileChange}
                                                            className="account-file-input"
                                                            hidden
                                                            accept="image/png, image/jpeg"
                                                        />
                                                    </label>
                                                    <button
                                                        type="button"
                                                        className="btn btn-outline-secondary account-image-reset mb-4"
                                                        // onClick={handleResetClick}
                                                    >
                                                        <i className="fas fa-redo-alt d-block d-sm-none" />
                                                        <span className="d-none d-sm-block">Reset</span>
                                                    </button>
                                                    <p className="text-muted mb-0">File JPG or PNG. Maksimal 1Mb</p>
                                                </div>
                                            </div>
                                            <hr className="my-0" />
                                        </div>



                                        <div className="card-header align-items-center border-0 mt-1 mr-2">
                                            <div className="card-title">
                                                <h5 className="m-0">Info Pengembang</h5>
                                                <p className="text-muted mb-0"><small>Pemilik Perusahaan atau Direktur Utama</small></p>
                                            </div>
                                            <div className="card-tools">
                                                {/* <button type="button" id="tambahModal" data-toggle="modal" 
                                                            data-target="#ModalPermohonanBgh" className="btn btn-success">Update Data
                                                        </button> */}

                                                <button
                                                    type="button"
                                                    id="tambahModal" data-toggle="modal"
                                                    data-target="#ModalPermohonanBgh"
                                                    className="btn btn-success float-left"
                                                    onClick={handleModalOpen}
                                                >Update Data</button>

                                                {/* <ModalProfile
                                                        sendModal={isModal}
                                                        sendItem={itemData}
                                                    /> */}

                                                {isModalOpen && <ModalProfile onClose={handleModalClose} />}

                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <div className="table-responsive">
                                                        <table className="table border-bottom border-right border-left">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Nama Direktur</td>
                                                                    <td>:</td>
                                                                    <td>{data.namaDirektur}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Perusahaan</td>
                                                                    <td>:</td>
                                                                    <td>{data.pengembang_nama}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email Perusahaan</td>
                                                                    <td>:</td>
                                                                    <td>{data.email}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">

                                                    <div className="table-responsive">
                                                        <table className="table border-bottom border-right border-left">
                                                            <tbody>
                                                                <tr>
                                                                    <td>NPWP</td>
                                                                    <td>:</td>
                                                                    <td>{data.npwp}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Asosiasi Pengembang</td>
                                                                    <td>:</td>
                                                                    <td>{data.kodeAsosiasi}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Telp Kantor</td>
                                                                    <td>:</td>
                                                                    <td>{data.telp}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                            <br />
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="me-1">

                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h5 className="m-0">Admin Pengembang</h5>
                                                        <p className="text-muted mb-0"><small>Petugas admininistrasi dari pengembang</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <table className="table border-bottom border-right border-left">
                                                    <tbody>
                                                        <tr>
                                                            <td>Nama</td>
                                                            <td>:</td>
                                                            <td>{data.pic}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>No. Handphone</td>
                                                            <td>:</td>
                                                            <td>{data.picTelp}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <br />
                                            <div className="d-flex justify-content-between align-items-center mb-3">
                                                <div className="d-flex align-items-center">
                                                    <div className="me-1">

                                                    </div>
                                                    <div className="d-flex flex-column">
                                                        <h5 className="m-0">Lokasi</h5>
                                                        <p className="text-muted mb-0"><small>Lokasi Kantor Pengembang</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">

                                                    <div className="table-responsive">
                                                        <table className="table border-bottom border-right border-left">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Provinsi</td>
                                                                    <td>:</td>
                                                                    <td>{data.provinsi}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kab/Kota</td>
                                                                    <td>:</td>
                                                                    <td>{data.kabupaten}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                                <div className="col-md-6">

                                                    <div className="table-responsive">
                                                        <table className="table border-bottom border-right border-left">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Kecamatan</td>
                                                                    <td>:</td>
                                                                    <td>{data.kecamatan ? data.kecamatan.toUpperCase() : ''}</td>


                                                                </tr>
                                                                <tr>
                                                                    <td>Kelurahan</td>
                                                                    <td>:</td>
                                                                    <td>{data.kelurahan ? data.kelurahan.toUpperCase() : ''}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                                <div className="col-12 col-lg-4">
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            <h5 className="m-0">Dokumen</h5>
                                            <p className="text-muted mb-0"><small>Kelengkapan Dokumen Perizinan</small></p>
                                        </div>
                                        <div className="card-body">
                                            <ul className="mailbox-attachments">
                                                <li>
                                                    <span className="mailbox-attachment-icon"><i className="far fa-file-pdf" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <a href={`${apiUrl}/api/public/displaypdf/${data.dokAktePendirian}`} target="_blank"
                                                            className="mailbox-attachment-name" >
                                                            <i className="fas fa-paperclip" />
                                                            Akta Pendirian.pdf</a>
                                                        <span className="mailbox-attachment-size clearfix mt-1">
                                                            <span>1,245 KB</span>
                                                            <a href={`${apiUrl}/api/public/displaypdf/${data.dokAktePendirian}`} target="_blank" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
                                                        </span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <span className="mailbox-attachment-icon"><i className="far fa-file-word" /></span>
                                                    <div className="mailbox-attachment-info">
                                                        <a href={`${apiUrl}/api/public/displaypdf/${data.dokNib}`} target="_blank" className="mailbox-attachment-name" ><i className="fas fa-paperclip" />
                                                            NIB Perusahaan.pdf</a>
                                                        <span className="mailbox-attachment-size clearfix mt-1">
                                                            <span>1,245 KB</span>
                                                            <a href={`${apiUrl}/api/public/displaypdf/${data.dokNib}`} target="_blank" className="btn btn-default btn-sm float-right"><i className="fas fa-cloud-download-alt" /></a>
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card mb-4">
                                        <div className="card-header">
                                            <h5 className="m-0">Alamat</h5>
                                            <p className="text-muted mb-0"><small>Alamat Lengkap Kantor Pengembang</small></p>
                                        </div>
                                        <div className="card-body">
                                            <div className="d-flex justify-content-start align-items-center mb-4">
                                                <span className="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center"><i className="fas fa-map-marker-alt mr-1" /></span>
                                                <h6 className="text-body text-nowrap mb-0">{data.alamat}</h6>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                                {/* <h6>Contact info</h6> */}
                                            </div>
                                            <p className=" mb-1">Kode Pos : {data.kodePos}</p>
                                            {/* <p class=" mb-0">Mobile: +1 (609) 972-22-22</p> */}
                                        </div>
                                    </div>


                                    {/* <div className="card mb-4">
                                                <div className="card-header">
                                                    <h5 className="m-0">Maps</h5>
                                                     <p className="text-muted mb-0"><small>Kantor Pusat Pengembang</small></p>
                                                </div>
                                                <div className="card-body">
                                                    <div className="card mb-4">
                                                        <div id="map" style={{ height: '180px' }} />
                                                    </div>
                                                </div>
                                            </div> */}



                                </div>


                            </div>
                        </div>
                    </section>
                    {/* /.content */}


                </div>
                {/* /.content-wrapper */}




                {/* <div className="content-wrapper">
                    
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Profile Pengembang</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Profile Pengembang</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                    </section>
                    
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">


                                    

                                    {isLoading ?
                                        <p>Loading Data...</p> :


                                        <>

                                            
                                            <div className="card card-primary card-outline">
                                                <div className="card-body box-profile">
                                                    
                                                    <h1>{dataProfile.nama_perumahan}</h1>
                                                    <strong><i className="fas fa-map-marker-alt mr-1" /> Alamat</strong>
                                                    <h5>{data.alamat}</h5>
                                                    <br />

                                                    <strong><i className="far fa-file-alt mr-1" /> Info</strong>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Pengembang Nama</td>
                                                                                <td>:</td>
                                                                                <td>                                                                                  
                                                                                    {data.pengembang_nama}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Asosiasi</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {data.kodeAsosiasi}
                                                                                </td>
                                                                            </tr>
                                                                           
                                                                            <tr>
                                                                                <td>Nama Direktur</td>
                                                                                <td>:</td>
                                                                                <td>                                                                                    
                                                                                    {data.namaDirektur}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>
                                                                            
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Email</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {data.email}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Npwp</td>
                                                                                <td>:</td>
                                                                            
                                                                                <td>
                                                                                    {data.npwp}
                                                                                </td>
                                                                            </tr>
                                                                           
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>

                                                                        </thead>
                                                                        <tbody>                                                                            
                                                                            <tr>
                                                                                <td>Kodepos</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {data.kodePos}
                                                                                </td>
                                                                            </tr>

                                                                            <tr>
                                                                                <td>Telp Kantor</td>
                                                                                <td>:</td>
                                                                                <td>                                                                            
                                                                                    {data.telp}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>
                                                                
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Nama PIC</td>
                                                                                <td>:</td>
                                                                                <td>                                                                                    
                                                                                    {data.pic}
                                                                                </td>

                                                                            </tr>
                                                                            <tr>
                                                                                <td>No Hp Pic</td>
                                                                                <td>:</td>                                                                                
                                                                                <td>
                                                                                    {data.picTelp}
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Provinsi</td>
                                                                                <td>:</td>
                                                                                <td>                                                                                    
                                                                                    {data.provinsi}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Kab/Kota</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {data.kabupaten}
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>
                                                                            
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Kecamatan</td>
                                                                                <td>:</td>
                                                                                <td>                                                                                  
                                                                                    {data.kecamatan}
                                                                                </td>

                                                                            </tr>
                                                                            <tr>
                                                                                <td>Kelurahan</td>
                                                                                <td>:</td>
                                                                                
                                                                                <td>
                                                                                    {data.kelurahan}
                                                                                </td>
                                                                            </tr>

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <strong><i className="far fa-file-alt mr-1" /> Dokumen</strong>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Dokumen Akte Pendirian</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    <a href={`${apiUrl}/api/public/displaypdf/${data.dokAktePendirian}`} target="_blank" >Lihat File</a>
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>
                                                                            
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Dokumen NIB</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    <a href={`${apiUrl}/api/public/displaypdf/${data.dokNib}`} target="_blank" >Lihat File</a>
                                                                                </td>
                                                                            </tr>
                                                                            
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>


                                                    <button
                                                        
                                                        type="button"
                                                        id="tambahModal" data-toggle="modal"
                                                        data-target="#ModalPermohonanBgh"
                                                        className="btn btn-success float-left"


                                                    >Update Data</button>


                                                    <ModalProfile
                                                        sendModal={isModal}
                                                        sendItem={itemData}                                                    
                                                    />



                                                </div>
                                                

                                            </div>
                                            
                                           
                                        </>
                                    }

                                </div>
                                

                            </div>
                            
                        </div>
                    </section>
                    
                </div> */}


            </>



        </>
    )
}

export default PengembangProfileNew
