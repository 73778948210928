import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import { AuthContext } from '../../../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import CustomIframe from '../../CustomIFrame';
import MapContainer from '../../igahp_admin/LokasiPerumahan/MapContainer';

import ModalLokasiPerumahanPerorangan from './ModalLokasiPerumahanPerorangan';
import Swal from 'sweetalert2';

const LokasiPerumahanPerorangan = () => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [dataProfile, setDataProfile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});

    let refreshToken = null;

    const navigate = useNavigate()


    const [selectedStatusPembangunan, setSelectedStatusPembangunan] = useState('');
    const handleOptionStatusPembangunanChange = event => {
        setSelectedStatusPembangunan(event.target.value);
    };


    useEffect(() => {
        // console.log('masuk ke pengembang lokasi profile...')
        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            (async () => {
                await fetchData();
                // await fetchDataStatusPengajuan();

                setIsLoading(false);
                setIsTokenExpired(false);
            })();
        }

    }, [isLoading, isTokenExpired]);


    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };




    const [isModalLokasiPerumahanOpen, setModalLokasiPerumahanOpen] = useState(false);


    const handleOpenLokasiPerumahanClick = () => {
        setModalLokasiPerumahanOpen(true);
    }

    const handleModalLokasiPerumahanClose = () => {
        setModalLokasiPerumahanOpen(false);
        fetchData();
    };

    const [tglSelesaiPembangunan, setTglSelesaiPembangunan] = useState('');



    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        // console.log(user);

        const url = apiUrl + "/api/igahp/lokasi/detail/" + user.perumahan_id;

        let token = user.accessToken;

        // console.log('token...');
        // console.log(user);



        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // setIsLoading(false);
            // setIsTokenExpired(false);

            console.log('respon api profile peruahan ....');
            console.log(data);
            setDataProfile(data);
            setSelectedStatusPembangunan(data.statusPembangunan)
            setTglSelesaiPembangunan(data.tglSelesaiPembangunan)
            setLokasiGps({ lat: data.latitude, lng: data.longitude });
            setIsLoading(false)

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };


    const imageContainerStyle = {
        height: '500px', // Set the desired height
        overflow: 'hidden'
    };

    const imageStyle = {
        height: '100%',
        width: '100%',
        objectFit: 'cover' // Ensure the image covers the container
    };


    const [isButtonProcess, setIsButtonProcess] = useState(false);

    const handleSubmit = async (e) => {
        setIsButtonProcess(true);

        e.preventDefault();

        //   alert('Sukses');


        var isTambahData = true;


        if (user.tiperumah_state == 'edit') {
            isTambahData = false;
        } else {
            isTambahData = true;
        }

        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        formData.append('id', user.perumahan_id);
        
        formData.append('statusPembangunan', selectedStatusPembangunan);
        formData.append('tglSelesaiPembangunan', tglSelesaiPembangunan);
        // formData.append('dokSertifikat', dokSertifikat);

        // formData.append('dokRks', dokRks);
        // formData.append('dokBoq', dokBoq);

        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;

            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            await fetch(apiUrl + '/api/igahp/updateStatusPembangunan', {
                credentials: 'include', 
                method: 'POST',                
                body: formData,
                headers: {                    
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {                 
                    Toast.fire({
                        icon: 'success',
                        title: data.status
                    });
                    setIsButtonProcess(false);                 
                })
                .catch(error => {
                    setIsButtonProcess(false);
                    console.error(error);                 
                    Toast.fire({
                        icon: 'error',
                        title: error
                    });
                });
        } catch (error) {
            setIsButtonProcess(false);
            console.error('An error occurred:', error);
        }
    };


    return (
        <>

            <div className="col-12 col-lg-9">
                <div className="card">


                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="card-header align-items-center border-0 mt-2 mr-2">
                                    <div className="card-title">
                                        <h5 className="text-muted m-0">Detail Lokasi Rumah</h5>
                                        {/* <p class="text-muted mb-0"><small>Pemilik Perusahaan atau Direktur Utama</small></p> */}
                                    </div>
                                    <div className="card-tools">
                                        {/* <button type="button" id="#" data-toggle="modal" data-target="#modal-lg" 
                                        className="btn btn-success float-right"><i className="fas fa-plus" /> Ajukan Lokasi</button> */}

                                        <button
                                            type="button"
                                            // id="peminatBankModal" 
                                            data-toggle="modal"
                                            data-target="#ModalLokasiPerumahan"
                                            className="btn btn-success float-left"
                                            onClick={handleOpenLokasiPerumahanClick}
                                        >Ajukan Lokasi</button>


                                    </div>


                                </div>
                            </div>
                        </div>





                        <div className="card-body mb-0">

                            {!isLoading ? (

                                <>
                                    <div className="row">


                                        <div className="col-md-6">
                                            <div className="table-responsive">
                                                <table className="table border-bottom border-right border-left">
                                                    <tbody>
                                                        <tr>
                                                            <td>Jenis Perumahan</td>
                                                            <td>:</td>
                                                            <td>{dataProfile.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>IMB/PBG</td>
                                                            <td>:</td>
                                                            <td>{dataProfile.imb}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Tanggal IMB/PBG</td>
                                                            <td>:</td>
                                                            <td>{dataProfile.tgl_imb}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Kecamatan IMG/PBG</td>
                                                            <td>:</td>
                                                            <td>{dataProfile.infoKecamatan}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>Dokumen IMB/PBG</td>
                                                            <td>:</td>
                                                            <td><a href={`${apiUrl}/api/public/displaypdf/${dataProfile.dokumen_imb}`} target="_blank" >Lihat File</a></td>
                                                        </tr>
                                                        <tr>
                                                            <td>Foto Gambar Kerja Siteplan</td>
                                                            <td>:</td>
                                                            <td><a href={`${apiUrl}/api/public/displaypdf/${dataProfile.siteplan_ttd}`} target="_blank" >Lihat File</a></td>
                                                        </tr>
                                                    </tbody></table>
                                            </div>
                                        </div>


                                        <div className="col-12 col-lg-6">
                                            <div className="card">
                                                <div className="row">
                                                    <div className="col-12">
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <h5 className="text-muted accent-gray-dark">Target Pembangunan</h5>
                                                        {/* <p class="text-muted mb-0"><small>Isi form untuk daftar perumahan. </small></p> */}
                                                    </div>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="form-group">
                                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Status Pembangunan</label>

                                                            <select
                                                                value={selectedStatusPembangunan} 
                                                                onChange={handleOptionStatusPembangunanChange} 
                                                                className="form-control" 
                                                                required
                                                                >
                                                                <option value="">Pilih</option>
                                                                <option value="tahap-pembangunan">Proses Tahap Pembangunan</option>
                                                                <option value="pembangunan-selesai">Pembangunan Selesai</option>
                                                            </select>

                                                        </div>
                                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Selesai Pembangunan</label>
                                                        <div className="form-group">
                                                            <div className="input-group">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text"><i className="far fa-calendar-alt" /></span>
                                                                </div>
                                                                {/* <input type="text" defaultValue="23/04/2025" className="form-control" data-inputmask-alias="datetime" data-inputmask-inputformat="dd/mm/yyyy" data-mask inputMode="numeric" /> */}
                                                            
                                                                <input
                                                                    className="form-control bg-white" required data-pristine-required-message="Tanggal selesai pembangunan"
                                                                    type="date"
                                                                    value={tglSelesaiPembangunan}
                                                                    onChange={(e) => setTglSelesaiPembangunan(e.target.value)}
                                                                    require
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="modal-footer justify-content-end align-content-between">
                                                            <button type="submit" className="btn btn-success">Simpan</button>
                                                        </div>
                                                    </form>
                                                    {/* <a className="btn btn-success float-right">Simpan</a> */}
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-12">
                                            <div className="card card-primary card-outline">
                                                <div className="card-body box-profile">
                                                    <h4>Peta Lokasi Rumah</h4>
                                                    <small>Koordinat lokasi anda akan muncul setelah anda memasukan foto lokasi yang valid.</small>
                                                    <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">
                                                        <>
                                                            <MapContainer
                                                                gps={lokasiGps}
                                                                onMapContainerReady={handleMapContainerReady}
                                                            />
                                                        </>
                                                    </CustomIframe>
                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                        </div>

                                    </div>
                                    <br />
                                    <div className="form-group">
                                        <h6 className="text-muted accent-gray-dark">Foto Lokasi Perumahan</h6>
                                        {/* <p class="text-muted mb-0"><small>Isi form untuk daftar perumahan. </small></p> */}
                                    </div>

                                    <div className="row mb-0">
                                        <div className="col-md-4 col-lg-4 mb-3">
                                            <div className="card h-60">
                                                <div style={imageContainerStyle}>
                                                    <img
                                                        src={`${apiUrl}/api/public/displayimage/${dataProfile.foto_gerbang}`}
                                                        style={imageStyle}
                                                        className="card-img-top card-img"
                                                        alt="Image"
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <p className="text-muted h6">FOTO GERBANG DEPAN/ JALAN UTAMA</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-4 mb-3">
                                            <div className="card h-60">
                                                <div style={imageContainerStyle}>
                                                    <img
                                                        src={`${apiUrl}/api/public/displayimage/${dataProfile.foto_tengah}`}
                                                        style={imageStyle}
                                                        className="card-img-top card-img"
                                                        alt="Image"
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <p className="text-muted h6">FOTO TENGAH PERUMAHAN</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 col-lg-4 mb-3">
                                            <div className="card h-60">
                                                <div style={imageContainerStyle}>
                                                    <img
                                                        src={`${apiUrl}/api/public/displayimage/${dataProfile.foto_contoh}`}
                                                        style={imageStyle}
                                                        className="card-img-top card-img"
                                                        alt="Image"
                                                    />
                                                </div>
                                                <div className="card-body">
                                                    <p className="text-muted h6">FOTO CONTOH RUMAH</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
              
                                </>
                            ) : (

                                <>
                                    <p>Loading ...</p>
                                </>
                            )}

                        </div>
                    </>



                </div>
            </div>

            {isModalLokasiPerumahanOpen && <ModalLokasiPerumahanPerorangan onClose={handleModalLokasiPerumahanClose}
                dataMinat={dataProfile}
            />}


        </>
    )
}

export default LokasiPerumahanPerorangan
