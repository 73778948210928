import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import NavBarAtas from './components/Front/NavBarAtas';

function ForgotPassword() {

    // const [username, setUsername] = useState('');
    // const [password, setPassword] = useState('');
    // const { user, setUser } = useContext(UserContext);

    const navigate = useNavigate()

    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [isLoading, setIsLoading] = useState(false);


    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };



    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    // const handleSubmit = async (event) => {
    //     event.preventDefault();

    //     try {
    //         const response = await fetch(apiUrl + '/api/auth/request-password-reset', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //             body: new URLSearchParams({ email }),
    //         });

    //         const result = await response.json();

    //         if (response.ok) {
    //             setMessage(result.message);
    //         } else {
    //             setMessage(result.message || 'An error occurred.');
    //         }
    //     } catch (error) {
    //         setMessage('An error occurred while processing your request.');
    //         console.error('Error:', error);
    //     }
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();

        setIsLoading(true);
        try {
            const response = await fetch(apiUrl + '/api/auth/request-password-reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams({
                    email: email,
                }),
            });

            let result;
            const contentType = response.headers.get('Content-Type');
            if (contentType && contentType.includes('application/json')) {
                result = await response.json();
            } else {
                result = { message: 'Unexpected response format' };
            }

            if (response.ok) {
                setMessage(result.message);
            } else if (response.status === 404) {
                setMessage('API endpoint not found.');
            } else {
                setMessage(result.message || 'An error occurred');
            }
            setIsLoading(false);
        } catch (error) {
            console.error('Error:', error);
            setIsLoading(false);
            setMessage('email not found');
        }
    };


    require("./css/common.css");

    return (
        <>

            <NavBarAtas showButton={false} />

            <div className="wrapper-login-content">
                <div className="content-login-form">
                    <div className="form-login">
                        <div className="login-title igahp-l-semi-bold">Lupa Kata Sandi</div>
                        <form onSubmit={handleSubmit} className="wrapper-form-login">

                            <div className="igahp-form-item">
                                <div className="igahp-m-regular neutral-90-text">Email</div>
                                <input type="text"
                                    placeholder="Masukkan email"
                                    className={`form-border-rad-12 igahp-m-regular neutral-90-text`}
                                    id="email"
                                    value={email}
                                    onChange={handleEmailChange}
                                    required
                                />
                            </div>
                            
                            <button
                                type="submit"
                                // disabled={isButtonProcess}
                                disabled={isLoading}
                                className="btn-login igahp-s-semibold">Kirim Password Baru</button>


                            {/* {message && <div className="error-message text-danger">{message}</div>} */}

                            {message && (
                                <div className={`alert ${message.includes('successful') ? 'alert-success' : 'alert-danger'}`} role="alert">
                                    {message}
                                </div>
                            )}


                            <div className="divider-or">
                                <div className="divider" />
                                <div className="or-text igahp-xs-regular">Atau</div>
                                <div className="divider" />
                            </div>
                            <div className="wrapper-registration">
                                <a href="/login" className="btn-registration igahp-s-semibold">Masuk</a>
                            </div>

                            {isLoading ?
                                <div className="divider-or">
                                    <div>Loading...</div>
                                </div>
                                : <p></p>}

                        </form>
                    </div>
                </div>
            </div>
        </>
        // <div className="hold-transition login-page">


        //     <div className="login-box">
        //         <div className="login-logo">
        //             {/* <center><img src="/dist/logo/certif_logo.jpeg" className="brand-image style" width={350} height={90} /></center> */}
        //             <br />
        //             <a href="../../index2.html"><b>Lupa password</b> Akun</a>
        //         </div>

        //         <div className="card">
        //             <div className="card-body login-card-body">
        //                 <p className="login-box-msg">Anda lupa password? Masukan email anda dibawah ini</p>
        //                 <b>
        //                     <form onSubmit={handleSubmit}>

        //                         <input type="hidden" name="_csrf" defaultvalue="5ace8d3b-350c-4c19-ae9c-756796ceec27" />
        //                         <div className="input-group mb-3">
        //                             {/* <input type="email" className="form-control" placeholder="Masukan Email" id="email" name="Email" /> */}


        //                             <input 
        //                                 className="form-control"
        //                                 type="email"
        //                                 placeholder="Masukkan email"
        //                                 // className="form-border-rad-12 igahp-m-regular neutral-90-text"
        //                                 // className={`form-border-rad-12 igahp-m-regular neutral-90-text ${usernameError && 'error'}`}
        //                                 id="email"
        //                                 value={email}
        //                                 onChange={handleEmailChange}

        //                                 required
        //                             />

        //                             <div className="input-group-append">
        //                                 <div className="input-group-text">
        //                                     <span className="fas fa-envelope">
        //                                     </span>'
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <br />
        //                         <div className="row">
        //                             <div className="col-12">
        //                                 <button   className="btn btn-primary btn-block">Permohonan password baru</button>
        //                             </div>
        //                         </div>
        //                         <br />

        //                     </form>

        //                     {message && <p>{message}</p>}

        //                     <p className="mb-0">
        //                         <a href="/login" className="text-center">Login</a>
        //                     </p>
        //                     <p className="mb-0">
        //                         <a href="/register" className="text-center">Kembali ke halaman daftar akun</a>
        //                     </p>
        //                 </b>
        //             </div>
        //         </div>
        //     </div>

        // </div>
    );
}

export default ForgotPassword;
