import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import { AuthContext } from "../../../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
// import { refreshTokenRequest } from '../../refreshTokenRequest';
// import TambahLokasi from './TambahLokasi';
import { refreshTokenRequest } from '../../refreshTokenRequest';
// import ModalTipeRumah from './ModalTipeRumah';

import ModalTipeRumahPerorangan from './ModalTipeRumahPerorangan';
import ModalUnggahSertifikatPerorangan from './ModalUnggahSertifikatPerorangan';



const TipeUnitPerorangan = () => {

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const { loginContext } = useContext(AuthContext);

    let refreshToken = null;

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const navigate = useNavigate()

    const handleIsModal = (item, event) => {
        // console.log(event.target.id.split('-')[1]);
        // console.log(item.id);
        // console.log(isModal);
        console.log(item);
        if (event.target.id.split('-')[1] == item.id || item === "tambah-data") {
            // console.log('aaaa');
            isModal ? setIsModal(false) : setIsModal(true);
            setItemData(item);
        }
    }

    function changeModal() {
        var newModalId = "#newModal"; // Replace with the ID of the new modal you want to open
        var button = document.querySelector('[data-toggle="modal"]');
        button.setAttribute('data-target', newModalId);
    }

    const handleNilaiBalikan = (nilaiBalikan) => {
        setIsLoading(nilaiBalikan);
    }

    const setModalFalse = () => {
        isModal ? setIsModal(false) : setIsModal(true);
    }

    // useEffect(() => {        
    //     setTokenJwt(user.accessToken);     
    // }, []);

    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);


    const [isModalTipeRumahOpen, setModalTipeRumahOpen] = useState(false);


    const handleOpenTipeRumahClick = () => {
        setModalTipeRumahOpen(true);
    }

    const handleModalTipeRumahClose = () => {
        setModalTipeRumahOpen(false);
        fetchData();
    };




    const [isModalUnggahSertifikatOpen, setModalUnggahSertifikatOpen] = useState(false);

    const handleOpenUnggahSertifikatClick = () => {
        setModalUnggahSertifikatOpen(true);
    }

    const handleModalUnggahSertifikatClose = () => {
        setModalUnggahSertifikatOpen(false);
        fetchData();
    };



    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl + '/api/public/getTipeRumah/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            // console.log(response.data)

            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };



    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }


            try {
                await fetch(apiUrl + '/api/igahp/hapusTipeRumah', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    },
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                        alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                        alert(data.status);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }


    // const handleAddTipeRumah = () => {
    //     user['tiperumah_state'] = 'add';
    //     loginContext(user);

    //     navigate(`/igahp/pengembang/PengembangLokasiTipeRumahTambah`);
    // };

    let sequenceNumber = 1;


    const imageContainerStyle = {
        height: '500px', // Set the desired height for the image container
        overflow: 'hidden'
    };

    const imageStyle = {
        width: '100%',
        height: '100%',
        objectFit: 'cover' // Ensure the image covers the container 
    };


    return (
        <>

            <div className="col-12 col-lg-9">
                <div className="card">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-header align-items-center border-0 mt-2 mr-2">
                                <div className="card-title">
                                    <h5 className="text-muted m-0">Tipe Unit Rumah</h5>
                                    {/* <p class="text-muted mb-0"><small>Pemilik Perusahaan atau Direktur Utama</small></p> */}
                                </div>
                                <div className="card-tools">
                                    <div className="info d-flex align-content-center justify-content-end flex-md-row flex-column mb-3 md-2 mb-md-0">
                                        <button type="button" id="#" data-toggle="modal" data-target="#ModalTipeRumah" 
                                            
                                            onClick={(event) => {
                                                
                                                user['tiperumah_state'] = 'add';
                                                user['tiperumah_nama'] = '';
                                                user['tiperumah_lt'] = '';
                                                user['tiperumah_lb'] = '';
                                                user['tiperumah_harga'] = '';


                                                user['tiperumah_jumlantai'] = '';
                                                user['tiperumah_km'] = '';
                                                user['tiperumah_kt'] = '';

                                                user['tiperumah_atap'] = '';
                                                user['tiperumah_dinding'] = '';
                                                user['tiperumah_lantai'] = '';
                                                user['tiperumah_pondasi'] = '';
                                                user['tiperumah_status'] = '';

                                                user['tiperumah_fotoDenah'] = '';
                                                user['tiperumah_fotoPotongan'] = '';
                                                user['tiperumah_fotoTampakDepan'] = '';
                                                user['tiperumah_fotoTampakBelakang'] = '';

                                                user['tiperumah_dokRks'] = '';
                                                user['tiperumah_dokBoq'] = '';





                                                loginContext(user);

                                                setModalTipeRumahOpen(true);
                                                // navigate(`/igahp/pengembang/PengembangLokasiTipeRumahTambah`);
                                            }}
                                            className="btn btn-success mr-1 mb-1"><i className="fas fa-plus" /> 
                                            Tambah Tipe Rumah
                                        </button>


                                        <button type="button" id="#" 
                                            data-toggle="modal"
                                            data-target="#ModalUnggahSertifikat"
                                            onClick={handleOpenUnggahSertifikatClick}
                                            className="btn btn-outline-success mb-1">
                                            <i className="fas fa-file-upload" /> Unggah Sertifikat Hijau
                                            
                                        </button>

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="card-body mb-0">
                        <div className="row">


                            {isLoading ?
                                <p>Loading Data...</p> :

                                <div className="card">
                                    
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered table-striped">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>NAMA TYPE</th>
                                                    <th>LT</th>
                                                    <th>LB</th>
                                                    <th>HARGA</th>
                                                    {/* <th>STATUS</th> */}
                                                    <th>AKSI</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {data.map((item) => (
                                                    <tr key={item.id}>
                                                        <td>{sequenceNumber++}</td>
                                                        <td>{item.nama}</td>
                                                        <td>{item.luasTanah} m2</td>
                                                        <td>{item.luasBangunan} m2</td>
                                                        {/* <td>{item.harga.toLocaleString('en-US')}</td> */}
                                                        <td style={{ textAlign: 'right' }}>Rp. {item.harga.toLocaleString('en-US')}</td>
                                                        {/* <td>{item.status}</td> */}

                                                        <td>
                                                            {/* <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                                onClick={(event) => {
                                                                    user['perumahan_id'] = item.id;
                                                                    user['perumahan_nama'] = item.nama_perumahan;                                                                        
                                                                    loginContext(user); // isi context                                                                        
                                                                    navigate(`/igahp/pengembang/PengembangLokasiProfile`);
                                                                }}
                                                            >
                                                                <i className="fas fa-eye" />
                                                            </button> */}

                                                            <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                                data-toggle="modal"
                                                                data-target="#ModalTipeRumah"
                                                                onClick={(event) => {
                                                                    user['tiperumah_id'] = item.id;
                                                                    user['tiperumah_state'] = 'edit';
                                                                    user['tiperumah_nama'] = item.nama;
                                                                    user['tiperumah_lt'] = item.luasTanah;
                                                                    user['tiperumah_lb'] = item.luasBangunan;
                                                                    user['tiperumah_harga'] = item.harga;

                                                                    user['tiperumah_jumlantai'] = item.jumlahLantai;
                                                                    user['tiperumah_km'] = item.kamarMandi;
                                                                    user['tiperumah_kt'] = item.kamarTidur;

                                                                    user['tiperumah_atap'] = item.spesifikasiAtap;
                                                                    user['tiperumah_dinding'] = item.spesifikasiDinding;
                                                                    user['tiperumah_lantai'] = item.spesifikasiLantai;
                                                                    user['tiperumah_pondasi'] = item.spesifikasiPondasi;
                                                                    user['tiperumah_status'] = item.status;

                                                                    user['tiperumah_fotoDenah'] = item.fotoDenah;
                                                                    user['tiperumah_fotoPotongan'] = item.fotoPotonganBangunan;
                                                                    user['tiperumah_fotoTampakDepan'] = item.fotoTampakDepan;
                                                                    user['tiperumah_fotoTampakBelakang'] = item.fotoTampakBelakang;


                                                                    user['tiperumah_dokRks'] = item.dokRks;
                                                                    user['tiperumah_dokBoq'] = item.dokBoq;


                                                                    loginContext(user);

                                                                    setModalTipeRumahOpen(true);
                                                                    // navigate(`/igahp/pengembang/PengembangLokasiTipeRumahTambah`);
                                                                }}
                                                            >
                                                                <i className="fas fa-edit" id={`i-${item.id}`} />

                                                                {/* <TambahLokasi/> */}
                                                            </button>

                                                            <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                                <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                </div>



                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="form-group">
                                    <h5 className="text-muted accent-gray-dark">Foto Tipe Rumah</h5>
                                </div>
                                <div className="row">
                                    {data.map((item) => (
                                        <div key={item.id} className="col-12 col-md-6 mb-3">
                                            <div className="card h-100">
                                                <div className="row g-0">
                                                    <div className="col-md-12" style={imageContainerStyle}>
                                                        <img
                                                            className="card-img-top"
                                                            src={`${apiUrl}/api/public/displayimage/${item.fotoTampakDepan}`}
                                                            alt="Image"
                                                            style={imageStyle}
                                                        />
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="card-body">
                                                            <h4 className="card-title">Rp. {item.harga.toLocaleString('en-US')}</h4>
                                                            <p className="card-text text-muted">
                                                                Nama Tipe: {item.nama}
                                                            </p>
                                                            <p className="card-text"><small className="text-muted">LB / LT: {item.luasBangunan} / {item.luasTanah}</small></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>






            </div>


            {isModalTipeRumahOpen && <ModalTipeRumahPerorangan onClose={handleModalTipeRumahClose}
                dataMinat={data}
            />}


            {isModalUnggahSertifikatOpen && <ModalUnggahSertifikatPerorangan onClose={handleModalUnggahSertifikatClose}
                dataMinat={data}
            />}

        </>
    )
}

export default TipeUnitPerorangan
