import React from 'react'
import { useEffect } from 'react';
import { AuthContext } from "../../AuthContext";

import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";



const Navbar = () => {
    const $ = window.$;
    function loadTreeview() {
        $('[data-widget="treeview"]').each(function () {
            if ($(this).data('treeview-init') === undefined) {
                $(this).Treeview('init');
                $(this).data('treeview-init', true);
            }
        });
    }

    useEffect(() => {
        loadTreeview();
    }, [])


    return (
        <>



            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars" /></a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="index3.html" className="nav-link">Home</a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="#" className="nav-link">Contact</a>
                    </li>
                </ul>
                {/* Right navbar links */}
                
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <Link to="/logout" className="nav-link">
                            <i className="nav-icon  	fas fa-power-off"></i>
                        </Link>
                    </li>
                </ul>
            </nav>

        </>
    )
}

export default Navbar


