import React, { useEffect, useState } from 'react';

const BrowserDetection = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const userAgent = navigator.userAgent;

        // Check for common mobile keywords in the userAgent string
        const mobileKeywords = ['Android', 'webOS', 'iPhone', 'iPad', 'iPod', 'BlackBerry', 'Windows Phone'];
        const isMobileDevice = mobileKeywords.some(keyword => userAgent.includes(keyword));

        setIsMobile(isMobileDevice);
    }, []);

    return (
        <div>
            {isMobile ? alert('Untuk pengalaman menjelajah yang lebih baik, gunakan browser di desktop (PC/Laptop)') : <></>}
        </div>
    );
};

export default BrowserDetection;
