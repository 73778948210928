import React from 'react'
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";

import { AuthContext } from '../../AuthContext';
import { useContext } from "react";

import { useEffect } from 'react';


const Sidebar = () => {


    const { user, setUser } = useContext(AuthContext);

    useEffect(() => {

        // const script2 = document.createElement('script');
        // script2.src = "/js/toast.js";
        // script2.async = true;
        // document.body.appendChild(script2);

    }, []);


    return (
        <>


            {/* Main Sidebar Container */}
            {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}

            <aside id="control_sidebar" className="main-sidebar sidebar-light-success elevation-4">


                {/* Brand Logo */}
                <a href="/igahp" className="brand-link">
                    <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                    <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA</b></h4>
                </a>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                        <div className="image">
                            <img src="/dist/img/user2-160x160.jpg" 
                            className="img-circle elevation-2" alt="User Image" />
                        </div>
                        <div className="info p-0 ml-4">
                        <div>
                            <info className="text-muted">
                                    USER PEMDA
                            <a href="#" className="d-block">{user.username}</a>
                            </info>
                            </div>
                        </div>
                    </div>

                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                            <li className="nav-item">
                                <Link to="pemda/dashboard" className="nav-link">
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>Dashboard</p>
                                </Link>
                            </li>

                            {/* <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-copy" />
                                    <p>
                                        Menu
                                        <i className="fas fa-angle-left right" />
                                    </p>
                                </a>

                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/ListPengajuanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>List Pengajuan BGH</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="tabgh/TaBghHasilPenilaian" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Hasil Penilaian BGH</p>
                                        </Link>
                                    </li>
                                
                                </ul>
                            </li> */}

                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-copy" />
                                    <p>
                                        Data Reference
                                        <i className="fas fa-angle-left right" />
                                        {/* <span className="badge badge-info right">6</span> */}
                                    </p>
                                </a>

                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pemda/ListPendataanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-danger"></i>
                                            <p>Data Awal/Quesioner</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pemda/LolosSubsidiChecking" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Lolos Subsidi Checking</p>
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="pemda/Toast" className="nav-link">
                                            <i className="nav-icon far fa-circle text-info"></i>
                                            <p>Toast</p>
                                        </Link>
                                    </li> */}


                                    {/* <li className="nav-item">
                                      <Link to="tabgh/TaBghHasilPenilaian" className="nav-link">
                                          <i className="nav-icon far fa-circle text-warning"></i>
                                          <p>Hasil Penilaian</p>
                                      </Link>
                                  </li>

                                  <li className="nav-item">
                                      <a href="pages/layout/boxed.html" className="nav-link">
                                          <i className="far fa-circle nav-icon" />
                                          <p>Laporan</p>
                                      </a>
                                  </li>

                                  <li className="nav-item">
                                      <Link to="bank/BankDataCompare" className="nav-link">
                                          <i className="nav-icon far fa-circle text-info"></i>
                                          <p>Data Compare</p>
                                      </Link>
                                  </li> */}

                                    {/* <li className="nav-item">
                                       
                                        <Link to={`https://indonesiahijau.id/login`} className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Logout</p>
                                        </Link>
                                    </li> */}

                                </ul>
                            </li>

                        </ul>
                    </nav>
                    {/* /.sidebar-menu */}
                </div>
                {/* /.sidebar */}
            </aside>

        </>
    )
}

export default Sidebar
