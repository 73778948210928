

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import NavBarAtas from './NavBarAtas';

import { useParams, useNavigate } from 'react-router-dom';

const ResetPassword = () => {

    const [passwordMatchError, setPasswordMatchError] = useState(false);

    const [token, setToken] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
    const [message, setMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        // setKode(searchParams.get('kode'));
        const tokenP = searchParams.get('token');
        setToken(tokenP);

        // const activationEndpoint = apiUrl + '/api/auth/aktivasi?kode=' + kod;

        const activateUser = async () => {
            try {
                // const response = await fetch(activationEndpoint);
                // if (response.ok) {
                //     const data = await response.json();
                //     setTimeout(() => {
                //         navigate('/login');
                //     }, 3000);
                // } else {
                //     setTimeout(() => {
                //         navigate('/register');
                //     }, 30000);
                // }
            } catch (error) {
                setTimeout(() => {
                    navigate('/register');
                }, 30000);
            }
        };

        activateUser();

        return () => { }; // No need to clear the timeout here

    }, []);


    const handleBlur = () => {

        if (newPassword !== newPasswordConfirmation) {
            setPasswordMatchError(true);
            alert('Isian password tidak sama..')
            return;
        } else {
            console.log('password sama...')
            setPasswordMatchError(false);
        }

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // try {
        //     const response = await axios.post(apiUrl + '/api/auth/reset-password', { token, newPassword });
        //     setMessage(response.data.message);
        // } catch (error) {
        //     setMessage(error.response.data.message);x`
        // }

        if (newPassword && newPasswordConfirmation) {
            try {
                setIsLoading(true);
                const response = await fetch(apiUrl + '/api/auth/reset-password', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    body: new URLSearchParams({ token, newPassword }),
                });

                const result = await response.json();

                if (response.ok) {
                    setMessage(result.message);
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    setMessage(result.message || 'An error occurred.');
                }
                setIsLoading(false);
            } catch (error) {
                setIsLoading(false);
                setMessage('An error occurred while processing your request.');
                console.error('Error:', error);
            }
        }

    };

    require("./../../css/common.css");

    return (
        <>

            <NavBarAtas showButton={false} />

            <div className="wrapper-login-content">
                <div className="content-login-form">
                    <div className="form-login">
                        <div className="login-title igahp-l-semi-bold">Buat Kata Sandi Baru</div>

                        {message && (
                            <div className={`alert ${message.includes('successful') ? 'alert-success' : 'alert-danger'}`} role="alert">
                                {message}
                            </div>
                        )}
                        <form onSubmit={handleSubmit} className="wrapper-form-login">
                            <div className="igahp-form-item">
                                <div className="igahp-m-regular neutral-90-text">Kata Sandi</div>
                                <input type="password" placeholder="******"
                                    className={`form-border-rad-12 igahp-m-regular neutral-90-text`}
                                    id="newPassword"
                                    value={newPassword}
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    required
                                />
                                {/* {passError && <div className="error-message text-danger">{passError}</div>} */}

                            </div>
                            <div className="igahp-form-item">
                                <div className="igahp-m-regular neutral-90-text">Konfirmasi Kata Sandi</div>
                                <input type="password" placeholder="******"
                                    className={`form-border-rad-12 igahp-m-regular neutral-90-text`}
                                    id="newPasswordConfirmation"
                                    value={newPasswordConfirmation}
                                    onChange={(e) => setNewPasswordConfirmation(e.target.value)}
                                    onBlur={handleBlur}
                                    required
                                />
                                {/* {passError && <div className="error-message text-danger">{passError}</div>} */}

                            </div>
                            <button
                                type="submit"
                                // disabled={isButtonProcess}
                                disabled={isLoading}
                                className="btn-login igahp-s-semibold">Simpan Password Baru</button>

                            {isLoading ?
                                <div className="divider-or">
                                    <div>Loading...</div>
                                </div>
                                : <p></p>}

                        </form>
                    </div>
                </div>
            </div>
            {/* <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-6">
                        <div className="card card-primary mt-5">
                            <div className="card-header">
                                <h3 className="card-title">Reset Password</h3>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="card-body">
                                    {message && (
                                        <div className={`alert ${message.includes('successful') ? 'alert-success' : 'alert-danger'}`} role="alert">
                                            {message}
                                        </div>
                                    )}
                                    <div className="form-group">
                                        <label htmlFor="token">Token</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="token"
                                            value={token}
                                            onChange={(e) => setToken(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="newPassword">New Password</label>
                                        <input
                                            type="password"
                                            className="form-control"
                                            id="newPassword"
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary btn-block">Reset Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default ResetPassword
