import React from 'react'
import PengembangAdminDashboard from './PengembangAdminDashboard'
import { AuthContext } from '../../AuthContext'
import { useContext } from 'react'
import PengembangProfile from './PengembangProfileFirstPage'
import PengembangProfileFirstPage from './PengembangProfileFirstPage'

const PengembangContentDepan = () => {


const { user, setUser } = useContext(AuthContext);

return (
    <>
{/* 
        {(user.pengembang_id == null) ? (
            <>
                <PengembangProfileFirstPage />
            </>
        ): (
            <>
                <PengembangAdminDashboard />
            </>
        )} */}
        
        <PengembangAdminDashboard />
    </>
  )
}

export default PengembangContentDepan
