import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { OnegreenProvider } from './OnegreenContext';
import { BrowserRouter as Router, Route } from 'react-router-dom';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <App />
    
    <OnegreenProvider>
      {/* <Router> */}
        <App />
      {/* </Router> */}
    </OnegreenProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


