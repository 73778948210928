import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(Cookies.getJSON('user') || null);
    const [isAuthenticated, setIsAuthenticated] = useState(
        Cookies.get('isAuthenticated') === 'true'
    );

    useEffect(() => {
        // Load isAuthenticated from cookie on component mount
        const savedIsAuthenticated = Cookies.get('isAuthenticated') === 'true';
        setIsAuthenticated(savedIsAuthenticated);

        const savedUser = Cookies.getJSON('user');
        if (savedUser) {
          setUser(savedUser);
        }
      }, []);

    const loginContext = (userData) => {
        // Make API call to authenticate the user
        // Example: authService.login(userData)
        // Handle the authentication response and update the user state
        setUser(userData);
        Cookies.set('user', userData, { expires: 1 / 24 }); 

        setIsAuthenticated(true);
        Cookies.set('isAuthenticated', true, { expires: 1 / 24 }); // Expires in 1 hour    
    };

    const logoutContext = () => {
        // Make API call to logout the user or clear the authentication state
        // Example: authService.logout()
        // Handle the logout action and update the user state
        setUser(null);
        Cookies.remove('user');

        setIsAuthenticated(false);
        // Remove isAuthenticated cookie
        Cookies.remove('isAuthenticated');
    };

    return (
        <AuthContext.Provider value={{ user, loginContext, logoutContext, isAuthenticated }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
