// src/components/EnvProvider.js
import React from 'react';

const EnvProvider = () => {
  return (
    <script>
      {`
        window.env = {
          API_URL: "${process.env.REACT_APP_API_ENDPOINT}"
        };
      `}
    </script>
  );
};

export default EnvProvider;
