import React from 'react'
import { useEffect } from 'react';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import axios from 'axios';


const PengajuanBghDetail = () => {

    // const { id } = useParams();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const [dataProfile, setDataProfile] = useState({});
    const [dataFile, setDataFile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);

    const [dataStatus, setDataStatus] = useState();
    const [dataPengajuanBgh, setDataPengajuanBgh] = useState({});


    useEffect(() => {
        console.log(user);
    }, []);


    useEffect(() => {
        // console.log('masuk ke pengembang lokasi profile...')
        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            (async () => {
                await fetchData();
                await fetchDataBgh();
                // await fetchDataStatusPengajuan();

                setIsLoading(false);
                setIsTokenExpired(false);
            })();
        }

    }, [isLoading, isTokenExpired]);


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        console.log(user);

        const url = apiUrl + "/api/igahp/lokasi/detail/" + user.perumahanId;

        let token = user.accessToken;

        // console.log('token...');
        // console.log(user);



        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // setIsLoading(false);
            // setIsTokenExpired(false);
            setDataProfile(data);

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };


    const fetchDataBgh = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        console.log(user);
        
        // alert(user.pengajuanbgh_id);

        const url = apiUrl + "/api/igahp/getPengajuanBghDetail/" + user.pengajuanbgh_id;

        let token = user.accessToken;

        // console.log('token...');
        // console.log(user);



        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // setIsLoading(false);
            // setIsTokenExpired(false);
            console.log('------------------respon api detail permohonan bgh --------------');
            console.log(data)
            setDataPengajuanBgh(data);

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };

    return (
        <>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Pengajuan Rumah Hijau</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">DataTables</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>


                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12">

                                {isLoading ?
                                    <p>Loading Data...</p> :


                                    <>

                                        {/* Profile Image */}
                                        <div className="card card-primary card-outline">
                                            <div className="card-body box-profile">
                                                {/* <div className="text-center">
                                                <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user4-128x128.jpg" alt="User profile picture" />
                                            </div> */}
                                                <h1>{dataProfile.nama_perumahan}</h1>
                                                <strong><i className="fas fa-map-marker-alt mr-1" /> Alamat</strong>
                                                <h5>{dataProfile.info_kelurahan}</h5>
                                                <br />

                                                <strong><i className="far fa-file-alt mr-1" /> Profile</strong>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="card">

                                                            {/* /.card-header */}
                                                            <div className="card-body p-0">
                                                                <table className="table table-sm">
                                                                    <thead>

                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Jenis Rumah</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                {dataProfile.jenis_perumahan === 0 ? 'rumah tapak' : 'rumah susun'}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Kecamatan IMB/PBG</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                {dataProfile.infoKecamatan}
                                                                            </td>
                                                                        </tr>
                                                                        
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* /.card-body */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="card">

                                                            {/* /.card-header */}
                                                            <div className="card-body p-0">
                                                                <table className="table table-sm">
                                                                    <thead>
                                                                        {/* <tr>
                                                                    
                                                                    <th>Task</th>
                                                                    <th>Progress</th>
                                                                    <th>Label</th>
                                                                </tr> */}
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>IMB/PBG</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                {dataProfile.imb}
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Tanggal IMB/PBG</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                {dataProfile.tgl_imb}
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* /.card-body */}
                                                        </div>
                                                    </div>
                                                </div>

                                                <strong><i className="far fa-file-alt mr-1" /> Dokumen Pengajuan</strong>
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="card">

                                                            {/* /.card-header */}
                                                            <div className="card-body p-0">
                                                                <table className="table table-sm">
                                                                    <thead>

                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Dokumen Surat Permohonan</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_surat_permohonan_penilaian_kinerja_bgh}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Dokumen Surat Perencanaan</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_sertifikat_perencanaan_bgh}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Dokumen IMB/PBG Induk</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_imbpbg_induk_cap_dinas}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Dokumen IMB/PBG Rumah Usulan</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_imbpbg_rumah_usulan_cap_dinas}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Dokumen Siteplan Cap Dinas</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_siteplan_perumahan_cap_dinas}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td>Dokumen Siteplan Jalur Air</td>
                                                                            <td>:</td>
                                                                            {/* <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_siteplan_jalur_air_cap_dinas}`} target="_blank" >Lihat File</a>
                                                                            </td> */}

                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displayimage/${dataPengajuanBgh.dok_siteplan_jalur_air_cap_dinas}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>


                                                                        <tr>
                                                                            <td>Dokumen RKS</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_rks}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* /.card-body */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="card">

                                                            {/* /.card-header */}
                                                            <div className="card-body p-0">
                                                                <table className="table table-sm">
                                                                    <thead>
                                                                        {/* <tr>
                                                                    
                                                                    <th>Task</th>
                                                                    <th>Progress</th>
                                                                    <th>Label</th>
                                                                </tr> */}
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>Dokumen BOQ</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_boq}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Dokumen Denah Rumah</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displayimage/${dataPengajuanBgh.dok_denah_rumah}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>Dokumen Tampak Depan</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displayimage/${dataPengajuanBgh.dok_tampak_depan}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Dokumen Tampak Belakang</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displayimage/${dataPengajuanBgh.dok_tampak_belakang}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>

                                                                        <tr>
                                                                            <td>Dokumen Potongan Bangunan</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displayimage/${dataPengajuanBgh.dok_potongan_bangunan}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr>

{/* 
                                                                        <tr>
                                                                            <td>Dokumen Penilaian BGH</td>
                                                                            <td>:</td>
                                                                            <td>
                                                                                <a href={`${apiUrl}/api/public/displaypdf/${dataPengajuanBgh.dok_penilaian}`} target="_blank" >Lihat File</a>
                                                                            </td>
                                                                        </tr> */}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                            {/* /.card-body */}
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            {/* /.card-body */}
                                        </div>
                                        {/* /.card */}



                                    </>
                                }

                            </div>
                            {/* /.col */}

                        </div>
                        {/* /.row */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}


            </div>
            {/* /.content-wrapper */}




        </>
    )
}

export default PengajuanBghDetail
