import React from 'react'

import LocationSelect from '../../shared/LocationSelect'
import { useState } from 'react'
import { useRef } from 'react'
import jwt_decode from 'jwt-decode'
import axios from 'axios';
import { AuthContext } from '../../../AuthContext'
import { useContext } from 'react'
import { refreshTokenRequest } from '../../refreshTokenRequest'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

const ModalInfoPenghuni = ({ onClose }) => {


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);



  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  const [modalVisible, setModalVisible] = useState(false);

  const [nama, setNama] = useState('');
  const [usia, setUsia] = useState('');
  const [npwpPerusahaan, setNpwpPerusahaan] = useState('');
  const [emailPerusahaan, setEmailPerusahaan] = useState('');
  const [websitePerusahaan, setWebsitePerusahaan] = useState('');
  const [alamatPerusahaan, setAlamatPerusahaan] = useState('');
  const [kodePos, setKodePos] = useState('');
  const [rt, setRt] = useState('');
  const [rw, setRw] = useState('');
  const [npwp, setNpwp] = useState('');
  const [telpKantor, setTelpKantor] = useState('');
  const [telpDirektur, setTelpDirektur] = useState('');
  const [namaPicPemohon, setNamaPicPemohon] = useState('');
  const [telpPicPemohon, setTelpPicPemohon] = useState('');
  const [dok1, setDok1] = useState(null);
  const [dok2, setDok2] = useState(null);
  const [dokNpwp, setDokNpwp] = useState(null);
  const [jenisKelamin, setJenisKelamin] = useState("");
  const [tanggalLahir, setTanggalLahir] = useState('');
  const [email, setEmail] = useState('');
  const [alamat, setAlamat] = useState('');
  const [nomorBnba, setNomorBnba] = useState('');

  const [namaPemilik, setNamaPemilik] = useState('');


  const [nik, setNik] = useState('');
  const [kk, setKk] = useState('');
  const [nomorHp, setNomorHp] = useState('');

  const [statusPemilikanTanah, setStatusPemilikanTanah] = useState('');

  const [selectedPekerjaanOption, setSelectedPekerjaanOption] = useState('');
  


  let refreshToken = null;

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;


  const handleVillageChange = (villageId) => {
    setSelectedVillage(villageId);
  };


  // const handleOptionAsosiasiChange = event => {
  //   setSelectedAsosiasiOption(event.target.value);
  // };

  const [wilayah, setWilayah] = useState('');
  const { user, setUser } = useContext(AuthContext);
  const [selectedVillage, setSelectedVillage] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedAsosiasiOption, setSelectedAsosiasiOption] = useState('');

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    (async () => {
      // await fetchOptionsFromApi();

      // await fetchOptionsFromApi()
      //   .then(data => setOptions(data))
      //   .catch(error => console.error('Error fetching options:', error));

      await fetchOptionsPekerjaanFromApi()
        .then(data => setOptions(data))
        .catch(error => console.error('Error fetching options:', error));


      await fetchData();



      setIsLoading(false);
      setIsTokenExpired(false);
    })();

  }, []);


  const fetchOptionsPekerjaanFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllPekerjaan');
      const data = await response.json();
      // console.log('data pekerjaan ....');
      // console.log(data);
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };


  // const fetchOptionsFromApi = async () => {
  //   try {
  //     const response = await fetch(apiUrl + '/api/public/getAllAsosiasi');
  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     throw new Error('Failed to fetch options from API');
  //   }
  // };


  const handleOptionAsosiasiChange = event => {
    setSelectedAsosiasiOption(event.target.value);
  };

  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }
    try {
      console.log('user id ' + user.id);
      console.log('pengembang id ' + user.pengembang_id);
      const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + user.pengembang_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);

      console.log(response.data);
      setData(response.data);


      setNama(response.data.pengembang_nama);
      setNik(response.data.nik);
      setKk(response.data.kk);
      setJenisKelamin(response.data.jk)
      setNpwp(response.data.npwp);
      setEmail(response.data.email);
      setNamaPicPemohon(response.data.pic);
      setTelpPicPemohon(response.data.picTelp);
      setTelpKantor(response.data.telp);
      setKodePos(response.data.kodePos);
      setSelectedVillage(response.data.wilayah);
      setSelectedAsosiasiOption(response.data.asosiasiId);
      setAlamat(response.data.alamat);
      setStatusPemilikanTanah(response.data.statusPemilikTanah)
      setNamaPemilik(response.data.namaPemilik)
      setTanggalLahir(response.data.tglLahir)

      setDok1(response.data.doc1)
      setDok2(response.data.doc2)

      setSelectedPekerjaanOption(response.data.pekerjaanId)

      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // alert('simpan')


    // console.log('user === ' + user)

    const formData = new FormData();

    formData.append('id', user.pengembang_id);
    formData.append('userId', user.id);    
    formData.append('nama', nama);
    // formData.append('namaPerusahaan', namaPerusahaan);
    formData.append('npwp', npwp);
    formData.append('jenisKelamin', jenisKelamin);
    formData.append('email', email);
    formData.append('nomorBnba', nomorBnba);
    formData.append('noKk', kk);
    formData.append('nik', nik);
    formData.append('alamat', alamat);
    formData.append('namaPemilik', namaPemilik);
    formData.append('statusPemilikanTanah', statusPemilikanTanah);
    formData.append('kodeWilayah', selectedVillage);
    formData.append('tanggalLahir', tanggalLahir);
    formData.append('pekerjaan', selectedPekerjaanOption);

    

    // formData.append('websitePerusahaan', websitePerusahaan);
    // formData.append('alamatPerusahaan', alamatPerusahaan);
    // formData.append('asosiasiId', selectedAsosiasiOption);
    // formData.append('kodePos', kodePos);
    // formData.append('rt', rt);
    // formData.append('rw', rw);
    // formData.append('telpKantor', telpKantor);
    // formData.append('telpDirektur', telpDirektur);
    // formData.append('namaPicPemohon', namaPicPemohon);
    // formData.append('telpPicPemohon', telpPicPemohon);
    // formData.append('kodeWilayah', selectedVillage);
    // formData.append('dokAktePendirian', dokAktePendirian);
    formData.append('doc1', dok1);
    formData.append('doc2', dok2);


    const sUrl = apiUrl + '/api/igahp/editPengembangProfilePerorangan';

    // console.log(sUrl);
    try {
      await fetch(sUrl, {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          // console.log(data);
          // alert(data.status);


          Toast.fire({
            icon: 'success',
            title: data.status
          });

          // setModalVisible(false);

          // navigate("/igahp/pengembang/PengembangProfile");

        })
        .catch(error => {
          // Handle error
          // console.error(error);

          Toast.fire({
            icon: 'error',
            title: error
          });

          // alert(error);
        });

    } catch (error) {
      console.error('An error occurred:', error);

    }



  };

  const handleClose = () => {
    // Lakukan apa pun yang diperlukan sebelum menutup modal
    // ...
    // Panggil fungsi onClose untuk memberi tahu parent bahwa modal ditutup
    onClose();
  };


  const handleFileChangeDoc2 = (e) => {
    const file = e.target.files[0];
    //ukuran tidakboleh lebih dari 5 mb
    if (file && file.size > 10 * 1024 * 1024) {
      alert('Ukuran file NIB tidak boleh lebih dari 5MB');
      e.target.value = null; // Clear the file input
    } else {
      setDok2(file);
    }
  };


  const handleFileChangeDoc1 = (e) => {
    const file = e.target.files[0];
    //ukuran tidakboleh lebih dari 5 mb
    if (file && file.size > 10 * 1024 * 1024) {
      alert('Ukuran file AKTA tidak boleh lebih dari 5MB');
      e.target.value = null; // Clear the file input
    } else {
      setDok1(file);
    }
  };

  const handleChange = (e) => {
    setSelectedPekerjaanOption(e.target.value);
  };


  return (

    <>


      <div className="modal fade" id="ModalInfoPenghuni" tabIndex={-1} role="dialog"
        aria-labelledby="ModalInfoPenghuniLabel" aria-hidden="true" data-backdrop="static">





        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Input Data</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body">

                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">

                    <div className="row">
                      <div className="col-md-12">

                        <div className="card card-primary">
                          <div className="card-header">
                            <h3 className="card-title">Profile Penghuni</h3>
                          </div>
                          <div className="card-body">


                            <div className="row">

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Nama</label>
                                  <input type="text" className="form-control"
                                    value={nama} onChange={(e) => setNama(e.target.value)}
                                    placeholder='Cth. Budiyanto'
                                  />
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Tanggal Lahir</label>
                                  <input type="date" className="form-control"
                                    value={tanggalLahir} onChange={(e) => setTanggalLahir(e.target.value)}
                                    placeholder='Masukkan Tanggal Lahir'
                                  />
                                </div>
                              </div>


                              <div className="col-md-3">
                                <div className="form-group">
                                  <label>Jenis Kelamin</label>
                                  <select className="form-control" value={jenisKelamin} onChange={(e) => setJenisKelamin(e.target.value)}>
                                    <option value="" disabled>Pilih Jenis Kelamin</option>
                                    <option value="laki-laki">Laki-laki</option>
                                    <option value="perempuan">Perempuan</option>
                                    {/* <option value="lainnya">Lainnya</option> */}
                                  </select>
                                </div>
                              </div>

                            </div>

                            {/* <div className="col-md-3"> */}


                            {/* </div> */}


                            <div className="row">

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>NIK</label>
                                  <input type="text" className="form-control"
                                    value={nik} onChange={(e) => setNik(e.target.value)}
                                    placeholder='327501240490005'
                                    maxLength={16}
                                  />
                                </div>
                              </div>


                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>NO. KK</label>
                                  <input type="text" className="form-control"
                                    value={kk} onChange={(e) => setKk(e.target.value)}
                                    placeholder='327501240490005'
                                    maxLength={16}
                                  />
                                </div>
                              </div>


                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>NPWP</label>
                                  <InputMask className="form-control"
                                    mask="99.999.999.9-999.999"
                                    maskChar="_"
                                    value={npwp}
                                    onChange={(e) => setNpwp(e.target.value)}
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Pekerjaan</label>
                                  {/* <input type="text" className="form-control"
                                    value={nomorBnba} onChange={(e) => setNomorBnba(e.target.value)}
                                    placeholder='Pekerjaan'
                                  /> */}
                                  <select id="optionselect" className="form-control" value={selectedPekerjaanOption} onChange={handleChange}>
                                    <option value="">Pilih Pekerjaan</option>
                                    {options.map(option => (
                                      <option key={option.id} value={option.id}>
                                        {option.namaPekerjaan}
                                      </option>
                                    ))}
                                  </select>

                                </div>
                              </div>

                            </div>

                            <div className="row">
  
                            </div>



                          </div>
                        </div>

                        <div className="card card-secondary">
                          <div className="card-header">
                            <h3 className="card-title">Profile Pemilik</h3>
                          </div>
                          <div className="card-body">


                            <div className="row">

                    
                            </div>

  

                            <div className="row">


                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Nama Lengkap Pemilik</label>
                                  <input type="text" className="form-control"
                                    value={namaPemilik} onChange={(e) => setNamaPemilik(e.target.value)}
                                    placeholder='Cth. Susi'
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Status Pemilik Tanah</label>
                                  <input type="text" className="form-control"
                                    value={statusPemilikanTanah} onChange={(e) => setStatusPemilikanTanah(e.target.value)}
                                    placeholder=''
                                    maxLength={15}
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Alamat</label>
                                  <input type="text" className="form-control"
                                    value={alamat} onChange={(e) => setAlamat(e.target.value)}
                                    placeholder='Cth. Jalan Merdeka No. 10'
                                  />
                                </div>
                              </div>


                            </div>



                          </div>
                        </div>

                      </div>
                    </div>



                    <br />

                    <div className="form-group">
                      <label>Lokasi Rumah</label>
                      <div className="row">
                        <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                      </div>
                    </div>

                    <br />

                    <div className="card card-success">
                      <div className="card-header">
                        <h3 className="card-title">Dokumen</h3>
                      </div>

                      <div className="card-body">
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Dokumen pertama</label>
                                <input
                                  className="form-control form-control"
                                  type="file" accept=".pdf"
                                  onChange={handleFileChangeDoc1}
                                  require
                                />
                                <small>Format Dokumen berbentuk .pdf  </small><br></br>                                
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Dokumen kedua</label>
                                <div className="mb-6">
                                  <input
                                    className="form-control form-control"                                  
                                    type="file"
                                    accept=".pdf"                                    
                                    onChange={handleFileChangeDoc2}                                    
                                    require
                                  />
                                  <small>Format Dokumen berbentuk .pdf  </small><br></br>                                 
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>


                      </div>
                    </div>




                  </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}

              </div>

              <div className="modal-footer justify-content-between">
                {/* <button type="button" className="btn btn-default" data-dismiss="modal">Tutup</button> */}
                <button type="submit" className="btn btn-primary">Simpan</button>
              </div>
            </form>

          </div>

        </div>

      </div>

    </>


  )
}

export default ModalInfoPenghuni
