import React from 'react'
import { useEffect } from 'react'

const LokasiProfile = () => {

    useEffect(() => {

        console.log('masuk ke menu profile lokasi');

    }, []);


    return (
        <></>
    )
}

export default LokasiProfile
