import { useEffect, useState, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { AuthContext } from './../AuthContext';
import { refreshTokenRequest } from './refreshTokenRequest';
import { OnegreenContext } from '../OnegreenContext';
import axios from 'axios';

const PictureProfile = () => {
    const { profileData } = useContext(OnegreenContext);
    const { user, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [userPhoto, setUserPhoto] = useState('/dist/img/avatar_default.png');
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const fileInputRef = useRef(null);

    useEffect(() => {
        if (user.photo) {
            setUserPhoto(`${apiUrl}/api/public/displayimage/${user.photo}`);
        }
    }, [user.photo]);

    useEffect(() => {
        if (profileData?.photoProfile) {
            setUserPhoto(`${apiUrl}/api/public/displayimage/${profileData.photoProfile}`);
        }
    }, [profileData]);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 10 * 1024 * 1024) { // 1MB size limit
                alert('File size exceeds 1MB');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setUserPhoto(reader.result);
                uploadImage(file);
            };
            reader.readAsDataURL(file);
        }
    };

    const uploadImage = async (file) => {
        let decodedToken = jwt_decode(user.accessToken);
        let currentDate = new Date();

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                const refreshToken = await refreshTokenRequest(paramRefreshToken);
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                user.accessToken = refreshToken.accessToken;
                setIsTokenExpired(true);
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
        } else {
            console.log("Valid token");
        }

        const formData = new FormData();
        formData.append('id', user.id);
        formData.append('photo', file);

        const sUrl = `${apiUrl}/api/igahp/uploadPhotoProfile`;

        try {
            const response = await fetch(sUrl, {
                credentials: 'include',
                method: 'POST',
                body: formData,
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken,
                },
            });

            const data = await response.json();
            if (data) {
                console.log('Image uploaded successfully.');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    return (
        <>
            <label htmlFor="fileInput">
                <img
                    src={userPhoto}
                    className="img-circle elevation-2"
                    alt="profile"
                    style={{ cursor: 'pointer' }}
                />
            </label>
            <input
                type="file"
                id="fileInput"
                ref={fileInputRef}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
        </>
    );
};

export default PictureProfile;
