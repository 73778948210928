import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import Swal from 'sweetalert2';

import { format, parseISO } from 'date-fns';




import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';


const ModalUnggahSertifikat = ({ onClose, dataPemasaran }) => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });

    const [nomorSertifikat, setNomorSertifikat] = useState(null);

    const [tglTerbitSertfikat, setTglTerbitSertfikat] = useState('');

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);



    const handleClose = () => {
        onClose();
    };


    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const [isButtonProcess, setIsButtonProcess] = useState(false);

    let refreshToken = null;

    const navigate = useNavigate();

    const { user, setUser } = useContext(AuthContext);

    const [selectedTipeOption, setSelectedTipeOption] = useState('');

    const handleOptionTipeChange = event => {
        setSelectedTipeOption(event.target.value);
    };

    const [optionsTipe, setOptionsTipe] = useState([]);

    const [dokSertifikat, setDokSertifikat] = useState(null);




    // const fetchOptionsTipeFromApi = async () => {
    //     try {
    //         const response = await fetch(apiUrl + '/api/public/getAllBanks');
    //         const data = await response.json();
    //         return data;
    //     } catch (error) {
    //         throw new Error('Failed to fetch options from API');
    //     }
    // };



    const fetchOptionsFromApiTipeRumah = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/tipeRumahByPerumahanId/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };


    useEffect(() => {

        (async () => {

            await fetchOptionsFromApiTipeRumah()
                .then(data => setOptionsTipe(data))
                .catch(error => console.error('Error fetching options:', error));


        })();


    }, []);


    useEffect(() => {
            fetchData();
    }, [selectedTipeOption]);


    const [data, setData] = useState([]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        var pilihanTipe = selectedTipeOption;

        if (pilihanTipe == null || pilihanTipe === '') {
            pilihanTipe = 0;
        }

        try {
            const response = await axios.get(apiUrl + '/api/igahp/getTipeRumahById/' + pilihanTipe, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            if (response && response.data) {
                // console.log(response.data);

                setData(response.data);
                let aa = response.data;
                if (aa.nomorSertifikatHijau) {
                    setNomorSertifikat(aa.nomorSertifikatHijau);
                } else {
                    setNomorSertifikat('');
                }
                if (aa.tglSertifikatHijau) {
                    setTglTerbitSertfikat(convertToDate(aa.tglSertifikatHijau))
                } else {
                    setTglTerbitSertfikat('');
                }
                
                setIsTokenExpired(false);
            } else {
                console.error('No data returned from API.');
                setNomorSertifikat('')
                setTglTerbitSertfikat('');                
            }
        } catch (error) {
            console.error('Error getting API data...', error);
            if (error.response && error.response.status === 401) {
                // Handle unauthorized error, possibly refresh token or prompt login
            } else {
                // Handle other errors
            }
            navigate('/login');
        }

    };

    const convertToDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, 'yyyy-MM-dd');
    };


    const handleSubmit = async (e) => {
        setIsButtonProcess(true);

        e.preventDefault();


        var isTambahData = true;


        if (user.tiperumah_state == 'edit') {
            isTambahData = false;
        } else {
            isTambahData = true;
        }

        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        formData.append('id', selectedTipeOption);
        // formData.append('id', user.perumahan_id);
        formData.append('nomorSertifikat', nomorSertifikat);
        formData.append('tglSertifikat', tglTerbitSertfikat);
        formData.append('dokSertifikat', dokSertifikat);

        // formData.append('dokRks', dokRks);
        // formData.append('dokBoq', dokBoq);

        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;

            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            await fetch(apiUrl + '/api/igahp/uploadSertifikatHijau', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    // alert(data.status);

                    Toast.fire({
                        icon: 'success',
                        title: data.status
                    });

                    setIsButtonProcess(false);

                    // navigate('/igahp/pengembang/PengembangLokasiTipeRumah');
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    setIsButtonProcess(false);
                    console.error(error);
                    // alert(error);

                    Toast.fire({
                        icon: 'error',
                        title: error
                    });

                });

        } catch (error) {
            setIsButtonProcess(false);
            console.error('An error occurred:', error);
        }

    };

    return (
        <>
            {/*Modal Upload File Sertifikat Hijau*/}
            {/* <div className="modal fade" id="modal-lg"> */}
            <div className="modal fade" id="ModalUnggahSertifikat" tabIndex={-1} role="dialog" aria-labelledby="ModalUnggahSertifikatLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Isi Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <section className="content">
                                <div className="container-fluid">

                                    <form onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="card card-success">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Unggah File Sertifikat Hijau</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="form-group">

                                                            <p className="text-muted mb-0"><small>Pilih tipe rumah yang sesuai dengan
                                                                sertifikat hijau</small>
                                                            </p>
                                                        </div>
                                                        
                                                            <div className="form-group">
                                                                <label>Tipe Rumah</label>
                                                                <select className="form-control" value={selectedTipeOption} onChange={handleOptionTipeChange} required>
                                                                    <option value="">Pilih</option>
                                                                    {optionsTipe.map(option => (
                                                                        <option key={option.id} value={option.id}>
                                                                            {option.nama}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Nomor
                                                                            Sertifikat</label>
                                                                        <input
                                                                            placeholder="xx/xx/xxxxx/xxxx"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={nomorSertifikat}
                                                                            onChange={(e) => setNomorSertifikat(e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Tgl Terbit
                                                                            Sertifikat</label>
                                                                        <div className="input-group">
                                                                            <input
                                                                                className="form-control bg-white" required data-pristine-required-message="Tanggal terbit sertifikat"
                                                                                type="date"
                                                                                value={tglTerbitSertfikat}
                                                                                onChange={(e) => setTglTerbitSertfikat(e.target.value)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                {/* <label htmlFor="dafaultLabel" className="text-gray-dark">Unggah Serifikat
                                                                    Hijau</label> */}
                                                            <label
                                                                htmlFor="exampleInputFile"
                                                                className="text-gray-dark"
                                                                style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                                            >
                                                                Unggah Sertifikat Hijau
                                                                {data.docSertifikatHijau && (
                                                                    <a
                                                                        href={`${apiUrl}/api/public/displaypdf/${data.docSertifikatHijau}`}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                    >
                                                                        Lihat File
                                                                    </a>
                                                                )}
                                                            </label>


                                                                <div className="custom-file">
                                                                    {/* <input type="file" className="custom-file-input" id="customFile" /> */}
                                                                    <input
                                                                        className="form-control form-control"
                                                                        type="file"
                                                                        accept=".pdf"
                                                                        onChange={(e) => setDokSertifikat(e.target.files[0])}
                                                                        required
                                                                    />
                                                                    {/* <label className="custom-file-label" htmlFor="customFile">Choose file</label> */}
                                                                </div>
                                                                <small>1. File Dokumen harus dalam <b>format .pdf</b>
                                                                    <br />
                                                                    2. Dokumen disahkan lengkap dengan <b>cap dinas dan tanda
                                                                        tangan</b></small>
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer justify-content-end align-content-between">
                                            <button type="submit" className="btn btn-success">Simpan</button>
                                        </div>
                                    </form>
                                </div>
                            </section>
                        </div>

                    </div>
                </div>
            </div>
            {/* / .Modal Upload File Sertifikat Hijau*/}


        </>
    )
}

export default ModalUnggahSertifikat
