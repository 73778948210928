import React from 'react'

import { AuthContext } from '../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';
import ModalBanks from './childs/ModalBanks';
import Swal from 'sweetalert2';



const ManajemenBanks = () => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const [isModalOpen, setModalOpen] = useState(false);
  const [editingData, setEditingData] = useState(null);
  const { user, setUser } = useContext(AuthContext);
  let refreshToken = null;
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate()
  const { loginContext } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedRoleName, setSelectedRoleName] = useState('');

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;




  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/js/content.js";
    script.async = true;
    document.body.appendChild(script);

    if (isLoading || isTokenExpired) {
      console.log('Use effetc load fetchdata calling.....');
      // fetchDataRoles();
      fetchData();
    }
    return () => { script.remove(); }

  }, [isLoading, isTokenExpired]);


  const handleAddClick = () => {
    setEditingData(null);
    setModalOpen(true);
  }

  const handleEditClick = (data) => {
    setEditingData(data);    
    setModalOpen(true);
  };


  const handleDeleteClick = async (itemToDelete) => {
    setModalOpen(false);
    // Display confirmation dialog
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');

    
    // If user confirms deletion
    if (confirmDelete) {
      


      const formData = new FormData();

      formData.append('id', itemToDelete.id);
      
      const sUrl = apiUrl + '/api/igahp/hapusBank';

      // console.log(sUrl);
      try {
        await fetch(sUrl, {
          credentials: 'include', //getcookies
          method: 'POST',
          // body: JSON.stringify(formData),
          body: formData,
          headers: {
            // 'Content-Type': 'application/json',
            Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
            'X-XSRF-TOKEN': user.csrfToken
          },
        })
          .then(response => response.json())
          .then(data => {

            fetchData();

            Toast.fire({
              icon: 'success',
              title: data.status
            });

            // setModalVisible(false);

            navigate("/igahp/admin/ManajemenBanks");

          })
          .catch(error => {

            Toast.fire({
              icon: 'error',
              title: error
            });

            // alert(error);
          });

      } catch (error) {
        console.error('An error occurred:', error);

      }



    }
  };

  // Function to close the modal
  const handleModalClose = () => {
    console.log('Modal Close')
    setModalOpen(false);
    fetchData();

  };

  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    try {
      const response = await axios.get(apiUrl + '/api/igahp/getAllBanks', {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      console.log(response.data);
      setData(response.data);
      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }

    
  };







  return (
    <>
      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Manajemen Banks</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Manajemen Banks</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">

            <div className="row">

              {isLoading ?
                <p>Loading Data...</p> :

                <>
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        {/* <h3 className="card-title">Approval User </h3> */}

                         <button
                          type="button"
                          id="tambahModal" data-toggle="modal"
                          data-target="#ModalBank"
                          className="btn btn-success float-left"
                          onClick={handleAddClick}
                        >Tambah Data</button>
 
                      </div>
                      <div className="card-body">
                        <table id="example1" className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              {/* <th>ID</th> */}
                              <th>KODE</th>
                              <th>NAMA</th>
                              <th>LOGO</th>
                              <th>AKSI</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item) => (
                              <tr key={item.id}>
                                {/* <td>{item.id}</td> */}
                                <td>{item.kodeBank}</td>
                                <td>{item.namaBank}</td>
                                <td>
                                  <img
                                    src={`${apiUrl}/api/public/displayimage/${item.logo}`}
                                    alt="gambar lokasi"
                                    style={{ maxWidth: '100px', maxHeight: '100px', width: 'auto', height: 'auto' }}
                                    className="img-fluid"
                                  />
                                </td>
                                <td style={{ textAlign: 'center' }}>
                                  <button
                                    type="button"
                                    id="editHapusModal"
                                    data-toggle="modal"
                                    data-target="#ModalBank"
                                    className="btn btn-danger mr-2"
                                    onClick={() => handleDeleteClick(item)}
                                  >
                                    Hapus data
                                  </button>
                                  <button
                                    type="button"
                                    id="editHapusModal"
                                    data-toggle="modal"
                                    data-target="#ModalBank"
                                    className="btn btn-primary"
                                    onClick={() => handleEditClick(item)}
                                  >
                                    Edit Data
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>

                        {/* {isModalOpen && <ModalBanks onClose={handleModalClose} editingData={editingData} />} */}
                      </div>
                    </div>
                  </div>
                </>

              }


            </div>

            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>

       
        {isModalOpen && <ModalBanks onClose={handleModalClose} editingData={editingData} />}

        
      </div>
      {/* /.content-wrapper */}

      {/* <Footer /> */}
    </>
  )
}

export default ManajemenBanks
