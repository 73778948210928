import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { AuthContext } from "../../../AuthContext";
import { useContext } from 'react';
import { refreshTokenRequest } from '../../refreshTokenRequest';

import axios from 'axios';

const LolosSubsidiChecking = () => {


    // const history = useHistory();

    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();

    const [dataSudahDiPilih, setDataSudahDiPilih] = useState(false);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    let refreshToken = null;

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);

    const { loginContext } = useContext(AuthContext);

    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            const response = await fetch(apiUrl + '/api/igahp/listStatusSubsidiChecking', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data2 = await response.json();
            // setIsLoading(false);
            // setIsTokenExpired(false);
            console.log('------------------respon api data awal bgh --------------');
            console.log(data2)
            setData(data2);
            setIsLoading(false);

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };

    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");
    }

    let sequenceNumber = 1;

    const [selectedRows, setSelectedRows] = useState([]); // State for selected rows
    const [transferredData, setTransferredData] = useState([]); // State for transferred data
    const [isChecking, setIsChecking] = useState(false); // State to indicate API check in progress

    // Handle individual checkbox change
    const handleCheckboxChange = (item) => {
        setSelectedRows((prevSelectedRows) => {
            if (prevSelectedRows.includes(item)) {
                return prevSelectedRows.filter((i) => i !== item);
            } else {
                return [...prevSelectedRows, item];
            }
        });
    };

    // Handle "Select All" checkbox change
    const handleSelectAllChange = () => {
        if (selectedRows.length === data.length) {
            setSelectedRows([]); // Deselect all
        } else {
            setSelectedRows(data); // Select all
        }
    };

    // Handle transfer of selected rows after API check
    const handleTransfer = async () => {
        setIsChecking(true);
        try {
            // Check if all selected rows can be transferred
            const results = await Promise.all(
                selectedRows.map(async (item) => {


                    // const response = await 
                    // fetch(`${apiUrl}/api/igahp/cekSubsidiChecking?nik=${encodeURIComponent(item.nik)}&namaLengkap=${encodeURIComponent(item.namaLengkap)}`, {

                    //     headers: {
                    //         Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    //     },
                    // });
                    // if (!response.ok) {
                    //     throw new Error('Network response was not ok');
                    // }

                    // const canTransfer = await response.json();

                    const canTransfer = true;

                    return { item, canTransfer };
                })
            );

            // Filter out items that can be transferred
            const transferableItems = results.filter(result => result.canTransfer).map(result => result.item);

            // alert(transferableItems.length);


            if (transferableItems.length > 0) {

                const result = await axios.post(
                    apiUrl + '/api/igahp/transfer',
                    transferableItems,
                    {
                        headers: {
                            Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,  // Replace `yourToken` with the actual token
                        },
                    }
                );

                alert("Data telah masuk dalam antrian subsidi checking..")

                // Update transferred data
                setTransferredData((prevTransferredData) => [
                    ...prevTransferredData,
                    ...transferableItems,
                ]);

                // Remove transferred items from the original data
                // setData((prevData) => prevData.filter((item) => !transferableItems.includes(item)));
            }

            // Clear the selected rows
            setSelectedRows([]);
        } catch (error) {
            console.error('Error during API check:', error);
            alert('An error occurred during the API check.');
        } finally {
            setIsChecking(false);
            setIsLoading(true);
            fetchData();
        }
    };

    // let sequenceNumber = 1; // Initialize sequence number

    return (
        <>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Status Subsidi Checking</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">List Status</li>
                                </ol>
                            </div>
                        </div>
    
                        {isLoading ? (
                            <p>Loading Data...</p>
                        ) : (
                            <div className="card">
                                <div className="card-header">
                                    <div className="card-info"></div>
                                    <div className="row">
                                        <div className="col-12"></div>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <table id="example1" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>NAMA PROVINSI</th>
                                                <th>STATUS SUBSIDI CHECKING</th>
                                                <th style={{ textAlign: 'right' }}>JUMLAH</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((item) => (
                                                <tr key={item.id}>
                                                    <td>{sequenceNumber++}</td>
                                                    <td>{item.namaProvinsi}</td>
                                                    <td>{item.statusSubsidi}</td>
                                                    <td style={{ textAlign: 'right' }}>
                                                        {item.jumlah.toLocaleString()}
                                                    </td>    
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        )}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}
        </>
    );
    

}

export default LolosSubsidiChecking
