import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMap from 'highcharts/modules/map';
import HighchartsExporting from 'highcharts/modules/exporting';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { useNavigate } from 'react-router-dom';


// Initialize modules
if (typeof Highcharts === 'object') {
    HighchartsMap(Highcharts);
    HighchartsExporting(Highcharts);
}

const DemoMapIndonesiaHighChart = () => {
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const { user, setUser } = useContext(AuthContext);

    let refreshToken = null;

    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        const fetchData = async () => {
            const topologyResponse = await fetch(
                'https://code.highcharts.com/mapdata/countries/id/id-all.topo.json'
            );
            const topology = await topologyResponse.json();

            const data = await dataSebaranRumah();

            // const data = [
            //     ['id-3700', 10], 
            //     ['id-ac', 11],  // aceh
            //     ['id-jt', 12],  // jawa tengah
            //     ['id-be', 13],  // bengkulu
            //     ['id-bt', 14],  // banten
            //     ['id-kb', 15],  // kalimantan barat
            //     ['id-bb', 16],  //bangka belitung
            //     ['id-ba', 17],  // bali
            //     ['id-ji', 18],  // Jawa Timur
            //     ['id-ks', 19],  // kalimantan selatan
            //     ['id-nt', 20],  // NTT
            //     ['id-se', 21],  //sulawesi selatan
            //     ['id-kr', 22],   // kepulauan riau
            //     ['id-ib', 23],   //papua barat/ irian jaya barat
            //     ['id-su', 24],  // sumatera utara
            //     ['id-ri', 25],  // riau
            //     ['id-sw', 26],   // Sulawesi Utara
            //     ['id-ku', 27],  // kalimantan utara
            //     ['id-la', 28],   // Maluku utara
            //     ['id-sb', 29],  // sumatera barat
            //     ['id-ma', 30],  // Maluku
            //     ['id-nb', 31],  // ntb
            //     ['id-sg', 32],  // Sulawesi Tenggara
            //     ['id-st', 33],  // Sulawesi Tengah
            //     ['id-pa', 34],  // papua
            //     ['id-jr', 35],  // jawa barat
            //     ['id-ki', 36],  // kalimantan timur
            //     ['id-1024', 37],   // lampung
            //     ['id-jk', 38],   // Jakarta Raya
            //     ['id-go', 39],   // gorontalo
            //     ['id-yo', 40],   // Yogyakarta
            //     ['id-sl', 41],   // sumatera selatan
            //     ['id-sr', 42],   // sulawesi barat
            //     ['id-ja', 43],   // jambi
            //     ['id-kt  ', 44]     // kalimantan tengah
            // ];



            // Create the chart
            Highcharts.mapChart('containerHighChart', {

                
                chart: {
                    map: topology
                },

                title: {
                    text: 'Sebaran perumahan hijau Indonesia'
                },

                subtitle: {
                    text: 'Source map: <a href="http://code.highcharts.com/mapdata/countries/id/id-all.topo.json">Indonesia</a>'
                },

                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    }
                },

                colorAxis: {
                    min: 0
                },

                series: [{
                    data: data,
                    name: 'Sebaran perumahan',
                    states: {
                        hover: {
                            color: '#BADA55'
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}'
                    }
                }]
            });

            setLoading(false);
        };

        fetchData();

        // return () => {
        //     Highcharts.chart('container').destroy();
        // };

    }, []);


    const dataSebaranRumah = async () => {


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }

        try {
            // const token = 'YOUR_ACCESS_TOKEN'; // Replace 'YOUR_ACCESS_TOKEN' with your actual access token
            const response = await fetch(apiUrl + '/api/igahp/getDataPetaSebaranRumah', {

                headers: {

                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json' // You can adjust this based on your API requirements
                }
            });

            if (response.ok) {
                const data = await response.json();
                console.log(data);
                
                return data;
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            throw error;
        }


    };


    return (
        <div>
            {loading ? (
                
                    <p>Loading...</p>
                
            ) : (
                    <div id="containerHighChart"></div>
            )}
        </div>
    );
};

export default DemoMapIndonesiaHighChart;

