import React from 'react'
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import Footer from '../../Footer'
import LocationSelect from '../../shared/LocationSelect'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { AuthContext } from '../../../AuthContext'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';


const PengembangKantorPemasaranTambah = () => {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [selectedVillage, setSelectedVillage] = useState('');


    const [isButtonProcess, setIsButtonProcess] = useState(false);

    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);

    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {

        console.log('-------------------')
        // console.log(user);
        if (user.pemasaranrumah_state == 'edit') {
            console.log('masuk edit')
            fetchData();
        } else {
            console.log('masuk Add')
            // clearForm();
            console.log('masuk tambah data')
        }
    }, []);




    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            console.log(user.blokrumah_id)
            const response = await axios.get(apiUrl+'/api/igahp/getPemasaranRumahById/' + user.pemasaranrumah_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log('info tipe rumah for edit ')
            console.log(response.data)
            // console.log(response.data.nama)

            // setFormData({ alamat: response.data.alamat });

            setFormData(response.data);

            setIsChecked(response.data.kantorUtama);

            setSelectedVillage(response.data.kodeWilayah);

            // setBlok(response.data.blok)
            // setJumlahRumah(response.data.jumlahRumah)


        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const [formData, setFormData] = useState({
        alamat: '',
        nomor: '',
        kodeWilayah: '',
        noTelp: '',
        email: '',
        website: '',
        fax: '',
        isKantorUtama: false,
    });
    
    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
        setFormData({ ...formData, kodeWilayah: villageId });
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        console.log('apakah kantor utama ..')
        console.log(isChecked);
    };

    const handleSubmit = async (e) => {

        setIsButtonProcess(true);

        e.preventDefault();

        let tambahData=false;
        if (user.pemasaranrumah_state == 'edit') {
            tambahData = false;
        } else {
            tambahData = true;
        }
        console.log('isChecked submit')
        console.log(isChecked)
        // Prepare the data to be sent to the API
        const requestData = {
            id: user.pemasaranrumah_id,
            alamat: formData.alamat,
            nomor: formData.nomor,
            kodeWilayah: formData.kodeWilayah,
            noTelp: formData.noTelp,
            email: formData.email,
            website: formData.website,
            fax: formData.fax,
            kantorUtama: isChecked,
            perumahan_id: user.perumahan_id,
            tambahData : tambahData
        };



        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        console.log(requestData);
        // Send formData to API
        await fetch(apiUrl+'/api/igahp/pengembang/lokasi/simpanKantorPemasaran', {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                // Handle API response
                // console.log(data);
                // alert(data.status);

                Toast.fire({
                    icon: 'success',
                    title: data.status
                });

                setIsButtonProcess(false);
                navigate('/igahp/pengembang/PengembangKantorPemasaran');
            })
            .catch(error => {
                // Handle error

                setIsButtonProcess(false);
                console.error(error);

                Toast.fire({
                    icon: 'error',
                    title: error
                });
            });



    };

  return (
    <>

          {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
        <aside className="main-sidebar sidebar-light-success elevation-4">
 
              <MenuPerumahanDetail/>
          </aside>

          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <section className="content-header">
                  <div className="container-fluid">
                      <div className="row mb-4 mt-2">
                          <div className="col-sm-6">
                              <h1 className="text-muted fw-light">TAMBAH KANTOR<b> PEMASARAN</b></h1>
                          </div>
                          <div className="col-sm-6">
                              <ol className="breadcrumb float-sm-right">
                                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                                  <li className="breadcrumb-item active">Kantor Pemasaran</li>
                              </ol>
                          </div>
                      </div>
                  </div>{/* /.container-fluid */}
              </section>
              {/* Main content */}
              <section className="content">



                  <div className="container-fluid">



                      <div className="row">
                          <div className="col-md-12">


                              <div className="card card-primary">

                                  {/* form start */}
                                  <form onSubmit={handleSubmit}>
                                      <div className="card-body">
                                          <div className="form-group">
                                              <label htmlFor="exampleInputAlamat">Alamat Kantor</label>
                                              {/* <input type="text" name="alamat" className="form-control" id="exampleInputAlamat" /> */}
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="alamat"
                                                  name="alamat"
                                                  value={formData.alamat}
                                                  onChange={handleChange}
                                              required
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="exampleInputNomor">No. Kantor</label>
                                              {/* <input type="text" name="nomor" className="form-control" id="exampleInputNomor" /> */}
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="nomor"
                                                  name="nomor"
                                                  value={formData.nomor}
                                                  onChange={handleChange}
                                              required
                                              />
                                          </div>

                                          <div className="form-group">
                                              {/* <label htmlFor="exampleInputKelurahan">Kelurahan</label> */}
                                              <label>Lokasi Kantor</label>
                                              <div className="row">
                                                  <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                                              </div>
                                              {/* <input type="text" name="kelurahan" className="form-control" id="exampleInputKelurahan" /> */}
                                              {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    id="kodeWilayah"
                                                    name="kodeWilayah"
                                                    value={formData.kodeWilayah}
                                                    onChange={handleChange}
                                                    // required
                                                /> */}
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="exampleInputTelp">No. Telp Kantor</label>
                                              {/* <input type="text" name="Telp" className="form-control" id="exampleInputTelp" /> */}
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="noTelp"
                                                  name="noTelp"
                                                  value={formData.noTelp}
                                                  onChange={handleChange}
                                                  maxLength={15}
                                              required
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="exampleInputEmail">Email Kantor</label>
                                              {/* <input type="email" name="Temail" className="form-control" id="exampleInputEmail" /> */}
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="email"
                                                  name="email"
                                                  value={formData.email}
                                                  onChange={handleChange}
                                              required
                                              />
                                          </div>

                                          <div className="form-group">
                                              <label htmlFor="exampleInputwebsite">Website</label>
                                              {/* <input type="text" name="Website" className="form-control" id="exampleInputWebsite" /> */}

                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="website"
                                                  name="website"
                                                  value={formData.website}
                                                  onChange={handleChange}
                                              required
                                              />
                                          </div>


                                          {/* <div className="form-group">
                                              <label htmlFor="exampleInputFax">Fax</label>                                              
                                              <input
                                                  type="text"
                                                  className="form-control"
                                                  id="fax"
                                                  name="fax"
                                                  value={formData.fax}
                                                  onChange={handleChange}
                                                required
                                              />
                                          </div> */}
                                          
                                          <div className="form-group mb-0">
                                              <label>
                                                  <input
                                                      type="checkbox"
                                                      checked={isChecked}
                                                      onChange={handleCheckboxChange}
                                                      style={{ marginRight: '10px' }} // You can adjust the margin as needed
                                                  />
                                                  Kantor utama
                                              </label>
                                          </div>


                                      </div>
                                      {/* /.card-body */}
                                      <div className="card-footer">
                                          <button type="submit" disabled={isButtonProcess}  className="btn btn-primary">Simpan</button>
                                      </div>
                                  </form>
                                  {/* /.card */}


                              </div>
                          </div>
                      </div>


                  </div>
                  {/* /.container-fluid */}
              </section>
              {/* /.content */}
          </div>
          {/* /.content-wrapper */}


 
    </>
  )
}

export default PengembangKantorPemasaranTambah
