import React from 'react'

import LocationSelect from '../../shared/LocationSelect'
import { useState } from 'react'
import { useRef } from 'react'
import jwt_decode from 'jwt-decode'
import axios from 'axios';
import { AuthContext } from '../../../AuthContext'
import { useContext } from 'react'
import { refreshTokenRequest } from '../../refreshTokenRequest'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

const AdminModalMinatRumah = ({ onClose }) => {


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);




  const [modalVisible, setModalVisible] = useState(false);


  let refreshToken = null;

  const [handphone, setHandphone] = useState('');

  const [statusKawin, setStatusKawin] = useState('');

  const [jenisKelamin, setJenisKelamin] = useState('');

  const [nik, setNik] = useState('');

  const [tanggalLahir, setTanggalLahir] = useState('');

  const [hargaRumah, setHargaRumah] = useState(0);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;

  const navigate = useNavigate()

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });


  const [selectedVillage, setSelectedVillage] = useState('');

  const handleClose = () => {
    onClose();
  };

  const handleVillageChange = (villageId) => {
    setSelectedVillage(villageId);
  };


  const { user, setUser } = useContext(AuthContext);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  // const [optionPerumahanId, setOptionPerumahanId] = useState([]);
  const [optionTipeRumah, setOptionTipeRumah] = useState([]);
  const [selectedOptionTipeRumah, setSelectedOptionTipeRumah] = useState('');

  const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('');
  const [optionsPerumahan, setOptionsPerumahan] = useState([]);

  const [selectedOptionPengembang, setSelectedOptionPengembang] = useState('');
  const [optionsPengembang, setOptionsPengembang] = useState([]);

  const [namaLengkap, setNamaLengkap] = useState('');

  const [namaPengembang, setNamaPengembang] = useState('');

  // const [namaPengembang, setNamaPengembang] = useState('');


  const handleOptionChangePerumahan = event => {
    setSelectedOptionPerumahan(event.target.value);
  };

  const handleOptionChangePengembang = event => {
    setSelectedOptionPengembang(event.target.value);
  };

  useEffect(() => {

    // alert(user.pengajuanbgh_id)

    fetchOptionsFromApiPengembang()
      .then(data => setOptionsPengembang(data))
      .catch(error => console.error('Error fetching options:', error));

    fetchOptionsFromApiPerumahan()
      .then(data => setOptionsPerumahan(data))
      .catch(error => console.error('Error fetching options:', error));


  }, []);


  const fetchOptionsFromApiPengembang = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    // var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

    // var idPengembang = user.pengembang_id;

    var idPengembang = '0';

    try {
      const response = await axios.get(apiUrl + '/api/igahp/getAllPengembang' , {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // console.error('Error get api data...');
      // navigate('/login');
    }
  };

  const handleOptionChangeTipeRumah = event => {
    setSelectedOptionTipeRumah(event.target.value);
  };

  useEffect(() => {
    fetchOptionsFromApiTipeRumah()
      .then(data => setOptionTipeRumah(data))
      .catch(error => console.error('Error fetching options:', error));


    // fetchOptionsFromApiTipeRumah()
    //   .then(data => setCheckboxData(data))
    //   .catch(error => console.error('Error fetching options:', error));

  }, [selectedOptionPerumahan]);


  useEffect(() => {
    fetchOptionsFromApiPerumahan()
      .then(data => setOptionsPerumahan(data))
      .catch(error => console.error('Error fetching options:', error));


    // fetchOptionsFromApiTipeRumah()
    //   .then(data => setCheckboxData(data))
    //   .catch(error => console.error('Error fetching options:', error));

  }, [selectedOptionPengembang]);


  const fetchOptionsFromApiPerumahan = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    var idPengembang = (selectedOptionPengembang === '') ? 0 : selectedOptionPengembang;

    // var idPengembang = user.pengembang_id;

    try {
      const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // console.error('Error get api data...');
      // navigate('/login');
    }
  };



  const fetchOptionsFromApiTipeRumah = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    var idPerumahan = (selectedOptionPerumahan === '') ? 0 : selectedOptionPerumahan;

    // var idPengembang = user.pengembang_id;

    // console.log('id perumahan = ' + idPerumahan)

    try {
      const response = await axios.get(apiUrl + '/api/igahp/tipeRumahByPerumahanId/' + idPerumahan, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // console.error('Error get api data...');
      // navigate('/login');
    }
  };

  const resetFormFields = () => {
    setNamaLengkap('');
    setHandphone('');
    setSelectedOptionTipeRumah('');
    setSelectedOptionPerumahan('')
    setHargaRumah(0);
    setNik('');
    setStatusKawin('')
    setJenisKelamin('')
  };

  const handleNamaLengkapChange = (event) => {
    setNamaLengkap(event.target.value);
  };

  const handleNikChange = (event) => {
    setNik(event.target.value);
  };

  const handleHandphoneChange = (event) => {
    setHandphone(event.target.value);
  };


  const disableScroll = (e) => {
    e.preventDefault();
  };

  const ignoreScroll = useRef(null);

  useEffect(() => {
    ignoreScroll.current && ignoreScroll.current.addEventListener("wheel", disableScroll);
  }, [ignoreScroll]);


  const handleSubmit = async (event) => {
    event.preventDefault();
    // Perform form submission, e.g., send the data to your API

    if (!tanggalLahir) {
      alert('Tanggal Lahir harus diisi.');
      return;
    }

    if (!selectedOptionPerumahan) {
      alert('Perumahan dipilih terlebih dahulu...')
      return;
    }

    // if (!selectedOptionTipeRumah) {
    //   alert('Tipe Rumah dipilih terlebih dahulu...')
    //   return;
    // }

    const formData = {
      namaLengkap,
      handphone,
      tipeRumahId: selectedOptionTipeRumah,
      hargaRumah,
      namaPengembang,
      perumahanId: selectedOptionPerumahan,
      nik,
      tanggalLahir,
      jenisKelamin,
      statusKawin,
      kodeWilayah: selectedVillage
    };

    // Use axios or other methods to submit the formData
    console.log('Form data:', formData);

    try {
      // Make a POST request to your API
      const response = await axios.post(`${apiUrl}/api/public/minatUnit`, formData);

      // Handle successful response (e.g., display success message)
      console.log('Response:', response.data);
      alert('Data berhasil disimpan');

      // Reset form fields after successful submission
      resetFormFields();
    } catch (error) {
      // Handle error (e.g., display error message)
      console.error('Error saving data:', error);
      alert('Terjadi kegagalan saat menyimpan data');
    }

  };



  return (

    <>


      <div className="modal fade" id="modal-minat-rumah" tabIndex={-1} role="dialog"
        aria-labelledby="modal-minat-rumah-label" aria-hidden="true" data-backdrop="static">




        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Input Baru Data Minat</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close"
                onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>



            <form onSubmit={handleSubmit}>
              <div className="modal-body">

                <div className="form-group">
                  <div className="mb-3">
                    <label htmlFor="namaLengkap" className="form-label">Nama Lengkap</label>
                    <input
                      id="namaLengkap"
                      type="text"
                      placeholder="Budiyanto"
                      className="form-control form-control"
                      value={namaLengkap}
                      onChange={handleNamaLengkapChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="mb-3">
                    <label htmlFor="nik" className="form-label">Nik</label>
                    <input
                      id="nik"
                      type="text"
                      placeholder=""
                      className="form-control form-control"
                      value={nik}
                      onChange={handleNikChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <div className="mb-3">
                    <label htmlFor="jenisKelamin" className="form-label">Jenis Kelamin</label>
                    <select
                      name="jenisKelamin"
                      className="form-control bg-white"
                      value={jenisKelamin}
                      onChange={(e) => setJenisKelamin(e.target.value)}
                      required
                    >
                      <option value="">Pilih</option>
                      <option value="1">Pria</option>
                      <option value="2">Wanita</option>
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <div className="mb-3">
                    <label htmlFor="namaLengkap" className="form-label">Tanggal Lahir</label>
                    <input
                      className="form-control bg-white"
                      type="date"
                      value={tanggalLahir}
                      onChange={(e) => setTanggalLahir(e.target.value)}
                      require
                    />
                  </div>
                </div>


                <div className="form-group">
                  <div className="mb-3">
                    <label htmlFor="jenisKelamin" className="form-label">Status Kawin</label>
                    <select name="jenisKelamin"
                      className="form-control bg-white"
                      value={statusKawin}
                      onChange={(e) => setStatusKawin(e.target.value)}
                      required
                    >
                      <option value="">Pilih</option>
                      <option value="1">Lajang</option>
                      <option value="2">Menikah</option>
                      <option value="3">Duda</option>
                      <option value="4">Janda</option>
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <div className="mb-3">
                    <label htmlFor="handphone" className="form-label">Handphone</label>
                    <input
                      id="handphone"
                      ref={ignoreScroll}
                      type="number"
                      placeholder="081100010002"
                      className="form-control form-control"
                      value={handphone}
                      onChange={handleHandphoneChange}
                      required
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label>Wilayah</label>
                  <div className="row">
                    <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                  </div>
                </div>

                <div className="form-group">
                  <div className="mb-3">
                    <label>Pilih Pengembang</label>
                    <select className="form-control" value={selectedOptionPengembang}
                      onChange={handleOptionChangePengembang}>
                      <option value="">Select an option</option>
                      {optionsPengembang.map(option3 => (
                        <option key={option3.id} value={option3.id}>
                          {option3.nama}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-group">
                  <div className="mb-3">
                    <label>Pilih Perumahan</label>
                    <select className="form-control" value={selectedOptionPerumahan}
                      onChange={handleOptionChangePerumahan}>
                      <option value="">Select an option</option>
                      {optionsPerumahan.map(option2 => (
                        <option key={option2.id} value={option2.id}>
                          {option2.nama_perumahan}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>


                {/* 
                <div className="form-group">
                  <div className="mb-3">
                    <label>Pilih Tipe Unit Rumah</label>
                    <select className="form-control" value={selectedOptionTipeRumah} onChange={handleOptionChangeTipeRumah}>
                      <option value="">Select an option</option>
                      {optionTipeRumah.map(option3 => (
                        <option key={option3.id} value={option3.id}>
                          {option3.nama}
                        </option>
                      ))}
                    </select>
                  </div>
                </div> */}

              </div>

              <div className="modal-footer justify-content-between">
                <button type="submit" className="btn btn-primary">Simpan</button>
              </div>
            </form>


          </div>

        </div>

      </div>

    </>


  )
}

export default AdminModalMinatRumah
