import React, { useState } from 'react';

const PengembangSurvey = () => {
    const [question, setQuestion] = useState('');
    const [children, setChildren] = useState([]);

    const handleAddChild = () => {
        setChildren([...children, { question: '' }]);
    };

    const handleChildQuestionChange = (index, value) => {
        const updatedChildren = [...children];
        updatedChildren[index].question = value;
        setChildren(updatedChildren);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Perform submit action, e.g., send data to backend API
        console.log({ question, children });
        // Reset form
        setQuestion('');
        setChildren([]);
    };

    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Survey</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">DataTables</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">


                                <form onSubmit={handleSubmit}>
                                    <label>
                                        Question:
                                        <input
                                            type="text"
                                            value={question}
                                            onChange={(e) => setQuestion(e.target.value)}
                                        />
                                    </label>
                                    <br />

                                    {children.map((child, index) => (
                                        <div key={index}>
                                            <label>
                                                Child {index + 1}:
                                                <input
                                                    type="text"
                                                    value={child.question}
                                                    onChange={(e) => handleChildQuestionChange(index, e.target.value)}
                                                />
                                            </label>
                                            <br />
                                        </div>
                                    ))}

                                    <button type="button" onClick={handleAddChild}>
                                        Add Child
                                    </button>
                                    <br />

                                    <button type="submit">Submit</button>
                                </form>




                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

        </>


    );
};

export default PengembangSurvey;
