import React from 'react'
import { useEffect } from 'react';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import axios from 'axios';
import KantorPemasaran from './childs/KantorPemasaran'
import MenuPerumahanDetail from './MenuPerumahanDetail';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import MyTimeline from '../shared/MyTimeline';
import ModalPengajuan from './childs/ModalPengajuan';
// import Timeline from 'react-timeline-horizontal';
// import 'react-timeline-horizontal/dist/style.css'; // Import the timeline styles


const PengembangLokasiProfile = () => {

    // const { id } = useParams();

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const [dataProfile, setDataProfile] = useState({});
    const [dataFile, setDataFile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [isModal, setIsModal] = useState(false);

    const [dataStatus, setDataStatus] = useState();


    useEffect(() => {
        // console.log('masuk ke pengembang lokasi profile...')
        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            (async () => {
                await fetchData();
                await fetchDataStatusPengajuan();

                setIsLoading(false);
                setIsTokenExpired(false);
            })();
        }

    }, [isLoading, isTokenExpired]);


    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        // console.log(user);

        const url = apiUrl + "/api/igahp/lokasi/detail/" + user.perumahan_id;

        let token = user.accessToken;

        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            
            setDataProfile(data);
            setLokasiGps({ lat: data.latitude, lng: data.longitude });

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };


    const fetchDataStatusPengajuan = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }



        try {
            await fetch(apiUrl + '/api/igahp/getStatusPengajuan/' + user.perumahan_id, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            })
                .then(response => response.json())
                .then(data => {
                    // console.log(data)
                    setDataStatus(data)
                    if (dataStatus.kantorPemasaran == false) {
                        alert("Isikan terlabih dahulu kantor pemasaran, agar lokasi perumahan ini bisa diajukan ke admin");

                    }
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    // alert(data.status);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }


    };



    const handleNilaiBalikan = async (nilaiBalikan) => {
        // setIsLoading(nilaiBalikan);
        // console.log('Modal Close ' + nilaiBalikan)

        (async () => {
            await fetchData();
            await fetchDataStatusPengajuan();
        })();
    };

    const [itemData, setItemData] = useState(null);


    const handleIsModal = (item, event) => {

        isModal ? setIsModal(false) : setIsModal(true);
        setItemData(dataProfile);

        console.log('Modal Open')
        // setModalOpen(true);

    }



    return (
        <>



            {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
            <aside className="main-sidebar sidebar-light-success elevation-4">

                <MenuPerumahanDetail />
            </aside>

            <>
                <div className="content-wrapper">
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-4 mt-2">
                                <div className="col-sm-6">
                                    <h1 className="text-muted fw-light">DETAIL<b> PERUMAHAN</b></h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Lokasi Profile</li>
                                    </ol>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">

                                    {isLoading ?
                                        <p>Loading Data...</p> :


                                        <>

                                            {/* Profile Image */}
                                            <div className="card card-primary card-outline">
                                                <div className="card-body box-profile">
                                                    {/* <div className="text-center">
                                                <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user4-128x128.jpg" alt="User profile picture" />
                                            </div> */}
                                                    <h1>{dataProfile.nama_perumahan}</h1>
                                                    <strong><i className="fas fa-map-marker-alt mr-1" /> Alamat</strong>
                                                    <h5>{dataProfile.info_kelurahan}</h5>
                                                    <br />

                                                    <strong><i className="far fa-file-alt mr-1" /> Notes</strong>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                {/* /.card-header */}
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>

                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Jenis Perumahan</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {dataProfile.jenis_perumahan === 0 ? 'rumah tapak' : 'rumah susun'}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>IMB/PBG</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {dataProfile.imb}
                                                                                </td>
                                                                            </tr>
                                                                            {/* <tr>
                                                                                <td>Nik Pemilik IMB/PBG</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {dataProfile.pemilik_imb}
                                                                                </td>
                                                                            </tr> */}
                                                                            <tr>
                                                                                <td>Tanggal IMB/PBG</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {dataProfile.tgl_imb}
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* /.card-body */}
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="card">

                                                                {/* /.card-header */}
                                                                <div className="card-body p-0">
                                                                    <table className="table table-sm">
                                                                        <thead>
                                                                            {/* <tr>
                                                                    
                                                                    <th>Task</th>
                                                                    <th>Progress</th>
                                                                    <th>Label</th>
                                                                </tr> */}
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td>Kecamatan IMB/PBG</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    {dataProfile.infoKecamatan}
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Dokumen IMB/PBG</td>
                                                                                <td>:</td>
                                                                                <td>


                                                                                    <a href={`${apiUrl}/api/public/displaypdf/${dataProfile.dokumen_imb}`} target="_blank" >Lihat File</a>
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Foto Gambar Kerja Siteplan</td>
                                                                                <td>:</td>
                                                                                <td>
                                                                                    <a href={`${apiUrl}/api/public/displaypdf/${dataProfile.siteplan_ttd}`} target="_blank" >Lihat File</a>
                                                                                </td>

                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                {/* /.card-body */}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                            {/* /.card */}

                                            <div className="card card-primary card-outline">

                                                <div className="bs-stepper">
                                                    <div className="bs-stepper-header" role="tablist">
                                                        {/* your steps here */}
                                                        <div className="step active" data-target="#logins-part">
                                                            <button type="button" className="step-trigger" role="tab" aria-controls="logins-part" id="logins-part-trigger">
                                                                <span className="bs-stepper-circle">1</span>
                                                                <span className="bs-stepper-label">Data Lokasi dan Perizinan</span>
                                                            </button>
                                                        </div>
                                                        <div className="line" />
                                                        <div className="step active" data-target="#information-part">
                                                            <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                                <span className="bs-stepper-circle">2</span>
                                                                <span className="bs-stepper-label">Upload Photo Lokasi</span>
                                                            </button>
                                                        </div>
                                                        <div className="line" />
                                                        <div className="step active" data-target="#information-part3">
                                                            <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                                <span className="bs-stepper-circle">3</span>
                                                                <span className="bs-stepper-label">Upload Siteplan Latar</span>
                                                            </button>
                                                        </div>
                                                        <div className="line" />

                                                        {(dataStatus.kantorPemasaran) ?
                                                            <>
                                                                <div className="step active" data-target="#information-part2">
                                                                    <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                                        <span className="bs-stepper-circle">4</span>
                                                                        <span className="bs-stepper-label">Isi Data Kantor Pemasaran</span>
                                                                    </button>
                                                                </div>

                                                            </> :
                                                            <>
                                                                <div className="step" data-target="#information-part2">
                                                                    <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                                        <span className="bs-stepper-circle">4</span>
                                                                        <span className="bs-stepper-label">Isi Data Kantor Pemasaran</span>
                                                                    </button>
                                                                </div>

                                                            </>
                                                        }

                                                        <div className="line" />
                                                        {(dataStatus.diajukan) ?
                                                            <>
                                                                <div className="step active" data-target="#information-part3">
                                                                    <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                                        <span className="bs-stepper-circle">5</span>
                                                                        <span className="bs-stepper-label">Pengecekan Lokasi</span>
                                                                    </button>
                                                                </div>
                                                            </> :
                                                            <>
                                                                <div className="step" data-target="#information-part3">
                                                                    <button type="button" className="step-trigger" role="tab" aria-controls="information-part" id="information-part-trigger">
                                                                        <span className="bs-stepper-circle">5</span>
                                                                        <span className="bs-stepper-label">Pengecekan Lokasi</span>
                                                                    </button>
                                                                </div>
                                                            </>
                                                        }

                                                    </div>

                                                </div>


                                                <div className="card-footer">


                                                    <button
                                                        type="button"
                                                        id="tambahModal"
                                                        data-toggle="modal"
                                                        data-target="#ModalPengajuan"
                                                        className="btn btn-success float-left"
                                                        onClick={(event) => {
                                                            handleIsModal("tambah-data", event);
                                                        }}
                                                        disabled={dataStatus.kantorPemasaran !== true} // Disable the button if dataStatus has a value
                                                    >
                                                        Ajukan Lokasi Perumahan ini ke Admin
                                                    </button>

                                                    {/* 
                                                    <button
                                                        type="button" 
                                                        id="tambahModal" data-toggle="modal"
                                                        data-target="#ModalPengajuan"
                                                        className="btn btn-success float-left"

                                                        onClick={(event) => {
                                                            handleIsModal("tambah-data", event)
                                                        }}     
                                                    >Ajukan Lokasi Perumahan ini ke Admin
                                                    </button> */}

                                                    {itemData == null ? <> </> :
                                                        <>
                                                            <ModalPengajuan
                                                                sendModal={isModal}
                                                                sendItem={itemData}
                                                                penampungBalikan={handleNilaiBalikan}
                                                            />
                                                        </>
                                                    }


                                                    {/* <button type="submit" class="btn btn-default float-right">Cancel</button> */}
                                                </div>
                                            </div>



                                            {/* Profile Image */}
                                            <div className="card card-primary card-outline">
                                                <div className="card-body box-profile">
                                                    {/* <div className="text-center">
                                                <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user4-128x128.jpg" alt="User profile picture" />
                                            </div> */}
                                                    <h4>Foto Lokasi Perumahan</h4>
                                                    <small>Lokasi Harus Difoto Menggunakan Smartphone Dalam Kondisi GPS/Lokasi Aktif.</small>

                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">
                                                                        <i className="fas fa-text-width" />
                                                                        {/* Unordered List */}
                                                                    </h3>
                                                                </div>
                                                                {/* /.card-header */}
                                                                <div className="card-body">
                                                                    <div>
                                                                        <img src={`${apiUrl}/api/public/displayimage/${dataProfile.foto_gerbang}`} className="card-img-top card-img" alt="Image" />
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">Foto Gerbang</h5>
                                                                            {/* <p className="card-text">This is a card with a full-fill image.</p> */}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {/* /.card-body */}
                                                            </div>
                                                            {/* /.card */}
                                                        </div>
                                                        {/* ./col */}
                                                        <div className="col-md-4">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">
                                                                        <i className="fas fa-text-width" />
                                                                        {/* Ordered Lists */}
                                                                    </h3>
                                                                </div>
                                                                {/* /.card-header */}
                                                                <div className="card-body">
                                                                    <div>
                                                                        <img src={`${apiUrl}/api/public/displayimage/${dataProfile.foto_tengah}`} className="card-img-top card-img" alt="Image" />
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">Foto Tengah</h5>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            {/* /.card */}
                                                        </div>
                                                        {/* ./col */}
                                                        <div className="col-md-4">
                                                            <div className="card">
                                                                <div className="card-header">
                                                                    <h3 className="card-title">
                                                                        <i className="fas fa-text-width" />
                                                                        {/* Unstyled List */}
                                                                    </h3>
                                                                </div>
                                                                {/* /.card-header */}
                                                                <div className="card-body">
                                                                    <div>
                                                                        <img src={`${apiUrl}/api/public/displayimage/${dataProfile.foto_contoh}`} className="card-img-top card-img" alt="Image" />
                                                                        <div className="card-body">
                                                                            <h5 className="card-title">Foto Contoh</h5>
                                                                            {/* <p className="card-text">This is a card with a full-fill image.</p> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* /.card-body */}
                                                            </div>
                                                            {/* /.card */}
                                                        </div>
                                                        {/* ./col */}
                                                    </div>



                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                            {/* /.card */}


                                            {/* Profile Image */}
                                            <div className="card card-primary card-outline">
                                                <div className="card-body box-profile">
                                                    
                                                    <h4>Peta Lokasi Perumahan</h4>
                                                    <small>Koordinat lokasi anda akan muncul setelah anda memasukan foto lokasi yang valid.</small>

                                                    <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">

                                                        <>
                                                            <MapContainer
                                                                gps={lokasiGps}
                                                                onMapContainerReady={handleMapContainerReady}
                                                            />
                                                        </>

                                                    </CustomIframe>
                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                            
                                            {/* /.card */}

                                        </>
                                    }

                                </div>
                                {/* /.col */}

                            </div>
                            {/* /.row */}
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
            </>



        </>
    )
}

export default PengembangLokasiProfile
