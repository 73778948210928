import React from 'react'
import FooterFront from './FooterFront'
import NavBarAtas from './NavBarAtas';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { coilState } from '../../coilState';
import { useRecoilState } from 'recoil';
import axios from 'axios'
import { AuthContext } from '../../AuthContext';
import { useContext } from 'react';
import ModalMinat from './modals/ModalMinat';
// import Carousel from "react-simply-carousel";

import Carousel from 'react-bootstrap/Carousel';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';



const PerumahanDetail = () => {

    const [activeSlide, setActiveSlide] = useState(0);

    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);

    const [isLoading, setIsLoading] = useState(true);
    const [coilData, setCoilData] = useRecoilState(coilState);
    const [data, setData] = useState([]);
    const [dataTipe, setDataTipe] = useState([]);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [lokasiGps, setLokasiGps] = useState({});

    const [fasilitas, setFasilitas] = useState();

    const statusToColor = {
        'perencanaan-hijau': '#808080',
        'pembangunan-hijau': '#0000FF',
        'sertifikasi-hijau': '#28a745',
        'terjual': '#dc3545',
        'subsidi': '#f7ca18',
        'komersil': '#718096',
        'dibooking': '#007bff',
        'pembangunan': '#e67e22',
        'komersil-terjual': '#343a40',
        'komersil-dibooking': '#4C1D95',
        'komersil-pembangunan': '#855831',
        'komersil-tersedia': '#362825',
        'subsidi-hijau': '#343a40',
        'komersil-hijau': '#343a40',
    };

    // const warna = statusToColor[this.status] || '#000000'; // Nilai default jika tidak ada status yang cocok

    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };


    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        // console.log('MapContainer finished rendering');
    };


    useEffect(() => {
        if (isLoading) {
            fetchDataAndTipe();
        }
        setIsLoading(false)
    }, []);


    const fetchDataAndTipe = async () => {
        await fetchData();
        await fetchDataTipe();
        await fetchDataProfile();
        await fetchFasilitasData();
    };




    const fetchFasilitasData = async () => {

        try {
            const response = await axios.get(apiUrl + '/api/public/getLokasiFasilitas/' + user.perumahan_id);
            // console.log(response.data)
            setFasilitas(response.data);
            // setPerumahan(response.data);

            // setValueFas('prasaranaUmum', 'gerbangPerumahan', response.data.gerbangPerumahan);
            // setValueFas('prasaranaUmum', 'tamanBermain', response.data.tamanBermain);
            // setValueFas('prasaranaUmum', 'jalanUmum', response.data.jalanUmum);
            // setValueFas('prasaranaUmum', 'keamanan', response.data.keamanan);
            // setValueFas('prasaranaUmum', 'drainase', response.data.drainase);
            // setValueFas('prasaranaUmum', 'aksesParkir', response.data.aksesParkir);

            // setValueFas('gayaHidup', 'bankSampah', response.data.bankSampah);
            // setValueFas('gayaHidup', 'tempatSampahDaurUlang', response.data.tempatSampahDaurUlang);
            // setValueFas('gayaHidup', 'pengelolaanLimbah', response.data.pengelolaanLimbah);

            // setValueFas('transportasi', 'gerbangTol', response.data.gerbangTol);
            // setValueFas('transportasi', 'terminalBus', response.data.terminalBus);
            // setValueFas('transportasi', 'bankAtm', response.data.bankAtm);
            // setValueFas('transportasi', 'stasiunKereta', response.data.stasiunKereta);
            // setValueFas('transportasi', 'spbu', response.data.spbu);
            // setValueFas('transportasi', 'pasarInduk', response.data.pasarInduk);

            // setValueFas('pendidikan', 'tk', response.data.tk);
            // setValueFas('pendidikan', 'sdn', response.data.sdn);
            // setValueFas('pendidikan', 'sdn', response.data.smp);
            // setValueFas('pendidikan', 'sma', response.data.sma);
            // setValueFas('pendidikan', 'universitas', response.data.universitas);

            // setValueFas('ibadah', 'masjid', response.data.masjid);
            // setValueFas('ibadah', 'gereja', response.data.gereja);

        } catch (error) {
            console.error('Error fetching perumahan data:', error);
        }
    };


    const fetchData = async () => {
        try {
            const response = await axios.get(apiUrl + '/api/public/getAllPerumahanById/' + user.perumahan_id, {
            });
            // await Delay(5000);
            const tampung = response.data[0];
             console.log(tampung)

            setData(tampung);

            try {
                const response2 = await axios.get(apiUrl + '/api/public/getSitePlanImageFile/' + user.perumahan_id + '/' + tampung.sitePlan, {
                });
                const tampung2 = response2.data;
                console.log(response2.data)
            } catch (error) {
                console.error('Error get api data...');
                // navigate('/login');
            } finally {
                // setIsLoading(false)
            }

        } catch (error) {
            console.error('Error get api data...');
            // navigate('/login');
        } finally {
            // setIsLoading(false)
        }
    };

    const fetchDataTipe = async () => {
        try {
            const response = await axios.get(apiUrl + '/api/public/getTipeRumah/' + user.perumahan_id, {
            });
            // await Delay(5000);
            const tampung2 = response.data;
            //  console.log(tampung2)
            setDataTipe(tampung2);
        } catch (error) {
            console.error('Error get api data...');
            // navigate('/login');
        } finally {
            // setIsLoading(false)
        }
    };


    const fetchDataProfile = async () => {

        const url = apiUrl + "/api/public/lokasiProfile/" + user.perumahan_id;

        let token = user.accessToken;

        // console.log('token...');
        // console.log(user);



        try {
            const response = await fetch(url, {
                // headers: {
                //     Authorization: `Bearer ${token}`,
                // },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            // setIsLoading(false);
            // setIsTokenExpired(false);

            // setDataProfile(data);
            setLokasiGps({ lat: data.latitude, lng: data.longitude });

            // return data;
        } catch (error) {
            // Handle errors here
            console.error('Error fetching data:', error);
        }

    };

    const [isModalOpen, setModalOpen] = useState(false);

    function handleMinatClick() {
        setModalOpen(true);
    }

    // Function to close the modal
    const handleModalClose = () => {
        setModalOpen(false);

    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });


    require("./../../css/common.css");


    return (

        <>

            {(!isLoading) ? (

                <>
                    <NavBarAtas showButton={true} />
                    <div>
                        <section className="wrapper-history-detail-perumahan">
                            <a href="/catalog">
                                <div className="btn-circle-green">
                                    <img src="images/frontcatalog/ic_arrow_left.svg" alt />
                                </div>
                            </a>
                            <div className="history-back-mobile">Kembali</div>
                        </section>
                        <section className="wrapper-hero-image-detail-perumahan">
                            {/* <img src="images/perumahandetail/image_hero_detail_rumah.png" id="image-hero-detail-rumah" /> */}

                            {/* <img src={`${apiUrl}/api/public/displayimage/${data.photoContoh}`} /> */}

                            <img
                                src={`${apiUrl}/api/public/displayimage/${data.photoContoh}`}
                                style={{
                                    width: '100%', // Set the desired width
                                    height: '350px'
                                }} // Apply the inline styles
                                alt="Image description"
                                id="image-hero-detail-rumah"
                            />

                            <div className="hero-image-overlay" />

                            <div className="wrapper-igahp-detail-house">
                                <div className="wrapper-igahp-detail-house-title">
                                    <a href="/">
                                        <button className="btn-circle-grey" id="btn-back-detail-perumahan">
                                            <img src="images/perumahandetail/ic_back.svg" />
                                        </button>
                                    </a>
                                    <div className="igahp-subheading-medium white-text">
                                        {data.nama_perumahan}
                                    </div>
                                    <div className="wrapper-certified-house">
                                        <img src="images/perumahandetail/ic_certified_house.svg" />
                                        <div className="igahp-xxs-regular green-main-text">Perumahan Subsidi Hijau</div>
                                    </div>
                                </div>
                                <div className="wrapper-detail-house-description">
                                    <div className="wrapper-detail-house-information">
                                        <div className="igahp-m-regular white-text">ID Perumahan</div>
                                        <div className="igahp-m-regular white-text">:</div>
                                        <div className="igahp-m-semi-bold white-text igahp-margin-left-25">{data.idLokasiString}</div>
                                    </div>
                                    <div className="wrapper-detail-house-information">
                                        <div className="igahp-m-regular white-text">Developer</div>
                                        <div className="igahp-m-regular white-text">:</div>
                                        <div className="igahp-m-semi-bold white-text igahp-margin-left-25">{data.namaPengembang}</div>
                                    </div>
                                    <div className="wrapper-detail-house-information">
                                        <div className="igahp-m-regular white-text">Asosiasi</div>
                                        <div className="igahp-m-regular white-text">:</div>
                                        <div className="igahp-m-semi-bold white-text igahp-margin-left-25">{data.namaAsosiasi}</div>
                                    </div>
                                </div>
                                <div className="igahp-m-medium white-text" id="address-text">
                                    {data.kecamatan}
                                </div>
                            </div>
                        </section>



                        <section className="section-detail-perumahan">
                            <div className="wrapper-detail-perumahan-content">
                                <section className="wrapper-igahp-type-unit">
                                    <div className="igahp-h3-semi-bold black-text" id="type-unit-title">Tipe Unit</div>
                                    <div className="wrapper-list-rumah-detail-perumahan">
                                        <div className="green-house-list">

                                            <ul>

                                                <Carousel activeIndex={index} onSelect={handleSelect}>
                                                    {dataTipe.map((item, i) => {
                                                        return (
                                                            <Carousel.Item interval={1300}>

                                                                <li>
                                                                    <div className="list-grid-house">
                                                                        <div className="wrapper-house-subsidi">
                                                                            <p className="igahp-xs-medium white-text">Subsidi Hijau</p>
                                                                        </div>
                                                                        <div className="house-photo">
                                                                            {/* <img src="image_perumahan_taman_anggrek_1.png" loading="lazy" alt /> */}
                                                                            <img src={`${apiUrl}/api/public/displayimage/${item.fotoTampakDepan}`} />
                                                                        </div>
                                                                        <div className="wrapper-house-name">
                                                                            <div className="igahp-l-medium black-text">Tipe {item.nama}</div>
                                                                            <div className="badge-certified-green">
                                                                                <img src="images/perumahandetail/ic_certified_leaf_green.svg" />
                                                                                <div className="igahp-xs-medium green-main-text">Green Certified</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="igahp-xs-regular neutral-80-text" id="house-id">{formatter.format(item.harga)}</div>
                                                                        <div className="wrapper-house-facilities">
                                                                            <div className="wrapper-total-facility">
                                                                                <img src="images/perumahandetail/ic_bed.svg" width={20} height={20} />
                                                                                <div className="igahp-s-regular neutral-90-text">{item.kamarTidur}</div>
                                                                            </div>
                                                                            <div className="wrapper-total-facility">
                                                                                <img src="images/perumahandetail/ic_shower.svg" width={20} height={20} />
                                                                                <div className="igahp-s-regular neutral-90-text">{item.kamarMandi}</div>
                                                                            </div>
                                                                            <div className="wrapper-total-facility">
                                                                                <img src="images/perumahandetail/ic_levels.svg" width={20} height={20} />
                                                                                <div className="igahp-s-regular neutral-90-text">{item.jumlahLantai}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="wrapper-luas-bangunan-rumah">
                                                                            <div className="wrapper-luas-tanah">
                                                                                <div className="igahp-m-regular neutral-60-text">LT</div>
                                                                                <div className="igahp-m-regular neutral-60-text">:</div>
                                                                                <div className="igahp-m-regular neutral-90-text">{item.luasTanah} m<sup className="super-sub">2</sup>
                                                                                </div>
                                                                            </div>
                                                                            <div className="wrapper-luas-tanah">
                                                                                <div className="igahp-m-regular neutral-60-text">LB</div>
                                                                                <div className="igahp-m-regular neutral-60-text">:</div>
                                                                                <div className="igahp-m-regular neutral-90-text">{item.luasBangunan} m<sup className="super-sub">2</sup>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {/* <div className="igahp-xs-regular blue-text" id="total-unit">1 Unit Tersedia</div> */}
                                                                    </div>
                                                                </li>

                                                            </Carousel.Item>
                                                        )
                                                    })}

                                                </Carousel>
                                            </ul>



                                            {/* <ul>
                                                <Carousel
                                                    containerProps={{
                                                        style: {
                                                            width: "100%",
                                                            justifyContent: "space-between",
                                                            userSelect: "none"
                                                        }
                                                    }}
                                                    preventScrollOnSwipe
                                                    swipeTreshold={60}
                                                    activeSlideIndex={activeSlide}
                                                    activeSlideProps={{
                                                        style: {
                                                            background: "white"

                                                        }
                                                    }}
                                                    onRequestChange={setActiveSlide}
                                                    forwardBtnProps={{
                                                        children: ">",
                                                        style: {
                                                            width: 60,
                                                            height: 60,
                                                            minWidth: 60,
                                                            alignSelf: "center",
                                                            backgroundColor: "green",//"#3498db", // Ganti warna sesuai keinginan Anda
                                                            borderRadius: 30, // Agar tombol bulat
                                                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)", // Tambahkan bayangan
                                                            transition: "box-shadow 0.3s ease", // Efek transisi untuk bayangan
                                                            cursor: "pointer" // Ganti cursor saat dihover
                                                        },
                                                        hoverStyle: {
                                                            boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.4)" // Bayangan yang lebih besar saat dihover
                                                        }
                                                    }}

                                                    backwardBtnProps={{
                                                        children: "<",
                                                        style: {
                                                            width: 60,
                                                            height: 60,
                                                            minWidth: 60,
                                                            alignSelf: "center",
                                                            backgroundColor: "green",//"#3498db", // Ganti warna sesuai keinginan Anda
                                                            borderRadius: 30, // Agar tombol bulat
                                                            boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.2)", // Tambahkan bayangan
                                                            transition: "box-shadow 0.3s ease", // Efek transisi untuk bayangan
                                                            cursor: "pointer" // Ganti cursor saat dihover
                                                        },
                                                        hoverStyle: {
                                                            boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.4)" // Bayangan yang lebih besar saat dihover
                                                        }
                                                    }}
                                                    dotsNav={{
                                                        show: true,
                                                        itemBtnProps: {
                                                            style: {
                                                                height: 10,
                                                                width: 10,
                                                                borderRadius: "50%",
                                                                border: 0,
                                                                backgroundColor: "lightgray", // Ubah warna latar belakang ke abu-abu muda
                                                                margin: "0 4px", // Tambahkan sedikit margin horizontal antara setiap item
                                                                position: "relative", // Tambahkan posisi relatif
                                                                top: "15px" // Turunkan posisi
                                                            }
                                                        },
                                                        activeItemBtnProps: {
                                                            style: {
                                                                height: 16,
                                                                width: 16,
                                                                borderRadius: "50%",
                                                                border: 0,
                                                                backgroundColor: "yellow", // Ubah warna latar belakang item yang aktif menjadi biru
                                                                margin: "0 4px", // Tambahkan sedikit margin horizontal antara setiap item
                                                                position: "relative", // Tambahkan posisi relatif
                                                                top: "15px" // Turunkan posisi
                                                            }
                                                        }
                                                    }}


                                                    itemsToShow={3}
                                                    speed={800}
                                                    centerMode
                                                >

                                                    {dataTipe.map((item, index) => (
                                                        <div key={item.id}>
                                                            <li>
                                                                <div className="list-grid-house">
                                                                    <div className="wrapper-house-type">
                                                                        <p className="igahp-xs-medium white-text">Rumah Tapak</p>
                                                                    </div>
                                                                    <div className="house-photo">
                                                                        <img src="image_perumahan_taman_anggrek_1.png" loading="lazy" alt />
                                                                    </div>
                                                                    <div className="wrapper-house-name">
                                                                        <div className="igahp-l-medium black-text">Tipe {item.nama}</div>
                                                                        <div className="badge-certified-green">
                                                                            <img src="images/perumahandetail/ic_certified_leaf_green.svg" />
                                                                            <div className="igahp-xs-medium green-main-text">Green Certified</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="igahp-xs-regular neutral-80-text" id="house-id">KD2930283183</div>
                                                                    <div className="wrapper-house-facilities">
                                                                        <div className="wrapper-total-facility">
                                                                            <img src="images/perumahandetail/ic_bed.svg" width={20} height={20} />
                                                                            <div className="igahp-s-regular neutral-90-text">2</div>
                                                                        </div>
                                                                        <div className="wrapper-total-facility">
                                                                            <img src="images/perumahandetail/ic_shower.svg" width={20} height={20} />
                                                                            <div className="igahp-s-regular neutral-90-text">1</div>
                                                                        </div>
                                                                        <div className="wrapper-total-facility">
                                                                            <img src="images/perumahandetail/ic_levels.svg" width={20} height={20} />
                                                                            <div className="igahp-s-regular neutral-90-text">1</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="wrapper-luas-bangunan-rumah">
                                                                        <div className="wrapper-luas-tanah">
                                                                            <div className="igahp-m-regular neutral-60-text">LT</div>
                                                                            <div className="igahp-m-regular neutral-60-text">:</div>
                                                                            <div className="igahp-m-regular neutral-90-text">60 m<sup className="super-sub">2</sup>
                                                                            </div>
                                                                        </div>
                                                                        <div className="wrapper-luas-tanah">
                                                                            <div className="igahp-m-regular neutral-60-text">LB</div>
                                                                            <div className="igahp-m-regular neutral-60-text">:</div>
                                                                            <div className="igahp-m-regular neutral-90-text">30 m<sup className="super-sub">2</sup>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="igahp-xs-regular blue-text" id="total-unit">1 Unit Tersedia</div>
                                                                </div>
                                                            </li>

                                                        </div>
                                                    ))}


                                                </Carousel>
                                            </ul> */}



                                            {/* <ul>
                                                <li>
                                                    <div className="list-grid-house">
                                                        <div className="wrapper-house-type">
                                                            <p className="igahp-xs-medium white-text">Rumah Tapak</p>
                                                        </div>
                                                        <div className="house-photo">
                                                            <img src="image_perumahan_taman_anggrek_1.png" loading="lazy" alt />
                                                        </div>
                                                        <div className="wrapper-house-name">
                                                            <div className="igahp-l-medium black-text">Tipe 30</div>
                                                            <div className="badge-certified-green">
                                                                <img src="images/perumahandetail/ic_certified_leaf_green.svg" />
                                                                <div className="igahp-xs-medium green-main-text">Green Certified</div>
                                                            </div>
                                                        </div>
                                                        <div className="igahp-xs-regular neutral-80-text" id="house-id">KD2930283183</div>
                                                        <div className="wrapper-house-facilities">
                                                            <div className="wrapper-total-facility">
                                                                <img src="images/perumahandetail/ic_bed.svg" width={20} height={20} />
                                                                <div className="igahp-s-regular neutral-90-text">2</div>
                                                            </div>
                                                            <div className="wrapper-total-facility">
                                                                <img src="images/perumahandetail/ic_shower.svg" width={20} height={20} />
                                                                <div className="igahp-s-regular neutral-90-text">1</div>
                                                            </div>
                                                            <div className="wrapper-total-facility">
                                                                <img src="images/perumahandetail/ic_levels.svg" width={20} height={20} />
                                                                <div className="igahp-s-regular neutral-90-text">1</div>
                                                            </div>
                                                        </div>
                                                        <div className="wrapper-luas-bangunan-rumah">
                                                            <div className="wrapper-luas-tanah">
                                                                <div className="igahp-m-regular neutral-60-text">LT</div>
                                                                <div className="igahp-m-regular neutral-60-text">:</div>
                                                                <div className="igahp-m-regular neutral-90-text">60 m<sup className="super-sub">2</sup>
                                                                </div>
                                                            </div>
                                                            <div className="wrapper-luas-tanah">
                                                                <div className="igahp-m-regular neutral-60-text">LB</div>
                                                                <div className="igahp-m-regular neutral-60-text">:</div>
                                                                <div className="igahp-m-regular neutral-90-text">30 m<sup className="super-sub">2</sup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="igahp-xs-regular blue-text" id="total-unit">1 Unit Tersedia</div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="list-grid-house">
                                                        <div className="wrapper-house-type">
                                                            <p className="igahp-xs-medium white-text">Rumah Tapak</p>
                                                        </div>
                                                        <div className="house-photo">
                                                            <img src="image_perumahan_taman_anggrek_2.png" loading="lazy" alt />
                                                        </div>
                                                        <div className="wrapper-house-name">
                                                            <div className="igahp-l-medium black-text">Tipe 30</div>
                                                            <div className="badge-certified-green">
                                                                <img src="images/perumahandetail/ic_certified_leaf_green.svg" />
                                                                <div className="igahp-xs-medium green-main-text">Green Certified</div>
                                                            </div>
                                                        </div>
                                                        <div className="igahp-xs-regular neutral-80-text" id="house-id">KD2930283183</div>
                                                        <div className="wrapper-house-facilities">
                                                            <div className="wrapper-total-facility">
                                                                <img src="images/perumahandetail/ic_bed.svg" width={20} height={20} />
                                                                <div className="igahp-s-regular neutral-90-text">2</div>
                                                            </div>
                                                            <div className="wrapper-total-facility">
                                                                <img src="images/perumahandetail/ic_shower.svg" width={20} height={20} />
                                                                <div className="igahp-s-regular neutral-90-text">1</div>
                                                            </div>
                                                            <div className="wrapper-total-facility">
                                                                <img src="images/perumahandetail/ic_levels.svg" width={20} height={20} />
                                                                <div className="igahp-s-regular neutral-90-text">1</div>
                                                            </div>
                                                        </div>
                                                        <div className="wrapper-luas-bangunan-rumah">
                                                            <div className="wrapper-luas-tanah">
                                                                <div className="igahp-m-regular neutral-60-text">LT</div>
                                                                <div className="igahp-m-regular neutral-60-text">:</div>
                                                                <div className="igahp-m-regular neutral-90-text">60 m<sup className="super-sub">2</sup>
                                                                </div>
                                                            </div>
                                                            <div className="wrapper-luas-tanah">
                                                                <div className="igahp-m-regular neutral-60-text">LB</div>
                                                                <div className="igahp-m-regular neutral-60-text">:</div>
                                                                <div className="igahp-m-regular neutral-90-text">30 m<sup className="super-sub">2</sup>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="igahp-xs-regular blue-text" id="total-unit">1 Unit Tersedia</div>
                                                    </div>
                                                </li>
                                            </ul>
                                             */}
                                        </div>
                                    </div>
                                </section>
                                <section className="wrapper-detail-fasilitas-perumahan">
                                    <div className="igahp-h3-semi-bold black-text">Detail {data.nama_perumahan}</div>
                                    <div className="wrapper-info-detail-perumahan">
                                        <div className="list-info-perumahan-item">
                                            <img src="images/perumahandetail/ic_home_green.svg" height={20} width={20} />
                                            <div className="igahp-m-regular black-text">Jumlah Unit</div>
                                            <div className="igahp-s-regular neutral-90-text">{data.totalUnitRumah} Unit</div>
                                        </div>
                                        <div className="list-info-perumahan-item">
                                            <img src="images/perumahandetail/ic_property.svg" height={20} width={20} />
                                            <div className="igahp-m-regular black-text">Tipe Properti</div>
                                            <div className="igahp-s-regular neutral-90-text">Rumah {data.jenis_perumahan === 0 ? 'tapak' : 'susun'}</div>
                                        </div>
                                        <div className="list-info-perumahan-item">
                                            <img src="images/perumahandetail/ic_tools.svg" height={20} width={20} />
                                            <div className="igahp-m-regular black-text">Status Pembangunan</div>
                                            <div className="igahp-s-regular neutral-90-text">Dalam Pembangunan</div>
                                        </div>
                                        <div className="list-info-perumahan-item">
                                            <img src="images/perumahandetail/ic_building.svg" height={20} width={20} />
                                            <div className="igahp-m-regular black-text">Selesai Pembangunan</div>
                                            <div className="igahp-s-regular neutral-90-text">-</div>
                                        </div>
                                    </div>
                                    <div className="divider-neutral-30" />

                                    {fasilitas ? (
                                        <div className="wrapper-sarana-prasarana">
                                            <div className="wrapper-prasarana">
                                                <div className="igahp-l-medium neutral-60-text">Lokasi Terdekat</div>
                                                <div className="wrapper-list-sarana-prasarana">

                                                    {fasilitas.tk ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_kindergarten.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Taman Kanak-kanak</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.sdn ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_school.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Sekolah Dasar</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.smp ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_junior_school.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Sekolah Menengah Pertama</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.sma ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_senior_school.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Sekolah Menengah Atas</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.universitas ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_university.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Universitas</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.bankAtm ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_atm_sign.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Bank/ATM</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}


                                                    {fasilitas.rumahSakit ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_hospital.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Rumah Sakit</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}


                                                    {fasilitas.pasarInduk ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_market.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Pasar Induk</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.spbu ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_gas_station.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">SPBU/Pom Bensin</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.gerbangTol ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_toll.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Gerbang Tol</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.stasiunKereta ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_station.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Stasiun Kereta</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.terminalBus ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_bus.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Terminal Bus</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="divider-neutral-30" />
                                            <div className="wrapper-prasarana">
                                                <div className="igahp-l-medium neutral-60-text">Prasarana Umum</div>
                                                <div className="wrapper-list-sarana-prasarana">

                                                    {fasilitas.gerbangPerumahan ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_house_gate.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Gerbang Perumahan</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.tamanBermain ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_playground.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Taman Bermain</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.jalanUmum ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_street.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Jalan Umum</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.keamanan ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_house_gate.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Keamanan</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.drainase ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_drainase.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Drainase</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.aksesParkir ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_park_access.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Akses Parkir</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="divider-neutral-30" />
                                            <div className="wrapper-sarana">
                                                <div className="igahp-l-medium neutral-60-text">Sarana</div>
                                                <div className="wrapper-list-sarana-prasarana">


                                                    {fasilitas.masjid ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_mosque.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Masjid</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.gereja ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_church.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Gereja</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="divider-neutral-30" />
                                            <div className="wrapper-gaya-hidup-berkelanjutan">
                                                <div className="igahp-l-medium neutral-60-text">Gaya Hidup Berkelanjutan</div>
                                                <div className="wrapper-list-sarana-prasarana">

                                                    {fasilitas.bankSampah ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_trash_bank.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Bank Sampah</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.tempatSampahDaurUlang ? (
                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_recycle_20.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Tempat Sampah Daur Ulang</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                    {fasilitas.pengelolaanLimbah ? (

                                                        <div className="wrapper-sarana-prasarana-item">
                                                            <img src="images/perumahandetail/ic_limbah.svg" width={20} height={20} />
                                                            <div className="igahp-m-medium neutral-90-text">Pengelolaan Limbah</div>
                                                        </div>
                                                    ) : (
                                                        <></>
                                                    )}

                                                </div>
                                            </div>
                                            <div className="divider-neutral-30" />
                                        </div>
                                    ) : (
                                        // If fasilitas does not contain data, render this block
                                        <p>Tidak ditemukan fasilitas di perumahan ini</p>
                                    )}





                                </section>
                                <section className="wrapper-siteplan-perumahan">
                                    <div className="igahp-h3-semi-bold black-text">Siteplan Perumahan</div>
                                    {/* <div className="igahp-m-regular neutral-90-text" id="siteplan-cursor-description">Arahkan kursor ke kotak yang berwarna</div> */}
                                    <div className="wrapper-image-siteplan-perumahan">
                                        {/* <img src="images/perumahandetail/image_siteplan.png" id="image-siteplan-perumahan" /> */}
                                        <img src={`${apiUrl}/api/public/displayimage/siteplan_image_${data.sitePlan}`} />

                                        <div className="wrapper-keterangan-siteplan">
                                            <div className="igahp-s-medium black-text">Legenda</div>
                                            <div className="wrapper-warna-siteplan">
                                                {/* <div className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">Sudah Disertifikasi</div> */}

                                                <div style={{ backgroundColor: statusToColor['perencanaan-hijau'] }} className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">Perencanaan hijau</div>

                                                <div style={{ backgroundColor: statusToColor['pembangunan-hijau'] }} className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">pembangunan hijau</div>

                                                <div style={{ backgroundColor: statusToColor['sertifikasi-hijau'] }} className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">sertifikasi hijau</div>

                                                <div style={{ backgroundColor: statusToColor['terjual'] }} className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">terjual</div>

                                                <div style={{ backgroundColor: statusToColor['subsidi'] }} className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">subsidi</div>

                                                <div style={{ backgroundColor: statusToColor['komersil'] }} className="yellow-siteplan" />
                                                <div className="igahp-s-regular neutral-90-text">komersil</div>


                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section className="wrapper-info-lokasi-perumahan">
                                    <div className="igahp-h3-semi-bold black-text">Info Lokasi Perumahan</div>
                                    {/* <img src="images/perumahandetail/image_maps.png" />
                                    <div className="wrapper-alamat-lokasi">
                                        <img src="images/perumahandetail/ic_marker_location.svg" width={24} height={24} />
                                        <div className="igahp-l-regular neutral-90-text">Jalan Ir. Soekarno, Cirendang, Cigugur, Kuningan,
                                            Jawa Barat</div>
                                    </div> */}

                                    <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">

                                        <>
                                            <MapContainer
                                                gps={lokasiGps}
                                                onMapContainerReady={handleMapContainerReady}
                                            />
                                        </>

                                    </CustomIframe>

                                </section>


                            </div>
                            <div className="wrapper-profile-perumahan">
                                <div className="wrapper-profile">
                                    <img src="ic_logo_puri_mega_sarana_land.png" alt width={48} height={48} />
                                    <div className="igahp-m-medium black-text">{data.namaPengembang}</div>
                                    <div className="igahp-s-regular neutral-60-text">Developer</div>
                                    {/* <div className="igahp-s-regular neutral-80-text">Telp : {data.noTelp}</div> */}
                                </div>
                                <div className="divider-neutral-30" />
                                <div className="wrapper-button-profile">
                                    <a href={"https://wa.me/+62" + data.noTelp}>
                                        <div className="btn-green-border ">
                                            <img src="images/perumahandetail/ic_whatsapp.svg" width={22} height={22} />
                                            <div className="igahp-xs-semibold green-main-text">Hubungi Developer</div>
                                        </div>
                                    </a>
                                    <div
                                        onClick={handleMinatClick}
                                        data-toggle="modal"
                                        data-target="#modal-minat"
                                        className="btn-green-solid-pressed igahp-xs-semibold white-text">Minat Rumah?</div>
                                </div>
                            </div>
                        </section>
                    </div>

                    {isModalOpen &&
                        <ModalMinat onClose={handleModalClose}

                            namaPengembang={data.namaPengembang}
                            namaPerumahan={data.nama_perumahan}
                            perumahanId={user.perumahan_id}
                            pengembangId={data.pengembangId}
                        // hargaRumah={120000}
                        />
                    }


                    <FooterFront />

                </>

            ) : (
                <div>Loading...</div >
            )
            }
        </>

    )
}

export default PerumahanDetail
