import React from 'react'

const NavBarAtas = (props) => {

    
    const [selectedPage, setSelectedPage] = React.useState(0)
    const [showLoginButton, setShowLoginButton] = React.useState(true)

    React.useEffect(() => {
        setSelectedPage(props.page)
        setShowLoginButton(props.showButton)
    }, [props.page, props.showButton])



    React.useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/collapsable-navbar-igahp.js";
        script.async = true;
        document.body.appendChild(script);

    }, []);

    return (
        <>
            <nav className="igahp-navbar-content">
                <div className="igahp-navbar">
                    <div className="igahp-navbar-left">
                        <a href='/'><img src="/ic_igahp_logo_new.svg" alt="IGAHP" id='logo-igahp'/></a>
                    </div>
                    <div className="igahp-navbar-right">
                        <div className="igahp-navbar-icon">
                            <img src="/ic_menu.svg" id="icon-burger" />
                        </div>
                        <div className="igahp-navbar-menu">
                            <ul>
                                <li className={selectedPage == 1 ? "igahp-navbar-item selected" : "igahp-navbar-item"}>
                                    <a href="/comingsoon" className="igahp-s-regular">IGAHP</a>
                                    <div className="navbar-selected-line" />
                                </li>
                                <li className={selectedPage == 2 ? "igahp-navbar-item selected" : "igahp-navbar-item"}>
                                    <a href="/comingsoon" className="igahp-s-regular">Produk</a>
                                    <div className="navbar-selected-line" />
                                </li>
                                <li className={selectedPage == 3 ? "igahp-navbar-item selected" : "igahp-navbar-item"}>
                                    <a href="/comingsoon" className="igahp-s-regular">Sertifikasi</a>
                                    <div className="navbar-selected-line" />
                                </li>
                                <li className={selectedPage == 4 ? "igahp-navbar-item selected" : "igahp-navbar-item"}>
                                    <a href="/comingsoon" className="igahp-s-regular">Regulasi</a>
                                    <div className="navbar-selected-line" />
                                </li>
                                <li className={selectedPage == 5 ? "igahp-navbar-item selected" : "igahp-navbar-item"}>
                                    <a href="/comingsoon" className="igahp-s-regular">Kontak</a>
                                    <div className="navbar-selected-line" />
                                </li>
                                <li className={showLoginButton ? "igahp-navbar-button" : "igahp-navbar-button hidden"}>
                                    <a href="/login" className="igahp-m-medium">
                                        <div className="btn-green-solid-pressed">
                                            Login ke Portal
                                            <img src="/ic_arrow_right_white.svg" alt />
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="navbar-menu-expanded" id="navbar-menu-expanded">
                    <div className="igahp-navbar-menu-expanded">
                        <a href="/catalog"><div className="igahp-l-medium black-text">Katalog</div></a>
                        <div className="igahp-divider"></div>
                        <a href="/blog"><div className="igahp-l-medium black-text">Blog</div></a>
                        <div className="igahp-divider"></div>
                        <a href="/aplikasi"><div className="igahp-l-medium black-text">Aplikasi</div></a>
                        <div className="igahp-divider"></div>
                        <a href="/login" className={showLoginButton ? "igahp-s-semibold" : "igahp-s-semibold hide-btn-login"}>
                            <div className="igahp-btn-portal">
                                Login ke Portal
                                <img src="/ic_arrow_right.svg" alt />
                            </div>
                        </a>
                        <div className="igahp-divider"></div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default NavBarAtas
