
export async function refreshTokenRequest(user) {
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    try {
        const response = await fetch(apiUrl+'/api/auth/refreshtoken', {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
                'X-XSRF-TOKEN': user.csrfToken,
            },
            body: JSON.stringify({ refreshToken: user.refreshToken }),
        });

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error:', error);
    }
}
