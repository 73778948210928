import React, { useState } from 'react'
import { useContext } from 'react';
import { AuthContext } from "../../../AuthContext"
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { refreshCsrfTokenRequest } from '../../refreshCsrfTokenRequest';

import { Link, useNavigate } from 'react-router-dom';

const ModalContentLokasi = (props) => {

  const penampungBalikan = props.penampungBalikan;
  const [isTambahData, setIsTambahData] = useState(false);

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;


  const navigate = useNavigate()
  const { user, setUser } = useContext(AuthContext);
  const [namaPerumahan, setNamaPerumahan] = useState('');
  const [kodeWilayah, setKodeWilayah] = useState('');

  let refreshToken = null;
  let refreshCsrfToken = null;


  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(user)


    //----------------- cek jwt -----------------------------------------------------------------------------------------------

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      // setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }


  //----------------- mulai handel form ---------------------------------------------------------------------------------------
    const formData = new FormData();
    //  formData.append('id', props.sendItem.id);
    formData.append('id',1);
    formData.append('is_tambah_data', isTambahData);
    formData.append('kode_wilayah', kodeWilayah);
    formData.append('nama_perumahan', namaPerumahan);

    for (var key of formData.entries()) {
      console.log(key[0] + ', ' + key[1]);
    }

    // refreshCsrfToken = await refreshCsrfTokenRequest();
    // console.log('refreshCsrfToken');
    // console.log(refreshCsrfToken);
    // console.log(user.csrfToken)


    try {
      await fetch(apiUrl+'/api/igahp/simpanLokasiPerumahan', {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
            // 'Content-Type': 'application/json',
              Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
              'X-XSRF-TOKEN': user.csrfToken  
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          // console.log(data);

          alert(data.status);
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle error
          console.error(error);
          alert(error);
        });

    } catch (error) {
      console.error('An error occurred:', error);
    }


  };


  const handleClose = () => {
    penampungBalikan(true)
  };


  return (
    <div
      className="modal fade"
      id="ModalContentLokasi"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="ModalContentKompetensi"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title">Add/Edit Lokasi Perumahan</h4>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="modal-body">
              <div className="card-body">
                <div className="form-group">
                  <label htmlFor="kodeKompetensi">Kode Wilayah</label>
                  {/* <input type="text" className="form-control" id="KodeProgram" placeholder="Kode Program" /> */}
                  <input
                    type="text"
                    className="form-control"
                    id="kodeWilayah"
                    placeholder=""
                    value={kodeWilayah}
                    onChange={(e) => setKodeWilayah(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="namaUnit">Nama Perumahan</label>
                  {/* <input type="text" className="form-control" id="NamaProgram" placeholder={"Nama Program"} /> */}

                  <input
                    type="text"
                    className="form-control"
                    id="namaPerumahan"
                    placeholder=""
                    value={namaPerumahan}
                    onChange={(e) => setNamaPerumahan(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-end">
              <button
                type="button"
                onClick={handleClose}
                className="btn btn-default"
                data-dismiss="modal"
              >
                Close
              </button>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalContentLokasi
