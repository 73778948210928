import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import ModalContentLokasi from '../../master/modals/ModalContentLokasi'
import { AuthContext } from "../../../AuthContext";
import { Link, useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import EXIF from 'exif-js';
import MapContainer from './MapContainer';
import CustomIframe from '../../CustomIFrame';
import Iframe from 'react-iframe';
import moment from 'moment';

const TambahLokasi = () => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const { id } = useParams();

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);

    const [gpsLoaded, setGpsLoaded] = useState(false);

    const [isTokenExpired, setIsTokenExpired] = useState(false);


    const handleMapContainerReady = () => {
        setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };


    let refreshToken = null;
    const navigate = useNavigate()

    const [lokasiGps, setLokasiGps] = useState({});
    const [selectedOption, setSelectedOptionKodeWIlayah] = useState(null);
    const [wilayahTerdekatTampil, setWilayahTerdekatTampil] = useState(false);
    const [namaPerumahan, setNamaPerumahan] = useState('');
    const [jenisPerumahan, setJenisPerumahan] = useState('0');
    const [nomorIMB, setNomorIMB] = useState('');
    const [nikNPWPPemilikIMB, setNikNPWPPemilikIMB] = useState('');
    const [tglTerbitIMB, setTglTerbitIMB] = useState('');
    const [fileUploadIMB, setFileUploadIMB] = useState(null);
    const [fileUploadSitePlan, setFileUploadSitePlan] = useState(null);
    const [fotoGerbang, setFotoGerbang] = useState(null);
    const [fotoGerbangImg, setFotoGerbangImg] = useState(null);
    const [fotoTengah, setFotoTengah] = useState(null);
    const [fotoTengahImg, setFotoTengahImg] = useState(null);
    const [fotoContoh, setFotoContoh] = useState(null);
    const [fotoContohImg, setFotoContohImg] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [options, setOptions] = useState([]);
    const [displayGps, setDisplayGps] = useState(false);


    const [gerbangLongi, setGerbangLongi] = useState('');
    const [gerbangLati, setGerbangLati] = useState('');
    const [tengahLongi, setTengahLongi] = useState('');
    const [tengahLati, setTengahLati] = useState('');
    const [contohLongi, setContohLongi] = useState('');
    const [contohLati, setContohLati] = useState('');

    useEffect(() => {

        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (id > 0) {
            fetchData();
        }

        return () => { script.remove(); }


    }, []);


    console.log('render tambah lokasi');

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl+'/api/igahp/lokasi/detail/' + id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log(response.data.tgl_imb)
            setNamaPerumahan(response.data.nama_perumahan);
            setJenisPerumahan(response.data.jenis_perumahan)
            setNomorIMB(response.data.imb);
            // setTglTerbitIMB(response.data.tgl_imb)

            setTglTerbitIMB(moment(response.data.tgl_imb).format('YYYY-MM-DD'))

            setNikNPWPPemilikIMB(response.data.pemilik_imb)

        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    function getExifData(img, tag) {
        var exifData = EXIF.readFromBinaryFile(base64ToArrayBuffer(img.src));
        return exifData && exifData[tag] ? exifData[tag] : null;
    }

    function base64ToArrayBuffer(base64) {
        var binaryString = window.atob(base64.split(',')[1]);
        var len = binaryString.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }
        return bytes.buffer;
    }

    function convertDMSToDD(dms, ref) {
        var sign = ref == 'N' || ref == 'E' ? 1 : -1;
        var d = dms[0];
        var m = dms[1];
        var s = dms[2];
        return sign * (d + m / 60 + s / 3600);
    }



    const handleOptionChange = (event) => {
        setSelectedOptionKodeWIlayah(event.target.value);
        // alert(event.target.value);
    };

    const handleJenisPerumahan = (event) => {
        setJenisPerumahan(event.target.value);
        //  alert(jenisPerumahan);
    };

    const handleFotoGerbang = (event) => {

        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setFotoGerbangImg(reader.result);
        };
        reader.readAsDataURL(file);

        setFotoGerbang(event.target.files[0]);

        // Check if the file is an image
        if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }

        EXIF.getData(event.target.files[0], function () {
            // Check if GPS data exists
            const lat = EXIF.getTag(this, 'GPSLatitude');
            const latRef = EXIF.getTag(this, 'GPSLatitudeRef');
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lonRef = EXIF.getTag(this, 'GPSLongitudeRef');

            if (lat === undefined || lon === undefined) {
                alert('Image does not contain GPS data.');
                setWilayahTerdekatTampil(false);
                setOptions([]);
                return;
            }
            var latitude = convertDMSToDD(lat, latRef);
            var longitude = convertDMSToDD(lon, lonRef);

            setGerbangLongi(longitude);
            setGerbangLati(latitude)
            // setLokasiGps({ lat: latitude, lng: longitude });
            // populateRadioButton(latitude, longitude);

        })
    }

    const handleFotoTengah = (e) => {

        const file = e.target.files[0];


        const reader = new FileReader();

        reader.onload = () => {
            setFotoTengahImg(reader.result);
        };

        reader.readAsDataURL(file);

        setFotoTengah(e.target.files[0]);

        if (!file || !file.type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }

        EXIF.getData(file, function () {
            // Check if GPS data exists
            const lat = EXIF.getTag(this, 'GPSLatitude');
            const latRef = EXIF.getTag(this, 'GPSLatitudeRef');
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lonRef = EXIF.getTag(this, 'GPSLongitudeRef');

            if (lat === undefined || lon === undefined) {
                alert('Image does not contain GPS data.');
                setWilayahTerdekatTampil(false);
                setOptions([]);
                return;
            }
            var latitude = convertDMSToDD(lat, latRef);
            var longitude = convertDMSToDD(lon, lonRef);

            setTengahLongi(longitude)
            setTengahLati(latitude);

            setLokasiGps({ lat: latitude, lng: longitude });
            populateRadioButton(latitude, longitude);

        })

    }

    const handleFotoContoh = (e) => {

        const file = e.target.files[0];
        const reader = new FileReader();

        reader.onload = () => {
            setFotoContohImg(reader.result);
        };

        reader.readAsDataURL(file);

        setFotoContoh(e.target.files[0]);

        // Check if the file is an image
        if (!file || !file.type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }

        EXIF.getData(file, function () {
            // Check if GPS data exists
            const lat = EXIF.getTag(this, 'GPSLatitude');
            const latRef = EXIF.getTag(this, 'GPSLatitudeRef');
            const lon = EXIF.getTag(this, 'GPSLongitude');
            const lonRef = EXIF.getTag(this, 'GPSLongitudeRef');

            if (lat === undefined || lon === undefined) {
                alert('Image does not contain GPS data.');
                setWilayahTerdekatTampil(false);
                setOptions([]);
                return;
            }
            var latitude = convertDMSToDD(lat, latRef);
            var longitude = convertDMSToDD(lon, lonRef);

            setContohLongi(longitude);
            setContohLati(latitude)
            // setLokasiGps({ lat: latitude, lng: longitude });
            // populateRadioButton(latitude, longitude);

        })

    }

    const populateRadioButton = async (latitude, longitude) => {
        setIsLoading(true)
        setWilayahTerdekatTampil(true)
        setDisplayGps(false);

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {

            const response = await fetch(`${apiUrl}/api/igahp/getLokasiTerdekat/${latitude}/${longitude}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'X-XSRF-TOKEN': user.csrfToken,
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                },

            });
            const data = await response.json();
            const updatedData = [...data];
            updatedData[0].label = updatedData[0].label + ` => [terdekat]`;

            setOptions(updatedData);
            // setOptions(data);
            setIsLoading(false)
            setDisplayGps(true);
            setWilayahTerdekatTampil(true)


        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handlePetaSalah = async (e) => {
        setGpsLoaded(false)
        setFotoGerbang(null);
        setFotoTengah(null);
        setFotoContoh(null)
        setOptions([]);
        setDisplayGps(false);
        setWilayahTerdekatTampil(false)

    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        let isTambahData = true;
        if (id > 0) {
            // alert('item di edit')
            isTambahData = false;
        } else {
            // alert('item di add');
            isTambahData = true;
        }

        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        //  formData.append('id', props.sendItem.id);
        formData.append('id', id);
        formData.append('is_tambah_data', isTambahData);
        formData.append('pengembang_id', '1');
        formData.append('kode_wilayah', selectedOption);
        formData.append('nama_perumahan', namaPerumahan);
        formData.append("jenisPerumahan", jenisPerumahan);
        formData.append("no_imb", nomorIMB);
        formData.append("pemilikIMB", nikNPWPPemilikIMB);
        formData.append("tgl_imb", tglTerbitIMB);

        formData.append("imb_pdf", fileUploadIMB);
        formData.append("siteplan_ttd", fileUploadSitePlan);
        formData.append("input_foto_gerbang", fotoGerbang);
        formData.append("input_foto_tengah", fotoTengah);
        formData.append("input_foto_contoh", fotoContoh);

        formData.append("kodeWilayahDiPilih", selectedOption);
        formData.append("longitude_gerbang", gerbangLongi);
        formData.append("latitude_gerbang", gerbangLati);
        formData.append("longitude_tengah", tengahLongi);
        formData.append("latitude_tengah", tengahLati);
        formData.append("longitude_contoh", contohLongi);
        formData.append("latitude_contoh", contohLati);


        console.log(namaPerumahan);

        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            await fetch(apiUrl+'/api/igahp/simpanLokasiPerumahan', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    alert(data.status);
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert(error);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }

    };


    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Lokasi Perumahan</h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard v1</li>
                                </ol>
                            </div>{/* /.col */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">


                        <div className="row">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor>Nama Perumahan</label>
                                            {/* <input id="nama_perumahan" name="nama_perumahan" className="form-control" required data-pristine-pattern="/^((?!recycle).)*$/igm" data-pristine-pattern-message="Nama perumahan tidak boleh menggunakan kata recycle" data-pristine-required-message="Nama Perumahan tidak boleh kosong" /> */}

                                            <input
                                                className="form-control" required data-pristine-pattern="/^((?!recycle).)*$/igm" data-pristine-pattern-message="Nama perumahan tidak boleh menggunakan kata recycle" data-pristine-required-message="Nama Perumahan tidak boleh kosong"
                                                type="text"
                                                value={namaPerumahan}
                                                onChange={(e) => setNamaPerumahan(e.target.value)}
                                            />

                                            <small>Sesuaikan penulisan nama dengan dokumen PKS dengan Bank Pelaksana
                                            </small>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group"><label htmlFor="jenisPerumahan">Jenis Perumahan</label>
                                            {/* <select name="jenisPerumahan" value={jenisPerumahan} onChange={(e) => setJenisPerumahan(e.target.value)}  
                                            id="jenisPerumahan" className="form-control" >
                                            <option value="0">Rumah Tapak</option>
                                            <option value="1">Rumah Susun</option>
                                        </select> */}

                                            <select value={jenisPerumahan} onChange={handleJenisPerumahan} className="form-control" >
                                                <option value="0">RUMAH TAPAK</option>
                                                <option value="1">RUMAH SUSUN</option>
                                            </select>

                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group"><label htmlFor>Nomor IMB/PBG Kolektif</label>
                                            {/* <input name="no_imb" id="no_imb" className="form-control" placeholder="2200/IMB/E/2019" required data-pristine-required-message="Nomor IMB/PBG tidak boleh kosong" /> */}

                                            <input
                                                className="form-control" placeholder="2200/IMB/E/2019" required data-pristine-required-message="Nomor IMB/PBG tidak boleh kosong"
                                                type="text"
                                                value={nomorIMB}
                                                onChange={(e) => setNomorIMB(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group"><label htmlFor>NIK/NPWP Pemilik IMB/PBG</label>
                                            {/* <input name="pemilikIMB" id="pemilikIMB" className="form-control" required maxLength={16} minLength={15} data-pristine-type="number" data-pristine-number-message="NIK/NPWP hanya boleh angka" data-pristine-required-message="NIK/NPWP tidak boleh kosong" data-pristine-minlength-message="NIK/NPWP minimal 15 digit karakter numerik" /> */}

                                            <input
                                                className="form-control" required maxLength={16} minLength={15} data-pristine-type="number" data-pristine-number-message="NIK/NPWP hanya boleh angka" data-pristine-required-message="NIK/NPWP tidak boleh kosong" data-pristine-minlength-message="NIK/NPWP minimal 15 digit karakter numerik"
                                                type="text"
                                                value={nikNPWPPemilikIMB}
                                                onChange={(e) => setNikNPWPPemilikIMB(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor="tglImb">Tanggal terbit IMB/PBG</label>
                                            {/* <input type="date" id="tgl_imb" name="tgl_imb" className="form-control bg-white" required data-pristine-required-message="Tanggal terbit IMB/PBG tidak boleh kosong" /> */}

                                            <input
                                                className="form-control bg-white" required data-pristine-required-message="Tanggal terbit IMB/PBG tidak boleh kosong"
                                                type="date"
                                                value={tglTerbitIMB}
                                                onChange={(e) => setTglTerbitIMB(e.target.value)}
                                            />

                                            {/* <input
                                            className="form-control bg-white" required data-pristine-required-message="Tanggal terbit IMB/PBG tidak boleh kosong"
                                            type="date"
                                            // value={tglTerbitIMB}
                                            value={setTglTerbitIMB ? moment(tglTerbitIMB).format('DD/MM/YYYY') : ''}
                                            onChange={(e) => setTglTerbitIMB(e.target.value)}
                                        /> */}
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group"><label htmlFor>Upload PDF IMB/PBG Asli
                                            (berwarna)</label>
                                            <div className="mb-3">
                                                {/* <input className="form-control" id="imb_pdf" name="imb_pdf" type="file" accept=".pdf" data-pristine-required-message="Dokumen IMB/PBG tidak boleh kosong" required /> */}
                                                <input
                                                    className="form-control form-control"
                                                    data-pristine-required-message="Dokumen IMB/PBG tidak boleh kosong" required
                                                    type="file"
                                                    accept="application/pdf" // Spesifikasikan jenis file yang diizinkan
                                                    onChange={(e) => setFileUploadIMB(e.target.files[0])}
                                                />
                                                <button type="button" data-toggle="modal" data-target="#modal-pimi6si">hint</button>
                                            </div>
                                            <small> *Masukan salah satu IMB/PBG unit untuk kondisi IMB/PBG induk
                                                yang sudah pecah untuk
                                                sampling <br />*Tulisan pada foto harus bisa terbaca dengan jelas,
                                                ukuran file maksimal 10MB</small>
                                        </div>
                                        <hr />
                                        <div className="modal fade" tabIndex={-1} role="dialog" id="modal-pimi6si" aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Dokumen IMB/PBG</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="custom-file" id="customFile" lang="es"><label className="custom-file-label" htmlFor="input-file-pimi6si" style={{ cursor: 'pointer' }}>Pilih Dokumen
                                                            IMB/PBG</label></div>
                                                        <img src="@{/dist/Assets/panduanimb.jpeg}" alt="panduan Dokumen IMB/PBG" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label htmlFor>Upload Siteplan yang sudah di tanda tangan <br />1. Rumah
                                                Tapak : Dokumen Siteplan
                                                Pemda yang telah disahkan lengkap Stempel, tanda tangan dan NIP
                                                Berwenang<br />2. Rumah Susun : Dokumen Siteplan Pemda dan denah
                                                lanti yang telah disahkan lengkap Stempel, tanda tangan dan NIP
                                                Berwenang</label>
                                            <div className="mb-3">
                                                {/* <label for="formFileSm" class="form-label">Small file input example</label> */}
                                                {/* <input className="form-control form-control" id="siteplan_ttd" name="siteplan_ttd" type="file" accept=".jpg,.jpeg,.pdf" data-pristine-required-message="Foto Siteplan tidak boleh kosong" required /> */}

                                                <input
                                                    className="form-control form-control"
                                                    data-pristine-required-message="Dokumen Siteplan  tidak boleh kosong" required
                                                    type="file"
                                                    accept=".jpg,.jpeg,.pdf" // Spesifikasikan jenis file yang diizinkan
                                                    onChange={(e) => setFileUploadSitePlan(e.target.files[0])}
                                                />
                                                <button type="button" data-toggle="modal" data-target="#modal-nnfjzxt">hint</button>
                                            </div>
                                        </div>

                                        <div className="modal fade" tabIndex={-1} role="dialog" id="modal-nnfjzxt" aria-hidden="true">
                                            <div className="modal-dialog modal-xl">
                                                <div className="modal-content">
                                                    <div className="modal-header">
                                                        <h5 className="modal-title">Siteplan Pemda</h5>
                                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="custom-file" id="customFile" lang="es"><label className="custom-file-label" htmlFor="input-file-nnfjzxt" style={{ cursor: 'pointer' }}>Pilih Siteplan
                                                            Pemda</label></div>
                                                        <img src="@{/dist/Assets/panduansiteplanimb.jpeg}" alt="panduan Siteplan Pemda" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="col-12">
                                        <h5 className="text-center mb-4">Pilih Foto Lokasi yang memiliki koordinat GPS
                                        </h5>
                                        <div className="row">


                                            <div className="col-sm-4 mb-4">
                                                <div className="card card-foto-gerbang ">
                                                    <div className="card-body text-center " >
                                                        <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                                            <img id="uploaded-image-tengah" src={fotoGerbangImg} className="label-preview-image b-1 svelte-zttspj" alt="preview foto tampak" width={350} height={310} />
                                                        </label>
                                                        <input
                                                            data-pristine-required-message="Foto Gerbang" className="svelte-zttspj"
                                                            type="file"
                                                            accept="image/jpeg" // Spesifikasikan jenis file yang diizinkan
                                                            // onChange={(e) => setFotoGerbang(e.target.files[0])}
                                                            onChange={handleFotoGerbang}
                                                        />
                                                    </div>
                                                    <h5 className="card-body text-center">Foto Gerbang/Jalan Utama</h5>
                                                </div>
                                            </div>


                                            <div className="col-sm-4 mb-4">
                                                <div className="card card-foto-tengah ">
                                                    <div className="card-body text-center " >
                                                        <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                                            <img id="uploaded-image-tengah" src={fotoTengahImg} className="label-preview-image b-1 svelte-zttspj" alt="preview foto tampak" width={350} height={310} />
                                                        </label>
                                                        <input
                                                            data-pristine-required-message="Foto tengah" className="svelte-zttspj"
                                                            type="file"
                                                            accept="image/*" // Spesifikasikan jenis file yang diizinkan
                                                            // onChange={(e) => setFotoGerbang(e.target.files[0])}
                                                            onChange={(e) => handleFotoTengah(e)}
                                                        />
                                                    </div>
                                                    <h5 className="card-body text-center">Foto Tengah</h5>
                                                </div>
                                            </div>

                                            <div className="col-sm-4 mb-4">
                                                <div className="card card-foto-contoh ">
                                                    <div className="card-body text-center " >
                                                        <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                                            <img id="uploaded-image-contoh" src={fotoContohImg} className="label-preview-image b-1 svelte-zttspj" alt="preview foto tampak" width={350} height={310} />
                                                        </label>
                                                        <input
                                                            data-pristine-required-message="Foto Contoh" className="svelte-zttspj"
                                                            type="file"
                                                            accept="image/*" // Spesifikasikan jenis file yang diizinkan
                                                            // onChange={(e) => setFotoGerbang(e.target.files[0])}
                                                            onChange={(e) => handleFotoContoh(e)}
                                                        />
                                                    </div>
                                                    <h5 className="card-body text-center">Foto Contoh Rumah/Salah Satu Kavling</h5>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div id="pertanyaanbutton" />
                                    <div id="my-radio" />
                                    <div id="pertanyaanmap" />
                                    <div id="map" />


                                    <div className="row">
                                        {wilayahTerdekatTampil ? (
                                            <div className='row'>
                                                {isLoading ? (
                                                    <div>Loading...</div>
                                                ) : (
                                                    <>
                                                        <h5 className="text-center mb-4">Silahkan pilih kelurahan sesuai IMB/PBG</h5>
                                                        {options.map((option, index) => (
                                                            <div key={option.value}
                                                                // className="col-6 text-left text-muted"
                                                                className={`col-6 text-left  ${index === 0 ? 'text-bold' : 'text-muted'} `}

                                                            >
                                                                <label>
                                                                    <input
                                                                        type="radio"
                                                                        name="location"
                                                                        value={option.value}
                                                                        // checked={selectedOption === option.value}
                                                                        onChange={handleOptionChange}
                                                                    />
                                                                    {' ' + option.label}
                                                                </label>
                                                            </div>
                                                        ))}

                                                    </>
                                                )}
                                            </div>
                                        ) : null}

                                    </div>

                                    {wilayahTerdekatTampil && displayGps &&
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-md-6 mt-5 mb-4">
                                                    {/* Place your map code or embed here */}
                                                    <h1 className="text-center">Posisi lokasi dalam peta</h1>
                                                    <h5 className="text-center">Apakah koordinat yang ada dalam peta sudah sesuai dengan lokasi perumahan anda?</h5>
                                                </div>


                                            </div>

                                            <div className="d-flex justify-content-center mb-4 ">
                                                <button className="col-md-4 btn btn-primary" >Ya</button>
                                                <button className="col-md-4 btn btn-secondary" onClick={handlePetaSalah}>Tidak</button>
                                            </div>


                                        </div>
                                    }

                                    <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">
                                        {wilayahTerdekatTampil && displayGps &&

                                            <>
                                                <MapContainer
                                                    gps={lokasiGps}
                                                    onMapContainerReady={handleMapContainerReady}
                                                />
                                            </>
                                        }
                                    </CustomIframe>


                                    {gpsLoaded && <div className="d-flex justify-content-center mt-4 mb-4">
                                        <button className="btn btn-primary">Simpan Lokasi Perumahan</button>
                                    </div>}


                                </div>



                            </form>
                        </div>

                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

        </>
    )
}

export default TambahLokasi
