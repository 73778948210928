import React from 'react'
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import Footer from '../../Footer'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { AuthContext } from '../../../AuthContext'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { Link, useNavigate, useParams } from 'react-router-dom';

import Swal from 'sweetalert2';

const PengembangLokasiBlokTambah = () => {


  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  const { user, setUser } = useContext(AuthContext);
  const [data, setData] = useState([]);


  const [blok, setBlok] = useState(null);
  const [jumlahRumah, setJumlahRumah] = useState(null);
  const [isTokenExpired, setIsTokenExpired] = useState(false);


  const [isButtonProcess, setIsButtonProcess] = useState(false);

  let refreshToken = null;
  const navigate = useNavigate()

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;



  useEffect(() => {

    console.log('-------------------')
    // console.log(user);
    if (user.blokrumah_state == 'edit') {
      console.log('masuk edit')
      fetchData();
    } else {
      console.log('masuk Add')
      clearForm();
      console.log('masuk tambah data')
    }
  }, []);


  const clearForm = async (e) => {

    setBlok(null)
    setJumlahRumah(null)
  }

  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }
    try {
      console.log(user.blokrumah_id)
      const response = await axios.get(apiUrl+'/api/igahp/getBlokRumahById/' + user.blokrumah_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      console.log('info tipe rumah for edit ')
      console.log(response.data)
      // console.log(response.data.nama)

      setBlok(response.data.blok)
      setJumlahRumah(response.data.jumlahRumah)
      
      
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };


  const handleSubmit = async (e) => {


    setIsButtonProcess(true);

    e.preventDefault();

    // alert('item di add');
    var isTambahData = true;


    if (user.blokrumah_state == 'edit') {
      isTambahData = false;
    } else {
      isTambahData = true;
    }

    //----------------- mulai handel form ---------------------------------------------------------------------------------------
    const formData = new FormData();
    formData.append('id', user['blokrumah_id']);
    // formData.append('id', user.perumahan_id);
    formData.append('is_tambah_data', isTambahData);
    formData.append('pengembang_id', user.pengembang_id);
    formData.append('perumahan_id', user.perumahan_id);

    formData.append("blok", blok);
    formData.append("jumlahRumah", jumlahRumah);

    for (var key of formData.entries()) {
      // console.log(key[0] + ', ' + key[1]);
    }


    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }


    try {
      await fetch(apiUrl+'/api/igahp/simpanBlokRumah', {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          // console.log(data);

          // alert(data.status);
          Toast.fire({
            icon: 'success',
            title: data.status
          });


          setIsButtonProcess(false);


           navigate('/igahp/pengembang/PengembangLokasiBlok');
          // handleNilaiBalikan();
        })
        .catch(error => {
          setIsButtonProcess(false);

          // Handle error
          console.error(error);
          // alert(error);
          Toast.fire({
            icon: 'error',
            title: error
          });


        });

    } catch (error) {
      setIsButtonProcess(false);

      console.error('An error occurred:', error);
    }

  };



  return (
    <>
      {/* Main Sidebar Container */}
      {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
        <aside className="main-sidebar sidebar-light-success elevation-4">
  
        <MenuPerumahanDetail />
      </aside>

      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-4 mt-2">
              <div className="col-sm-6">
                <h1 className="text-muted fw-light">ISI DATA<b> BLOK</b></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Blok</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              {/* left column */}
              <div className="col-md-12">
                {/* jquery validation */}
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Form<small></small></h3>
                  </div>
                  {/* /.card-header */}
                  {/* form start */}
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="exampleInput">Nama Blok</label>
                       
                        <input
                          type="text"
                          className="form-control"
                          name="blok"
                          value={blok}
                          onChange={(e) => setBlok(e.target.value)}
                        />

                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInput">Jumlah</label>
                        <input
                          type="text"
                          className="form-control"
                          name="jumlahRumah"
                          value={jumlahRumah}
                          onChange={(e) => setJumlahRumah(e.target.value)}
                        />

                      </div>

                    </div>
                    {/* /.card-body */}
                    <div className="card-footer">
                      <button type="submit" disabled={isButtonProcess}  className="btn btn-primary">Simpan</button>
                    </div>
                  </form>
                </div>
                {/* /.card */}
              </div>
              {/*/.col (left) */}
              {/* right column */}
              <div className="col-md-6">
              </div>
              {/*/.col (right) */}
            </div>
            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}
      </div>
      {/* /.content-wrapper */}

      {/* < Footer /> */}



    </>
  )
}

export default PengembangLokasiBlokTambah
