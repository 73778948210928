import React, { createContext, useState } from 'react';

const OnegreenContext = createContext();

const OnegreenProvider = ({ children }) => {
    const [profileData, setProfileData] = useState(null);

    return (
        <OnegreenContext.Provider value={{ profileData, setProfileData }}>
            {children}
        </OnegreenContext.Provider>
    );
};

export { OnegreenContext, OnegreenProvider };
