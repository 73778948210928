import React from 'react'

import NavBarAtas from './NavBarAtas'
import FooterFront from './FooterFront';
import { useState } from 'react';

const Catalog = () => {

    const [isActivePondasi, setIsActivePondasi] = useState(true);
    const handlePondasiClick = () => {
        setIsActivePondasi(!isActivePondasi);
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)
    };

    const [isActiveDinding, setIsActiveDinding] = useState(false);
    const handleDindingClick = () => {
        setIsActivePondasi(false)
        setIsActiveDinding(!isActiveDinding)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)
    };

    const [isActiveKusen, setIsActiveKusen] = useState(false);
    const handleKusenClick = () => {
        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(!isActiveKusen)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)

    };

    const [isActiveAtap, setIsActiveAtap] = useState(false);
    const handleAtapClick = () => {
        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(!isActiveAtap)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)

    };


    const [isActiveLedeng, setIsActiveLedeng] = useState(false);
    const handleLedengClick = () => {
        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(!isActiveLedeng)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)

    };

    const [isActiveElektrik, setIsActiveElektrik] = useState(false);
    const handleElektrikClick = () => {
        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(!isActiveElektrik);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)

    };


    const [isActiveMaterial, setIsActiveMaterial] = useState(false);
    const handleMaterialClick = () => {
        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(!isActiveMaterial)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(false)

    };

    const [isActiveFurnitur, setIsActiveFurnitur] = useState(false);
    const handleFurniturClick = () => {

        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(!isActiveFurnitur)
        setIsActiveDekorasi(false)

    };


    const [isActiveDekorasi, setIsActiveDekorasi] = useState(false);
    const handleDekorasiClick = () => {

        setIsActivePondasi(false)
        setIsActiveDinding(false)
        setIsActiveKusen(false)
        setIsActiveAtap(false)
        setIsActiveLedeng(false)
        setIsActiveElektrik(false);
        setIsActiveMaterial(false)
        setIsActiveFurnitur(false)
        setIsActiveDekorasi(!isActiveDekorasi)

    };

    require("./../../css/common.css");

    return (
        <>
            <NavBarAtas page="1" showButton="true" />


            <div>
                <section className="wrapper-contoh-rumah">
                    <div className="wrapper-contoh-rumah-title">
                        <div className="one-green-h1-semi-bold black-text">Selamat Datang di Katalog Rumah Hijau</div>
                        <div className="one-green-m-regular blue-text">Silakan arahkan kursor Anda pada titik-titik putih yang ada pada
                            gambar di bawah ini</div>
                    </div>
                    <div className="wrapper-contoh-rumah-gambar">
                        <img src="images/frontcatalog/image_contoh_rumah.png" id="contoh-rumah" />
                        <div className="wrapper-tooltip" id="tooltip-struktur-atap">
                            <a href="#kategori-struktur-atap">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_genteng_tanah_liat.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Struktur Atap</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-dinding">
                            <a href="#kategori-dinding">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_hollow_block.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Dinding</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-kusen-ventilasi">
                            <a href="#kategori-kusen-ventilasi">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_kusen_jendela.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Kusen &amp; Ventilasi</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-material-bangunan">
                            <a href="#kategori-material-bangunan">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_dulux_biru.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Material Bangunan</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-struktur-pondasi">
                            <a href="#kategori-struktur-pondasi">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_pavling_block.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Struktur &amp; Pondasi</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-dekorasi">
                            <a href="#kategori-dekorasi">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_amarilis_merah.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Vegetasi</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-furniture">
                            <a href="#kategori-furnitur">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_tempat_sampah.jpg" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Furnitur</div>
                                </div>
                            </div>
                        </div>
                        <div className="wrapper-tooltip" id="tooltip-ledeng-saniter">
                            <a href="#kategori-furnitur">
                                <div className="tooltip-dot">
                                    <div className="white-dot-14" />
                                </div>
                            </a>
                            <div className="tooltip-info-material" id="info-material">
                                <img src="images/frontcatalog/image_pipa.png" width={88} height={88} />
                                <div className="wrapper-detail-info-material">
                                    <div className="one-green-xs-medium neutral-60-text">Ledeng &amp; Saniter</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="wrapper-konten-katalog">
                    <div className="wrapper-konten-katalog-title">
                        <div className="konten-katalog-title-text">Katalog</div>
                        <div className="wrapper-choose-category-mobile">
                            <div className="one-green-s-regular neutral-90-text">Struktur &amp; Pondasi</div>
                            <img src="images/aplikasi/ic_chevron_down.svg" />
                        </div>
                    </div>

                    <div className="wrapper-katalog-material">

                        <div className="wrapper-kategori-katalog">
                            
                            <div className={`kategori-katalog-item ${isActivePondasi ? 'selected' : ''}`} id="kategori-struktur-pondasi" onClick={handlePondasiClick}>
                                <div className="kategori-katalog-title" >
                                    <div className="one-green-s-regular">Struktur &amp; Pondasi</div>
                                    <svg className="chevron-kategori" width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveDinding ? 'selected' : ''}`} id="kategori-dinding" onClick={handleDindingClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Dinding</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveKusen ? 'selected' : ''}`} id="kategori-kusen-ventilasi" onClick={handleKusenClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Kusen &amp; Ventilasi</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveAtap ? 'selected' : ''}`} id="kategori-struktur-atap" onClick={handleAtapClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Struktur Atap</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveLedeng ? 'selected' : ''}`} id="kategori-ledeng-saniter" onClick={handleLedengClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Ledeng &amp; Saniter</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveElektrik ? 'selected' : ''}`} id="kategori-elektrik" onClick={handleElektrikClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Elektrik</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveMaterial ? 'selected' : ''}`} id="kategori-material-bangunan" onClick={handleMaterialClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Material Bangunan</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveFurnitur ? 'selected' : ''}`} id="kategori-furnitur" onClick={handleFurniturClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Furnitur</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                            <div className={`kategori-katalog-item ${isActiveDekorasi ? 'selected' : ''}`} id="kategori-dekorasi" onClick={handleDekorasiClick}>
                                <div className="kategori-katalog-title">
                                    <div className="one-green-s-regular">Dekorasi</div>
                                    <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#9E9E9E" />
                                    </svg>
                                </div>
                            </div>
                            <div className="divider-neutral-30" />
                        </div>



                        <div className="wrapper-katalog-list-material">


                            {isActivePondasi && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_batu_kali.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Batu Kali</div>
                                        </div>
                                    </a>
                                    <a href="/catalogdetail">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_besi_beton.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Besi Beton</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_semen_iso.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Semen ISO</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_besi_sloof.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Besi Sloof</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_pavling_block.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Paving Block</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveDinding && (
                                <>
                                    <a href="/CatalogDetail2">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_hollow_block.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Hollow Block</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_bata_merah.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Bata Merah</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_gypsum.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Gypsum</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_triplek.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Triplek</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveKusen && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_kayu.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Kayu</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_alumunium_kusen.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Alumunium Kusen</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_jendela.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Jendela</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_roster.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Roster</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_pintu.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Pintu</div>
                                        </div>
                                    </a>
                                </>
                            )}


                            {isActiveAtap && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_baja_ringan.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Baja Ringan</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_genteng_tanah_liat.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Genteng Tanah Liat</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_baja_wf.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Baja WF</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_beton.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Beton</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_metal_pasir_hijau.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Metal Pasir Hijau</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveLedeng && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_meteran_air.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Meteran Air</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_shower.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Shower</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_wastafel.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Wastafel</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_closet.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Closet</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_pipa_pvc.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Pipa PVC</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveElektrik && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_kabel_listrik.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Kabel Listrik</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_meteran_listrik.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Meteran Listrik</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_mcb.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">MCB</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_stopkontak.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Stopkontak</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_bohlam.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Bohlam</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_lampu_taman_tenaga_surya.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Lampu Taman Tenaga Surya</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_saklar.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Saklar</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_panel_surya.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Panel Surya</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_lampu_sel.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Lampu Foto Sel</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveMaterial && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_cat_biru.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Cat Biru</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_cat_coklat.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Cat Coklat</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_ubin_stately_pearl_grey.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Ubin Stately Pearl Grey</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveFurnitur && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_lemari.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Lemari</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_meja.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Meja</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_kursi.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Kursi</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_buffet.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Buffet</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_kabinet.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Kabinet</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_sofa.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Sofa</div>
                                        </div>
                                    </a>
                                </>
                            )}

                            {isActiveDekorasi && (
                                <>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_jam_dinding.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Jam Dinding</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_bingkai_foto.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Bingkai Foto</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_tanaman_amarilis_merah.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Tanaman Amarilis Merah</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_tempat_tisu_anyaman_batik.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Tempat Tisu Anyaman Batik</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_gorden_hitam.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Gorden Hitam</div>
                                        </div>
                                    </a>
                                    <a href="#">
                                        <div className="wrapper-material-item">
                                            <img src="images/frontcatalog/image_cermin.png" width={88} height={88} />
                                            <div className="one-green-s-medium black-text">Cermin</div>
                                        </div>
                                    </a>
                                </>
                            )}

                        </div>


                    </div>
                </section>
                <FooterFront />
            </div>

        </>
    )
}

export default Catalog



