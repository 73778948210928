import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'
import InputMask from 'react-input-mask';
import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';
import LocationSelect from '../../shared/LocationSelect';


const ModalDataPribadi = ({ onClose, dataMinat }) => {

    const [selectedVillage, setSelectedVillage] = useState('');

    const [options, setOptions] = useState([]);
    const [selectedPekerjaanOption, setSelectedPekerjaanOption] = useState("");


    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
    };

    

    const fetchOptionsPekerjaanFromApi = async () => {
        try {
            const response = await fetch(apiUrl + '/api/public/getAllPekerjaan');
            const data = await response.json();
            // console.log('data pekerjaan ....');
            // console.log(data);
            return data;
        } catch (error) {
            throw new Error('Failed to fetch options from API');
        }
    };

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);



    useEffect(() => {

        (async () => {
            // await fetchOptionsFromApi();
            // console.log('use effect .....')

            await fetchOptionsPekerjaanFromApi()
                .then(data => setOptions(data))
                .catch(error => console.error('Error fetching options:', error));


        })();

    }, []);


    const handleClose = () => {
        onClose();
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [isTokenExpired, setIsTokenExpired] = useState(false);


    let refreshToken = null;

    const navigate = useNavigate();

    const { user, setUser } = useContext(AuthContext);


    useEffect(() => {

        console.log('data modal pribadi....')
        console.log(dataMinat);


        (async () => {

            // await fetchOptionsFromApiPerumahan()
            //     .then(data => setOptionsPerumahan(data))
            //     .catch(error => console.error('Error fetching options:', error));
            // setSelectedOptionPerumahan(perumahanId);

            // const response = await axios.get(`${apiUrl}/api/public/getTipeRumahById2/${tipeRumahId}`);
            //             setHargaRumah(response.data.harga);

        })();

        setFormData({
            ...formData,  // Keep the existing formData
            nikKTP: dataMinat.nik, // Update nikKTP with the new value
            nomorNPWP: dataMinat.npwp,
            domisili: dataMinat.domisili,
            penghasilanPerbulan: dataMinat.penghasilan,
            jenisKelamin: dataMinat.jenisKelamin,
            tanggalLahir : dataMinat.tanggalLahir,
            statusKawin : dataMinat.statusKawin,
            agama : dataMinat.agama,
            pendidikan : dataMinat.pendidikan,
            minatProgram : dataMinat.minatProgram,
            minatTahun : dataMinat.minatTahun,
            domisiliKodeWilayah : dataMinat.domisiliKodeWilayah,
            statusKepemilikanRumah : dataMinat.statusKepemilikanRumah,
            pekerjaanId : dataMinat.pekerjaanId,
            kondisiRumah : dataMinat.kondisiRumah,
            kepemilikanLahanLain :  dataMinat.kepemilikanLahanLain,
            kepemilikanLahan :  dataMinat.kepemilikanLahan,
            tanggunganJiwa : dataMinat.tanggunganJiwa


        });
        setSelectedVillage(dataMinat.domisiliKodeWilayah);
        setSelectedPekerjaanOption(dataMinat.pekerjaanId)

    }, []);


    const [formData, setFormData] = useState({
        nikKTP: '',
        penghasilanPerbulan: '',
        nomorNPWP: '',
        domisili: '',
        jenisKelamin : '',
        tanggalLahir : '',
        statusKawin : '',
        agama : '',
        pendidikan : '',
        minatProgram : '',
        minatTahun : '',
        domisiliKodeWilayah : '',
        statusKepemilikanRumah : '',
        pekerjaanId : '',
        kondisiRumah : '',
        kepemilikanLahanLain : '',
        kepemilikanLahan : '',
        tanggunganJiwa : ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleChangePekerjaan = (e) => {
        const { name, value } = e.target;
        setSelectedPekerjaanOption(e.target.value);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        // alert(peminatId)
        let decodedToken = jwt_decode(user.accessToken);

        let currentDate = new Date();

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);

            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }

            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {

        }


        const data = {
            id: dataMinat.id,
            nik: formData.nikKTP,
            npwp: formData.nomorNPWP,
            domisili: formData.domisili,
            penghasilan: formData.penghasilanPerbulan,
            jenisKelamin : formData.jenisKelamin,
            tanggalLahir : formData.tanggalLahir,
            statusKawin : formData.statusKawin,
            agama : formData.agama,
            pendidikan : formData.pendidikan,
            minatProgram : formData.minatProgram,
            minatTahun : formData.minatTahun,
            domisiliKodeWilayah : selectedVillage,
            statusKepemilikanRumah : formData.statusKepemilikanRumah,
            pekerjaan : selectedPekerjaanOption,
            kondisiRumah : formData.kondisiRumah,
            kepemilikanLahanLain : formData.kepemilikanLahanLain,
            kepemilikanLahan : formData.kepemilikanLahan,
            tanggunganJiwa : formData.tanggunganJiwa

            // perumahanId: selectedOptionPerumahan,
            // tipeRumahId: selectedOptionTipeRumah

        };


        axios.post(apiUrl + '/api/igahp/pengembang/updateMinatUnit', data,
            {
                headers: {

                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json' // You can adjust this based on your API requirements
                }
            })
            .then(response => {
                console.log('API Response:', response.data);

                alert('Update berhasil..');
            })
            .catch(error => {
                console.error('API Error:', error);
                alert('Update Gagal..');
            });

    };


    return (
        <>


            <div className="modal fade" id="ModalDataPribadi" tabIndex={-1} role="dialog"
                aria-labelledby="ModalDataPribadiLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>



                        <div className="modal-body">
                            <div className="form-group">
                                <h6 className="m-0">Data Pribadi </h6>
                                <p className="text-muted mb-0"><small>Rekam data pribadi peminat</small></p>
                            </div>


                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="nikKTP" className="text-gray-dark">
                                                Nomor NIK KTP</label>
                                            <input
                                                type="number"
                                                name="nikKTP"
                                                value={formData.nikKTP}
                                                onChange={handleChange}
                                                className="form-control"
                                                id="nikKTP"
                                                placeholder="Masukan NIK KTP anda"
                                                maxLength="16"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="penghasilanPerbulan" className="text-gray-dark">Penghasilan Perbulan</label>
                                            <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">Rp.</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="penghasilanPerbulan"
                                                    value={formData.penghasilanPerbulan}
                                                    onChange={handleChange}
                                                    className="form-control"
                                                    id="penghasilanPerbulan"
                                                    placeholder="x.xxx.xxx,-"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="nomorNPWP" className="text-gray-dark">Nomor NPWP</label>
                                            <InputMask
                                                type="text"
                                                name="nomorNPWP"
                                                value={formData.nomorNPWP}
                                                onChange={handleChange}
                                                mask="99.999.999.9-999.999"
                                                maskChar="_"
                                                className="form-control"
                                                id="nomorNPWP"
                                            // placeholder={'00'}
                                            />


                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="domisili" className="text-gray-dark">Domisili Saat Ini</label>
                                            <input
                                                type="text"
                                                name="domisili"
                                                value={formData.domisili}
                                                onChange={handleChange}
                                                className="form-control"
                                                id="domisili"
                                                placeholder="Masukan lokasi domisili saat ini"
                                            />
                                        </div>


                                    </div>

                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label>Kode Wilayah Domisili</label>
                                            <div className="row">
                                                <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Jenis Kelamin</label>
                                            <select name="jenisKelamin"
                                                className="form-control bg-white"
                                            value={formData.jenisKelamin} 
                                            onChange={handleChange}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Pria</option>
                                                <option value="2">Wanita</option>
                                            </select>
                                        </div>
                                    </div>


                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Tanggal Lahir</label>

                                            <input
                                                className="form-control bg-white"
                                                type="date"
                                                value={formData.tanggalLahir}
                                                onChange={handleChange}
                                                require
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Status Kawin</label>
                                            <select name="statusKawin"
                                                className="form-control bg-white"
                                                value={formData.statusKawin}
                                                onChange={handleChange}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Lajang</option>
                                                <option value="2">Menikah</option>
                                                <option value="3">Duda</option>
                                                <option value="4">Janda</option>
                                            </select>
                                        </div>
                                    </div>

                                    
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="" className="text-gray-dark">
                                                Jumlah Tanggungan</label>
                                            <input
                                                type="number"
                                                name="tanggunganJiwa"
                                                value={formData.tanggunganJiwa}
                                                onChange={handleChange}
                                                className="form-control"
                                                // id="nikKTP"
                                                placeholder=""
                                                maxLength="4"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Agama</label>
                                            <select name="agama"
                                                className="form-control bg-white"
                                                value={formData.agama}
                                                onChange={handleChange}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Islam</option>
                                                <option value="2">Katolik</option>
                                                <option value="3">Protestan</option>
                                                <option value="4">Budha</option>
                                                <option value="5">Hindu</option>
                                                <option value="6">Konghucu</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Pekerjaan</label>
                                            <select id="optionselect"
                                                className="form-control bg-white"
                                                nama = "perkejaanId"
                                                value={selectedPekerjaanOption}
                                                onChange={handleChangePekerjaan}>
                                                <option value="">Pilih Pekerjaan</option>
                                                {options.map(option => (
                                                    <option key={option.id} value={option.id}>
                                                        {option.namaPekerjaan}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Pendidikan</label>
                                            <select name="pendidikan"
                                                className="form-control bg-white"
                                                value={formData.pendidikan}
                                                onChange={handleChange}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">SD</option>
                                                <option value="2">SMP</option>
                                                <option value="3">SMA</option>
                                                <option value="4">S1</option>
                                                <option value="5">S2</option>
                                                <option value="6">S3</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Minat Program</label>
                                            <select name="minatProgram"
                                                className="form-control bg-white"
                                                value={formData.minatProgram}
                                                onChange={handleChange}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">KPR</option>
                                                <option value="2">KRR</option>
                                                <option value="3">KBR</option>

                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="" className="text-gray-dark">
                                                Minat Tahun</label>
                                            <input
                                                type="number"
                                                name="minatTahun"
                                                value={formData.minatTahun}
                                                onChange={handleChange}
                                                className="form-control"
                                                // id="nikKTP"
                                                placeholder=""
                                                maxLength="4"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Status Kepemilikan Rumah</label>
                                            <select name="statusKepemilikanRumah"
                                                className="form-control bg-white"
                                                value={formData.statusKepemilikanRumah}
                                                onChange={handleChange} required
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Milik Sendiri</option>
                                                <option value="2">Menumpang</option>
                                                <option value="3">Bebas Sewa</option>
                                                
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Kondisi Rumah</label>
                                            <select name="kondisiRumah"
                                                className="form-control bg-white"
                                            value={formData.kondisiRumah} 
                                            onChange={handleChange} required
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Layak</option>
                                                <option value="2">Tidak Layak</option>
                                                
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Kepemilikan Lahan</label>
                                            <select name="kepemilikanLahan"
                                                className="form-control bg-white"
                                                value={formData.kepemilikanLahan}
                                                onChange={handleChange} required
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Ya</option>
                                                <option value="2">Tidak</option>
                                                
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label>Kepemilikan Lahan Tempat Lain</label>
                                            <select name="kepemilikanLahanLain"
                                                className="form-control bg-white"
                                                value={formData.kepemilikanLahanLain}
                                                onChange={handleChange} required
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Ya</option>
                                                <option value="2">Tidak</option>
                                                
                                            </select>
                                        </div>
                                    </div>

                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>



                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalDataPribadi
