import React from 'react'

import { useEffect } from 'react';
import { useState } from 'react';
import LocationSelect from '../../shared/LocationSelect';
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import axios from 'axios';

const ModalPengajuan = (props) => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const penampungBalikan = props.penampungBalikan;

    const [kodeKelengkapan, setKodeKelengkapan] = useState('');
    const [kelengkapanDokumen, setKelengkapanDokumen] = useState('');
    const [isTambahData, setIsTambahData] = useState(false);
    const { user, setUser } = useContext(AuthContext);

    const [id, setId] = useState(0);
    const [nama, setNama] = useState('');
    const [noKtp, setNoKtp] = useState('');
    const [noTelp, setNoTelp] = useState('');
    const [alamat, setAlamat] = useState('');
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [isButtonProcess, setIsButtonProcess] = useState(false);



    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };    

    const navigate = useNavigate();
    let refreshToken = null;


    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
        // setFormData({ ...formData, kodeWilayah: villageId });
    };

    const [selectedVillage, setSelectedVillage] = useState('');


    useEffect(() => {
        
        console.log('USE EFFECT MODAL TRIGGER MODAL');
        console.log(props.sendItem);
        
        // if (isLoading || isTokenExpired) {
        //     console.log('Use effetc load fetchdata calling.....');
        //     fetchData();
        // }

        fetchData();

    }, [props.sendModal]);



    const fetchData = async () => {

        console.log('fetch data...');
        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            await fetch(apiUrl+'/api/igahp/getPengajuan/' + user.perumahan_id, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }            })
                .then(response => response.json())
                .then(data => {

                    setId(data[0].id);
                    setNoKtp(data[0].nik)
                    setNama(data[0].nama)
                    setAlamat(data[0].alamat)
                    setNoTelp(data[0].noTelp)
                    setSelectedVillage(data[0].kodeWilayah);

                    // setData(response.data);
                    setIsLoading(false);
                    setIsTokenExpired(false);

                    // Handle API response
                    // console.log(data);

                    // setIsLoading(true);
                    // alert(data.status);
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    // alert(data.status);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }


    };


    const handleSubmit = async (e) => {

        setIsButtonProcess(true);
        e.preventDefault();

        console.log(user)
        //----------------- cek jwt -----------------------------------------------------------------------------------------------

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            // setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        //  formData.append('id', props.sendItem.id);
        formData.append('id', id);
        formData.append('is_tambah_data', isTambahData);
        formData.append('kodeWilayah', selectedVillage);
        formData.append('alamat', alamat);

        formData.append('idPetugas', user.id);
        formData.append('nama', nama);
        formData.append('nik', noKtp);
        formData.append('telp', noTelp);
        formData.append('idLokasi', user.perumahan_id);

        // for (var key of formData.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }


        try {
            await fetch(apiUrl +'/api/igahp/simpanPengajuanApprovalLokasi', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    alert(data.status);
                    setIsButtonProcess(false);

                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    setIsButtonProcess(false);

                    alert(error);
                });

        } catch (error) {
            setIsButtonProcess(false);

            console.error('An error occurred:', error);
        }


    };


    const handleClose = () => {
        penampungBalikan(true)
    };


    return (
        // <div
        //     className="modal fade"
        //     id="ModalPengajuan"
        //     tabIndex="-1"
        //     role="dialog"
        //     aria-labelledby="ModalPengajuan"
        //     aria-hidden="true"
        // >

            <div className="modal fade" id="ModalPengajuan" tabIndex={-1} role="dialog"
                aria-labelledby="ModalPengajuan" aria-hidden="true" data-backdrop="static">

            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Pengajuan Lokasi Perumahan</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>

                        <div className="card card-danger">
                            {/* <div className="card-header">
                                <h3 className="card-title">Different Width</h3>
                            </div> */}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <label htmlFor="">Surat pernyataan kebenaran data dan dokumen lokasi perumahan</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">                                        
                                        <label>Dengan ini saya :</label>
                                    </div>
                                    <div className="col-4">                                        
                                    </div>
                                    <div className="col-5">                                        
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1"> 
                                    </div>
                                    <div className="col-2">
                                        <label>Nama</label>
                                    </div>
                                    <div className="col-8">
                                        {/* <input type="text" className="form-control" placeholder="" /> */}

                                        <input
                                            type="text"
                                            className="form-control"
                                            id="nama"
                                            placeholder=""
                                            value={nama}
                                            onChange={(e) => setNama(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-1">                                        
                                    </div>                                    
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>No Ktp</label>
                                    </div>
                                    <div className="col-8">
                                        {/* <input type="text" className="form-control" placeholder="" /> */}

                                        <input
                                            type="text"
                                            className="form-control"
                                            id="noKtp"
                                            placeholder=""
                                            value={noKtp}
                                            onChange={(e) => setNoKtp(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>No Telp</label>
                                    </div>
                                    <div className="col-8">
                                        {/* <input type="text" className="form-control" placeholder="" /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="noTelp"
                                            placeholder=""
                                            value={noTelp}
                                            onChange={(e) => setNoTelp(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Alamat</label>
                                    </div>
                                    <div className="col-8">
                                        {/* <input type="text" className="form-control" placeholder="" /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="alamat"
                                            placeholder=""
                                            value={alamat}
                                            onChange={(e) => setAlamat(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Kelurahan</label>
                                    </div>
                                    <div className="col-8">                                        
                                        <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-3">
                                        <label>Kuasa dari :</label>
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-5">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Nama Pengembang</label>
                                    </div>
                                    <div className="col-8">
                                        <label>{props.sendItem.pengembangNama}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Npwp</label>
                                    </div>
                                    <div className="col-8">
                                        <label>{props.sendItem.pengembangNpwp}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>No. IMB</label>
                                    </div>
                                    <div className="col-8">                                        
                                        <label>{props.sendItem.imb}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-3">
                                        <label>Permohonan Lokasi Perumahan :</label>
                                    </div>
                                    <div className="col-4">
                                    </div>
                                    <div className="col-5">
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Nama Lokasi</label>
                                    </div>
                                    <div className="col-8">
                                        <label>{props.sendItem.nama_perumahan}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Wilayah</label>
                                    </div>
                                    <div className="col-8">
                                        <label>{props.sendItem.info_kelurahan}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Kecamatan</label>
                                    </div>
                                    <div className="col-8">
                                        <label>{props.sendItem.infoKecamatan}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div> */}
                                {/* <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Kabupaten/Kota</label>
                                    </div>
                                    <div className="col-8">
                                        <label>Kab Tangerang</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Provinsi</label>
                                    </div>
                                    <div className="col-8">
                                        <label>Banten</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-1">
                                    </div>
                                    <div className="col-2">
                                        <label>Koordinat</label>
                                    </div>
                                    <div className="col-8">
                                        <label>{props.sendItem.longitude}, {props.sendItem.latitude}</label>
                                    </div>
                                    <div className="col-1">
                                    </div>
                                </div>



                                <div className="row">
                                    <div className="col-12">
                                        <label>Dengan ini menyatakan bahwa data/dokumen yang disampaikan adalah benar apa adanya, apabila dikemudian hari data/dokumen yang kami sampaikant idak benar
                                            dan terjadi permasalahan, maka kami bersedia diberikan sanksi sesuai ketentuan yang berlaku dan tidak melibatkan pihak applikasi yang meberikan persetujuan.
                                            Demikian pernyataan ini Kami buat dengan sebenarnya dan penuh tanggung jawab.
                                        </label>
                                    </div>                                    
                                </div>

                                <div className="row">
                                    <div className="col-7">                                        
                                    </div>
                                    
                                    <div className="col-5">

                                        <div className="icheck-primary d-inline">
                                            <input 
                                                type="checkbox" 
                                                id="checkboxPrimary3" 
                                                checked={isChecked}
                                                onChange={handleCheckboxChange}
                                            ></input>
                                            <label for="checkboxPrimary3">
                                                Saya setuju dengan pernyataan di atas
                                            </label>
                                        </div>
                                        
                                    </div>


                                </div>

                                
                            </div>

                            <div class="card-footer">
                              
                                <button
                                    type="button"
                                    onClick={handleClose}
                                    className="btn btn-secondary float-right"
                                    data-dismiss="modal"
                                >
                                    Close
                                </button>

                                <button disabled={!isChecked || isButtonProcess} type="submit" class="btn btn-primary float-left">Ajukan</button>
                            </div>
                           
                        </div>



                        {/* <div className="modal-body">
                            <div className="card-body">
                                <div className="form-group">
                                    <label htmlFor="kodeKelengkapan">Kode</label>
                                    
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="kodeKelengkapan."
                                        placeholder=""
                                        value={kodeKelengkapan}
                                        onChange={(e) => setKodeKelengkapan(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="kelengkapanDokumen">Desc Kelengkapan Dokumen</label>
                                  
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="kelengkapanDokumen"
                                        placeholder=""
                                        value={kelengkapanDokumen}
                                        onChange={(e) => setKelengkapanDokumen(e.target.value)}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-end">
                            <button
                                type="button"
                                onClick={handleClose}
                                className="btn btn-default"
                                data-dismiss="modal"
                            >
                                Close
                            </button>
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary"
                            >
                                Simpan
                            </button>
                        </div> */}
                    </form>
                </div>
            </div>
        </div>
    );

}

export default ModalPengajuan
