import React from 'react'
import PemdaDashboard from './PemohonDashboard'
import PemohonDashboard from './PemohonDashboard'

const PemohonContentDepan = () => {
  return (
    <>
      <PemohonDashboard />
    </>
  )
}

export default PemohonContentDepan
