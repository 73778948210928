import React from 'react'

const BankProfile = () => {
    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">Profil <b>Perusahaan</b></h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                            {/* /.content-card-body */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-sm-12 col-lg-8">
                                <div className="card mb-4">
                                    <div className="card-header align-items-center border-0 mt-1 mr-2">
                                        <div className="card-title">
                                            <h5 className="m-0">Info Perusahaan</h5>
                                            <p className="text-muted mb-0"><small>Pemilik Perusahaan atau Direktur Utama</small></p>
                                        </div>
                                        <div className="card-tools">
                                            <button type="button" id="tambahModal" data-toggle="modal" data-target="#ModalPermohonanBgh" className="btn btn-success">Update Data</button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card mb-3 border shadow-none">
                                                    <div className="table-responsive text-nowrap rounded">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Nama</td>
                                                                    <td>:</td>
                                                                    <td>Ferdy Jayadi</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Perusahaan</td>
                                                                    <td>:</td>
                                                                    <td>PT. BPD JAWA BARAT DAN BANTEN</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Email</td>
                                                                    <td>:</td>
                                                                    <td>bpdbjb@gmail.com</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card mb-3 border shadow-none">
                                                    <div className="table-responsive text-nowrap rounded">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>NPWP</td>
                                                                    <td>:</td>
                                                                    <td>01.659.173.7-426.000</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Asosiasi</td>
                                                                    <td>:</td>
                                                                    <td>PERUMNAS</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Telp Kantor</td>
                                                                    <td>:</td>
                                                                    <td>02318512042</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="me-2">
                                                    <i className="bx bx-globe bx-md" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h5 className="m-0">Admin</h5>
                                                    <p className="text-muted mb-0"><small>Pejabat berwenang</small></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mb-3 border shadow-none">
                                            <div className="table-responsive text-nowrap rounded">
                                                <table className="table">
                                                    <tbody>
                                                        <tr>
                                                            <td>Nama</td>
                                                            <td>:</td>
                                                            <td>Zifa Andriani</td>
                                                        </tr>
                                                        <tr>
                                                            <td>No. Handphone</td>
                                                            <td>:</td>
                                                            <td>081-1240442</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <div className="d-flex align-items-center">
                                                <div className="me-3">
                                                    <i className="fi fi-us fis rounded-circle fs-2" />
                                                </div>
                                                <div className="d-flex flex-column">
                                                    <h5 className="m-0">Lokasi</h5>
                                                    <p className="text-muted mb-0"><small>Lokasi Kantor</small></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card mb-3 border shadow-none">
                                                    <div className="table-responsive text-nowrap rounded">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Provinsi</td>
                                                                    <td>:</td>
                                                                    <td>Jawa Barat</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kab/Kota</td>
                                                                    <td>:</td>
                                                                    <td>Cirebon</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card mb-3 border shadow-none">
                                                    <div className="table-responsive text-nowrap rounded">
                                                        <table className="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td>Kecamatan</td>
                                                                    <td>:</td>
                                                                    <td>Kedawung</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Kelurahan</td>
                                                                    <td>:</td>
                                                                    <td>Kedungjaya</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-lg-4">
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="m-0">Alamat</h5>
                                        <p className="text-muted mb-0"><small>Alamat Lengkap Kantor Pusat</small></p>
                                    </div>
                                    <div className="card-body">
                                        <div className="d-flex justify-content-start align-items-center mb-4">
                                            <span className="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center"><i className="bx bx-cart-alt bx-sm lh-sm" /></span>
                                            <h6 className="text-body text-nowrap mb-0">Jl. Serayasa Barat I No. Kav 19</h6>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                            {/* <h6>Contact info</h6> */}
                                        </div>
                                        <p className=" mb-1">Kode Pos : 45153</p>
                                        {/* <p class=" mb-0">Mobile: +1 (609) 972-22-22</p> */}
                                    </div>
                                </div>
                                <div className="card mb-4">
                                    <div className="card-header">
                                        <h5 className="m-0">Maps</h5>
                                        <p className="text-muted mb-0"><small>Kantor Pusat Pengembang</small></p>
                                    </div>
                                    <div className="card-body">
                                        <div className="card mb-4">
                                            <div id="map" style={{ height: 180 }}>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}


        </>
    )
}

export default BankProfile
