import React from 'react'
import { useState, useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ModalKantorPemasaran from './ModalKantorPemasaran';

const KantorPemasaran = () => {

    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);

    const [isModalKantorPemasaranOpen, setModalKantorPemasaranOpen] = useState(false);


    const handleOpenKantorPemasaranClick = () => {
        setModalKantorPemasaranOpen(true);
    }

    const handleModalKantorPemasaranClose = () => {
        setModalKantorPemasaranOpen(false);
        fetchData();
    };


    const [formData, setFormData] = useState({
        alamat: '',
        nomor: '',
        kodeWilayah: '',
        noTelp: '',
        email: '',
        website: '',
        fax: '',
        isKantorUtama: false,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl + '/api/igahp/getPemasaranRumah/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log('data pemasaran utama..')
            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }


            try {
                await fetch(apiUrl + '/api/igahp/hapusKantorPemasaran', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    },
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                        alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                        alert(data.status);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }


    let sequenceNumber = 1;


    return (
        <>



            <div className="col-12 col-lg-9">
                <div className="card">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-header align-items-center border-0 mt-2 mr-2">
                                <div className="card-title">
                                    <h5 className="text-muted m-0">Kantor Pemasaran</h5>
                                    {/* <p class="text-muted mb-0"><small>Pemilik Perusahaan atau Direktur Utama</small></p> */}
                                </div>
                                <div className="card-tools">
                                    {/* <button type="button" id="#" data-toggle="modal" data-target="#modal-lg" 
                                    className="btn btn-success"><i className="fas fa-plus" /> Tambah Kantor</button> */}
                                    <button
                                        type="button"
                                        // id="peminatBankModal" 
                                        data-toggle="modal"
                                        data-target="#ModalKantorPemasaran"
                                        className="btn btn-success float-left"
                                        // onClick={handleOpenKantorPemasaranClick}

                                        onClick={(event) => {
                                            
                                            user['pemasaranrumah_state'] = 'add';

                                            user['pemasaranrumah_wilayah'] = '';
                                            user['pemasaranrumah_alamat'] = '';
                                            user['pemasaranrumah_notelp'] = '';
                                            user['pemasaranrumah_email'] = '';
                                            user['pemasaranrumah_nomor'] = '';
                                            user['pemasaranrumah_website'] = '';
                                            user['pemasaranrumah_kantorutama'] = '';
                                            loginContext(user);
                                          
                                            setModalKantorPemasaranOpen(true)


                                        }}
                                    >Tambah Kantor</button>

                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="card-body mb-0">
                        <div className="row">

                            {!isLoading ? (


                                <div className="col-md-12">
                                    <table id="example1" className="table table-bordered table-striped">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>ALAMAT</th>
                                                <th>EMAIL</th>
                                                <th>TELP</th>
                                                <th>KANTOR UTAMA</th>
                                                <th>WEB</th>
                                                <th>AKSI</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {data.map((item) => (
                                                <tr key={item.id}>

                                                    <td>{sequenceNumber++}</td>
                                                    <td>{item.alamat}</td>
                                                    <td>{item.email}</td>
                                                    <td>{item.noTelp}</td>
                                                    <td>

                                                        <input
                                                            type="checkbox"
                                                            checked={item.kantorUtama}
                                                            // onChange={() => handleCheckboxChange(item.id)}
                                                            onChange={() => {
                                                            }}
                                                        />
                                                    </td>
                                                    <td>{item.website}</td>

                                                    <td>
                                                        {/* <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                            onClick={(event) => {
                                                                user['perumahan_id'] = item.id;
                                                                user['perumahan_nama'] = item.nama_perumahan;                                                                        
                                                                loginContext(user); // isi context                                                                        
                                                                navigate(`/igahp/pengembang/PengembangLokasiProfile`);
                                                            }}
                                                        >
                                                            <i className="fas fa-eye" />
                                                        </button> */}

                                                        <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                            data-toggle="modal"
                                                            data-target="#ModalKantorPemasaran"

                                                            onClick={(event) => {
                                                                user['pemasaranrumah_id'] = item.id;
                                                                user['pemasaranrumah_state'] = 'edit';

                                                                user['pemasaranrumah_wilayah'] = item.kodeWilayah;
                                                                user['pemasaranrumah_alamat'] = item.alamat;
                                                                user['pemasaranrumah_notelp'] = item.noTelp;
                                                                user['pemasaranrumah_email'] = item.email;
                                                                user['pemasaranrumah_nomor'] = item.nomor;
                                                                user['pemasaranrumah_website'] = item.website;
                                                                user['pemasaranrumah_kantorutama'] = item.kantorUtama;
                                                                loginContext(user);
                                                                // navigate(`/igahp/pengembang/PengembangKantorPemasaranTambah`);

                                                                setModalKantorPemasaranOpen(true)


                                                            }}
                                                        >
                                                            <i className="fas fa-edit" id={`i-${item.id}`} />

                                                            {/* <TambahLokasi/> */}
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                            <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div>

                            ) : (

                                <>
                                    <p>Loading ...</p>
                                </>
                            )}

                            {isModalKantorPemasaranOpen && <ModalKantorPemasaran onClose={handleModalKantorPemasaranClose}
                                dataPemasaran={data}
                            />}


                        </div>
                        <br />
                        {/* <div className="form-group">
                            <h6 className="text-muted accent-gray-dark">Foto Lokasi Perumahan</h6>                           
                        </div> */}
                    </div>
                </div>
            </div>



        </>
    )
}

export default KantorPemasaran
