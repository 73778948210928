import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';

const ModalTangguhkanLokasi = (props) => {


    const { user, setUser } = useContext(AuthContext);
    const { loginContext } = useContext(AuthContext);
    const penampungBalikan = props.penampungBalikan;
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [dataTolakId, setDataTolakId] = useState(0);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;



    const handleClose = () => {
        penampungBalikan(true)
    };


    const initialFormData = [
        {
            kategori: 'Dokumen IMB',
            items: [
                {
                    checked: false,
                    alasan:
                        'Nomor IMB/PBG tidak sesuai (mohon dicek kembali kesesuaian nomor IMB/PBG antara data yang diinput dengan dokumen yang diunggah).',
                },
                {
                    checked: false,
                    alasan:
                        'Tanggal terbit IMB/PBG tidak sesuai (mohon dicek kembali kesesuaian tanggal IMB/PBG antara data yang diinput dengan dokumen yang diunggah).',
                },
                {
                    checked: false,
                    alasan:
                        'Pengesahan IMB/PBG tidak sesuai (mohon dicek kembali tanda tangan dan nama pejabat pemda yang berwenang serta cap instansi resmi untuk dokumen cetak atau QR code untuk dokumen yang ditandatangani secara elektronik).',
                },
                {
                    checked: false,
                    alasan:
                        'Identitas IMB/PBG tidak sesuai (mohon dicek kembali kesesuaian identitas antara data yang diinput dengan dokumen yang diunggah. Untuk perusahaan yang berganti nama, dilengkapi akta notaris yang memuat perubahan nama perusahaan. Untuk perusahaan KSO/JO, dilengkapi akta notaris yang memuat nama-nama seluruh perusahaan yang bekerjasama).',
                },
                {
                    checked: false,
                    alasan:
                        'IMB lama, rumah tapak >5 tahun atau rumah susun >10 tahun (mohon dilengkapi dengan surat permohonan perpanjangan untuk IMB lama yang mencantumkan masa berlaku habis atau dilengkapi surat keterangan masih berlaku dari instansi penerbit untuk IMB lama yang tidak mencantumkan masa berlaku).',
                },
                {
                    checked: false,
                    alasan:
                        'IMB terbit ≥2 Agustus 2021 (mulai tanggal 2 Agustus 2021, pemda menerbitkan dokumen Persetujuan Bangunan Gedung/PBG).',
                },
                {
                    checked: false,
                    alasan:
                        'IMB Kecamatan (kecamatan hanya menerbitkan IMB untuk bangunan gedung sederhana satu lantai).',
                },
                {
                    checked: false,
                    alasan:
                        'Kesalahan unggah dokumen/dokumen tidak terbaca jelas/dokumen tidak dapat dibuka (mohon dicek kembali dokumen IMB yang diunggah).',
                },
            ],
        },
        {
            kategori: 'Siteplan IMB',
            items: [
                {
                    checked: false,
                    alasan:
                        'Pengesahan gambar kerja siteplan (siteplan IMB) tidak sesuai (mohon dicek kembali tanda tangan dan nama pejabat pemda yang berwenang serta cap instansi resmi untuk dokumen cetak atau QR code untuk dokumen yang ditandatangani secara elektronik).',
                },
                {
                    checked: false,
                    alasan:
                        'Kesalahan unggah dokumen/dokumen tidak terbaca jelas/dokumen tidak dapat dibuka (mohon dicek kembali dokumen siteplan IMB yang diunggah/dokumen siteplan IMB seluruh lantai khusus rumah susun).',
                },
            ],
        },
        {
            kategori: 'Siteplan Latar',
            items: [
                {
                    checked: false,
                    alasan:
                        'Bentuk siteplan latar tidak sesuai (mohon dicek kembali kesesuaian bentuk antara siteplan latar dengan siteplan IMB).',
                },
                {
                    checked: false,
                    alasan:
                        'Jumlah kaveling/persil siteplan latar tidak sesuai (mohon dicek kembali kesesuaian jumlah kaveling/persil antara siteplan latar dengan siteplan IMB).',
                },
                {
                    checked: false,
                    alasan:
                        'Kaveling/persil siteplan latar tidak dapat diwarnai (mohon dicek kembali setiap kaveling/persil harus bersih dan tidak ada tulisan/garis/warna yang mengganggu). ',
                },
                {
                    checked: false,
                    alasan:
                        'Terdapat garis yang terputus pada salah satu atau lebih dari satu kaveling/persil siteplan latar (mohon dicek kembali kejelasan garis pada siteplan latar).',
                },
                {
                    checked: false,
                    alasan:
                        'Kesalahan unggah dokumen/dokumen tidak terbaca jelas/dokumen tidak dapat dibuka (mohon dicek kembali dokumen siteplan latar yang diunggah).',
                },
            ],
        },
        {
            kategori: 'Lokasi Tidak Sesuai',
            items: [
                {
                    checked: false,
                    alasan:
                        'Lokasi perumahan yang didaftarkan tidak sesuai (mohon dicek kembali lokasi perumahan yang didaftarkan, bukan per unit-unit rumah).',
                },
            ],
        },
    ];

    const initialCheck = () => {

        const inputStrings = [
            "Nomor IMB/PBG tidak sesuai (mohon dicek kembali kesesuaian nomor IMB/PBG antara data yang diinput dengan dokumen yang diunggah).",
            "Tanggal terbit IMB/PBG tidak sesuai (mohon dicek kembali kesesuaian tanggal IMB/PBG antara data yang diinput dengan dokumen yang diunggah).",
            'Lokasi perumahan yang didaftarkan tidak sesuai (mohon dicek kembali lokasi perumahan yang didaftarkan, bukan per unit-unit rumah).',
            'Pengesahan gambar kerja siteplan (siteplan IMB) tidak sesuai (mohon dicek kembali tanda tangan dan nama pejabat pemda yang berwenang serta cap instansi resmi untuk dokumen cetak atau QR code untuk dokumen yang ditandatangani secara elektronik).'
        ];

        for (const inputString of inputStrings) {
            for (const category of initialFormData) {
                for (const item of category.items) {
                    if (item.alasan == inputString) {
                        item.checked = true; // Set the 'checked' property to true
                        break; // No need to continue searching once found
                    }
                }
            }
        }
        console.log(initialFormData);
        setFormData(initialFormData)
    }

    // const [formData, setFormData] = useState(initialFormData);
    const [formData, setFormData] = useState(null);

    const handleCheckboxChange = (kategoriIndex, itemIndex) => {
        const updatedFormData = [...formData];
        updatedFormData[kategoriIndex].items[itemIndex].checked = !updatedFormData[kategoriIndex].items[itemIndex].checked;
        setFormData(updatedFormData);
    };


    useEffect(() => {

        console.log('USE EFFECT MODAL TRIGGER MODAL');
        console.log(props.sendItem);

        // if (isLoading || isTokenExpired) {
        //     console.log('Use effetc load fetchdata calling.....');
        //     fetchData();
        // }

        // initialCheck();
        fetchData();

    }, [props.sendModal], isLoading);


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        const url = apiUrl+"/api/igahp/getPengajuTolak/" + user.perumahan_id;

        let token = user.accessToken;

        try {

            // console.log(user.perumahan_id)


            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data.length)

            if (data.length === 0) {
                console.log('Data is empty');
                // Lakukan tindakan yang sesuai untuk data kosong
            } else {
                setDataTolakId(data.id);


                const inputStrings = JSON.parse(data.alasan);
                for (const inputString of inputStrings) {
                    for (const category of initialFormData) {
                        for (const item of category.items) {
                            if (item.alasan == inputString) {
                                item.checked = true; // Set the 'checked' property to true
                                break; // No need to continue searching once found
                            }
                        }
                    }
                }
                // console.log(initialFormData);
                setFormData(initialFormData)
            }
            

           
            setIsLoading(false);
            setIsTokenExpired(false);
            // return data;
        } catch (error) {
            // Handle errors here
            setFormData(initialFormData)
            setIsLoading(false);
            setIsTokenExpired(false);
            console.error('Error fetching data:', error);
        }

    };


    const handleSubmit = async (e) => {
        e.preventDefault();



        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            loginContext(user);
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }



        const alasanArray = [];
        for (const category of formData) {
            for (const item of category.items) {
                if (item.checked === true) {
                    // console.log(item.alasan);
                    alasanArray.push(item.alasan);
                }
            }
        }
        const jsonAlasanArray = JSON.stringify(alasanArray);
        // Menyimpan objek JSON dalam sebuah variabel
        const jsonString = jsonAlasanArray;
        console.log(jsonString); // Menampilkan JSON string dalam konsol


        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData2 = new FormData();
        //  formData.append('id', props.sendItem.id);
        formData2.append('id', dataTolakId);
        formData2.append('is_tambah_data', true);
        formData2.append('idLokasi', user.perumahan_id);
        formData2.append('idPetugas', user.id);

        formData2.append('alasan', jsonString);

        // for (var key of formData.entries()) {
        //     console.log(key[0] + ', ' + key[1]);
        // }


        try {
            await fetch(apiUrl+'/api/igahp/simpanPengajuanTolak', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData2,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    alert(data.status);
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert(error);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }



    };



    return (
        <div
            className="modal fade"
            id="ModalTangguhkanLokasi"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="ModalPengajuan"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Tangguhkan Pengajuan Lokasi</h4>
                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>



                    <div className="card-body">
                        <table id="example2" className="table table-bordered table-hover"></table>
                        {/* <thead>
                            <tr>
                                <th>No</th>
                                <th>A</th>
                                <th>B</th>                               
                            </tr>
                        </thead> */}
                        <tbody>

                            {isLoading ?
                                <p>Loading Data...</p> :

                                <>

                                    {
                                        formData.map((kategori, kategoriIndex) => (
                                            <div key={kategoriIndex}>
                                                <h3>{kategori.kategori}</h3>
                                                {kategori.items.map((item, itemIndex) => (
                                                    <div key={itemIndex}>

                                                        <tr>
                                                            {/* <label> */}
                                                            <td>*</td>
                                                            <td><small>{item.alasan}</small></td>
                                                            <td>
                                                                <input
                                                                    type="checkbox"
                                                                    checked={item.checked}
                                                                    onChange={() => handleCheckboxChange(kategoriIndex, itemIndex)}
                                                                />
                                                            </td>

                                                            {/* </label> */}
                                                        </tr>
                                                    </div>
                                                ))}
                                            </div>


                                        ))
                                    }
                                </>


                            }
                        </tbody>
                        <button className="btn btn-primary float-right"  onClick={handleSubmit}>Simpan</button>
                    </div>


                </div>
            </div>


        </div>
    );

}

export default ModalTangguhkanLokasi
