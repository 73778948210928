import React from 'react'
import MenuPerumahanDetail from './MenuPerumahanDetail'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
// import ModalContentLokasi from '../../master/modals/ModalContentLokasi'
import { AuthContext } from "../../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
// import { refreshTokenRequest } from '../../refreshTokenRequest';
// import TambahLokasi from './TambahLokasi';
import { refreshTokenRequest } from '../refreshTokenRequest';
import Footer from '../Footer';


const PengembangLokasiTipeRumah = () => {
    
    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const { loginContext } = useContext(AuthContext);

    let refreshToken = null;

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const navigate = useNavigate()

    const handleIsModal = (item, event) => {
        // console.log(event.target.id.split('-')[1]);
        // console.log(item.id);
        // console.log(isModal);
        console.log(item);
        if (event.target.id.split('-')[1] == item.id || item === "tambah-data") {
            // console.log('aaaa');
            isModal ? setIsModal(false) : setIsModal(true);
            setItemData(item);
        }
    }

    function changeModal() {
        var newModalId = "#newModal"; // Replace with the ID of the new modal you want to open
        var button = document.querySelector('[data-toggle="modal"]');
        button.setAttribute('data-target', newModalId);
    }

    const handleNilaiBalikan = (nilaiBalikan) => {
        setIsLoading(nilaiBalikan);
    }

    const setModalFalse = () => {
        isModal ? setIsModal(false) : setIsModal(true);
    }

    // useEffect(() => {        
    //     setTokenJwt(user.accessToken);     
    // }, []);

    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);

    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl+'/api/public/getTipeRumah/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };



    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }


            try {
                await fetch(apiUrl+'/api/igahp/hapusTipeRumah', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    },
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response
                        // console.log(data);

                        setIsLoading(true);
                         alert(data.status);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                        alert(data.status);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }


    const handleAddTipeRumah = () => {
        user['tiperumah_state'] = 'add';
        loginContext(user);

        navigate(`/igahp/pengembang/PengembangLokasiTipeRumahTambah`);
    };

    let sequenceNumber = 1;

    return (
        <>

            {/* Main Sidebar Container */}
            {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
            <aside className="main-sidebar sidebar-light-success elevation-4">

                <MenuPerumahanDetail />
            </aside>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-4 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">TIPE UNIT<b> RUMAH</b></h1>

                            </div>
                            <div className="col-sm-6">

                                {/* <ol className="breadcrumb float-sm-right">
                                    <button type="submit" onClick={handleAddTipeRumah} className="btn btn-primary">Tambah Lokasi</button>                                   
                                </ol> */}
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    {/* Default box */}
                    <div className="card card-solid">
                        <div className="card-body pb-0">

                            <div className="row">

                                {isLoading ?
                                    <p>Loading Data...</p> :

                                    <div className="card">
                                        <div className="card-header">
                                            <div className="card-info">
                                                <button type="submit" onClick={handleAddTipeRumah} className="btn btn-primary">Tambah Tipe Rumah</button>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">

                                                </div>
                                            </div>
                                        </div>
                                        {/* /.card-header */}
                                        <div className="card-body">
                                            <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>NO</th>
                                                        <th>NAMA TYPE</th>
                                                        <th>LT</th>
                                                        <th>LB</th>
                                                        <th>HARGA</th>
                                                        {/* <th>STATUS</th> */}
                                                        <th>AKSI</th>
                                                    </tr>
                                                </thead>
                                                <tbody>

                                                    {data.map((item) => (
                                                        <tr key={item.id}>
                                                            <td>{sequenceNumber++}</td>
                                                            <td>{item.nama}</td>
                                                            <td>{item.luasTanah}</td>
                                                            <td>{item.luasBangunan}</td>
                                                            <td>{item.harga.toLocaleString('en-US')}</td>
                                                            {/* <td>{item.status}</td> */}

                                                            <td>
                                                                <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                                    onClick={(event) => {
                                                                        // user['perumahan_id'] = item.id;
                                                                        // user['perumahan_nama'] = item.nama_perumahan;                                                                        
                                                                        // loginContext(user); // isi context                                                                        
                                                                        // navigate(`/igahp/pengembang/PengembangLokasiProfile`);
                                                                    }}
                                                                >
                                                                    <i className="fas fa-eye" />
                                                                </button>

                                                                <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                                    onClick={(event) => {
                                                                        user['tiperumah_id'] = item.id;
                                                                        user['tiperumah_state'] = 'edit';
                                                                        loginContext(user);
                                                                        navigate(`/igahp/pengembang/PengembangLokasiTipeRumahTambah`);
                                                                    }}
                                                                >
                                                                    <i className="fas fa-edit" id={`i-${item.id}`} />

                                                                    {/* <TambahLokasi/> */}
                                                                </button>

                                                                <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                                    <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }





                            </div>

                            <div className="row">


                                {data.map((item) => (
                                    <div key={item.id} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                        {/* <h2>{item.nama_perumahan}</h2> */}


                                        <div className="card bg-light d-flex flex-fill">
                                            <div className="card-header text-muted border-bottom-0">
.                                                {/* {item.jenis_perumahan === 0 ? 'Rumah Tapak' : 'Rumah Susun'} */}
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-7">
                                                        {/* <h2 className="lead"><b>{item.nama_perumahan}</b></h2> */}
                                                        <p className="text-muted text-lg"><b>{item.nama} </b>  </p>
                                                        <ul className="ml-4 mb-0 fa-ul text-muted">
                                                            {/* <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span> {item.kecamatan}</li> */}
                                                            <li className="small">Rp. {item.harga.toLocaleString('en-US')}</li>
                                                        </ul>
                                                    </div>.
                                                    <div className="col-5 text-center">
                                                        <img src={`${apiUrl}/api/public/displayimage/${item.fotoTampakDepan}`}
                                                            alt="gambar lokasi"
                                                            style={{ width: '250px', height: '200px' }}
                                                            className="img-about img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-footer">


                                                <div className="text-right">
                                                    <button type="button" className="btn btn-primary btn-sm"
                                                        onClick={(event) => {
                                                            user['tiperumah_id'] = item.id;
                                                            user['tiperumah_state'] = 'edit';
                                                            loginContext(user);
                                                            navigate(`/igahp/pengembang/PengembangLokasiTipeRumahTambah`);
                                                        }}
                                                    >Edit
                                                    </button>

                                                    <button type="button" className="btn btn-danger btn-sm"
                                                        onClick={(event) => {                                                            
                                                            handleDelete(item.id)
                                                            navigate(`/igahp/pengembang/PengembangLokasiTipeRumah`);
                                                        }}
                                                    >Hapus
                                                    </button> 

                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}


                            </div>
                        </div>

                    </div>
                    {/* /.card */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



            
        </>
    )
}

export default PengembangLokasiTipeRumah
