import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';
import LocationSelect from '../../shared/LocationSelect';

import Swal from 'sweetalert2';




const ModalBlokPerumahan = ({ onClose, dataBlok }) => {


    const [blok, setBlok] = useState(null);
    const [jumlahRumah, setJumlahRumah] = useState(null);


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });


    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);


    const [isButtonProcess, setIsButtonProcess] = useState(false);


    const handleClose = () => {
        
        onClose();
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [isTokenExpired, setIsTokenExpired] = useState(false);


    let refreshToken = null;

    const navigate = useNavigate();

    const { user, setUser } = useContext(AuthContext);



    useEffect(() => {

        (async () => {

            setBlok(user.blokrumah_nama)
            setJumlahRumah(user.blokrumah_jumlah)


        })();

    }, []);


    const handleSubmit = async (e) => {


        setIsButtonProcess(true);

        e.preventDefault();

        // alert('item di add');
        var isTambahData = true;


        if (user.blokrumah_state == 'edit') {
            isTambahData = false;
        } else {
            isTambahData = true;
        }

        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        formData.append('id', user['blokrumah_id']);
        // formData.append('id', user.perumahan_id);
        formData.append('is_tambah_data', isTambahData);
        formData.append('pengembang_id', user.pengembang_id);
        formData.append('perumahan_id', user.perumahan_id);

        formData.append("blok", blok);
        formData.append("jumlahRumah", jumlahRumah);

        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            await fetch(apiUrl + '/api/igahp/simpanBlokRumah', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    // alert(data.status);
                    Toast.fire({
                        icon: 'success',
                        title: data.status
                    });


                    setIsButtonProcess(false);


                    // navigate('/igahp/pengembang/PengembangLokasiBlok');
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    setIsButtonProcess(false);

                    // Handle error
                    console.error(error);
                    // alert(error);
                    Toast.fire({
                        icon: 'error',
                        title: error
                    });


                });

        } catch (error) {
            setIsButtonProcess(false);

            console.error('An error occurred:', error);
        }

    };




    return (
        <>


            <div className="modal fade" id="ModalBlokPerumahan" tabIndex={-1} role="dialog"
                aria-labelledby="ModalBlokPerumahanLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Input Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <h6 className="m-0">Tambah Blok Perumahan </h6>
                                    <p className="text-muted mb-0"><small>Isi tambah data blok dan jumlah unit rumah</small></p>
                                </div>

                                <div>
                                    <div className="form-group">
                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Nama Blok Rumah</label>
                                        {/* <input type="name" className="form-control" id="settings" placeholder="Masukan Blok Rumah" /> */}

                                        <input
                                            type="text"
                                            className="form-control"
                                            name="blok"
                                            value={blok}
                                            onChange={(e) => setBlok(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Jumlah Unit Rumah</label>
                                        {/* <input type="number" className="form-control" id="settings" placeholder="Masukan Jumlah Unit Rumah" /> */}
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="jumlahRumah"
                                            value={jumlahRumah}
                                            onChange={(e) => setJumlahRumah(e.target.value)}
                                        />
                                    </div>
                                </div>



                            </div>

                            <div class="modal-footer justify-content-end align-content-between">

                                <button type="submit" disabled={isButtonProcess} className="btn btn-success">Simpan</button>
                            </div>

                        </form>


                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalBlokPerumahan
