import React from 'react'

import Swal from 'sweetalert2';


const ToastTes = () => {

    const launchSuccessToast = () => {
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000
        });

        Toast.fire({
            icon: 'success',
            title: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr.'
        });
    };


    return (
        <>

            <div className="content-wrapper">
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">                                
                            </div>
                        </div>
                    </div>
                </div>
                <section className="content">
                    <div className="container-fluid">



                        <button type="button" className="btn btn-success swalDefaultSuccess" onClick={launchSuccessToast}>
                            Launch Success Toast
                        </button>



                    </div>
                </section>                
            </div >


        </>
    )
}

export default ToastTes
