import React from 'react'

import LocationSelect from '../../shared/LocationSelect'
import { useState } from 'react'
import { useRef } from 'react'
import jwt_decode from 'jwt-decode'
import axios from 'axios';
import { AuthContext } from '../../../AuthContext'
import { useContext } from 'react'
import { refreshTokenRequest } from '../../refreshTokenRequest'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

const ModalPeminatBanks = ({ onClose, peminatId }) => {


  // console.log('id = ' + id)

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  const [modalVisible, setModalVisible] = useState(false);


  const [id, setId] = useState('');
  const [kodeBank, setKodeBank] = useState('');
  const [namaBank, setNamaBank] = useState('');

  const [logoBank, setLogoBank] = useState(null);

  const [selectedBankOption, setSelectedBankOption] = useState('');

  let refreshToken = null;

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;





  const [wilayah, setWilayah] = useState('');
  const { user, setUser } = useContext(AuthContext);
  const [selectedVillage, setSelectedVillage] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedAsosiasiOption, setSelectedAsosiasiOption] = useState('');

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    (async () => {
      // await fetchOptionsFromApi();
      // console.log('use effect .....')

      await fetchOptionsBankFromApi()
        .then(data => setOptions(data))
        .catch(error => console.error('Error fetching options:', error));

    })();

  }, []);

  useEffect(() => {
    if (peminatId) {
      

    } else {
      setId('');
      setKodeBank('')
      setNamaBank('')
      setLogoBank('')
    }

  }, [peminatId]);


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);



  const fetchOptionsBankFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllBanks');
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };



  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setLogoBank(file);

    // Membaca file dan menampilkannya sebagai gambar
    const reader = new FileReader();
    reader.onload = function (event) {
      const imgElement = document.getElementById('logo-preview');
      imgElement.src = event.target.result;
    };
    reader.readAsDataURL(file);
  };




  const handleSubmit = async (e) => {
    e.preventDefault();


    const formData = new FormData();

    formData.append('id', id);
    formData.append('idBank', selectedBankOption);
    formData.append('idPeminat', peminatId);
    
    const sUrl = apiUrl + '/api/igahp/simpanPeminatBank';

    // console.log(sUrl);
    try {
      await fetch(sUrl, {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {

          Toast.fire({
            icon: 'success',
            title: data.status
          });

          // setModalVisible(false);

          // navigate("/igahp/pengembang/PengembangBank");

        })
        .catch(error => {

          Toast.fire({
            icon: 'error',
            title: error
          });

          // alert(error);
        });

    } catch (error) {
      console.error('An error occurred:', error);

    }



  };

  const handleClose = () => {
    // Lakukan apa pun yang diperlukan sebelum menutup modal
    // ...
    // Panggil fungsi onClose untuk memberi tahu parent bahwa modal ditutup
    onClose();
  };

  const handleOptionBankChange = event => {
    setSelectedBankOption(event.target.value);
  };



  return (

    <>


      <div className="modal fade" id="ModalPeminatBank" tabIndex={-1} role="dialog"
        aria-labelledby="ModalPeminatBankLabel" aria-hidden="true" data-backdrop="static">




        <div className="modal-dialog modal-md">
          <div className="modal-content">
            <div className="modal-header">
              {/* <h4 className="modal-title">Input Data</h4> */}
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body">

                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">

                    <div className="row">


                      <div className="card card-primary">
                        <div className="card-header">
                          <h3 className="card-title">PILIH BANK</h3>
                        </div>
                        <div className="card-body">


                          <div className="row">

                            <div className="col-md-12">

                              <div className="form-group">
                                {/* <label>Kode Bank</label> */}
                                <select className="form-control" value={selectedBankOption} onChange={handleOptionBankChange}>
                                  <option value="">Select an option</option>
                                  {options.map(option => (
                                    <option key={option.id} value={option.id}>
                                      {option.namaBank}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>


                  </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}

              </div>

              <div className="modal-footer justify-content-between">
                {/* <button type="button" className="btn btn-default" data-dismiss="modal">Tutup</button> */}
                <button type="submit" className="btn btn-primary">Simpan</button>
              </div>
            </form>

          </div>

        </div>

      </div>

    </>


  )
}

export default ModalPeminatBanks
