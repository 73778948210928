import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import { AuthContext } from "../../../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
// import { refreshTokenRequest } from '../../refreshTokenRequest';
// import TambahLokasi from './TambahLokasi';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import ModalBlokPerumahan from './ModalBlokPerumahan';


const BlokPerumahan = () => {

    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const { loginContext } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    let refreshToken = null;

    const navigate = useNavigate()

    
    
    // useEffect(() => {        
    //     setTokenJwt(user.accessToken);     
    // }, []);


    const [isModalBlokPerumahanOpen, setModalBlokPerumahanOpen] = useState(false);


    const handleOpenBlokPerumahanClick = () => {
        setModalBlokPerumahanOpen(true);
    }

    const handleModalBlokPerumahanClose = () => {

        setModalBlokPerumahanOpen(false);
        fetchData();
    };


    useEffect(() => {


        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            // console.log('Use effetc load fetchdata calling.....');
            fetchData();
        }

        //setTokenJwt(user.accessToken);
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);


    const fetchData = async () => {

        // setIsLoading(true);

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/getBlokRumah/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log(response.data)
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };



    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this item?");

        if (confirmDelete) {

            var formData = new FormData();
            formData.append("id", id);


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }


            try {
                await fetch(apiUrl + '/api/igahp/hapusBlokRumah', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                    },
                    // body: JSON.stringify(formData),
                    // headers: {
                    //     'Content-Type': 'application/json'
                    // }
                    body: formData
                    // headers: {   
                    //     'Content-Type': 'application/x-www-form-urlencoded',
                    //   },
                })
                    .then(response => response.json())
                    .then(data => {
                        // Handle API response

                        // console.log('blok perumahan ada .....');
                        // console.log(data);

                        fetchData();
                        // setIsLoading(true);
                        // handleNilaiBalikan();
                    })
                    .catch(error => {
                        // Handle error
                        console.error(error);
                        alert(data.status);
                    });

            } catch (error) {
                console.error('An error occurred:', error);
            }


        } else {

        }
    }


    const handleAddBlokRumah = () => {
        user['blokrumah_state'] = 'add';
        loginContext(user);

        // navigate(`/igahp/pengembang/PengembangLokasiBlokTambah`);
    };

    let sequenceNumber = 1;


    return (
        <>
            <div className="col-12 col-lg-9">
                <div className="card">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-header align-items-center border-0 mt-2 mr-2">
                                <div className="card-title">
                                    <h5 className="text-muted m-0">Blok Perumahan</h5>
                                    {/* <p class="text-muted mb-0"><small>Pemilik Perusahaan atau Direktur Utama</small></p> */}
                                </div>
                                <div className="card-tools">
                                    {/* <button type="button" id="#" data-toggle="modal" data-target="#modal-lg" className="btn btn-success"><i className="fas fa-plus" /> Tambah Blok Rumah</button> */}
                                
                                    <button
                                        type="button"
                                        // id="peminatBankModal" 
                                        data-toggle="modal"
                                        data-target="#ModalBlokPerumahan"
                                        className="btn btn-success float-left"
                                        // onClick={handleOpenBlokPerumahanClick}

                                        onClick={(event) => {
                                            
                                            user['blokrumah_state'] = 'add';
                                            user['blokrumah_nama'] = '';
                                            user['blokrumah_jumlah'] = '';
                                            loginContext(user);
                                            // navigate(`/igahp/pengembang/PengembangLokasiBlokTambah`);
                                            setModalBlokPerumahanOpen(true)
                                        }}
                                    >Tambah Blok Rumah</button>

                                </div>
                                
                            </div>
                        </div>
                    </div>

                    <div className="card-body">

                        {!isLoading ? (
                            <>

                                <table id="example1" className="table table-bordered table-striped">
                                    <thead>
                                        <tr>
                                            <th>NO</th>
                                            <th>NAMA BLOK</th>
                                            <th>JUMLAH RUMAH</th>

                                            <th>AKSI</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <>

                                            {data.map((item) => (
                                                <tr key={item.id}>
                                                    {/* <td>{item.id}</td> */}
                                                    <td>{sequenceNumber++}</td>
                                                    <td>{item.blok}</td>
                                                    <td>{item.jumlahRumah}</td>

                                                    <td>
                                                        {/* <button type="button" className="btn btn-sm btn-outline-info mt-1"

                                                            onClick={(event) => {
                                                                user['perumahan_id'] = item.id;
                                                                user['perumahan_nama'] = item.nama_perumahan;                                                                        
                                                                loginContext(user); // isi context                                                                        
                                                                navigate(`/igahp/pengembang/PengembangLokasiProfile`);
                                                            }}
                                                        >
                                                            <i className="fas fa-eye" />
                                                        </button> */}

                                                        <button type="button" className="btn btn-sm btn-outline-info mt-1"
                                                            data-toggle="modal"
                                                            data-target="#ModalBlokPerumahan"

                                                            onClick={(event) => {
                                                                user['blokrumah_id'] = item.id;
                                                                user['blokrumah_state'] = 'edit';
                                                                user['blokrumah_nama'] = item.blok;
                                                                user['blokrumah_jumlah'] = item.jumlahRumah;
                                                                loginContext(user);
                                                                // navigate(`/igahp/pengembang/PengembangLokasiBlokTambah`);
                                                                setModalBlokPerumahanOpen(true)
                                                            }}
                                                        >
                                                            <i className="fas fa-edit" id={`i-${item.id}`} />

                                                            {/* <TambahLokasi/> */}
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-outline-danger mt-1">
                                                            <i className="fas fa-trash" onClick={() => handleDelete(item.id)} />
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}

                                        </>



                                    </tbody>
                                </table>

                            </>
                        ) : (

                            <>
                                <p>Loading ...</p>
                            </>
                        )}

                        {isModalBlokPerumahanOpen && <ModalBlokPerumahan onClose={handleModalBlokPerumahanClose}
                            dataBlok={data}
                        />}

                    </div>


                </div>


            </div>

        </>
    )
}

export default BlokPerumahan
