import React from 'react'
import NavBarAtas from './NavBarAtas';

const BlogDetail2 = () => {

    require("./../../css/common.css");


    return (
        <>
            <NavBarAtas page="2" showButton={true} />


            <div>
                <section className="wrapper-detail-news-post">
                    <div className="wrapper-detail-news-post-content">
                        <img src="images/blog/image_berita_1.png" id="image-detail-news" />
                        <div className="detail-news-post-content">
                            <div className="one-green-h2-semi-bold black-text">IGAHP EXPO: Potensi Rumah Subsidi Hijau di Indonesia</div>
                            <div className="detail-news-content one-green-m-regular neutral-90-text">
                                <p><b>Rumah Subsidi Hijau: Menuju Pembangunan Berkelanjutan</b></p>
                                <p>Pada tahun 2024, Indonesia berencana untuk membangun sebanyak 100.000 unit Rumah Subsidi Hijau. Langkah ini tidak hanya akan membantu menyediakan perumahan yang terjangkau, tetapi juga memiliki dampak besar dalam pengurangan emisi karbon. Dengan perhitungan yang cermat, diperkirakan bahwa pembangunan ini akan mengurangi sekitar 353.587 ton emisi karbon dioksida, mendukung komitmen global untuk melawan perubahan iklim.</p>
                                <p><b>Hidup Berkelanjutan di Rumah Subsidi Hijau</b></p>
                                <p>Rumah Subsidi Hijau tidak hanya dikenal karena harganya yang terjangkau, tetapi juga karena desain dan material bangunannya yang ramah lingkungan. Penggunaan material daur ulang dan efisiensi energi menjadi fokus utama dalam pengembangan rumah ini.
                                    Selain dari aspek fisik bangunan, Rumah Subsidi Hijau juga mendorong gaya hidup berkelanjutan di antara penghuninya. Konsep siklus Reduce-Reuse-Recycle (3R) diimplementasikan secara aktif, khususnya dalam penanganan sampah anorganik rumah tangga. Untuk menciptakan lingkungan yang lebih bersih dan lebih sehat, sambil melibatkan masyarakat dalam upaya pelestarian lingkungan.</p>
                                <p><b>Mendukung Visi Indonesia Menuju Masa Depan Hijau</b></p>
                                <p>Partisipasi dalam Expo IGAHP dan investasi dalam Rumah Subsidi Hijau tidak hanya mendukung pertumbuhan ekonomi, tetapi juga memperkuat posisi Indonesia dalam menghadapi isu rumah dan sampah. Dengan melibatkan seluruh masyarakat, termasuk pengembang, pemerintah, dan konsumen, Indonesia berada di jalur untuk mencapai visi masa depan yang hijau, berkelanjutan, dan ramah lingkungan. Expo IGAHP dan pengembangan Rumah Subsidi Hijau membuktikan bahwa inovasi dan kolaborasi adalah kunci untuk menciptakan perubahan positif menuju masa depan yang lebih baik bagi semua.</p>
                            </div>
                        </div>
                        <div className="divider-neutral-30" />
                        <div className="wrapper-detail-news-tags">
                            <div className="one-green-subheading-medium black-text">Tags</div>
                            <div className="wrapper-list-detail-news-tags">
                                <div className="list-tags-item">#SubsidiMurah</div>
                                <div className="list-tags-item">#RumahSubsidiHijau</div>
                            </div>
                            <div className="wrapper-share-news-social-media">
                                <img src="images/blog/ic_instagram.svg" width={24} height={24} />
                                <img src="images/blog/ic_twitter.svg" width={24} height={24} />
                            </div>
                        </div>
                        <div className="divider-neutral-30" />
                    </div>
                    <div className="wrapper-detail-news-sidebar">
                        <div className="wrapper-search-detail-news">
                            <input type="text" placeholder="Search" className="one-green-l-regular black-text" />
                            <img src="images/blog/ic_search_grey.svg" id="ic_search_detail_news" />
                        </div>
                        <div className="wrapper-category-detail-news">
                            <div className="one-green-subheading-medium neutral-90-text">Kategori</div>
                            <div className="wrapper-list-category-detail-news">
                                <div className="one-green-l-regular neutral-80-text">IGAHP</div>
                                <div className="divider-neutral-30" />
                                <div className="one-green-l-regular neutral-80-text">Bangunan Gedung Hijau</div>
                                <div className="divider-neutral-30" />
                                <div className="one-green-l-regular neutral-80-text">Rumah Hijau</div>
                                <div className="divider-neutral-30" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper-berita-detail-news">
                    <div className="one-green-h3-semi-bold black-text">Berita</div>
                    <div className="wrapper-news-content">
                        <a href="/blogdetail">
                            <div className="news-content-item">
                                <div className="wrapper-image-news">
                                    <img src="images/blog/image_berita_terbaru.png" id="image-list-news" />
                                    <div className="wrapper-category-text-news">
                                        <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                    </div>
                                </div>
                                <div className="wrapper-content-news">
                                    <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                    <div className="one-green-subheading-semi-bold neutral-90">Keunggulan Rumah Subsidi Hijau dalam Mendukung Lingkungan dan Kesejahteraan</div>
                                </div>
                            </div>
                        </a>
                        {/* <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_2.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_3.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            <div className="wrapper-content-news">
                                <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                <div className="one-green-subheading-semi-bold neutral-90">Kolaborasi Inovatif Membangun Rumah Subsidi
                                    Hijau Berkelanjutan di Indonesia</div>
                            </div>
                        </div> */}
                    </div>
                </section>
                <section className="wrapper-page-news">
                    <div className="page-news-list">
                        <div className="page-news-prev disabled">
                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.2071 5.29289C13.5976 5.68342 13.5976 6.31658 13.2071 6.70711L9.91421 10L13.2071 13.2929C13.5976 13.6834 13.5976 14.3166 13.2071 14.7071C12.8166 15.0976 12.1834 15.0976 11.7929 14.7071L7.79289 10.7071C7.40237 10.3166 7.40237 9.68342 7.79289 9.29289L11.7929 5.29289C12.1834 4.90237 12.8166 4.90237 13.2071 5.29289Z" fill="#E0E0E0" />
                            </svg>
                        </div>
                        <div className="page-news-item active">01</div>
                        <div className="page-news-item">02</div>
                        <div className="page-news-item">03</div>
                        <div className="page-news-next">
                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#5BB24A" />
                            </svg>
                        </div>
                    </div>
                </section>
            </div>


        </>
    )
}

export default BlogDetail2
