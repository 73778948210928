import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';


const ModalLokasiPerumahanPerorangan = ({ onClose,  dataPemasaran }) => {

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);



    const handleClose = () => {
        onClose();
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [isTokenExpired, setIsTokenExpired] = useState(false);


    let refreshToken = null;

    const navigate = useNavigate();

    const { user, setUser } = useContext(AuthContext);


    useEffect(() => {

        // console.log('data modal pribadi....')
        // console.log(dataMinat);


        (async () => {

            // await fetchOptionsFromApiPerumahan()
            //     .then(data => setOptionsPerumahan(data))
            //     .catch(error => console.error('Error fetching options:', error));
            // setSelectedOptionPerumahan(perumahanId);

            // const response = await axios.get(`${apiUrl}/api/public/getTipeRumahById2/${tipeRumahId}`);
            //             setHargaRumah(response.data.harga);

        })();

      
    }, []);



    return (
        <>


            <div className="modal fade" id="ModalLokasiPerumahan" tabIndex={-1} role="dialog"
                aria-labelledby="ModalLokasiPerumahanLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Update Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>



                        <div className="modal-body">
                            <div className="form-group">
                                <h6 className="m-0">Lokasi Perumahan </h6>
                                <p className="text-muted mb-0"><small>Rekam data lokasi perumahan</small></p>
                            </div>




                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalLokasiPerumahanPerorangan
