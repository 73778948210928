import React from 'react'
import NavBarAtas from './NavBarAtas';

const BlogDetail = () => {

    require("./../../css/common.css");


    return (
        <>
            <NavBarAtas page="2" showButton={true} />


            <div>
                <section className="wrapper-detail-news-post">
                    <div className="wrapper-detail-news-post-content">
                        <img src="images/blog/image_berita_terbaru.png" id="image-detail-news" />
                        <div className="detail-news-post-content">
                            <div className="one-green-h2-semi-bold black-text">Keunggulan Rumah Subsidi Hijau dalam Mendukung Lingkungan
                                dan Kesejahteraan</div>
                            <div className="detail-news-content one-green-m-regular neutral-90-text">
                                <p>Rumah subsidi hijau adalah konsep hunian yang dirancang untuk mengurangi dampak negatif terhadap
                                    lingkungan. Rumah subsidi hijau merupakan solusi bagi berbagai tantangan lingkungan dan
                                    kesejahteraan manusia. Berikut beberapa keunggulan rumah subsidi hijau yang membuatnya menjadi
                                    pilihan yang cerdas untuk masa depan.</p>
                                <p><b>Efisiensi Energi</b></p>
                                <p>Rumah subsidi hijau dirancang dengan fokus pada efisiensi energi. Pemanfaatan teknologi hemat
                                    energi, seperti pemasangan panel surya dan sistem penerangan sensor tenaga surya, dapat
                                    mengurangi konsumsi energi secara signifikan. Ini tidak hanya mengurangi tagihan listrik, tetapi
                                    juga membantu mengurangi emisi gas rumah kaca.</p>
                                <p><b>Penggunaan Bahan Ramah Lingkungan</b></p>
                                <p>Pembangunan rumah subsidi hijau menggunakan bahan bangunan yang ramah lingkungan, seperti batu
                                    bata ramah lingkungan dan bahan daur ulang lainnya. Penggunaan bahan-bahan ini membantu
                                    mengurangi dampak deforestasi dan pemrosesan bahan-bahan yang merugikan lingkungan.</p>
                                <p><b>Kualitas Udara Dalam Ruangan yang Baik</b></p>
                                <p>Desain rumah subsidi hijau sering kali memasukkan sistem ventilasi yang canggih untuk memastikan
                                    sirkulasi udara yang baik. Penggunaan bahan bangunan yang rendah emisi juga membantu mengurangi
                                    polusi udara dalam ruangan, menciptakan lingkungan yang lebih sehat bagi penghuninya.</p>
                                <p><b>Manajemen Air yang Efisien</b></p>
                                <p>Rumah subsidi hijau dilengkapi dengan teknologi pengelolaan air yang efisien, seperti pengumpulan
                                    air hujan dan sistem irigasi yang pintar. Hal ini membantu mengurangi konsumsi air yang tidak
                                    perlu
                                    dan mendukung keberlanjutan sumber daya air.</p>
                                <p>Rumah hijau bukan hanya sekadar tempat tinggal, tetapi juga perwujudan komitmen terhadap
                                    lingkungan
                                    dan kesejahteraan manusia. Dengan keunggulan-keunggulan yang dimilikinya, rumah subsidi hijau
                                    tidak
                                    hanya menguntungkan bagi penghuninya tetapi juga untuk generasi mendatang.</p>
                            </div>
                        </div>
                        <div className="divider-neutral-30" />
                        <div className="wrapper-detail-news-tags">
                            <div className="one-green-subheading-medium black-text">Tags</div>
                            <div className="wrapper-list-detail-news-tags">
                                <div className="list-tags-item">#SubsidiMurah</div>
                                <div className="list-tags-item">#RumahSubsidiHijau</div>
                            </div>
                            <div className="wrapper-share-news-social-media">
                                <img src="images/blog/ic_instagram.svg" width={24} height={24} />
                                <img src="images/blog/ic_twitter.svg" width={24} height={24} />
                            </div>
                        </div>
                        <div className="divider-neutral-30" />
                    </div>
                    <div className="wrapper-detail-news-sidebar">
                        <div className="wrapper-search-detail-news">
                            <input type="text" placeholder="Search" className="one-green-l-regular black-text" />
                            <img src="images/blog/ic_search_grey.svg" id="ic_search_detail_news" />
                        </div>
                        <div className="wrapper-category-detail-news">
                            <div className="one-green-subheading-medium neutral-90-text">Kategori</div>
                            <div className="wrapper-list-category-detail-news">
                                <div className="one-green-l-regular neutral-80-text">IGAHP</div>
                                <div className="divider-neutral-30" />
                                <div className="one-green-l-regular neutral-80-text">Bangunan Gedung Hijau</div>
                                <div className="divider-neutral-30" />
                                <div className="one-green-l-regular neutral-80-text">Rumah Hijau</div>
                                <div className="divider-neutral-30" />
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper-berita-detail-news">
                    <div className="one-green-h3-semi-bold black-text">Berita</div>
                    <div className="wrapper-news-content">
                        <a href="/blogdetail2">
                            <div className="news-content-item">
                                <div className="wrapper-image-news">
                                    <img src="images/blog/image_berita_1.png" id="image-list-news" />
                                    <div className="wrapper-category-text-news">
                                        <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                    </div>
                                </div>
                                <div className="wrapper-content-news">
                                    <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                    <div className="one-green-subheading-semi-bold neutral-90">IGAHP EXPO: Potensi Rumah Subsidi Hijau di
                                        Indonesia</div>
                                </div>
                            </div>
                        </a>
                        {/* <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_2.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_3.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            <div className="wrapper-content-news">
                                <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                <div className="one-green-subheading-semi-bold neutral-90">Kolaborasi Inovatif Membangun Rumah Subsidi
                                    Hijau Berkelanjutan di Indonesia</div>
                            </div>
                        </div> */}
                    </div>
                </section>
                {/* <section className="wrapper-page-news">
                    <div className="page-news-list">
                        <div className="page-news-prev disabled">
                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.2071 5.29289C13.5976 5.68342 13.5976 6.31658 13.2071 6.70711L9.91421 10L13.2071 13.2929C13.5976 13.6834 13.5976 14.3166 13.2071 14.7071C12.8166 15.0976 12.1834 15.0976 11.7929 14.7071L7.79289 10.7071C7.40237 10.3166 7.40237 9.68342 7.79289 9.29289L11.7929 5.29289C12.1834 4.90237 12.8166 4.90237 13.2071 5.29289Z" fill="#E0E0E0" />
                            </svg>
                        </div>
                        <div className="page-news-item active">01</div>
                        <div className="page-news-item">02</div>
                        <div className="page-news-item">03</div>
                        <div className="page-news-next">
                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#5BB24A" />
                            </svg>
                        </div>
                    </div>
                </section> */}
            </div>


        </>
    )
}

export default BlogDetail
