import React from 'react'

import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../../AuthContext';
import Footer from '../../Footer';

const ManajemenUsersTambah = () => {

    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        const url = apiUrl +'/api/auth/signup'; // Replace with your API endpoint
        const requestBody = {
            username: formData.username,
            password: formData.password,
            email: formData.email,
            role: [user.rolename.toLowerCase()
                .replace(/^role_/, "")]
        };

        try {
            const apiResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody)
            });

            const data = await apiResponse.json();
            console.log(data);

            alert(JSON.stringify(data));
            // setResponse(JSON.stringify(data, null, 2));
        } catch (error) {
            console.error('Error:', error);
        }
    }
    
    return (
        <>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manajemen Users - {user.rolename} ({user.roleid})</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Manajemen Users</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">

                        {(user.roleid == '1' || user.roleid == '9' || user.roleid == '10') ? (
                            <div className="row">
                                {/* left column */}
                                <div className="col-md-12">
                                    {/* jquery validation */}
                                    <div className="card card-primary">
                                        <div className="card-header">
                                            <h3 className="card-title">Form Input</h3>
                                        </div>
                                        {/* /.card-header */}
                                        {/* form start */}
                                        <form onSubmit={handleSubmit}>
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <label htmlFor="exampleInput">Username</label>
                                                    {/* <input type="text" name="username" className="form-control"  placeholder="username" /> */}
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="username"
                                                        value={formData.username}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInput">Email address</label>
                                                    {/* <input type="email" name="email" className="form-control"  placeholder="Enter email" /> */}
                                                    <input
                                                        className="form-control"
                                                        type="email"
                                                        name="email"
                                                        value={formData.email}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="exampleInput">Password</label>
                                                    {/* <input type="password" name="password" className="form-control"  placeholder="Password" /> */}
                                                    <input
                                                        className="form-control"
                                                        type="password"
                                                        name="password"
                                                        value={formData.password}
                                                        onChange={handleChange}
                                                    />
                                                </div>

                                                {/* <div className="form-group mb-0">
                                                    <div className="custom-control custom-checkbox">
                                                        <input type="checkbox" name="terms" className="custom-control-input" id="exampleCheck1" />
                                                        <label className="custom-control-label" htmlFor="exampleCheck1">I agree to the <a href="#">terms of service</a>.</label>
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* /.card-body */}
                                            <div className="card-footer">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    {/* /.card */}
                                </div>
                                {/*/.col (left) */}
                                {/* right column */}
                                <div className="col-md-6">
                                </div>
                                {/*/.col (right) */}
                            </div>

                        ) : (
                            <p></p>
                        )}
                        
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            <Footer/>
        </>
    )
}

export default ManajemenUsersTambah
