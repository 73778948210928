import React from 'react'
import TaBghDashboard from './TaBghDashboard'

const TaBghContentDepan = () => {
  return (
    <>
        <TaBghDashboard/>
    </>
  )
}

export default TaBghContentDepan
