import React from 'react'
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import Footer from '../../Footer'
import { useState, useContext, useEffect } from 'react'
import axios from 'axios'
import jwt_decode from 'jwt-decode'
import { AuthContext } from '../../../AuthContext'
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { Link, useNavigate, useParams } from 'react-router-dom';

import InputMask from 'react-input-mask';

import Swal from 'sweetalert2';

const PengembangLokasiTipeRumahTambah = () => {

  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  const { user, setUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_ENDPOINT;


  // const [fotoTampakImg, setFotoTampakImg] = useState(`${apiUrl}/dist/img/no-photo-choose.png`);


  const [fotoTampakDepanImg, setFotoTampakDepanImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');
  const [fotoTampakBelakangImg, setFotoTampakBelakangImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');

  const [fotoDenahImg, setFotoDenahImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');

  // const [fotoOrientasiImg, setFotoOrientasiImg] = useState(`${apiUrl}/dist/img/no-photo-choose.png`);
  const [fotoPotonganImg, setFotoPotonganImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');

  const [jenisPerumahan, setJenisPerumahan] = useState('0');
  const [statusUnit, setStatusUnit] = useState(null);
  const [namaTipe, setNamaTipe] = useState(null);
  const [harga, setHarga] = useState(null);
  const [luasLahan, setLuasLahan] = useState(null);
  const [luasBangunan, setLuasBangunan] = useState(null);
  const [jumlahLantai, setJumlahLantai] = useState(null);
  const [jumlahKamarTidur, setJumlahKamarTidur] = useState(null);
  const [jumlahKamarMandi, setJumlahKamarMandi] = useState(null);
  const [spesifikasiAtap, setSpesifikasiAtap] = useState(null);
  const [spesifikasiDinding, setSpesifikasiDinding] = useState(null);
  const [spesifikasiLantai, setSpesifikasiLantai] = useState(null);

  const [isButtonProcess, setIsButtonProcess] = useState(false);
  const [spesifikasiPondasi, setSpesifikasiPondasi] = useState(null);


  // const [fotoTampak, setFotoTampak] = useState(null);
  const [fotoTampakDepan, setFotoTampakDepan] = useState(null);
  const [fotoTampakBelakang, setFotoTampakBelakang] = useState(null);
  const [fotoDenah, setFotoDenah] = useState(null);

  const [fotoOrientasi, setFotoOrientasi] = useState(null);
  const [fotoPotongan, setFotoPotongan] = useState(null);

  const [isTokenExpired, setIsTokenExpired] = useState(false);


  const [dokRks, setDokRks] = useState(null);
  const [dokBoq, setDokBoq] = useState(null);

  let refreshToken = null;
  const navigate = useNavigate()

  const handleJenisPerumahan = (event) => {
    setJenisPerumahan(event.target.value);
    //  alert(jenisPerumahan);5
  };



  // Fungsi untuk memformat angka menjadi format tertentu
  const formatNumber = (number) => {
    return new Intl.NumberFormat('id-ID').format(number);
  };


  const handleChange = (e) => {
    const { value } = e.target;

    // Menghapus karakter non-angka dari input
    const formattedValue = value.replace(/\D/g, '');

    setHarga(formattedValue);
  };
  

  useEffect(() => {

    console.log('-------------------')
    
    // console.log(user);
    if (user.tiperumah_state == 'edit') {
      console.log('masuk edit')
      fetchData();
    } else {
      console.log('masuk Add')
      clearForm();
      console.log('masuk tambah data')
    }
  }, []);


  const clearForm = async (e) => {

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    setJenisPerumahan(null)
    setNamaTipe(null)
    setHarga(null)
    setLuasLahan(null)
    setLuasBangunan(null)
    setJumlahLantai(null);
    setJumlahKamarTidur(null)
    setJumlahKamarMandi(null);
    setSpesifikasiAtap(null)
    setSpesifikasiDinding(null);
    setSpesifikasiLantai(null);
    setSpesifikasiPondasi(null);
    // setFotoTampakImg(`${apiUrl}/dist/img/no-photo-choose.png`);


  }

  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }
    try {
      console.log(user.tiperumah_id)
      const response = await axios.get(apiUrl+'/api/public/getTipeRumahById/' + user.tiperumah_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      console.log('info tipe rumah for edit ')
      console.log(response.data)
      // console.log(response.data.nama)
      
      setJenisPerumahan(response.data.status)
      setNamaTipe(response.data.nama)
      setHarga(response.data.harga)
      setLuasLahan(response.data.luasTanah)
      setLuasBangunan(response.data.luasBangunan)
      setJumlahLantai(response.data.jumlahLantai);
      setJumlahKamarTidur(response.data.kamarTidur)
      setJumlahKamarMandi(response.data.kamarMandi);
      setSpesifikasiAtap(response.data.spesifikasiAtap)
      setSpesifikasiDinding(response.data.spesifikasiDinding);
      setSpesifikasiLantai(response.data.spesifikasiLantai);
      setSpesifikasiPondasi(response.data.spesifikasiPondasi);
      
      // setFotoTampakImg(apiUrl+'/api/public/displayimage/' + response.data.fotoTampak);
      setFotoTampakDepanImg(apiUrl + '/api/public/displayimage/' + response.data.fotoTampakDepan);
      setFotoTampakBelakangImg(apiUrl + '/api/public/displayimage/' + response.data.fotoTampakBelakang);


      setFotoDenahImg(apiUrl + '/api/public/displayimage/' + response.data.fotoDenah);

      // setFotoOrientasiImg(apiUrl + '/api/public/displayimage/' + response.data.fotoOrientasiBangunan);
      setFotoPotonganImg(apiUrl + '/api/public/displayimage/' + response.data.fotoPotonganBangunan);

    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };


  const handleSubmit = async (e) => {
    setIsButtonProcess(true);

    e.preventDefault();

    // alert('item di add');
    var isTambahData = true;

    
    if (user.tiperumah_state == 'edit') {
      isTambahData = false;
    } else {
      isTambahData = true;
    }

    //----------------- mulai handel form ---------------------------------------------------------------------------------------
    const formData = new FormData();
    formData.append('id', user['tiperumah_id']);
    // formData.append('id', user.perumahan_id);
    formData.append('is_tambah_data', isTambahData);
    formData.append('pengembang_id', user.pengembang_id);
    formData.append('perumahan_id', user.perumahan_id);

    formData.append("jenisPerumahan", jenisPerumahan);
    formData.append("namaTipe", namaTipe);
    formData.append("harga", harga);
    formData.append("luasLahan", luasLahan);
    formData.append("luasBangunan", luasBangunan);
    formData.append("jumlahLantai", jumlahLantai);
    formData.append("jumlahKamarTidur", jumlahKamarTidur);
    formData.append("jumlahKamarMandi", jumlahKamarMandi);
    formData.append("spesifikasiAtap", spesifikasiAtap);

    formData.append("spesifikasiDinding", spesifikasiDinding);
    formData.append("spesifikasiLantai", spesifikasiLantai);
    formData.append("spesifikasiPondasi", spesifikasiPondasi);
    
    // formData.append("fotoTampak", fotoTampak);

    formData.append("fotoTampakDepan", fotoTampakDepan);
    formData.append("fotoTampakBelakang", fotoTampakBelakang);
    formData.append("fotoDenah", fotoDenah);
    formData.append("fotoPotongan", fotoPotongan);
    // formData.append("fotoOrientasi", fotoOrientasi);

    formData.append('dokRks', dokRks);
    formData.append('dokBoq', dokBoq);

    for (var key of formData.entries()) {
      // console.log(key[0] + ', ' + key[1]);
    }


    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }


    try {
      await fetch(apiUrl+'/api/igahp/simpanTipeRumah', {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          // console.log(data);

          // alert(data.status);

          Toast.fire({
            icon: 'success',
            title: data.status
          });

          setIsButtonProcess(false);

          navigate('/igahp/pengembang/PengembangLokasiTipeRumah');
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle error
          setIsButtonProcess(false);
          console.error(error);
          // alert(error);

          Toast.fire({
            icon: 'error',
            title: error
          });

        });

    } catch (error) {
      setIsButtonProcess(false);
      console.error('An error occurred:', error);
    }

  };


  // const handleFotoTampak = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //      setFotoTampakImg(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  //   setFotoTampak(event.target.files[0]);
    
  //   if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
  //     alert('Please select a valid image file.');
  //     return;
  //   }
  // }

  const handleFotoTampakDepan = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setFotoTampakDepanImg(reader.result);
    };
    reader.readAsDataURL(file);
    setFotoTampakDepan(event.target.files[0]);
    // Check if the file is an image
    if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }
  }


  const handleFotoTampakBelakang = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setFotoTampakBelakangImg(reader.result);
    };
    reader.readAsDataURL(file);
    setFotoTampakBelakang(event.target.files[0]);
    // Check if the file is an image
    if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }
  }


  const handleFotoDenah = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setFotoDenahImg(reader.result);
    };
    reader.readAsDataURL(file);
    setFotoDenah(event.target.files[0]);
    // Check if the file is an image
    if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }
  }

  // const handleFotoOrientasi = (event) => {
  //   const file = event.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setFotoOrientasiImg(reader.result);
  //   };
  //   reader.readAsDataURL(file);
  //   setFotoOrientasi(event.target.files[0]);
   
  //   if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
  //     alert('Please select a valid image file.');
  //     return;
  //   }
  // }

  const handleFotoPotongan = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      setFotoPotonganImg(reader.result);  
    };
    reader.readAsDataURL(file);
    setFotoPotongan(event.target.files[0]);
    // Check if the file is an image
    if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
      alert('Please select a valid image file.');
      return;
    }
  }

  return (
    <>
      {/* Main Sidebar Container */}
      {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
        <aside className="main-sidebar sidebar-light-success elevation-4">
  
        <MenuPerumahanDetail />
      </aside>

      {/* Content Wrapper. Contains page content */}
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-4 mt-2">
              <div className="col-sm-6">
                <h1 className="text-muted fw-light">ISI DATA<b> TIPE</b></h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><a href="#">Home</a></li>
                  <li className="breadcrumb-item active">Tipe Rumah</li>
                </ol>
              </div>
            </div>
          </div>{/* /.container-fluid */}
        </section>


        {/* Main content */}
        <section className="content">
          <div className="container-fluid">

            <form onSubmit={handleSubmit}>
              <div className="row">

                <div className="col-md-6">
                  <div className="card card-primary">
                    <div className="card-header">
                      <h3 className="card-title">Pengisian Data</h3>
                    </div>
                    <div className="card-body">

                      <div className="form-group">
                        <label>Jenis Perumahan</label>
                        {/* <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" /> */}

                        <select value={jenisPerumahan} onChange={handleJenisPerumahan} className="form-control" >
                          <option value="subsidi">SUBSIDI HIJAU</option>
                          <option value="komersil">KOMERSIL HIJAU</option>
                        </select>
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Nama Tipe</label>
                        {/* <input type="email" className="form-control" placeholder="" /> */}
                        <input
                          type="text"
                          className="form-control"
                          name="namaTipe"
                          value={namaTipe}
                          onChange={(e) => setNamaTipe(e.target.value)}
                          required
                        />
                      </div>

                      {/* <div className="form-group">
                        <label htmlFor="exampleInput">Harga</label>                        
                        <input
                          type="text"
                          className="form-control"
                          name="harga"
                          value={harga}
                          onChange={(e) => setHarga(e.target.value)}
                        />
                      </div> */}

                      <div className="form-group">
                        <label htmlFor="exampleInput">Harga</label>
                        <input
                          type="text"
                          className="form-control"
                          name="harga"
                          value={formatNumber(harga)} // Menampilkan nilai harga dengan format angka
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Luas Lahan (m2)</label>
                        {/* <input type="text" className="form-control" id="" /> */}
                        <input
                          type="text"
                          className="form-control"
                          name="luasLahan"
                          value={luasLahan}
                          onChange={(e) => setLuasLahan(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Luas Bangunan (m2) </label>
                        {/* <input type="text" className="form-control" /> */}
                        <input
                          type="text"
                          className="form-control"
                          name="luasBangunan"
                          value={luasBangunan}
                          onChange={(e) => setLuasBangunan(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Jumlah Lantai</label>
                        {/* <input type="text" className="form-control" /> */}
                        <input
                          type="text"
                          className="form-control"
                          name="jumlahLantai"
                          value={jumlahLantai}
                          onChange={(e) => setJumlahLantai(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Jumlah Kamar Tidur</label>
                        {/* <input type="text" className="form-control" /> */}
                        <input
                          type="text"
                          className="form-control"
                          name="jumlahKamarTidur"
                          value={jumlahKamarTidur}
                          onChange={(e) => setJumlahKamarTidur(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Jumlah Kamar Mandi</label>
                        {/* <input type="text" className="form-control" /> */}
                        <input
                          type="text"
                          className="form-control"
                          name="jumlahKamarMandi"
                          value={jumlahKamarMandi}
                          onChange={(e) => setJumlahKamarMandi(e.target.value)}
                          required
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Spesifikasi Teknis Atap</label>
                        {/* <textarea class="form-control" rows="3" placeholder="Enter ..."></textarea> */}
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Enter ..."
                          name="spesifikasiAtap"
                          value={spesifikasiAtap}
                          onChange={(e) => setSpesifikasiAtap(e.target.value)}
                          required
                        ></textarea>
                      </div>



                      <div className="form-group">
                        <label htmlFor="exampleInput">Spesifikasi Teknis Dinding</label>
                        {/* <textarea class="form-control" rows="3" placeholder="Enter ..."></textarea> */}
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Enter ..."
                          name="spesifikasiDinding"
                          value={spesifikasiDinding}
                          onChange={(e) => setSpesifikasiDinding(e.target.value)}
                          required
                        ></textarea>
                      </div>


                      <div className="form-group">
                        <label htmlFor="exampleInput">Spesifikasi Teknis Lantai</label>
                        {/* <textarea class="form-control" rows="3" placeholder="Enter ..."></textarea> */}
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Enter ..."
                          name="spesifikasiLantai"
                          value={spesifikasiLantai}
                          onChange={(e) => setSpesifikasiLantai(e.target.value)}
                          required
                        ></textarea>
                      </div>

                      <div className="form-group">
                        <label htmlFor="exampleInput">Spesifikasi Teknis Pondasi</label>
                        {/* <textarea class="form-control" rows="3" placeholder="Enter ..."></textarea> */}
                        <textarea
                          className="form-control"
                          rows="3"
                          placeholder="Enter ..."
                          name="spesifikasiPondasi"
                          value={spesifikasiPondasi}
                          onChange={(e) => setSpesifikasiPondasi(e.target.value)}
                          required
                        ></textarea>
                      </div>


                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  {/* general form elements disabled */}
                  <div className="card card-secondary">
                    <div className="card-header">
                      <h3 className="card-title">Dokumen</h3>
                    </div>
                    {/* /.card-header */}
                    <div className="card-body">

                      <div className="row">
                      
                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputFile">Rencana Kerja dan Syarat (RKS)</label>
                            <div className="mb-3">
                              <input
                                className="form-control form-control"                                
                                type="file"
                                accept=".pdf"                              
                                onChange={(e) => setDokRks(e.target.files[0])}
                              />
                              <small>Format Dokumen berbentuk .pdf
                              </small>
                            </div>
                          </div>         
                        </div>


                        <div className="col-sm-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputFile">BOQ Rumah</label>
                            <div className="mb-3">
                              <input
                                className="form-control form-control"                                
                                type="file"
                                accept=".pdf"                              
                                onChange={(e) => setDokBoq(e.target.files[0])}
                              />
                              <small>Format Dokumen berbentuk .pdf
                              </small>
                            </div>
                          </div>
                        </div>


                      
                      </div>
                      
                      
                      <div className="row">                     


                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="card card-foto-denah ">
                              <div className="card-body text-center " >
                                <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                  <img id="uploaded-image-tengah"
                                    src={fotoDenahImg}
                                    className="label-preview-image b-1 svelte-zttspj"
                                    alt="preview foto denah" width={350} height={310}
                                  />
                                </label>

                                <input
                                  required
                                  data-pristine-required-message="Foto Denah" className="svelte-zttspj"
                                  type="file"
                                  accept="image/jpeg"
                                  onChange={handleFotoDenah}
                                />
                                
                              </div>
                              <h5 className="card-body text-center">Ilustrasi Denah Bangunan   </h5>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="card card-foto-potongan ">
                              <div className="card-body text-center " >
                                <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                  <img id="uploaded-image-potongan"
                                    src={fotoPotonganImg}
                                    className="label-preview-image b-1 svelte-zttspj"
                                    alt="preview foto potongan" width={350} height={310}
                                  />
                                </label>
                                <input
                                  required
                                  data-pristine-required-message="Foto potongan" className="svelte-zttspj"
                                  type="file"
                                  accept="image/jpeg"
                                  onChange={handleFotoPotongan}
                                />
                              </div>
                              <h5 className="card-body text-center">Ilustrasi Potongan Bangunan   </h5>
                            </div>
                          </div>
                        </div>


                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="card card-foto-tampak ">
                              <div className="card-body text-center " >
                                <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                  <img id="uploaded-image-tengah"
                                    src={fotoTampakDepanImg}
                                    className="label-preview-image b-1 svelte-zttspj"
                                    alt="preview foto tampak depan" width={350} height={310}
                                  />
                                </label>
                                <input
                                  required
                                  data-pristine-required-message="Foto Tampak Depan" className="svelte-zttspj"
                                  type="file"
                                  accept="image/jpeg"
                                  onChange={handleFotoTampakDepan}
                                />
                              </div>
                              <h5 className="card-body text-center">Foto Tampak Depan Bangunan</h5>
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-6">
                          <div className="form-group">
                            <div className="card card-foto-belakang">
                              <div className="card-body text-center " >
                                <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                  <img id="uploaded-image-tengah"
                                    src={fotoTampakBelakangImg}
                                    className="label-preview-image b-1 svelte-zttspj"
                                    alt="preview foto tampak belakang" width={350} height={310}
                                  />
                                </label>
                                <input
                                  required
                                  data-pristine-required-message="Foto Tampak Belakang" className="svelte-zttspj"
                                  type="file"
                                  accept="image/jpeg"
                                  onChange={handleFotoTampakBelakang}
                                />
                              </div>
                              <h5 className="card-body text-center">Foto Tampak Belakang Bangunan</h5>
                            </div>
                          </div>
                        </div>


                        {/* <div className="col-sm-6">                        
                          <div className="form-group">
                            <div className="card card-foto-orientasi ">
                              <div className="card-body text-center " >
                                <label htmlFor="input-foto-s94ae5c268" className="w-100">
                                  <img id="uploaded-image-tengah"
                                    src={fotoOrientasiImg}
                                    className="label-preview-image b-1 svelte-zttspj"
                                    alt="preview foto orientasi" width={350} height={310}
                                  />
                                </label>

                                <input
                                  required
                                  data-pristine-required-message="Foto orientasi" className="svelte-zttspj"
                                  type="file"
                                  accept="image/jpeg"
                                  onChange={handleFotoOrientasi}
                                />
                              </div>
                              <h5 className="card-body text-center">Ilustrasi/Foto Orientasi Bangunan</h5>
                            </div>
                          </div>
                        </div> */}

                    
                      </div>


                      <div class="mt-4">                       
                        <button className="btn btn-primary" disabled={isButtonProcess}>Simpan Tipe Rumah</button>                       
                      </div>

                    </div>
                    {/* /.card-body */}

                  </div>
                  {/* /.card */}

                </div>

              </div>
            </form>

          </div>{/* /.container-fluid */}
        </section>
        {/* /.content */}


      </div>
      {/* /.content-wrapper */}

      {/* <Footer /> */}



    </>
  )
}

export default PengembangLokasiTipeRumahTambah
