import React from 'react'
import PemdaDashboard from './PemdaDashboard'

const PemdaContentDepan = () => {
  return (
    <>
      <PemdaDashboard />
    </>
  )
}

export default PemdaContentDepan
