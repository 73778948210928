import React from 'react'
import { useEffect } from 'react';
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    // Link,
    Outlet,
} from "react-router-dom";

import { useState } from 'react';
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import PictureProfile from '../PictureProfile';

const SidebarPengembangPerorangan = () => {


    const [isTokenExpired, setIsTokenExpired] = useState(false);

    const navigate = useNavigate()

    const { user, setUser } = useContext(AuthContext);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [data, setData] = useState({});

    let refreshToken = null;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/aktifmenu.js";
        script.async = true;
        document.body.appendChild(script);

        // fetchData();

        return () => { script.remove(); }
    }, []);


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            // console.log('user id ' + user.id);
            // console.log('pengembang id ' + user.pengembang_id);
            var pengembangId = '';
            const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + user.pengembang_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            // console.log(response.data);
            setData(response.data);
            // setNamaDirektur(response.data.namaDirektur);


            // setIsLoading(false);
            // setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    return (
        <>

            <div>

                {/* Main Sidebar Container */}
                <aside className="main-sidebar sidebar-light-success elevation-4">
                    {/* Brand Logo */}
                    <a href="/igahp" className="brand-link">
                    <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                    <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA</b></h4>
                </a>


                    {/* Sidebar */}
                    <div className="sidebar">

                        <div className="user-panel mt-3 pb-3 mb-3 d-flex flex-wrap">
                            <div className="image image-profile-container mt-2">
                                {/* <img src="/dist/img/user1-128x128.jpg" className="img-circle elevation-2" alt="image" /> */}
                                <PictureProfile />

                            </div>
                            <div className="info info-box-content ml-2">
                                <div>
                                    <small className="text-muted">Admin</small><br />{data.pic}
                                </div>

                            </div>
                        </div>
                        {/* <div className="card card-img">
                            <img src="/dist/img/logo_pengembang/pengembang_001.jpg" alt="image" className="img-thumbnail" />
                        </div> */}

                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">


                                {/* <li className="nav-item">
                                    <Link to="perorangan/dashboard" className="nav-link">
                                        <i className="nav-icon fas fa-tachometer-alt"></i>
                                        <p>Dashboard</p>
                                    </Link>
                                </li> */}


                                <li className="nav-item">
                                    <Link to="perorangan/PenghuniProfile" className="nav-link">
                                        <i className="nav-icon fas fa-user "></i>
                                        <p>Info Penghuni</p>
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-house-user"></i>
                                        <p>
                                            Data Rumah
                                            <i className="fas fa-angle-left right"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <Link to="perorangan/lokasiperorangan" className="nav-link">
                                                <i className="nav-icon far fa-circle text-warning"></i>
                                                <p>Tambah Rumah</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="perorangan/listlokasiperorangan" className="nav-link">
                                                <i className="nav-icon far fa-circle text-warning"></i>
                                                <p>Info Perumahan</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>


                                <li class="nav-item">
                                    <a href="#" class="nav-link">
                                        <i class="nav-icon fas fa-paste"></i>
                                        <p>
                                            Sertifikasi
                                            <i class="fas fa-angle-left right"></i>
                                        </p>
                                    </a>
                                    <ul class="nav nav-treeview">


                                        <li className="nav-item">
                                            <Link to="perorangan/pengajuanbgh" className="nav-link">
                                                <i className="nav-icon far fa-circle text-warning"></i>
                                                <p>Pengajuan</p>
                                            </Link>
                                        </li>

                                        <li className="nav-item">
                                            <Link to="perorangan/penugasanbgh" className="nav-link">
                                                <i className="nav-icon far fa-circle text-warning"></i>
                                                <p>Penugasan TA</p>
                                            </Link>
                                        </li>
                                    </ul>
                                </li>

                                <li className="nav-item">
                                    <Link to="perorangan/profiledokumen" className="nav-link">
                                        <i className="nav-icon fas fa-home"></i>
                                        <p>Data Pemohon</p>
                                    </Link>
                                </li>

                                {/* <li className="nav-item has-treeview">
                                    <a href="data_pengajuan_rumah_perorangan.html" className="nav-link">
                                        <i className="nav-icon fas fa-home" />
                                        <p>
                                            Data Pengajuan
                                            <i className="right fas fa-angle-left" />
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="data_pengajuan_rumah_perorangan.html" className="nav-link">
                                                <i className="far fa-circle text-warning nav-icon" />
                                                <p>Data Rumah</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="data_pengajuan_status_perorangan.html" className="nav-link">
                                                <i className="far fa-circle text-warning nav-icon" />
                                                <p>Status Sertifikasi</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="data_pengajuan_pribadi_perorangan.html" className="nav-link">
                                                <i className="far fa-circle text-warning nav-icon" />
                                                <p>Data Pribadi</p>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <a href="data_pengajuan_dokumen_perorangan.html" className="nav-link">
                                                <i className="far fa-circle text-warning nav-icon" />
                                                <p>Dokumen</p>
                                            </a>
                                        </li>
                                    </ul>
                                </li> */}



                                <li className="nav-item">
                                    <a href="#" className="nav-link">
                                        <i className="nav-icon fas fa-landmark"></i>
                                        <p>
                                            Bank
                                            <i className="fas fa-angle-left right"></i>
                                        </p>
                                    </a>
                                    <ul className="nav nav-treeview">


                                        <li className="nav-item">
                                            <Link to="pengembang/PengembangBank" className="nav-link">
                                                <i className="nav-icon far fa-circle text-warning"></i>
                                                <p>Data Bank</p>
                                            </Link>
                                        </li>


                                    </ul>
                                </li>

                                {/* <li className="nav-item">
                                    <Link to="pengembang/PengembangAdminListLokasi2" className="nav-link">
                                        <i className="nav-icon fas fa-laptop-house"></i>
                                        <p>Info Perumahan</p>
                                    </Link>
                                </li> */}

                                {/* <li className="nav-item">
                                    <Link to="pengembang/dataMinat" className="nav-link">
                                        <i className="nav-icon fas fa-user"></i>
                                        <p>Data Minat Unit</p>
                                        <span className='badge badge-warning right'>{data.totalMinat}</span>
                                    </Link>
                                </li> */}

                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                    {/* /.sidebar */}
                </aside>
            </div>


        </>
    )
}

export default SidebarPengembangPerorangan
