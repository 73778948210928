import React, { useState } from 'react'
import { createPortal } from 'react-dom'

const CustomIframe = ({
    children,
    ...props
}) => {
    const [contentRef, setContentRef] = useState(null)

    const mountNode =
        contentRef?.contentWindow?.document?.body

    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe {...props} ref={setContentRef}>
            {mountNode && createPortal(children, mountNode)}
        </iframe>
    )
}

export default CustomIframe;