import React from 'react'
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';
import ModalDataRumah from './ModalDataRumah';
import ModalPeminatBanks from '../childs/ModalPeminatBanks';
import ModalDataPribadi from './ModalDataPribadi';

const MinatInfo = () => {

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;
  const [isLoading, setIsLoading] = useState(true);
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const navigate = useNavigate();
  let refreshToken = null;
  const { user, setUser } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [itemData, setItemData] = useState(null);
  const [dataBank, setDataBank] = useState(null);
  const [isModal, setIsModal] = useState(false);
  const [wilayah, setWilayah] = useState({
    namaProp: ''
  });
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const { loginContext } = useContext(AuthContext);

  const [isModalOpenDataRumah, setModalOpenDataRumah] = useState(false);

  const [isModalOpenDataPribadi, setModalOpenDataPribadi] = useState(false);

  const [userPhoto, setUserPhoto] = useState('/dist/img/user1-128x128.jpg');

  const handleModalOpenDataRumah = () => {
    console.log('Modal Open')
    setModalOpenDataRumah(true);
  };

  const handleModalOpenDataPribadi = () => {
    console.log('Modal Open')
    setModalOpenDataPribadi(true);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 10 * 1024 * 1024) { // 1MB size limit
        alert('File size exceeds 1MB');
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUserPhoto(reader.result);
        uploadImage(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const [isModalBankOpen, setModalBankOpen] = useState(false);

  const [isModalDataPribadiOpen, setModalDataPribadiOpen] = useState(false);

  const handleModalBankClose = () => {
    setModalBankOpen(false);
    fetchData();
  };


  const handleModalDataPribadiClose = () => {
    setModalDataPribadiOpen(false);
    fetchData();
  };

  const handleModalCloseDataRumah = () => {
    setModalOpenDataRumah(false);
    fetchData();
  };

  const handleModalCloseDataPribadi = () => {
    setModalOpenDataPribadi(false);
    fetchData();
  };


  const handleOpenBankClick = () => {
    setModalBankOpen(true);
  }

  const handleOpenDataPribadiClick = () => {
    setModalDataPribadiOpen(true);
  }


  const formatter = new Intl.NumberFormat('id-ID', {
    style: 'currency',
    currency: 'IDR',
    currencyDisplay: 'symbol',
    maximumFractionDigits: 0,
  });

  const [options, setOptions] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState('');

  const fileInputRef = useRef(null);


  const uploadImage = async (file) => {
    let decodedToken = jwt_decode(user.accessToken);
    let currentDate = new Date();

    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        const refreshToken = await refreshTokenRequest(paramRefreshToken);
        if (refreshToken.statusCode === 403) {
          console.error('Expired. Please sign in.');
          navigate('/login');
        }
        user.accessToken = refreshToken.accessToken;
        setIsTokenExpired(true);
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
    } else {
      console.log("Valid token");
    }

    const formData = new FormData();
    formData.append('id', user.minat_id);
    formData.append('photo', file);

    const sUrl = `${apiUrl}/api/igahp/uploadPhotoPeminat`;

    try {
      const response = await fetch(sUrl, {
        credentials: 'include',
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken,
        },
      });

      const data = await response.json();
      if (data) {
        console.log('Image uploaded successfully.');
        alert('Image uploaded successfully');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };


  useEffect(() => {


    // const script = document.createElement('script');
    // script.src = "/js/content.js";
    // script.async = true;
    // document.body.appendChild(script);

    if (isLoading || isTokenExpired) {
      // console.log('Use effetc load fetchdata calling.....');
      fetchData();


      fetchOptionsFromUser()
        .then(data => setOptions(data))
        .catch(error => console.error('Error fetching options:', error));

      // fetchOptionsFromApiPerumahan()
      //   .then(data => setOptionsPerumahan(data))
      //   .catch(error => console.error('Error fetching options:', error));


    }

    //setTokenJwt(user.accessToken);
    // return () => { script.remove(); }

  }, [isLoading, isTokenExpired]);



  const fetchOptionsFromApiPerumahan = async () => {
    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();'
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    // var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

    // if (user.roles.includes("ROLE_IGAHP_ADMIN")) {
    
    // } 

    var idPengembang = '0';

    // alert(user.pengembang_id)

    if (user.pengembang_id==null) {

      idPengembang = '0';

    } else {
      idPengembang = user.pengembang_id;
    }

     

    try {
      const response = await axios.get(apiUrl + '/api/igahp/lokasibypengembangid/' + idPengembang, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });

      return response.data;
    } catch (error) {
      // c/login');
    }
  };



  const fetchOptionsFromUser = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllUsers');
      const data = await response.json();
      console.log('data users ');
      console.log(data)

      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };


  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }

    // console.log('pengembang id ' + user.pengembang_id);

    try {
      const response = await axios.get(apiUrl + '/api/igahp/pengembang/getDataMinatById/' + user.minat_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);

      // console.log(response.data);
      const tampung = response.data[0];
      setData(tampung);
      // setNamaPengembang(tampung.namaPengembang)

      setFullName(tampung.namaLengkap)
      setPhoneNumber(tampung.handphone)
      setSelectedUserOption(tampung.userId)


      setDataStatus(prevDataStatus => ({
        ...prevDataStatus,
        sudahKonfirmasi: tampung.sudahKonfirmasi
      }));

      setDataStatus(prevDataStatus => ({
        ...prevDataStatus,
        sudahAjukanKpr: tampung.sudahAjukanKpr
      }));

      setDataStatus(prevDataStatus => ({
        ...prevDataStatus,
        sudahAkad: tampung.sudahAkad
      }));

      setDataStatus(prevDataStatus => ({
        ...prevDataStatus,
        sudahSerahTerima: tampung.sudahSerahTerima
      }));


      try {

        const response2 = await axios.get(apiUrl + '/api/igahp/getWilayahInfo/' + tampung.kodeWilayah, {
          headers: {
            Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
          }
        });
        // console.log(response2.data)
        const tampung2 = response2.data[0];
        setWilayah(tampung2);


        setIsLoading(false);
        setIsTokenExpired(false);

      } catch (error) {
        console.error('Error get api data...');
        navigate('/login');
      }

    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }

    try {
      const response = await axios.get(apiUrl + '/api/igahp/peminatBank/' + user.minat_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);
      // console.log('data bank yang dipilih...')
      // console.log(response.data);
      setDataBank(response.data);
      // setIsLoading(false);
      // setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }

  };


  const handleOptionUserChange = event => {
    setSelectedUserOption(event.target.value);
  };


  const handleSubmit = async () => {
    // Perform form submission logic here
    // console.log('Form submitted:', { fullName, username, phoneNumber });
    // You can send the form data to an API endpoint or do any other action here

    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }


    const data = {
      id: user.minat_id,
      fullName: fullName,
      username: username,
      phoneNumber: phoneNumber,
      userId: selectedUserOption

    };


    axios.post(apiUrl + '/api/igahp/pengembang/updateMinatUnit', data,
      {

        headers: {

          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'Content-Type': 'application/json' // You can adjust this based on your API requirements
        }
      })
      .then(response => {
        console.log('API Response:', response.data);
        // Handle success response here

        alert('Update berhasil..');
        fetchData();
      })
      .catch(error => {
        console.error('API Error:', error);
        alert('Update Gagal..');
        // Handle error here
      });


  };

  const [dataStatus, setDataStatus] = useState({
    sudahKonfirmasi: false,
    sudahAjukanKpr: false,
    sudahAkad: false,
    sudahSerahTerima: false
  });


  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    setDataStatus(prevData => ({
      ...prevData,
      [id]: checked
    }));
  };



  const handleSubmitStatus = async () => {



    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      // console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      // console.log("Valid token");
      // result = true;
    }


    const data = {
      id: user.minat_id,
      sudahKonfirmasi: dataStatus.sudahKonfirmasi,
      sudahAjukanKpr: dataStatus.sudahAjukanKpr,
      sudahAkad: dataStatus.sudahAkad,
      sudahSerahTerima: dataStatus.sudahSerahTerima
    };


    axios.post(apiUrl + '/api/igahp/pengembang/updateMinatUnitStatus', data,
      {

        headers: {

          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'Content-Type': 'application/json' // You can adjust this based on your API requirements
        }
      })
      .then(response => {
        console.log('API Response:', response.data);
        // Handle success response here

        alert('Update berhasil..');
        fetchData();
      })
      .catch(error => {
        console.error('API Error:', error);
        alert('Update Gagal..');
        // Handle error here
      });



  };



  const handleDeleteBankClick = async (itemToDelete) => {
    // setModalOpen(false);
    // Display confirmation dialog
    const confirmDelete = window.confirm('Are you sure you want to delete this item?');


    // If user confirms deletion
    if (confirmDelete) {

      var formData = new FormData();
      formData.append("id", itemToDelete.id);


      try {
        await fetch(apiUrl + '/api/igahp/hapusPeminatBank', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
          },
          // body: JSON.stringify(formData),
          // headers: {
          //     'Content-Type': 'application/json'
          // }
          body: formData
          // headers: {   
          //     'Content-Type': 'application/x-www-form-urlencoded',
          //   },
        })
          .then(response => response.json())
          .then(data => {
            // Handle API response
            // console.log(data);

            // setIsLoading(true);
            alert(data.status);
            fetchData();
            // handleNilaiBalikan();
          })
          .catch(error => {
            // Handle error
            console.error(error);
            alert(data.status);
          });

      } catch (error) {
        console.error('An error occurred:', error);
      }

      // alert(itemToDelete.id)


    }
  };



  const [dokPhoto3x4, setDokPhoto3x4] = useState(null);
  const [dokKtpSuamiIstri, setDokKtpSuamiIstri] = useState(null);
  const [dokNpwp, setDokNpwp] = useState(null);
  const [dokKk, setDokKk] = useState(null);
  const [dokKetKerja, setDokKetKerja] = useState(null);
  const [dokSlipGaji, setDokSlipGaji] = useState(null);
  const [dokBukuNikah, setDokBukuNikah] = useState(null);
  const [dokRekKoran, setDokRekKoran] = useState(null);
  const [dokGaji3Bulan, setDokGaji3Bulan] = useState(null);
  const [dokBelumMilikiRumah, setDokBelumMilikiRumah] = useState(null);
  const [dokBelumTerimaSubsidi, setDokBelumTerimaSubsidi] = useState(null);


  const handleSubmitDokumen = async (e) => {


    e.preventDefault();

    //----------------- mulai handel form ---------------------------------------------------------------------------------------
    const formData = new FormData();

    formData.append('id', user.minat_id);
    formData.append('dokPhoto3x4', dokPhoto3x4);
    formData.append('dokKtpSuamiIstri', dokKtpSuamiIstri);
    formData.append('dokNpwp', dokNpwp);
    formData.append('dokKk', dokKk);
    formData.append('dokKetKerja', dokKetKerja);
    formData.append('dokSlipGaji', dokSlipGaji);
    formData.append('dokBukuNikah', dokBukuNikah);
    formData.append('dokRekKoran', dokRekKoran);
    formData.append('dokGaji3Bulan', dokGaji3Bulan);
    formData.append('dokBelumMilikiRumah', dokBelumMilikiRumah);
    formData.append('dokBelumTerimaSubsidi', dokBelumTerimaSubsidi);


    let decodedToken = jwt_decode(user.accessToken);
    // console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;

      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }


    try {
      await fetch(apiUrl + '/api/igahp/simpanPeminatDokumen', {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          console.log(data);

          alert(data.status)

          fetchData();
          //  alert(data.status);

          // Toast.fire({
          //   icon: 'success',
          //   title: data.status
          // });

          // setIsButtonProcess(false);

          // navigate('/igahp/pengembang/PengembangLokasiTipeRumah');
          // handleNilaiBalikan();
        })
        .catch(error => {
          // Handle error
          // setIsButtonProcess(false);
          console.error(error);
          alert(error);

          // Toast.fire({
          //   icon: 'error',
          //   title: error
          // });

        });

    } catch (error) {
      // setIsButtonProcess(false);
      console.error('An error occurred:', error);
      alert(error)
    }

  };

  let sequenceNumber = 1;

  return (

    <>


      {!isLoading ? (
        <>

          <div>

            <div className="content-wrapper">
              {/* Content Header (Page header) */}
              <div className="content-header">
                <div className="container-fluid">
                  <div className="row mb-4 mt-2">
                    <div className="col-sm-6">
                      <h1 className="text-muted fw-light">PROFIL <b>DATA PEMINAT</b></h1>
                    </div>{/* /.col */}
                    <div className="col-sm-6">
                      <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="#">Pengembang</a></li>
                        <li className="breadcrumb-item active">Peminatan</li>
                      </ol>
                    </div>
                    {/* /.content-card-body */}
                  </div>{/* /.row */}
                </div>{/* /.container-fluid */}
              </div>
              {/* /.content-header */}
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-12 col-lg-4">
                      {/* User Card */}
                      <div className="card mb-0">
                        <div className="card-body mb-4">
                          <div className="user-avatar-section">
                            {/* <div className=" d-flex align-items-center flex-column">
                              <img className="img-fluid rounded my-4"
                                src="/dist/img/user8-128x128.jpg"
                                height={110} width={110} alt="User avatar"
                              />
                              <div className="user-info text-center">
                                <h4 className="mb-2">{data.namaLengkap}</h4>
                                <h5><span className="badge bg-gradient-gray">Peminat</span></h5>
                              </div>
                            </div> */}

                            <div className="d-flex align-items-center flex-column">
                              <img
                                className="img-fluid rounded my-4"
                                src="/dist/img/user8-128x128.jpg"
                                height={110}
                                width={110}
                                alt="User avatar"
                              />
                              <div className="user-info text-center">
                                <h4 className="mb-2">{data.namaLengkap}</h4>
                                <h5>
                                  <span className="badge bg-gradient-gray">Peminat</span>
                                </h5>
                                <div className="file-input mt-3">
                                  <input
                                    type="file"
                                    id="fileInput"

                                    style={{
                                      display: 'block',
                                      width: '100%',
                                      padding: '10px',
                                      border: '1px solid #ced4da',
                                      borderRadius: '0.25rem',
                                    }}

                                    onChange={handleFileChange}
                                  />
                                </div>
                              </div>
                            </div>

                          </div>
                          <div className="d-flex justify-content-around flex-wrap my-4 py-3">

                          </div>
                          <h6 className="pb-2 border-bottom text-muted mb-4">DETAIL PEMINAT</h6>
                          <div className="info-container">
                            <div className="table-responsive">
                              <table className="table no-border">
                                <tbody>
                                  {/* <tr>
                                    <td>Nama Lengkap</td>
                                    <td>:</td>
                                    <td>{data.namaLengkap}</td>
                                  </tr>
                                  <tr>
                                    <td>Username</td>
                                    <td>:</td>
                                    <td>{data.userName}</td>
                                  </tr> */}
                                  <tr>
                                    <td>Status</td>
                                    <td>:</td>
                                    <td><span className="badge bg-gradient-cyan">terkonfirmasi</span></td>
                                  </tr>
                                  <tr>
                                    <td>No. Handphone</td>
                                    <td>:</td>
                                    <td>{data.handphone}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                            <div className="pt-3">
                              <button type="button" id="#" data-toggle="modal" data-target="#modal-l" className="btn btn-success">
                                <i className="fas fa-edit mr-2" />Update Akun
                              </button>
                              <div className="modal fade" id="modal-l">
                                <div className="modal-dialog modal-l">
                                  <div className="modal-content">
                                    <div className="modal-header">
                                      <h6 className="text-muted m-0">Update Akun</h6>
                                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                      </button>
                                    </div>
                                    <div className="modal-body">
                                      <div className="form-group">
                                        <label htmlFor="fullName" className="text-gray-dark">Nama Lengkap</label>
                                        <input
                                          type="text"
                                          value={fullName}
                                          onChange={(e) => setFullName(e.target.value)}
                                          className="form-control"
                                          id="fullName"
                                          placeholder="Masukan nama lengkap"
                                        />
                                      </div>
                                      {/* <div className="form-group">

                                        <label>User Akun</label>
                                        <select className="form-control" value={selectedUserOption} onChange={handleOptionUserChange}>
                                          <option value="">Select an option</option>
                                          {options.map(option => (
                                            <option key={option.id} value={option.id}>
                                              {option.username}
                                            </option>
                                          ))}
                                        </select>


                                      </div> */}
                                      <div className="form-group">
                                        <label htmlFor="phoneNumber" className="text-gray-dark">No. Handphone</label>
                                        <input
                                          type="text"
                                          value={phoneNumber}
                                          onChange={(e) => setPhoneNumber(e.target.value)}
                                          className="form-control"
                                          id="phoneNumber"
                                          placeholder="081xxxxxxx"
                                        />
                                      </div>
                                    </div>
                                    <div className="modal-footer justify-content-end align-content-between">
                                      <button type="button" className="btn btn-success" onClick={handleSubmit}>Simpan</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* /User Card */}
                    </div>


                    {/* /.col */}
                    <div className="col-sm-12 col-lg-8">
                      <div className="card">
                        <div className="card-header p-2">
                          <ul className="nav nav-pills">
                            <li className="nav-item"><a className="nav-link active" href="#datarumah" data-toggle="tab"><i className="	far fa-file-alt mr-1" />Data Rumah</a></li>
                            <li className="nav-item"><a className="nav-link" href="#status" data-toggle="tab"> <i className="far fa-check-circle mr-1" /> Status Minat</a></li>
                            <li className="nav-item"><a className="nav-link" href="#pribadi" data-toggle="tab"><i className="far fa-file-alt mr-1" />Data Pribadi</a></li>
                            <li className="nav-item"><a className="nav-link" href="#dokumen" data-toggle="tab"><i className="far fa-folder-open mr-1" />Dokumen KPR</a></li>
                          </ul>
                        </div>{/* /.card-header */}
                        <div className="card-body">
                          <div className="tab-content">

                            {/* /.tab-pane */}
                            <div className="active tab-pane" id="datarumah">

                              <div className="card mb-4">
                                <div className="card-header align-items-center border-0 mt-1 mr-2">
                                  <div className="card-title">
                                    <h6 className="m-0">Data Minat Rumah </h6>
                                    <p className="text-muted mb-0"><small>Spesifikasi rumah yang diminati</small></p>
                                  </div>

                                  <div className="card-tools">
                                    <button type="button" id="#"
                                      data-toggle="modal"
                                      // data-target="#modal-lg-datarumah" 
                                      data-target="#ModalDataRumah"
                                      onClick={handleModalOpenDataRumah}
                                      className="btn btn-success"><i className="fas fa-edit" /> Update Data</button>
                                  </div>


                                </div>
                                <div className="card-body mb-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="table-responsive">
                                        <table className="table border-bottom border-right border-left">
                                          <tbody>
                                            <tr>
                                              <td>Perumahan</td>
                                              <td>:</td>
                                              <td>{data.namaPerumahan}</td>
                                            </tr>
                                            <tr>
                                              <td>Type Rumah</td>
                                              <td>:</td>
                                              <td>{data.namaTipe}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="table-responsive">
                                        <table className="table border-bottom border-right border-left">
                                          <tbody>
                                            <tr>
                                              <td>Pengembang</td>
                                              <td>:</td>
                                              <td>{data.namaPengembang}</td>
                                            </tr>
                                            <tr>
                                              <td>Harga Rumah</td>
                                              <td>:</td>
                                              <td>{formatter.format(data.harga)}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                  <br />
                                  <div className="d-flex justify-content-between align-items-center mb-3">
                                    <div className="d-flex align-items-center">
                                      <div className="d-flex flex-column">
                                        <h6 className="m-0">Lokasi Perumahan</h6>
                                        <p className="text-muted mb-0"><small>Lokasi Perumahan yang diminati</small></p>
                                      </div>
                                    </div>
                                  </div>

                                  {wilayah ? (
                                    <>
                                      <div className="row">
                                        <div className="col-md-6">
                                          <div className="table-responsive">
                                            <table className="table border-bottom border-right border-left">
                                              <tbody>
                                                <tr>
                                                  <td>Provinsi</td>
                                                  <td>:</td>
                                                  <td>{wilayah.namaProp}</td>
                                                </tr>
                                                <tr>
                                                  <td>Kab/Kota</td>
                                                  <td>:</td>
                                                  <td>{wilayah.namaKota}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="table-responsive">
                                            <table className="table border-bottom border-right border-left">
                                              <tbody>
                                                <tr>
                                                  <td>Kecamatan</td>
                                                  <td>:</td>
                                                  <td>{wilayah.namaKec}</td>
                                                </tr>
                                                <tr>
                                                  <td>Kelurahan</td>
                                                  <td>:</td>
                                                  <td>{wilayah.namaKel}</td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>

                                    </>
                                  ) : (
                                    <>

                                    </>
                                  )}



                                </div>
                              </div>

                            </div>

                            {/* /.tab-pane */}
                            <div className="tab-pane" id="status">

                              <div>

                                <div className="card mb-4">
                                  <div className="card-header align-items-center border-0 mt-1 mr-2">

                                    <div className="card-title">
                                      <h6 className="m-0">Info Status</h6>
                                      <p className="text-muted mb-4"><small>Tahapan status minat rumah</small></p>
                                    </div>

                                    <div className="card-tools">
                                      <button type="button" id="tambahModal" data-toggle="modal" data-target="#KonfirmasiStatus" className="btn btn-success">
                                        <i className="fas fa-save mr-2" />Simpan
                                      </button>
                                    </div>


                                    <div className="modal fade" id="KonfirmasiStatus">
                                      <div className="modal-dialog modal-sm">
                                        <div className="modal-content">
                                          <div className="modal-header">
                                            <h6 className="text-muted m-0">Update Status Peminat</h6>
                                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                              <span aria-hidden="true">×</span>
                                            </button>
                                          </div>
                                          <div className="modal-body">
                                            <p>Apakah anda yakin ingin simpan?</p>
                                          </div>
                                          <div className="modal-footer justify-content-between">
                                            {/* Close modal button */}
                                            <button type="button" className="btn btn-default" data-dismiss="modal">Kembali</button>
                                            {/* Submit button */}
                                            <button type="button" className="btn btn-success" onClick={handleSubmitStatus}>Yakin</button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="table-responsive mb-4">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th className="text-nowrap text-center text-gray-dark">Terkonfirmasi</th>
                                            <th className="text-nowrap text-center text-gray-dark">📑 Pengajuan KPR</th>
                                            <th className="text-nowrap text-center text-gray-dark">📝 Akad Kredit</th>
                                            <th className="text-nowrap text-center text-gray-dark">🏡 Serah Terima</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td>
                                              <div className="custom-control custom-checkbox d-flex justify-content-center">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  id="sudahKonfirmasi"
                                                  checked={dataStatus.sudahKonfirmasi}
                                                  onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="sudahKonfirmasi" className="custom-control-label" />
                                              </div>
                                            </td>
                                            <td>
                                              <div className="custom-control custom-checkbox d-flex justify-content-center">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  id="sudahAjukanKpr"
                                                  checked={dataStatus.sudahAjukanKpr}
                                                  onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="sudahAjukanKpr" className="custom-control-label" />
                                              </div>
                                            </td>
                                            <td>
                                              <div className="custom-control custom-checkbox d-flex justify-content-center">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  id="sudahAkad"
                                                  checked={dataStatus.sudahAkad}
                                                  onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="sudahAkad" className="custom-control-label" />
                                              </div>
                                            </td>
                                            <td>
                                              <div className="custom-control custom-checkbox d-flex justify-content-center">
                                                <input
                                                  className="custom-control-input"
                                                  type="checkbox"
                                                  id="sudahSerahTerima"
                                                  checked={dataStatus.sudahSerahTerima}
                                                  onChange={handleCheckboxChange}
                                                />
                                                <label htmlFor="sudahSerahTerima" className="custom-control-label" />
                                              </div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>

                                    </div>


                                  </div>
                                </div>


                                <div className="card mb-0">
                                  <div className="card-header align-items-center border-0 mt-1 mr-2">
                                    <div className="card-title">
                                      <h6 className="m-0">Pilih Bank Penyalur</h6>
                                      <p className="text-muted mb-0"><small>Bank penyalur </small></p>
                                    </div>
                                    <div className="card-tools">
                                      {/* <button type="button" id="#" data-toggle="modal" data-target="#pilihBank" className="btn btn-success btn-sm">Pilih Bank </button> */}

                                      <button
                                        type="button"
                                        id="peminatBankModal" data-toggle="modal"
                                        data-target="#ModalPeminatBank"
                                        className="btn btn-success float-left"
                                        onClick={handleOpenBankClick}
                                      >Tambah Bank</button>
                                      {/* 
                                       */}


                                    </div>
                                  </div>
                                  <div className="card-body mb-4">


                                    <div className="added-cards">
                                      <div className="cardMaster border p-3 rounded mb-3">
                                        {dataBank ? (
                                          <>
                                            <div id="pilihBank">
                                              <table id="example1" className="table table-bordered table-striped">
                                                <thead>
                                                  <tr>
                                                    <th>NO</th>
                                                    <th>KODE</th>
                                                    <th>NAMA</th>
                                                    <th>AKSI</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {dataBank.map((item) => (
                                                    <tr key={item.id}>
                                                      <td>{sequenceNumber++}</td>
                                                      <td>{item.kodeBank}</td>
                                                      <td>{item.namaBank}</td>
                                                      <td style={{ textAlign: 'center' }}>
                                                        {/* <button
                                                              type="button"
                                                              id="editHapusModal"
                                                              data-toggle="modal"
                                                              data-target="#ModalAsosiasi"
                                                              className="btn btn-danger mr-2"
                                                            onClick={() => handleDeleteClick(item)}
                                                            >
                                                              Hapus data
                                                            </button> */}

                                                        <button className="btn btn-outline-info mr-3" data-bs-toggle="modal" data-bs-target="#editCCModal">Edit </button>
                                                        <button className="btn btn-outline-secondary"
                                                          onClick={() => handleDeleteBankClick(item)}
                                                        >Delete</button>

                                                      </td>
                                                    </tr>
                                                  ))}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <p>Loading...</p>
                                          </>
                                        )}
                                      </div>
                                    </div>


                                  </div>
                                </div>
                              </div>

                            </div>

                            {/* /.tab-pane */}
                            <div className="tab-pane" id="pribadi">

                              <div className="card mb-4">
                                <div className="card-header align-items-center border-0 mt-1 mr-2">
                                  <div className="card-title">
                                    <h6 className="m-0">Data Pribadi </h6>
                                    <p className="text-muted mb-0"><small>Rekam data pribadi peminat</small></p>
                                  </div>
                                  <div className="card-tools">
                                    {/* <button type="button" id="#" data-toggle="modal" data-target="#modal-lg" className="btn btn-success">
                                      <i className="fas fa-edit mr-2" />Update Data
                                    </button> */}


                                    <button
                                      type="button"
                                      // id="peminatBankModal" 
                                      data-toggle="modal"
                                      data-target="#ModalDataPribadi"
                                      className="btn btn-success float-left"
                                      onClick={handleOpenDataPribadiClick}
                                    >Update Data</button>
                                  </div>

                                </div>
                                <div className="card-body mb-4">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="table-responsive">
                                        <table className="table border-bottom border-right border-left">
                                          <tbody>
                                            <tr>
                                              <td>Nomor NIK KTP</td>
                                              <td>:</td>
                                              <td>{data.nik}</td>
                                            </tr>
                                            <tr>
                                              <td>Nomor NPWP</td>
                                              <td>:</td>
                                              <td>{data.npwp}</td>
                                            </tr>

                                            <tr>
                                              <td>Jenis Kelamin</td>
                                              <td>:</td>
                                              <td>
                                                <select name="jenisKelamin"
                                                  className="form-control bg-white"
                                                // value={data.jenisKelamin} 
                                                // onChange={handleInputChange}
                                                >
                                                  <option value="pria">Pria</option>
                                                  <option value="wanita">Wanita</option>
                                                </select>
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Status Pernikahan</td>
                                              <td>:</td>
                                              <td>
                                                <select name="statusKawin" className="form-control bg-white"
                                                // value={data.jenisKelamin} 
                                                // onChange={handleInputChange}
                                                >
                                                  <option value="1">Single</option>
                                                  <option value="1">Menikah</option>
                                                </select>
                                              </td>
                                            </tr>


                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="table-responsive">
                                        <table className="table border-bottom border-right border-left">
                                          <tbody>
                                            <tr>
                                              <td>Penghasilan Perbulan</td>
                                              <td>:</td>
                                              <td>{formatter.format(data.penghasilan)}</td>
                                            </tr>
                                            <tr>
                                              <td>Domisili Saat Ini</td>
                                              <td>:</td>
                                              <td>{data.domisili}</td>
                                            </tr>

                                            <tr>
                                              <td>Tanggal Lahir</td>
                                              <td>:</td>
                                              <td>
                                                <input
                                                  className="form-control bg-white"
                                                  type="date"
                                                  // value={tglTerbitIMB}
                                                  // onChange={(e) => setTglTerbitIMB(e.target.value)}
                                                  require
                                                />
                                              </td>
                                            </tr>

                                            <tr>
                                              <td>Tanggungan Jiwa</td>
                                              <td>:</td>
                                              {/* <td>{formatter.format(data.penghasilan)}</td> */}
                                            </tr>

                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            {/* /.tab-pane */}

                            {/* /.tab-pane */}
                            <div className="tab-pane" id="dokumen">

                              <form onSubmit={handleSubmitDokumen}>
                                <div className="card mb-4">
                                  {/* Notifications */}
                                  <div className="card-header align-items-center border-0 mt-1 mr-2">
                                    <div className="card-title">
                                      <h6 className="m-0">File Dokumen KPR </h6>
                                      <p className="text-muted mb-4"><small>Unggah file dokumen KPR yang disyaratkan oleh Bank
                                        Penyalur</small></p>
                                    </div>
                                    <div className="table-responsive">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th className="text-nowrap text-center text-gray-dark"> Nama Dokumen</th>
                                            <th className="text-nowrap text-center text-gray-dark">📄 File</th>
                                            <th style={{ width: '20%' }}>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>


                                          <tr>
                                            <td>Foto 3x4</td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokPhoto3x4}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokPhoto3x4(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>

                                          <tr>
                                            <td>
                                              KTP Suami/ Istri
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokKtpSuamiIstri}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            {/* <td className="project-actions text-right">
                                              <a className="btn btn-info btn-sm" href="#">
                                                <i className="fas fa-upload">
                                                </i>
                                                Unggah
                                              </a>
                                            </td> */}

                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokKtpSuamiIstri(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              NPWP
                                            </td>
                                            <td>

                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokNpwp}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokNpwp(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Kartu Keluarga
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokKk}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokKk(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Surat Keterangan Kerja
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokKetKerja}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokKetKerja(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Slip Gaji
                                            </td>
                                            <td>
                                              {/* <a href="#" className="d-flex justify-content-center">Lihat File</a> */}
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokSlipGaji}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokSlipGaji(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Buku Nikah
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokBukuNikah}`}
                                                target="blank" >Lihat File</a>
                                            </td>

                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokBukuNikah(e.target.files[0])}
                                              />
                                            </td>

                                          </tr>
                                          <tr>
                                            <td>
                                              Rekening Koran
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokRekKoran}`}
                                                target="blank" >Lihat File</a>
                                            </td>

                                            {/* <td className="project-actions text-right">
                                              <a className="btn btn-info btn-sm" href="#">
                                                <i className="fas fa-upload">
                                                </i>
                                                Unggah
                                              </a>
                                            </td> */}

                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokRekKoran(e.target.files[0])}
                                              />
                                            </td>


                                          </tr>
                                          <tr>
                                            <td>
                                              Surat Keterangan Gaji 3 bulan
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokGaji3Bulan}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokGaji3Bulan(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Surat Pernyataan Belum Memiliki Rumah
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokBelumMilikiRumah}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokBelumMilikiRumah(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              Surat Keterangan Belum Pernah Menerima Subsidi
                                            </td>
                                            <td>
                                              <a className="d-flex justify-content-center" href={`${apiUrl}/api/public/displaypdf/${data.dokBelumTerimaSubsidi}`}
                                                target="blank" >Lihat File</a>
                                            </td>
                                            <td className="project-actions text-right">
                                              <input
                                                className="btn btn-info btn-sm"
                                                requireds
                                                type="file"
                                                accept=".pdf"
                                                onChange={(e) => setDokBelumTerimaSubsidi(e.target.files[0])}
                                              />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                    {/* /Notifications */}
                                  </div>
                                </div>

                                <div className="mt-4">
                                  <button className="btn btn-success" >Simpan Dokumen</button>
                                </div>

                              </form>

                            </div>
                            {/* /.tab-pane */}


                          </div>
                          {/* /.tab-content */}
                        </div>{/* /.card-body */}
                      </div>
                      {/* /.card */}
                    </div>
                    {/* /.col */}





                  </div>
                </div>
              </section>

            </div>

          </div>


          {isModalOpenDataRumah && <ModalDataRumah onClose={handleModalCloseDataRumah}
            namaPengembang={data.namaPengembang}
            perumahanId={data.perumahanId}
            tipeRumahId={data.tipeRumahId}
          />}

          {isModalBankOpen && <ModalPeminatBanks onClose={handleModalBankClose}
            peminatId={data.id}
          />}


          {isModalDataPribadiOpen && <ModalDataPribadi onClose={handleModalDataPribadiClose}
            dataMinat={data}
          />}


        </>
      ) : (

        <>
          <p>Loading...</p>
        </>
      )}




    </>
  )
}

export default MinatInfo
