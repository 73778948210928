import React, { useEffect, useState, useRef, useContext } from 'react';
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Outlet,
    Link,
    useNavigate
} from "react-router-dom";
import { AuthContext } from "../../AuthContext";
import jwt_decode from 'jwt-decode';
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import PictureProfile from '../PictureProfile';
import { OnegreenContext } from '../../OnegreenContext';

const SidebarPengembang = () => {
    const { profileData } = useContext(OnegreenContext);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();
    const { user, setUser } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [data, setData] = useState({});
    let refreshToken = null;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/aktifmenu.js";
        script.async = true;
        document.body.appendChild(script);

        fetchData();

        return () => { document.body.removeChild(script); }
    }, []);

    const fetchData = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();

        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
        }

        try {
            const response = await axios.get(`${apiUrl}/api/igahp/pengembangProfile/${user.pengembang_id}`, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            setData(response.data);
        } catch (error) {
            console.error('Error getting API data...');
            navigate('/login');
        }
    };

    const [imageSrcPengembang, setImageSrcPengembang] = useState('/dist/img/logo_pengembang/pengembang_default.jpg');
    const [userPhotoPengembang, setUserPhotoPengembang] = useState(null);

    useEffect(() => {
        if (user.pengembang_photo) {
            setUserPhotoPengembang(`${apiUrl}/api/public/displayimage/${user.pengembang_photo}`);
        }
    }, [user.pengembang_photo]);

    useEffect(() => {
        if (profileData?.photoProfilePengembang) {
            setUserPhotoPengembang(`${apiUrl}/api/public/displayimage/${profileData.photoProfilePengembang}`);
        }
    }, [profileData?.photoProfilePengembang]);

    return (
        <div>
            <aside className="main-sidebar sidebar-light-success elevation-4 position-fixed">
                <a href="/igahp" className="brand-link">
                    <img src="/dist/img/lentera_logo.svg" alt="Logo" className="brand-image" />
                    <h4><span className="brand-text text-muted ml-2"></span><b>LENTERA</b></h4>
                </a>

                <div className="sidebar">
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <PictureProfile />
                        </div>
                        <div className="info p-0 ml-4">
                            <div>
                                <info className="text-muted">
                                    Admin
                                    {/* {profileData && <div>{`Profile Name: ${profileData.photoProfilePengembang}`}</div>} */}
                                </info><br />{data.pic}
                            </div>
                        </div>
                    </div>
                    <div className="card card-img shadow-none">
                        <img
                            src={userPhotoPengembang || imageSrcPengembang}
                            className="img-thumbnail"
                            alt="image"
                            style={{ cursor: 'pointer' }}
                        />
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            <li className="nav-item">
                                <Link to="pengembang/dashboard" className="nav-link">
                                    <i className="nav-icon fas fa-tachometer-alt"></i>
                                    <p>Dashboard</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link to="pengembang/PengembangProfile" className="nav-link">
                                    <i className="nav-icon fas fa-user "></i>
                                    <p>Profile Pengembang</p>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-house-user"></i>
                                    <p>
                                        Perumahan
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pengembang/PengembangLokasiTambah" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Tambah Perumahan</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pengembang/PengembangAdminListLokasi2" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Info Perumahan</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-paste"></i>
                                    <p>
                                        Sertifikasi
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="/igahp/pengembang/PengembangPengajuanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Form Pengajuan</p>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link to="pengembang/PengembangPenugasanBgh" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Penugasan TA BGH</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-landmark"></i>
                                    <p>
                                        Bank
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pengembang/PengembangBank" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Data Bank</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <Link to="pengembang/dataMinat" className="nav-link">
                                    <i className="nav-icon fas fa-users"></i>
                                    <p>Data Minat Unit</p>
                                    <span className='badge badge-warning right'>{data.totalMinat}</span>
                                </Link>
                            </li>
                            <li className="nav-item">
                                <a href="#" className="nav-link">
                                    <i className="nav-icon fas fa-user-cog"></i>
                                    <p>
                                        Pengaturan Akun
                                        <i className="fas fa-angle-left right"></i>
                                    </p>
                                </a>
                                <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                        <Link to="pengembang/Akun" className="nav-link">
                                            <i className="nav-icon far fa-circle text-warning"></i>
                                            <p>Akun</p>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </nav>
                </div>
            </aside>
        </div>
    );
}

export default SidebarPengembang;
