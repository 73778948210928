import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import Swal from 'sweetalert2';



import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';


const ModalTipeRumah = ({ onClose, dataPemasaran }) => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });


    const [jenisPerumahan, setJenisPerumahan] = useState('0');

    const [namaTipe, setNamaTipe] = useState(null);
    const [harga, setHarga] = useState(null);
    const [luasLahan, setLuasLahan] = useState(null);
    const [luasBangunan, setLuasBangunan] = useState(null);
    const [jumlahLantai, setJumlahLantai] = useState(null);
    const [jumlahKamarTidur, setJumlahKamarTidur] = useState(null);
    const [jumlahKamarMandi, setJumlahKamarMandi] = useState(null);
    const [spesifikasiAtap, setSpesifikasiAtap] = useState(null);
    const [spesifikasiDinding, setSpesifikasiDinding] = useState(null);
    const [spesifikasiLantai, setSpesifikasiLantai] = useState(null);

    const [isButtonProcess, setIsButtonProcess] = useState(false);
    const [spesifikasiPondasi, setSpesifikasiPondasi] = useState(null);


    // const [fotoTampak, setFotoTampak] = useState(null);
    const [fotoTampakDepan, setFotoTampakDepan] = useState(null);
    const [fotoTampakBelakang, setFotoTampakBelakang] = useState(null);
    const [fotoDenah, setFotoDenah] = useState(null);

    const [fotoOrientasi, setFotoOrientasi] = useState(null);
    const [fotoPotongan, setFotoPotongan] = useState(null);


    // const [isTokenExpired, setIsTokenExpired] = useState(false);


    const [dokRks, setDokRks] = useState(null);
    const [dokBoq, setDokBoq] = useState(null);



    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);



    const handleClose = () => {
        onClose();
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [isTokenExpired, setIsTokenExpired] = useState(false);


    let refreshToken = null;

    const navigate = useNavigate();


    const [fotoTampakDepanImg, setFotoTampakDepanImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');
    const [fotoTampakBelakangImg, setFotoTampakBelakangImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');

    const [fotoDenahImg, setFotoDenahImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');

    // const [fotoOrientasiImg, setFotoOrientasiImg] = useState(`${apiUrl}/dist/img/no-photo-choose.png`);
    const [fotoPotonganImg, setFotoPotonganImg] = useState('https://indonesiahijau.id/dist/img/no-photo-choose.png');
    const { user, setUser } = useContext(AuthContext);


    useEffect(() => {

        // console.log('data modal pribadi....')
        // console.log(dataMinat);


        (async () => {


            setNamaTipe(user.tiperumah_nama)
            setLuasBangunan(user.tiperumah_lb)
            setLuasLahan(user.tiperumah_lt)
            setHarga(user.tiperumah_harga)

            setJumlahLantai(user.tiperumah_jumlantai)
            setJumlahKamarMandi(user.tiperumah_km)
            setJumlahKamarTidur(user.tiperumah_kt)

            setSpesifikasiAtap(user.tiperumah_atap)
            setSpesifikasiDinding(user.tiperumah_dinding)
            setSpesifikasiLantai(user.tiperumah_lantai)
            setSpesifikasiPondasi(user.tiperumah_pondasi);
            setJenisPerumahan(user.tiperumah_status)
            

            if (user.tiperumah_state == 'edit') {
                if (user.tiperumah_fotoTampakDepan !== null) {
                    setFotoTampakDepanImg(apiUrl + '/api/public/displayimage/' + user.tiperumah_fotoTampakDepan);
                }
                if (user.tiperumah_fotoTampakBelakang!==null) {
                    setFotoTampakBelakangImg(apiUrl + '/api/public/displayimage/' + user.tiperumah_fotoTampakBelakang);
                }                
                if (user.tiperumah_fotoDenah!==null) {
                    setFotoDenahImg(apiUrl + '/api/public/displayimage/' + user.tiperumah_fotoDenah);
                }
                if (user.tiperumah_fotoPotongan!==null) {
                    setFotoPotonganImg(apiUrl + '/api/public/displayimage/' + user.tiperumah_fotoPotongan);   
                }
            }

            setDokRks(user.tiperumah_dokRks)

            // await fetchOptionsFromApiPerumahan()
            //     .then(data => setOptionsPerumahan(data))
            //     .catch(error => console.error('Error fetching options:', error));
            // setSelectedOptionPerumahan(perumahanId);

            // const response = await axios.get(`${apiUrl}/api/public/getTipeRumahById2/${tipeRumahId}`);
            //             setHargaRumah(response.data.harga);

        })();


    }, []);


    const handleJenisPerumahan = (event) => {
        setJenisPerumahan(event.target.value);
        //  alert(jenisPerumahan);5
    };

    const formatNumber = (number) => {
        return new Intl.NumberFormat('id-ID').format(number);
    };


    const handleChange = (e) => {
        const { value } = e.target;

        // Menghapus karakter non-angka dari input
        const formattedValue = value.replace(/\D/g, '');

        setHarga(formattedValue);
    };


    const handleFotoTampakDepan = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setFotoTampakDepanImg(reader.result);
        };
        reader.readAsDataURL(file);
        setFotoTampakDepan(event.target.files[0]);
        // Check if the file is an image
        if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }
    }


    const handleFotoPotongan = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setFotoPotonganImg(reader.result);
        };
        reader.readAsDataURL(file);
        setFotoPotongan(event.target.files[0]);
        // Check if the file is an image
        if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }
    }

    const handleFotoTampakBelakang = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setFotoTampakBelakangImg(reader.result);
        };
        reader.readAsDataURL(file);
        setFotoTampakBelakang(event.target.files[0]);
        // Check if the file is an image
        if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }
    }


    const handleFotoDenah = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onload = () => {
            setFotoDenahImg(reader.result);
        };
        reader.readAsDataURL(file);
        setFotoDenah(event.target.files[0]);
        // Check if the file is an image
        if (!event.target.files[0] || !event.target.files[0].type.startsWith('image/')) {
            alert('Please select a valid image file.');
            return;
        }
    }


    const handleSubmit = async (e) => {
        setIsButtonProcess(true);

        e.preventDefault();


        if (!harga) {
            alert('Harga is required.');
            return;
        }
        // alert('item di add');
        var isTambahData = true;


        if (user.tiperumah_state == 'edit') {
            isTambahData = false;
        } else {
            isTambahData = true;
        }

        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        formData.append('id', user['tiperumah_id']);
        // formData.append('id', user.perumahan_id);
        formData.append('is_tambah_data', isTambahData);
        formData.append('pengembang_id', user.pengembang_id);
        formData.append('perumahan_id', user.perumahan_id);

        formData.append("jenisPerumahan", jenisPerumahan);
        formData.append("namaTipe", namaTipe);
        formData.append("harga", harga);
        formData.append("luasLahan", luasLahan);
        formData.append("luasBangunan", luasBangunan);
        formData.append("jumlahLantai", jumlahLantai);
        formData.append("jumlahKamarTidur", jumlahKamarTidur);
        formData.append("jumlahKamarMandi", jumlahKamarMandi);
        formData.append("spesifikasiAtap", spesifikasiAtap);

        formData.append("spesifikasiDinding", spesifikasiDinding);
        formData.append("spesifikasiLantai", spesifikasiLantai);
        formData.append("spesifikasiPondasi", spesifikasiPondasi);

        // formData.append("fotoTampak", fotoTampak);

        formData.append("fotoTampakDepan", fotoTampakDepan);
        formData.append("fotoTampakBelakang", fotoTampakBelakang);
        formData.append("fotoDenah", fotoDenah);
        formData.append("fotoPotongan", fotoPotongan);
        // formData.append("fotoOrientasi", fotoOrientasi);

        formData.append('dokRks', dokRks);
        formData.append('dokBoq', dokBoq);

        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;

            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            await fetch(apiUrl + '/api/igahp/simpanTipeRumah', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    // alert(data.status);

                    Toast.fire({
                        icon: 'success',
                        title: data.status
                    });

                    setIsButtonProcess(false);

                    // navigate('/igahp/pengembang/PengembangLokasiTipeRumah');
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    setIsButtonProcess(false);
                    console.error(error);
                    // alert(error);

                    Toast.fire({
                        icon: 'error',
                        title: error
                    });

                });

        } catch (error) {
            setIsButtonProcess(false);
            console.error('An error occurred:', error);
        }

    };


    return (
        <>


            <div className="modal fade" id="ModalTipeRumah" tabIndex={-1} role="dialog"
                aria-labelledby="ModalTipeRumahLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Input Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <div>
                            <div className="modal-body">
                                <section className="content">
                                    <div className="container-fluid">

                                        <form onSubmit={handleSubmit}>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="card card-success">
                                                        <div className="card-header">
                                                            <h3 className="card-title">Spesifikasi Data Rumah</h3>
                                                        </div>
                                                        <div className="card-body">
                                                            <div className="form-group">
                                                                {/* <h6 class="m-0">Tambah Tipe Rumah</h6> */}
                                                                <p className="text-muted mb-0"><small>Isi data jenis &amp; tipe rumah</small>
                                                                </p>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-8">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Jenis Perumahan</label>
                                                                        
                                                                        <select value={jenisPerumahan} onChange={handleJenisPerumahan} className="form-control" 
                                                                            required>
                                                                            <option value="">PILIH</option>
                                                                            <option value="subsidi">SUBSIDI HIJAU</option>
                                                                            <option value="komersil">KOMERSIL HIJAU</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Harga Rumah</label>
                                                                        <div className="input-group">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text">Rp.</span>
                                                                            </div>
                                                                        
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="harga"
                                                                                value={formatNumber(harga)} // Menampilkan nilai harga dengan format angka
                                                                                onChange={handleChange}
                                                                                required // Add this attribute to make the input required
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-xl-4 col-md-12 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Tipe Unit Rumah</label>
                                                                        {/* <input type="text" className="form-control" id="settings" placeholder="Cth. 21, 30, 36" /> */}
                                                                        <input
                                                                            placeholder="Cth. 21, 30, 36"
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="namaTipe"
                                                                            value={namaTipe}
                                                                            onChange={(e) => setNamaTipe(e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Luas Tanah</label>
                                                                        <div className="input-group mb-3">
                                                                            {/* <input type="text" className="form-control" id="settings" placeholder={'00'} /> */}

                                                                            <input
                                                                                placeholder={'00'}
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="luasLahan"
                                                                                value={luasLahan}
                                                                                onChange={(e) => setLuasLahan(e.target.value)}
                                                                                required
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text">m<sup>2</sup></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-4 col-md-6 col-sm-6">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Luas Bangunan</label>
                                                                        <div className="input-group">
                                                                            {/* <input type="number" className="form-control" id="settings" placeholder={'00'} /> */}

                                                                            <input
                                                                                placeholder={'00'}
                                                                                type="text"
                                                                                className="form-control"
                                                                                name="luasBangunan"
                                                                                value={luasBangunan}
                                                                                onChange={(e) => setLuasBangunan(e.target.value)}
                                                                                required
                                                                            />
                                                                            <div className="input-group-append">
                                                                                <span className="input-group-text">m<sup>2</sup></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                {/* <h6 class="m-0">Tambah Tipe Rumah</h6> */}
                                                                <p className="text-muted mb-0"><small>Isi jumlah spesifikasi rumah</small>
                                                                </p>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Jumlah Lantai</label>
                                                                        {/* <input type="number" className="form-control" id="settings" placeholder={'00'} /> */}

                                                                        <input
                                                                            placeholder={'00'}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="jumlahLantai"
                                                                            value={jumlahLantai}
                                                                            onChange={(e) => setJumlahLantai(e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Kamar Tidur</label>
                                                                        {/* <input type="number" className="form-control" id="settings" placeholder={'00'} /> */}
                                                                        <input
                                                                            placeholder={'00'}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="jumlahKamarTidur"
                                                                            value={jumlahKamarTidur}
                                                                            onChange={(e) => setJumlahKamarTidur(e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Kamar Mandi</label>
                                                                        {/* <input type="number" className="form-control" id="settings" placeholder={'00'} /> */}
                                                                        <input
                                                                            placeholder={'00'}
                                                                            type="text"
                                                                            className="form-control"
                                                                            name="jumlahKamarMandi"
                                                                            value={jumlahKamarMandi}
                                                                            onChange={(e) => setJumlahKamarMandi(e.target.value)}
                                                                            required
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="form-group">
                                                                {/* <h6 class="m-0">Tambah Tipe Rumah</h6> */}
                                                                <p className="text-muted mb-0"><small>Isi data spesifikasi teknis
                                                                    rumah</small>
                                                                </p>
                                                            </div>
                                                            <div className="row mb-3">
                                                                <div className="col-xl-3 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Material Atap</label>
                                                                        {/* <input type="text" className="form-control" id="settings" placeholder="Cth. Genteng Tanah Liat, dll." /> */}
                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Cth. Genteng Tanah Liat, dll."
                                                                            name="spesifikasiAtap"
                                                                            value={spesifikasiAtap}
                                                                            onChange={(e) => setSpesifikasiAtap(e.target.value)}
                                                                            required
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Material
                                                                            Dinding</label>
                                                                        {/* <input type="text" className="form-control" id="settings" placeholder="Cth. Bata Merah, dll" /> */}

                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Cth. Bata Merah, dll"
                                                                            name="spesifikasiDinding"
                                                                            value={spesifikasiDinding}
                                                                            onChange={(e) => setSpesifikasiDinding(e.target.value)}
                                                                            required
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Material Lantai</label>
                                                                        {/* <input type="text" className="form-control" id="settings" placeholder="Cth. Keramik" /> */}

                                                                        <input type="text"
                                                                            className="form-control"
                                                                            placeholder="Cth. Keramik"
                                                                            name="spesifikasiLantai"
                                                                            value={spesifikasiLantai}
                                                                            onChange={(e) => setSpesifikasiLantai(e.target.value)}
                                                                            required
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-3 col-md-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label htmlFor="dafaultLabel" className="text-gray-dark">Material
                                                                            Pondasi</label>
                                                                        {/* <input type="text" className="form-control" id="settings" placeholder="Cth. Batu Kali, dll" /> */}

                                                                        <input type="text"
                                                                            className="form-control"

                                                                            placeholder="Cth. Batu Kali, dll"
                                                                            name="spesifikasiPondasi"
                                                                            value={spesifikasiPondasi}
                                                                            onChange={(e) => setSpesifikasiPondasi(e.target.value)}
                                                                            required
                                                                        ></input>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-gray">
                                                <div className="card-header">
                                                    <h3 className="card-title">Dokumen</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-md-6 col-sm-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputFile" className="text-gray-dark" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        Rencana Kerja &amp; Syarat (RKS)
                                                                        {(user.tiperumah_state == 'edit' && user.tiperumah_dokRks!==null) ? (
                                                                            <>                                                                            
                                                                                <a
                                                                                    href={`${apiUrl}/api/public/displaypdf/${user.tiperumah_dokRks}`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    Lihat File
                                                                                </a>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                            </>
                                                                        )}

                                                                    </label>
                                                                    <input
                                                                        className="form-control form-control"
                                                                        type="file"
                                                                        accept=".pdf"
                                                                        onChange={(e) => setDokRks(e.target.files[0])}
                                                                    />
                                                                    <small>File Dokumen harus dalam <b>format .pdf</b></small>
                                                                </div>
                                                            </div>

                                                            <div className="col-xl-6 col-md-6 col-sm-12">
                                                                <div className="form-group">
                                                                    <label htmlFor="exampleInputFile" className="text-gray-dark" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                        BOQ Rumah
                                                                        {(user.tiperumah_state == 'edit' && user.tiperumah_dokBoq!==null) ? (
                                                                            <>
                                                                                <a
                                                                                    href={`${apiUrl}/api/public/displaypdf/${user.tiperumah_dokBoq}`}
                                                                                    target="_blank"
                                                                                    rel="noopener noreferrer"
                                                                                >
                                                                                    Lihat File
                                                                                </a>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                            </>
                                                                        )}
                                                                    </label>
                                                                    
                                                                    <input
                                                                        className="form-control form-control"
                                                                        type="file"
                                                                        accept=".pdf"
                                                                        onChange={(e) => setDokBoq(e.target.files[0])}
                                                                    />
                                                                    <small>File Dokumen harus dalam <b>format .pdf</b></small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card card-gray">
                                                <div className="card-header">
                                                    <h3 className="card-title">Foto Ilustrasi Bangunan</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div className="form-group">
                                                        <div className="row">
                                                            <div className="col-md">
                                                                <div className="card mb-3">
                                                                    <div className="row g-0">
                                                                        <div className="col-md-4">
                                                                            <img className="card-img card-img-left h-100" src={fotoDenahImg} alt="Card image" />
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="card-body">
                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Ilustrasi Denah
                                                                                    Bangunan</label>
                                                                                <div className="form-group">
                                                                                    {/* <input className="form-control form-control" type="file" accept=".pdf" /> */}
                                                                                    <input
                                                                                        // required
                                                                                        data-pristine-required-message="Foto Denah" className="svelte-zttspj"
                                                                                        type="file"
                                                                                        accept="image/jpeg"
                                                                                        onChange={handleFotoDenah}
                                                                                    />

                                                                                    <p className="card-text"><small className="text-muted">File Foto harus
                                                                                        dalam
                                                                                        <b>format .jpg/.jpeg </b></small>
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <div className="card mb-3">
                                                                    <div className="row g-0">
                                                                        <div className="col-md-4">
                                                                            <img className="card-img card-img-left h-100" src={fotoPotonganImg} alt="Card image" />
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="card-body">
                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Ilustrasi Potongan
                                                                                    Bangunan</label>
                                                                                <div className="form-group">
                                                                                    {/* <input className="form-control form-control" type="file" accept=".pdf" /> */}
                                                                                    <input
                                                                                        // required
                                                                                        data-pristine-required-message="Foto potongan" className="svelte-zttspj"
                                                                                        type="file"
                                                                                        accept="image/jpeg"
                                                                                        onChange={handleFotoPotongan}
                                                                                    />
                                                                                    <p className="card-text"><small className="text-muted">File Foto harus
                                                                                        dalam
                                                                                        <b>format .jpg/.jpeg </b></small></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-md">
                                                                <div className="card mb-3">
                                                                    <div className="row g-0">
                                                                        <div className="col-md-4">
                                                                            <img className="card-img card-img-left h-100" src={fotoTampakDepanImg} alt="Card image" />
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="card-body">
                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Foto Tampak Depan
                                                                                    Rumah</label>
                                                                                <div className="form-group">
                                                                                    {/* <input className="form-control form-control" type="file" accept=".pdf" /> */}
                                                                                    <input
                                                                                        // required
                                                                                        data-pristine-required-message="Foto Tampak Depan" className="svelte-zttspj"
                                                                                        type="file"
                                                                                        accept="image/jpeg"
                                                                                        onChange={handleFotoTampakDepan}
                                                                                    />
                                                                                    <p className="card-text"><small className="text-muted">File Foto harus
                                                                                        dalam
                                                                                        <b>format .jpg/.jpeg </b></small></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-md">
                                                                <div className="card mb-3">
                                                                    <div className="row g-0">
                                                                        <div className="col-md-4">
                                                                            <img className="card-img card-img-left h-100" src={fotoTampakBelakangImg} alt="Card image" />
                                                                        </div>
                                                                        <div className="col-md-8">
                                                                            <div className="card-body">
                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Foto Tampak
                                                                                    Belakang Rumah</label>
                                                                                <div className="form-group">
                                                                                    {/* <input className="form-control form-control" type="file" accept=".pdf" /> */}
                                                                                    <input
                                                                                        // required
                                                                                        data-pristine-required-message="Foto Tampak Belakang" className="svelte-zttspj"
                                                                                        type="file"
                                                                                        accept="image/jpeg"
                                                                                        onChange={handleFotoTampakBelakang}
                                                                                    />
                                                                                    <p className="card-text"><small className="text-muted">File Foto harus
                                                                                        dalam
                                                                                        <b>format .jpg/.jpeg </b></small></p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="modal-footer justify-content-end align-content-between">
                                                <button type="submit" className="btn btn-success">Simpan</button>
                                            </div>
                                        </form>
                                    </div>
                                </section>
                            </div>

                        </div>


                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalTipeRumah
