import React from 'react'
import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";
import { AuthContext } from '../../AuthContext';
import { useContext } from "react";
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import Footer from '../Footer';

const MenuPemohon = () => {

    const { user, setUser } = useContext(AuthContext);

    return (
        <>


            <div class="hold-transition sidebar-mini layout-fixed">


                <div className="wrapper">
                    {/* Preloader */}
                    {/* <div className="preloader flex-column justify-content-center align-items-center">
                        <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                    </div> */}


                    <Navbar />

                    <Sidebar />

                    <Outlet />

                    <Footer />

                </div>
                {/* ./wrapper */}

            </div>





        </>
    )
}

export default MenuPemohon
