import React from 'react'
import {    Outlet } from "react-router-dom";
import { AuthContext } from '../../AuthContext';
import { useContext } from "react";

import Footer from '../Footer';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const MenuBank = () => {

    const { user, setUser } = useContext(AuthContext);

    return (
        <>

            <div className="hold-transition sidebar-mini layout-fixed">


                <div className="wrapper">
                    {/* Preloader */}
                    {/* <div className="preloader flex-column justify-content-center align-items-center">
                        <img className="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height={60} width={60} />
                    </div> */}
                    

                    <Navbar/>

                    <Sidebar/>

                    <Outlet />

                    <Footer />
                    
                </div>
                {/* ./wrapper */}                

            </div>

        </>
    )
}

export default MenuBank
