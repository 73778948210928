import React from 'react'

import { useEffect } from 'react';
import { useState, useRef } from 'react';
// import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from './refreshTokenRequest';
import { AuthContext } from '../AuthContext';
import { useContext } from 'react';
import PictureProfile from './PictureProfile';
import { OnegreenContext } from '../OnegreenContext';
// import ModalDataRumah from './ModalDataRumah';
// import ModalPeminatBanks from '../childs/ModalPeminatBanks';
// import ModalDataPribadi from './ModalDataPribadi';


const AkunSetting = () => {

    const { setProfileData } = useContext(OnegreenContext);
    const handleProfileUpdate = (newProfileData) => {
        setProfileData(newProfileData);
    };



    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const [isLoading, setIsLoading] = useState(true);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate();
    let refreshToken = null;
    const { user, setUser } = useContext(AuthContext);
    const [data, setData] = useState([]);
    const [itemData, setItemData] = useState(null);
    const [dataBank, setDataBank] = useState(null);
    const [isModal, setIsModal] = useState(false);
    const [wilayah, setWilayah] = useState({
        namaProp: ''
    });

    const [namaLengkap, setNamaLengkap] = useState('');
    const [handphone, setHandphone] = useState('');
    const [email, setEmail] = useState('');
    const [namaPT, setNamaPT] = useState('');

    const [passwordLama, setPasswordLama] = useState('');
    const [passwordBaru, setPasswordBaru] = useState('');
    const [passwordBaru2, setPasswordBaru2] = useState('');


    const fileInputRef = useRef(null);

    useEffect(() => {
        // const script = document.createElement('script');
        // script.src = "/js/aktifmenu.js";
        // script.async = true;
        // document.body.appendChild(script);

        fetchData();

        // return () => { script.remove(); }
    }, []);


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            // console.log('user id ' + user.id);
            // console.log('pengembang id ' + user.pengembang_id);
            var pengembangId = '';
            const response = await axios.get(apiUrl + '/api/igahp/getInfoAkun/' + user.id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);

            console.log('tampil akun....')
            console.log(response.data);
            setData(response.data);

            setNamaLengkap(response.data.namaLengkap)
            setHandphone(response.data.nomorHp)
            setEmail(response.data.email)
            setNamaPT(response.data.namaPerusahaan)
            // setNamaDirektur(response.data.namaDirektur);response.data.namaLengkap


            setIsLoading(false);
            // setIsTokenExpired(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const handleHandphoneChange = (event) => {
        setHandphone(event.target.value);
    };


    const handleNamaLengkapChange = (event) => {
        setNamaLengkap(event.target.value);
    };


    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordLamaChange = (event) => {
        setPasswordLama(event.target.value);
    };


    const handlePasswordBaruChange = (event) => {
        setPasswordBaru(event.target.value);
    };


    const handlePasswordBaru2Change = (event) => {
        setPasswordBaru2(event.target.value);
    };





    const handlePasswordLamaBlur = async () => {


        if (passwordLama) {
            const loginRequest = {
                username: user.username, // Replace with actual username or get it from state/props
                password: passwordLama
            };


            try {
                const response = await fetch(apiUrl + '/api/auth/cekPassword', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(loginRequest)
                });

                if (response.ok) {
                    const result = await response.text();
                    // alert(result);
                } else {
                    const error = await response.text();
                    alert(error);
                }
            } catch (error) {
                console.error('Error:', error);
                // alert('An error occurred. Please try again.');
            }

        }

    };

    const handleFormSubmitPassword = async (event) => {
        event.preventDefault();

        if (passwordBaru != passwordBaru2) {
            alert('password baru dan password konfirmasi tidak sama')
            return
        }

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }





        const loginRequest = {
            username: user.username, // Replace with actual username or get it from state/props
            password: passwordLama
        };


        try {
            const response = await fetch(apiUrl + '/api/auth/cekPassword', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(loginRequest)
            });

            if (response.ok) {
                const result = await response.text();
                // alert(result);
            } else {
                const error = await response.text();
                alert(error);
                return
            }
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
            return
        }


        //--------------------------------------------------------------------------------------------------------------------

        var formData = new FormData();
        formData.append("id", user.id);
        formData.append("newPassword", passwordBaru2);

        try {
            await fetch(apiUrl + '/api/igahp/akunchangepassword', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                },
                body: formData  // Set formData directly as the body
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Parse JSON response
                })
                .then(data => {
                    // Handle API response
                    // setIsLoading(true);
                    fetchData();
                    alert(data.status);
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle fetch errors
                    console.error('Fetch error:', error);
                    alert('An error occurred while fetching data');
                });

        } catch (error) {
            // Handle other errors
            console.error('An error occurred:', error);
        }


        // alert('password');
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault();

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            // console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            // console.log("Valid token");
            // result = true;
        }

        var formData = new FormData();
        formData.append("id", user.id);
        formData.append("namaLengkap", namaLengkap);
        formData.append("email", email);
        formData.append("nomorHp", handphone);

        try {
            await fetch(apiUrl + '/api/igahp/updateakuninfo', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                },
                body: formData  // Set formData directly as the body
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Parse JSON response
                })
                .then(data => {
                    // Handle API response
                    // setIsLoading(true);
                    fetchData();
                    alert(data.status);
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle fetch errors
                    console.error('Fetch error:', error);
                    alert('An error occurred while fetching data');
                });

        } catch (error) {
            // Handle other errors
            console.error('An error occurred:', error);
        }



    };

    const profileImageStyle = {
        width: '100px',  // set width to 100px
        height: '100px',  // maintain aspect ratio
        // Add any other styles you want here
    };

    const [userPhoto, setUserPhoto] = useState(null); // Assuming user.photo is a string

    useEffect(() => {
        if (user.photo) {
            setUserPhoto(apiUrl + '/api/public/displayimage/' + user.photo);

        } else {
            setUserPhoto('/dist/img/user1-128x128.jpg');
        }
    }, [user.photo]);



    const [imageSrc, setImageSrc] = useState('/dist/img/user1-128x128.jpg');

    const handleFileChange = (event) => {

        const file = event.target.files[0];



        if (file) {
            if (file.size > 10 * 1024 * 1024) { // 1MB size limit
                alert('File size exceeds 1MB');
                return;
            }
            // setError(''); // Clear any previous errors
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageSrc(reader.result);
                uploadImage(file); // Upload image if size is OK
            };
            reader.readAsDataURL(file);
        }

        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setUserPhoto(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };


    const uploadImage = async (file) => {

        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }




        const formData = new FormData();
        formData.append('id', user.id);
        formData.append('photo', file);


        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }

        const sUrl = apiUrl + '/api/igahp/uploadPhotoProfile';

        console.log(sUrl);
        try {
            await fetch(sUrl, {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response




                    if (data) {

                        var tempData = data;
                        alert(tempData.status);

                        // setError('');
                        handleProfileUpdate({ photoProfile: tempData.photoProfile });

                    }

                })
                .catch(error => {

                    console.error(error);

                });

        } catch (error) {
            console.error(error);
        }

    };




    return (

        <>
            {!isLoading ? (
                <>

                    <div className="content-wrapper">
                        {/* Content Header (Page header) */}
                        <div className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-4 mt-2">
                                    <div className="col-sm-6">
                                        <h1 className="text-muted fw-light"><b>AKUN</b></h1>
                                    </div>{/* /.col */}
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Pengembang</a></li>
                                            <li className="breadcrumb-item active">Akun</li>
                                        </ol>
                                    </div>
                                    {/* /.content-card-body */}
                                </div>{/* /.row */}
                            </div>{/* /.container-fluid */}
                        </div>
                        {/* /.content-header */}
                        <section className="content">
                            <div className="container-fluid">
                                <div className="row">

                                    {/* /.col */}
                                    <div className="col-sm-12 col-lg-12">
                                        <div className="card">
                                            <div className="card-header p-2">
                                                <ul className="nav nav-pills">
                                                    <li className="nav-item"><a className="nav-link active" href="#Akun" data-toggle="tab"><i className="	far fa-file-alt mr-1" />Akun</a></li>
                                                    <li className="nav-item"><a className="nav-link" href="#keamanan" data-toggle="tab"> <i className="fa fa-lock mr-2" /> Keamanan</a></li>
                                                </ul>
                                            </div>{/* /.card-header */}
                                            <div className="card-body">
                                                <div className="tab-content">

                                                    {/* /.tab-pane */}
                                                    <div className="active tab-pane" id="Akun">

                                                        <div className="card mb-4">
                                                            <div className="card-body">
                                                                <div className="d-flex align-items-start align-items-sm-center">

                                                                    <img src={userPhoto} alt="user-avatar" className="d-block rounded" height={100} width={100} id="uploadedAvatar" />


                                                                    <div className="button-wrapper ml-4">
                                                                        <label htmlFor="upload" className="btn btn-success me-2 mb-4" tabIndex={0}>
                                                                            <span className="d-none d-sm-block">Upload new photo</span>
                                                                            <i className="fas fa-upload d-block d-sm-none" />

                                                                            {/* <input type="file" id="upload" className="account-file-input" 
                                                                                hidden accept="image/png, image/jpeg" /> */}


                                                                            <input
                                                                                type="file"
                                                                                id="fileInput"
                                                                                ref={fileInputRef}
                                                                                // style={{ display: 'none' }}
                                                                                onChange={handleFileChange}
                                                                            // hidden accept="image/png, image/jpeg"
                                                                            />


                                                                        </label>
                                                                        <button type="button" className="btn btn-outline-secondary account-image-reset mb-4">
                                                                            <i className="fas fa-redo-alt d-block d-sm-none" />
                                                                            <span className="d-none d-sm-block">Reset</span>
                                                                        </button>
                                                                        <p className="text-muted mb-0">Allowed JPG, GIF or PNG. Max size of 800K</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-0" />
                                                            <div className="card-header align-items-center border-0 mt-1 mr-2">
                                                                <div className="card-title">
                                                                    <h6 className="m-0">Akun Detail </h6>
                                                                    <p className="text-muted mb-0"><small>Isi form detail akun</small></p>
                                                                </div>
                                                                <div className="card-tools">
                                                                    <button type="button" id="#" data-toggle="modal" data-target="#modal-lg" className="btn btn-success"><i className="fas fa-edit mr-2" />Update Akun</button>
                                                                </div>
                                                                <div className="modal fade" id="modal-lg">
                                                                    <div className="modal-dialog modal-lg">
                                                                        <div className="modal-content ">
                                                                            <div className="modal-header">
                                                                                <h5 className="modal-title">Update Akun</h5>
                                                                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                                                    <span aria-hidden="true">×</span>
                                                                                </button>
                                                                            </div>
                                                                            <form onSubmit={handleFormSubmit}>

                                                                                <div className="modal-body">
                                                                                    <div className="form-group">
                                                                                        <h6 className="m-0">Akun Detail </h6>
                                                                                        <p className="text-muted mb-0"><small>Isi form detail akun</small></p>
                                                                                    </div>

                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Nama Lengkap</label>
                                                                                                {/* <input type="text" defaultValue="Budiyanto" 
                                                                                                    className="form-control" 
                                                                                                    id="settings" placeholder="Cth. Budianto" /> */}
                                                                                                <input
                                                                                                    id="namaLengkap"
                                                                                                    type="text"
                                                                                                    placeholder="Budiyanto"
                                                                                                    className="form-control"
                                                                                                    value={namaLengkap}
                                                                                                    onChange={handleNamaLengkapChange}
                                                                                                    required
                                                                                                />

                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Email</label>
                                                                                                <input type="email" defaultValue="Budiyanto@gmail.com"
                                                                                                    className="form-control" id="email"
                                                                                                    value={email}
                                                                                                    onChange={handleEmailChange}
                                                                                                    placeholder="onegreen@mail.com" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">Nama Perusahaan</label>
                                                                                                {/* <select className="form-control select2 select2-hidden-accessible" style={{ width: '100%' }} data-select2-id={10} tabIndex={-10} aria-hidden="true">
                                                                                                    <option selected="selected" data-select2-id={10}>Pilih</option>
                                                                                                    <option data-select2-id={100}>Mahasiswa</option>
                                                                                                    <option data-select2-id={101}>Pegawai Swasta</option>
                                                                                                    <option data-select2-id={101}>ASN/TNI/POLRI</option>
                                                                                                </select> */}

                                                                                                <input
                                                                                                    id="namaPT"
                                                                                                    type="text"
                                                                                                    placeholder=""
                                                                                                    className="form-control"
                                                                                                    value={namaPT}
                                                                                                    // onChange={handleNamaLengkapChange}
                                                                                                    required
                                                                                                    disabled
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6">
                                                                                            <div className="form-group">
                                                                                                <label htmlFor="dafaultLabel" className="text-gray-dark">No. Handphone</label>
                                                                                                {/* <input type="number" defaultValue={"000000"} 
                                                                                                className="form-control" id="settings" placeholder={"0811219121299"} /> */}

                                                                                                <input
                                                                                                    id="handphone"
                                                                                                    // ref={ignoreScroll}
                                                                                                    type="number"
                                                                                                    placeholder="081100010002"
                                                                                                    className="form-control"
                                                                                                    value={handphone}
                                                                                                    onChange={handleHandphoneChange}
                                                                                                    required
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className="modal-footer justify-content-end align-content-between">
                                                                                    <button type="submit" className="btn btn-success">Simpan</button>
                                                                                </div>

                                                                            </form>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body mb-4">
                                                                <div className="row">
                                                                    <div className="col-md-6">
                                                                        <div className="table-responsive">
                                                                            <table className="table border-bottom border-right border-left">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Nama Lengkap</td>
                                                                                        <td>:</td>
                                                                                        <td>{data.namaLengkap}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>Nama Perusahaan</td>
                                                                                        <td>:</td>
                                                                                        <td>{data.namaPerusahaan}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="table-responsive">
                                                                            <table className="table border-bottom border-right border-left">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>Email</td>
                                                                                        <td>:</td>
                                                                                        <td>{data.email}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>No. Handphone</td>
                                                                                        <td>:</td>
                                                                                        <td>{data.nomorHp}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    {/* /.tab-pane */}
                                                    <div className="tab-pane" id="keamanan">


                                                        <div className="card mb-4">
                                                            <div className="card-header align-items-center border-0 mt-1 mr-2">
                                                                <div className="card-title">
                                                                    <h5 className="text-muted mb-0">Reset Password</h5>
                                                                </div>
                                                            </div>


                                                            <form onSubmit={handleFormSubmitPassword}>

                                                                <div className="card-body mb-4">
                                                                    <div className="row">
                                                                        <div className="mb-3 col-12 col-sm-6">
                                                                            <label className="form-label text-muted" htmlFor="newPassword">Password Lama</label>
                                                                            <div className="input-group">
                                                                                <input
                                                                                    id="passwordLama"
                                                                                    type="password"
                                                                                    placeholder=""
                                                                                    className="form-control"
                                                                                    value={passwordLama}
                                                                                    onChange={handlePasswordLamaChange}
                                                                                    onBlur={handlePasswordLamaBlur}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="mb-3 col-12 col-sm-6">
                                                                            <label className="form-label text-muted" htmlFor="newPassword">Password Baru</label>
                                                                            <div className="input-group">
                                                                                {/* <input className="form-control" type="password" id="newPassword" name="newPassword" placeholder="············" /> */}

                                                                                <input
                                                                                    id="passwordBaru"
                                                                                    type="password"
                                                                                    placeholder=""
                                                                                    className="form-control"
                                                                                    value={passwordBaru}
                                                                                    onChange={handlePasswordBaruChange}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="mb-3 col-12 col-sm-6">
                                                                            <label className="form-label text-muted" htmlFor="confirmPassword">Ulangi Password Baru</label>
                                                                            <div className="input-group">
                                                                                {/* <input className="form-control" type="password" name="confirmPassword" id="confirmPassword" placeholder="············" /> */}

                                                                                <input
                                                                                    id="passwordBaru2"
                                                                                    type="password"
                                                                                    placeholder=""
                                                                                    className="form-control"
                                                                                    value={passwordBaru2}
                                                                                    onChange={handlePasswordBaru2Change}
                                                                                    required
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12 mb-4">
                                                                            <p className="text-muted mt-2">Pastikan persyaratan ini terpenuhi:</p>
                                                                            <ul className="ps-3 mb-0">
                                                                                <li className="text-muted mb-1">
                                                                                    Minimal 8 karakter &amp; 1 huruf besar
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                        <div className="ml-2">
                                                                            <button type="submit" className="btn btn-success">Simpan Password</button>
                                                                        </div>
                                                                    </div>
                                                                    <input type="hidden" />
                                                                </div>

                                                            </form>

                                                        </div>


                                                    </div>


                                                </div>
                                                {/* /.tab-content */}
                                            </div>{/* /.card-body */}
                                        </div>
                                        {/* /.card */}
                                    </div>
                                    {/* /.col */}





                                </div>
                            </div>
                        </section>

                    </div>

                </>

            ) : (
                <>
                    <p>Loading ...</p>
                </>
            )
            }
        </>






    )
}

export default AkunSetting
