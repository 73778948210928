import React from 'react'
import NavBarAtas from './NavBarAtas'

const Blog = () => {


    require("./../../css/common.css");

    return (
        <>
            <NavBarAtas page="2" showButton={true} />

            <div>
                <section className="wrapper-hero-image-detail-perumahan">
                    <img src="images/blog/image_hero_detail_rumah.png" id="image-hero-blog" />
                    <div className="wrapper-blog-title">
                        <div className="one-green-h1-semi-bold black-text">Berita</div>
                        <div className="one-green-l-regular neutral-80-text">Home / Blog</div>
                    </div>
                </section>
                <section className="wrapper-newest-news">
                    <div className="one-green-h3-semi-bold black-text">Berita Terbaru</div>
                    <div className="wrapper-newest-news-content">
                        <img src="images/blog/image_berita_terbaru.png" id="image-berita-terbaru" />
                        <div className="newest-news-content">
                            <div className="one-green-subheading-semi-bold black-text">Keunggulan Rumah Hijau dalam Mendukung Lingkungan
                                dan Kesejahteraan</div>
                            <div className="one-green-m-regular neutral-60-text">Rumah hijau adalah konsep hunian yang dirancang untuk
                                mengurangi dampak negatif terhadap lingkungan. Rumah hijaumerupakan solusi bagi berbagai tantangan
                                lingkungan dan kesejahteraan manusia. Berikut beberapa keunggulan rumah hijau yang membuatnya
                                menjadi pilihan yang cerdas untuk masa depan.</div>
                            <div className="newest-news-date">
                                <div className="one-green-m-regular neutral-60-text">26 Desember 2022</div>

                                <a href="/blogdetail">

                                    <div className="chevron-date-news">
                                        <img src="images/blog/ic_cheveron_right.svg" />
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wrapper-news">
                    {/* <div className="wrapper-news-title">
                        <div className="one-green-h3-semi-bold black-text">Berita</div>
                        <div className="wrapper-news-category-list">
                            <div className="news-category-item active">Semua Berita</div>
                            <div className="news-category-item">Popular</div>
                            <div className="news-category-item">Rumah Hijau</div>
                            <div className="news-category-item">Daur Ulang</div>
                            <div className="news-category-item">Sertifikasi</div>
                        </div>
                    </div> */}
                    <div className="wrapper-news-content">
                        <a href="/blogdetail2">
                            <div className="news-content-item">
                                <div className="wrapper-image-news">
                                    <img src="images/blog/image_berita_1.png" id="image-list-news" />
                                    <div className="wrapper-category-text-news">
                                        <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                    </div>
                                </div>
                                <div className="wrapper-content-news">
                                    <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                    <div className="one-green-subheading-semi-bold neutral-90">IGAHP EXPO: Potensi Rumah Subsidi Hijau di
                                        Indonesia</div>
                                </div>
                            </div>
                        </a>
                        {/* <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_2.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_3.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            <div className="wrapper-content-news">
                                <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                <div className="one-green-subheading-semi-bold neutral-90">Kolaborasi Inovatif Membangun Rumah Subsidi
                                    Hijau Berkelanjutan di Indonesia</div>
                            </div>
                        </div>
                        <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_4.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            
                        </div>
                        <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_5.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            <div className="wrapper-content-news">
                                <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                <div className="one-green-subheading-semi-bold neutral-90">Peletakan Batu Pertama Rumah Subsidi Hijau di
                                    Taman Anggrek Kuningan</div>
                            </div>
                        </div>
                        <div className="news-content-item">
                            <div className="wrapper-image-news">
                                <img src="images/blog/image_berita_6.png" id="image-list-news" />
                                <div className="wrapper-category-text-news">
                                    <div className="one-green-s-medium blue-text">Rumah Hijau</div>
                                </div>
                            </div>
                            <div className="wrapper-content-news">
                                <div className="one-green-m-regular neutral-80">26 Desember 2022</div>
                                <div className="one-green-subheading-semi-bold neutral-90">Kurangi Emisi, PUPR Siapkan Rumah Murah
                                    Berkonsep `GREEN`</div>
                            </div>
                        </div> */}
                    </div>
                </section>
                {/* <section className="wrapper-page-news">
                    <div className="page-news-list">
                        <div className="page-news-prev disabled">
                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M13.2071 5.29289C13.5976 5.68342 13.5976 6.31658 13.2071 6.70711L9.91421 10L13.2071 13.2929C13.5976 13.6834 13.5976 14.3166 13.2071 14.7071C12.8166 15.0976 12.1834 15.0976 11.7929 14.7071L7.79289 10.7071C7.40237 10.3166 7.40237 9.68342 7.79289 9.29289L11.7929 5.29289C12.1834 4.90237 12.8166 4.90237 13.2071 5.29289Z" fill="#E0E0E0" />
                            </svg>
                        </div>
                        <div className="page-news-item active">01</div>
                        <div className="page-news-item">02</div>
                        <div className="page-news-item">03</div>
                        <div className="page-news-next">
                            <svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" clipRule="evenodd" d="M7.79289 14.7071C7.40237 14.3166 7.40237 13.6834 7.79289 13.2929L11.0858 10L7.79289 6.70711C7.40237 6.31658 7.40237 5.68342 7.79289 5.29289C8.18342 4.90237 8.81658 4.90237 9.20711 5.29289L13.2071 9.29289C13.5976 9.68342 13.5976 10.3166 13.2071 10.7071L9.20711 14.7071C8.81658 15.0976 8.18342 15.0976 7.79289 14.7071Z" fill="#5BB24A" />
                            </svg>
                        </div>
                    </div>
                </section> */}
            </div>

        </>
    )
}

export default Blog
