import React from 'react'

const ContentAsosiasi = () => {
  return (
    <div>
      INI KONTEN ASOSIASI
    </div>
  )
}

export default ContentAsosiasi
