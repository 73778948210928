import React, { useEffect } from 'react'
import { useState } from 'react';
import axios from 'axios'
import { useContext } from 'react';
import { Axios } from 'axios';
import { AuthContext } from "../../AuthContext";
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import MenuPerumahanDetail from './MenuPerumahanDetail';
import Swal from 'sweetalert2';


const PengembangLokasiFasilitas = () => {

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });


    const navigate = useNavigate();
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;
    const { user, setUser } = useContext(AuthContext);

    

    const [fasilitas, setFasilitas] = useState({
        perumahanId : user.perumahan_id,
        prasaranaUmum: {
            gerbangPerumahan: false,
            keamanan: false,
            tamanBermain: false,
            drainase: false,
            jalanUmum: false,
            aksesParkir: false,
        },
        gayaHidup: {
            bankSampah: false,
            tempatSampahDaurUlang: false,
            pengelolaanLimbah: false,
        },
        transportasi: {
            gerbangTol: false,
            stasiunKereta: false,
            terminalBus: false,
            spbu: false,
            bankAtm: false,
            pasarInduk: false,
        },
        pendidikan: {
            tk: false,
            sdn: false,
            smp: false,
            sma: false,
            universitas: false,
        },
        ibadah: {
            masjid: false,
            gereja: false,
        },
    });

    const [perumahan, setPerumahan] = useState([]);

    // Fetch data from the API
    useEffect(() => {
        fetchFasilitasData();
    }, []);

    const fetchFasilitasData = async () => {


        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            const response = await axios.get(apiUrl + '/api/igahp/getLokasiFasilitas/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            console.log(response.data)
            setPerumahan(response.data);

            setValueFas('prasaranaUmum', 'gerbangPerumahan', response.data.gerbangPerumahan);
            setValueFas('prasaranaUmum', 'tamanBermain', response.data.tamanBermain);
            setValueFas('prasaranaUmum', 'jalanUmum', response.data.jalanUmum);
            setValueFas('prasaranaUmum', 'keamanan', response.data.keamanan);
            setValueFas('prasaranaUmum', 'drainase', response.data.drainase);
            setValueFas('prasaranaUmum', 'aksesParkir', response.data.aksesParkir);
            setValueFas('prasaranaUmum', 'rumahSakit', response.data.rumahSakit);

            setValueFas('gayaHidup', 'bankSampah', response.data.bankSampah);
            setValueFas('gayaHidup', 'tempatSampahDaurUlang', response.data.tempatSampahDaurUlang);
            setValueFas('gayaHidup', 'pengelolaanLimbah', response.data.pengelolaanLimbah);

            setValueFas('transportasi', 'gerbangTol', response.data.gerbangTol);
            setValueFas('transportasi', 'terminalBus', response.data.terminalBus);
            setValueFas('transportasi', 'bankAtm', response.data.bankAtm);
            setValueFas('transportasi', 'stasiunKereta', response.data.stasiunKereta);
            setValueFas('transportasi', 'spbu', response.data.spbu);
            setValueFas('transportasi', 'pasarInduk', response.data.pasarInduk);

            setValueFas('pendidikan', 'tk', response.data.tk);
            setValueFas('pendidikan', 'sdn', response.data.sdn);
            setValueFas('pendidikan', 'smp', response.data.smp);
            setValueFas('pendidikan', 'sma', response.data.sma);
            setValueFas('pendidikan', 'universitas', response.data.universitas);

            setValueFas('ibadah', 'masjid', response.data.masjid);
            setValueFas('ibadah', 'gereja', response.data.gereja);
            
        } catch (error) {
            console.error('Error fetching perumahan data:', error);
        }
    };

    const setValueFas = (section, key, newValue) => {
        setFasilitas((prevFasilitas) => ({
            ...prevFasilitas,
            [section]: {
                ...prevFasilitas[section],
                [key]: !prevFasilitas[section][key],
                [key]: newValue,
            },
        }));
    };


    const handleCheckboxChange = (section, key) => {
        setFasilitas((prevFasilitas) => ({
            ...prevFasilitas,
            [section]: {
                ...prevFasilitas[section],
                [key]: !prevFasilitas[section][key],
            },
        }));
    };

    const handleSave = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        try {
            // Assuming you want to save the fasilitas state to the API
            // const response = await axios.post('/api/perumahan', fasilitas);

            const response = await axios.post(apiUrl + '/api/igahp/simpanLokasiFasilitas' ,fasilitas, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            console.log('Data saved:', response.data);
            Toast.fire({
                icon: 'success',
                title: response.data.status
            });

            fetchFasilitasData(); // Refresh the data after saving
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };


  return (
    <>

          <aside className="main-sidebar sidebar-light-success elevation-4">
              <MenuPerumahanDetail />
          </aside>

          {/* Content Wrapper. Contains page content */}
          <div className="content-wrapper">
              {/* Content Header (Page header) */}
              
              <section className="content-header">
                  <div className="container-fluid">
                      <div className="row mb-4 mt-2">
                          <div className="col-sm-12 text-center"> {/* Modify the class here */}
                              <h1 className="text-muted fw-light">FASILITAS<b> PERUMAHAN</b></h1>
                          </div>
                      </div>
                  </div>
              </section>




              <section className="content d-flex align-items-center justify-content-center vh-100">

                  <div className="container-fluid">
                      <div className="row justify-content-center">
                          <div className="col-sm-12 col-lg-8">
                              <div className="card mb-4">
                                  <div className="card-header align-items-center border-0 mt-1 mr-2">
                                      <div className="card-title">
                                          <h5 className="m-0">Fasilitas Perumahan</h5>
                                          <p className="text-muted mb-0">
                                              <small>Fasilitas atau prasarana yang terdapat dilokasi perumahan</small>
                                          </p>
                                      </div>
                                      <div className="card-tools">
                                          <button type="button" onClick={handleSave} className="btn btn-success">
                                              <i className="fas fa-save mr-2"></i> Simpan
                                          </button>
                                      </div>
                                  </div>

                                  <div className="card-body">
                                      <div className="form-group">
                                          <h6 className="text-muted accent-gray-dark">Prasarana Umum Perumahan</h6>
                                          <div className="row">
                                              <div className="col-sm-3">
                                                  <div className="form-group">
                                                      <div className="form-check">
                                                          <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={fasilitas.prasaranaUmum.gerbangPerumahan}
                                                              onChange={() => handleCheckboxChange('prasaranaUmum', 'gerbangPerumahan')}
                                                          />
                                                          <label className="form-check-label">Gerbang Perumahan</label>
                                                      </div>
                                                      <div className="form-check">
                                                          <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={fasilitas.prasaranaUmum.keamanan}
                                                              onChange={() => handleCheckboxChange('prasaranaUmum', 'keamanan')}
                                                          />
                                                          <label className="form-check-label">Keamanan</label>
                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                              <div className="col-sm-3">
                                                  <div className="form-group">
                                                      <div className="form-check">
                                                          <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={fasilitas.prasaranaUmum.tamanBermain}
                                                              onChange={() => handleCheckboxChange('prasaranaUmum', 'tamanBermain')}
                                                          />
                                                          <label className="form-check-label">Taman Bermain</label>
                                                      </div>
                                                      <div className="form-check">
                                                          <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={fasilitas.prasaranaUmum.drainase}
                                                              onChange={() => handleCheckboxChange('prasaranaUmum', 'drainase')}
                                                          />
                                                          <label className="form-check-label">Drainase</label>
                                                      </div>
                                                  </div>
                                              </div>
                                              <div className="col-sm-3">
                                                  <div className="form-group">
                                                      <div className="form-check">
                                                          <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={fasilitas.prasaranaUmum.jalanUmum}
                                                              onChange={() => handleCheckboxChange('prasaranaUmum', 'jalanUmum')}
                                                          />
                                                          <label className="form-check-label">Jalan Umum</label>
                                                      </div>
                                                      <div className="form-check">
                                                          <input
                                                              className="form-check-input"
                                                              type="checkbox"
                                                              checked={fasilitas.prasaranaUmum.aksesParkir}
                                                              onChange={() => handleCheckboxChange('prasaranaUmum', 'aksesParkir')}
                                                          />
                                                          <label className="form-check-label">Akses Parkir</label>
                                                      </div>
                                                      
                                                  </div>
                                              </div>
                                          </div>
                                          

                                          <br />

                                          <div className="form-group">
                                              <h6 className="text-muted accent-gray-dark">Gaya Hidup Berkelanjutan</h6>
                                              <div className="row">
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.gayaHidup.bankSampah}
                                                                  onChange={() => handleCheckboxChange('gayaHidup', 'bankSampah')}
                                                              />
                                                              <label className="form-check-label">Bank Sampah</label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.gayaHidup.tempatSampahDaurUlang}
                                                                  onChange={() => handleCheckboxChange('gayaHidup', 'tempatSampahDaurUlang')}
                                                              />
                                                              <label className="form-check-label">Tempat Sampah Daur Ulang</label>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.gayaHidup.pengelolaanLimbah}
                                                                  onChange={() => handleCheckboxChange('gayaHidup', 'pengelolaanLimbah')}
                                                              />
                                                              <label className="form-check-label">Pengelolaan Limbah</label>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>

                                  <div className="card mb-4">
                                      <div className="row">
                                          <div className="col-12">
                                              <div className="card-header align-items-center border-0 mt-2">
                                                  <div className="card-title">
                                                      <h5 className="mb-0">Lokasi Terdekat Perumahan</h5>
                                                      <p className="text-muted mb-0">
                                                          <small>Fasilitas terdekat dari lokasi perumahan</small>
                                                      </p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="card-body mb-3">
                                          <div className="form-group">
                                              <h6 className="text-muted accent-gray-dark">Fasilitas Umum</h6>
                                              <div className="row">
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.transportasi.gerbangTol}
                                                                  onChange={() => handleCheckboxChange('transportasi', 'gerbangTol')}
                                                              />
                                                              <label className="form-check-label">Gerbang Tol</label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.transportasi.stasiunKereta}
                                                                  onChange={() => handleCheckboxChange('transportasi', 'stasiunKereta')}
                                                              />
                                                              <label className="form-check-label">Stasiun Kereta</label>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.prasaranaUmum.rumahSakit}
                                                                  onChange={() => handleCheckboxChange('prasaranaUmum', 'rumahSakit')}
                                                              />
                                                              <label className="form-check-label">Rumah Sakit</label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.transportasi.terminalBus}
                                                                  onChange={() => handleCheckboxChange('transportasi', 'terminalBus')}
                                                              />
                                                              <label className="form-check-label">Terminal Bus</label>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.transportasi.spbu}
                                                                  onChange={() => handleCheckboxChange('transportasi', 'spbu')}
                                                              />
                                                              <label className="form-check-label">SPBU / Pom Bensin</label>
                                                          </div>
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.transportasi.bankAtm}
                                                                  onChange={() => handleCheckboxChange('transportasi', 'bankAtm')}
                                                              />
                                                              <label className="form-check-label">Bank / ATM</label>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.transportasi.pasarInduk}
                                                                  onChange={() => handleCheckboxChange('transportasi', 'pasarInduk')}
                                                              />
                                                              <label className="form-check-label">Pasar Induk</label>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                              <br />
                                              <div className="form-group">
                                                  <h6 className="text-muted accent-gray-dark">Fasilitas Pendidikan</h6>
                                                  <div className="row">
                                                      <div className="col-sm-3">
                                                          <div className="form-group">
                                                              <div className="form-check">
                                                                  <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      checked={fasilitas.pendidikan.tk}
                                                                      onChange={() => handleCheckboxChange('pendidikan', 'tk')}
                                                                  />
                                                                  <label className="form-check-label">Taman Kanak-kanak</label>
                                                              </div>
                                                              <div className="form-check">
                                                                  <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      checked={fasilitas.pendidikan.sdn}
                                                                      onChange={() => handleCheckboxChange('pendidikan', 'sdn')}
                                                                  />
                                                                  <label className="form-check-label">Sekolah Dasar</label>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <div className="col-sm-3">
                                                          <div className="form-group">
                                                              <div className="form-check">
                                                                  <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      checked={fasilitas.pendidikan.smp}
                                                                      onChange={() => handleCheckboxChange('pendidikan', 'smp')}
                                                                  />
                                                                  <label className="form-check-label">Sekolah Menengah Pertama</label>
                                                              </div>
                                                              <div className="form-check">
                                                                  <input
                                                                      className="form-check-input"
                                                                      type="checkbox"
                                                                      checked={fasilitas.pendidikan.sma}
                                                                      onChange={() => handleCheckboxChange('pendidikan', 'sma')}
                                                                  />
                                                                  <label className="form-check-label">Sekolah Menengah Atas/ Kejuruan</label>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  <div className="col-sm-3">
                                                      <div className="form-group">
                                                          <div className="form-check">
                                                              <input
                                                                  className="form-check-input"
                                                                  type="checkbox"
                                                                  checked={fasilitas.pendidikan.universitas}
                                                                  onChange={() => handleCheckboxChange('pendidikan', 'universitas')}
                                                              />
                                                              <label className="form-check-label">Universitas</label>
                                                          </div>
                                                          {/* /.tambah fasilitas terdekat */}

                                                      </div>
                                                  </div>
                                                  </div>
                                                  <br />
                                                  <div className="form-group">
                                                      <h6 className="text-muted accent-gray-dark">Fasilitas Ibadah</h6>
                                                      <div className="row">
                                                          <div className="col-sm-3">
                                                              <div className="form-group">
                                                                  <div className="form-check">
                                                                      <input
                                                                          className="form-check-input"
                                                                          type="checkbox"
                                                                          checked={fasilitas.ibadah.masjid}
                                                                          onChange={() => handleCheckboxChange('ibadah', 'masjid')}
                                                                      />
                                                                      <label className="form-check-label">Masjid</label>
                                                                  </div>
                                                                  <div className="form-check">
                                                                      <input
                                                                          className="form-check-input"
                                                                          type="checkbox"
                                                                          checked={fasilitas.ibadah.gereja}
                                                                          onChange={() => handleCheckboxChange('ibadah', 'gereja')}
                                                                      />
                                                                      <label className="form-check-label">Gereja</label>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                             
                          </div>
                      </div>
                  </div>
              </section>

          </div>
          {/* /.content-wrapper */}

    </>
  )
}

export default PengembangLokasiFasilitas
