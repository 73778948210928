import React from 'react'

import { AuthContext } from '../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import Footer from '../Footer';

const ManajemenLokasi = () => {



    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedRoleName, setSelectedRoleName] = useState('');

    // useEffect(() => {
    //   fetchData();  
    // }, []);

    const ITEMS_PER_PAGE = 6; // Jumlah item per halaman

    const [currentPage, setCurrentPage] = useState(1);

    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = data.slice(startIndex, endIndex);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            // fetchDataRoles();
            fetchData();
        }
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);


    const fetchDataRoles = () => {
        // Replace 'API_URL' with your actual API endpoint
        fetch(apiUrl + '/api/public/getAllRoles')
            .then(response => response.json())
            .then(data => setOptions(data))
            .catch(error => console.error('Error fetching data:', error));
    };


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl+'/api/igahp/lokasi/getAllLokasiStatus', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const handleAddUsers = () => {
        // user['pemasaranrumah_state'] = 'add';
        // user['pemasaranrumah_id'] = null
        // loginContext(user);

        navigate(`/igahp/admin/ManajemenUsersTambah`);
    };



    const handleSelectRoleChange = event => {
        setSelectedRole(event.target.value);
        setSelectedRoleName(event.target.options[event.target.selectedIndex].text);
        // console.log('Selected role:', selectedRole);
        // console.log('Selected role name:', event.target.options[event.target.selectedIndex].text);
    };

    const handleSubmitAdd = event => {
        event.preventDefault();
        // Handle form submission or any other logic here

        if (selectedRole === '') {
            alert('Pilih Role terlebih dahulu...');
            return;
        }
        user['rolestate'] = 'add';
        user['roleid'] = selectedRole;
        user['rolename'] = selectedRoleName;
        loginContext(user);

        navigate(`/igahp/admin/ManajemenUsersTambah`);
        // console.log('Selected option:', selectedRole);
    };




    const handleApproveClick = async (id) => {
        const confirmApprove = window.confirm("Are you sure you want to Approve this Lokasi?");


        if (confirmApprove) {


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }

            doUpdateStatus(id, '1')


        } else {

        }
    }

    const doUpdateStatus = async (id, status) => {

        const requestBody = {
            id_lokasi: id,
            userId: user.id,
            status: status
        };


        try {
            await fetch(apiUrl+'/api/igahp/updateStatusLokasi', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),

            })
                .then(response => response.json())
                .then(data => {

                    alert(data.message);
                    fetchData();
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert(data.status);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }

    }

    const handleDeniedClick = async (id) => {
        const confirmApprove = window.confirm("Are you sure you want to Non-Aktif this user?");


        if (confirmApprove) {

            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }
            doUpdateStatus(id, '0')
        } else {

        }
    }

    let sequenceNumber = 1;

    return (
        <>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manajemen Lokasi</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Manajemen Lokasi</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">

                        <div className="row">
                            {/* /.col */}
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Card</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Tabel</a></li>
                                            {/* <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li> */}
                                        </ul>
                                    </div>{/* /.card-header */}
                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="active tab-pane" id="activity">

                                                <div className="row">
                                                    {isLoading ?
                                                        <p>Loading Data...</p> :
                                                        (
                                                            <>
                                                                <div className="row">
                                                                    {currentItems.map((item, index) => (
                                                                        <div key={index} className="col-12 col-sm-6 col-md-4 d-flex align-items-stretch flex-column">
                                                                            <div className="card bg-light d-flex flex-fill">
                                                                                <div className="card-header text-muted border-bottom-0">
                                                                                    {/* <span className="badge badge-warning badge-lg">{item.status}</span>                                                                                    */}
                                                                                </div>
                                                                                <div className="card-body pt-0">
                                                                                    <div className="row">

                                                                                        <div className="col-12 text-center">

                                                                                            <img src={`${apiUrl}/api/public/displayimage/${item.fotoGerbang}`}
                                                                                                alt="gambar lokasi"                                                                                                
                                                                                                style={{ width: '100%', height: '320px' }}
                                                                                                className="img-fluid" />


                                                                                            <div className="ribbon-wrapper ribbon-lg">

                                                                                                {item.status === 'Rumah Tapak' ?
                                                                                                    <>
                                                                                                        <div className="ribbon bg-success text-lg">
                                                                                                            <small>{item.status}</small>
                                                                                                        </div>  
                                                                                                    </> :
                                                                                                    <>
                                                                                                        <div className="ribbon bg-danger text-lg">
                                                                                                            <small>{item.status}</small>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                               
                                                                                            </div>

                                                                                        </div>
                                                                                        <div className="row">
                                                                                            <div className="col-12">
                                                                                                <h2 className="lead"><b>{item.namaPerumahan}</b></h2>

                                                                                                <h5 className="lead">{item.namaPengembang}  </h5>
                                                                                                {/* <p className="text-muted text-sm"><b>About: </b> Web Designer / UX / Graphic Artist / Coffee Lover </p> */}
                                                                                                <ul className="ml-4 mb-0 fa-ul text-muted">
                                                                                                    <li className="small"><span className="fa-li"><i className="fas fa-lg fa-building" /></span> {item.kelurahan}</li>
                                                                                                    {/* <li className="small"><span className="fa-li"><i className="fas fa-lg fa-phone" /></span> Phone #: + 800 - 12 12 23 52</li> */}
                                                                                                </ul>
                                                                                                {/* <br /> */}
                                                                                                {/* {/* <span className="badge badge-primary" style={{ marginRight: 5, marginLeft: 5 }}>1 Unit Subsidi</span> */}
                                                                                                <span className="badge badge-secondary" style={{ marginRight: 5, marginLeft: 5 }}>{item.npwp}</span>
                                                                                                <span className="badge badge-info" style={{ marginRight: 5, marginLeft: 5 }}>{item.idLokasi}</span>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="card-footer">
                                                                                    <div className="text-right">
                                                                                        {/* <a href="#" className="btn btn-sm bg-teal">
                                                                                            <i className="fas fa-comments" />
                                                                                        </a> */}



                                                                                        <button
                                                                                            type="button"
                                                                                            className="btn btn-sm btn-primary"
                                                                                            style={{ marginRight: 5, marginLeft: 5 }}
                                                                                            // onClick={() => handleApproveClick(item.id)}
                                                                                            onClick={(event) => {
                                                                                                user['perumahan_id'] = item.id;
                                                                                                user['perumahan_nama'] = item.nama_perumahan;
                                                                                                loginContext(user); // isi context                                                                            
                                                                                                navigate(`/igahp/admin/ManajemenLokasiApproval`);
                                                                                            }}
                                                                                        >Info Detail</button>




                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))}
                                                                </div>
                                                                <nav aria-label="Page navigation">
                                                                    <ul className="pagination">
                                                                        {Array.from({ length: totalPages }).map((_, index) => (
                                                                            <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                                                                <button className="page-link" onClick={() => handlePageChange(index + 1)}>
                                                                                    {index + 1}
                                                                                </button>
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </nav>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                            </div>
                                            {/* /.tab-pane */}
                                            <div className="tab-pane" id="timeline">

                                                <div className="row">

                                                    {isLoading ?
                                                        <p>Loading Data...</p> :

                                                        <>
                                                            <div className="col-12">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h3 className="card-title">Approval Lokasi </h3>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        <table id="example1" className="table table-bordered table-striped">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>NO</th>
                                                                                    <th>PENGEMBANG</th>
                                                                                    <th>PERUMAHAN</th>
                                                                                    <th>NPWP</th>
                                                                                    <th>VERIFIED</th>
                                                                                    <th>APPROVAL</th>
                                                                                    {/* <th>AKSI</th> */}
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>

                                                                                {data.map((item) => (
                                                                                    <tr key={item.id}>

                                                                                        {/* <td>{item.id}</td> */}
                                                                                        <td>{sequenceNumber++}</td>
                                                                                        <td>{item.namaPengembang}</td>
                                                                                        <td>{item.namaPerumahan}</td>
                                                                                        <td>{item.npwp}</td>
                                                                                        <td>{item.verified === true ? 'Verified' : 'Belum Verified'}</td>



                                                                                        <td>

                                                                                            <button type="button"
                                                                                                className="btn btn-danger float-right"
                                                                                                onClick={() => handleDeniedClick(item.id)}
                                                                                            >Denied</button>

                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary float-right"
                                                                                                style={{ marginRight: 5, marginLeft: 5 }}
                                                                                                onClick={() => handleApproveClick(item.id)}
                                                                                            >Approve</button>

                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-secondary float-right"
                                                                                                style={{ marginRight: 5, marginLeft: 5 }}
                                                                                                // onClick={() => handleApproveClick(item.id)}
                                                                                                onClick={(event) => {
                                                                                                    user['perumahan_id'] = item.id;
                                                                                                    user['perumahan_nama'] = item.nama_perumahan;
                                                                                                    loginContext(user); // isi context                                                                            
                                                                                                    navigate(`/igahp/admin/ManajemenLokasiApproval`);
                                                                                                }}
                                                                                            >Info</button>







                                                                                        </td>

                                                                                    </tr>
                                                                                ))}


                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>

                                                    }


                                                </div>


                                            </div>
                                            {/* /.tab-pane */}
                                            <div className="tab-pane" id="settings">

                                            </div>
                                            {/* /.tab-pane */}
                                        </div>
                                        {/* /.tab-content */}
                                    </div>{/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>




                        {/* /.row */}
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}





            {/* <Footer /> */}
        </>
    )
}

export default ManajemenLokasi
