import React, { useEffect, useRef } from 'react'
import NavBarAtas from './NavBarAtas';
import FooterFront from './FooterFront';
import Lottie from 'lottie-web';

const ComingSoon = () => {

    require("./../../css/common.css");

    const clockAnimation = useRef(null);

    useEffect(() => {
        Lottie.loadAnimation({
            animationData: require("./animation/clock_animation_green_pressed.json"),
            autoplay: true,
            container: clockAnimation.current,
            loop: true,
            renderer: "svg",
        })
    }, [])

    return (
        <>

            <div>
                <NavBarAtas showButton={true} />
                <section className="wrapper-coming-soon">
                    {/* <img src="background_illustration_coming_soon.svg" id="coming-soon-illustration" /> */}
                    <div className="wrapper-coming-soon-text">
                        <div ref={clockAnimation} className="clock-animation" />
                        <div className="one-green-m-regular neutral-90-text" id="coming-soon-1">Nyari rumah dari hulu ke hilir, jangan sampai kena PHP</div>
                        <div className="one-green-m-regular neutral-90-text"><b>Kami akan segera hadir, pantengin terus IGAHP</b></div>
                    </div>
                </section>
                <FooterFront />
            </div>



        </>
    )
}

export default ComingSoon
