import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';

import Swal from 'sweetalert2';

import LocationSelect from '../../shared/LocationSelect'

const ModalMinat = ({ onClose, namaPengembang, namaPerumahan, perumahanId, pengembangId }) => {


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const [optionTipeRumah, setOptionTipeRumah] = useState([]);
    const [selectedOptionTipeRumah, setSelectedOptionTipeRumah] = useState('');
    const [hargaRumah, setHargaRumah] = useState(0);
    const [namaLengkap, setNamaLengkap] = useState('');
    const [handphone, setHandphone] = useState('');

    const [selectedVillage, setSelectedVillage] = useState('');

    const [statusKawin, setStatusKawin] = useState('');

    const [jenisKelamin, setJenisKelamin] = useState('');

    const [nik, setNik] = useState('');

    const [tanggalLahir, setTanggalLahir] = useState('');

    const ignoreScroll = useRef(null);

    useEffect(() => {
        ignoreScroll.current && ignoreScroll.current.addEventListener("wheel", disableScroll);
    }, [ignoreScroll]);

    useEffect(() => {
        fetchOptionsFromApiTipeRumah()
            .then(data => setOptionTipeRumah(data))
            .catch(error => console.error('Error fetching options:', error));
    }, [perumahanId]);

    const disableScroll = (e) => {
        e.preventDefault();
    };

    const handleClose = () => {
        onClose();
    };


    const handleNikChange = (event) => {
        setNik(event.target.value);
    };

    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
    };


    const fetchOptionsFromApiTipeRumah = async () => {
        try {
            const response = await axios.get(`${apiUrl}/api/public/tipeRumahByPerumahanId/${perumahanId}`);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleOptionChangeTipeRumah = async (event) => {
        setSelectedOptionTipeRumah(event.target.value);
        try {
            const response = await axios.get(`${apiUrl}/api/public/getTipeRumahById2/${event.target.value}`);
            setHargaRumah(response.data.harga);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    const handleNamaLengkapChange = (event) => {
        setNamaLengkap(event.target.value);
    };

    const handleHandphoneChange = (event) => {
        setHandphone(event.target.value);
    };

    const resetFormFields = () => {
        setNamaLengkap('');
        setHandphone('');
        setSelectedOptionTipeRumah('');
        setHargaRumah(0);
        setNik('');
        setJenisKelamin('')
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        // Perform form submission, e.g., send the data to your API

        if (!statusKawin) {
            alert('Status pernikahan di pilih terlebih dahulu...')
            return;
        }

        if (!tanggalLahir) {
            alert('Tanggal Lahir harus diisi...');
            return;
        }


        if (!jenisKelamin) {
            alert('Jenis Kelamin harus diisi...');
            return;
        }


        //   if (!selectedOptionTipeRumah) {
        //     alert('Tipe Rumah dipilih terlebih dahulu...')
        //     return;
        //   }


        const formData = {
            namaLengkap,
            handphone,
            tipeRumahId: selectedOptionTipeRumah,
            pengembangId,
            hargaRumah,
            namaPengembang,
            perumahanId,
            nik,
            tanggalLahir,
            jenisKelamin,
            statusKawin,
            kodeWilayah : selectedVillage
        };

        // Use axios or other methods to submit the formData
        // console.log('Form data:', formData);

        try {
            // Make a POST request to your API
            const response = await axios.post(`${apiUrl}/api/public/minatUnit`, formData);

            // Handle successful response (e.g., display success message)
            // console.log('Response:', response.data);
            // alert('Data berhasil disimpan');


            Toast.fire({
                icon: 'success',
                title: 'Data  Minat berhasil disimpan'
            });
            resetFormFields();
            // handleClose();
        } catch (error) {
            alert('Terjadi kegagalan saat menyimpan data');
        }

    };

    return (
        <>
            <div className="modal fade" id="modal-minat" tabIndex={-1} role="dialog"
                aria-labelledby="modal-minat" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-md">
                    <div className="modal-content">

                        <section className="wrapper-modal-igahp center">
                            <div className="blur-background" />
                            <div className="wrapper-modal-minat">
                                <div className="wrapper-modal-header-minat">
                                    <div className="wrapper-modal-header-content">
                                        <div className="igahp-l-medium black-text">Isi Form Minat Rumah</div>
                                        <div className="igahp-s-regular neutral-60-text">Mohon isi data form dengan benar agar dapat kami hubungi.</div>
                                    </div>

                                    <div className="wrapper-close-modal" data-dismiss="modal" aria-label="Close" onClick={handleClose}><img src="ic_close_modal.svg" alt="Close" /></div>
                                    <div className="divider-neutral-30" />
                                </div>

                                <form onSubmit={handleFormSubmit}>
                                    <div className="wrapper-form-minat">
                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">Nama Lengkap</div>
                                            <input
                                                id="namaLengkap"
                                                type="text"
                                                placeholder="Budiyanto"
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                value={namaLengkap}
                                                onChange={handleNamaLengkapChange}
                                                required
                                            />
                                        </div>
                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">No. Handphone</div>
                                            <input
                                                id="handphone"
                                                ref={ignoreScroll}
                                                type="number"
                                                placeholder="081100010002"
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                value={handphone}
                                                onChange={handleHandphoneChange}
                                                required
                                            />
                                        </div>

                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">
                                                NIK</div>
                                            <input
                                                id="nik"
                                                ref={ignoreScroll}
                                                type="number"
                                                placeholder="1234567890000001"
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                value={nik}
                                                onChange={handleNikChange}
                                                required
                                            />
                                        </div>

                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">
                                                Jenis Kelamin</div>

                                            <select name="jenisKelamin"
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                value={jenisKelamin}
                                                onChange={(e) => setJenisKelamin(e.target.value)}>
                                                <option value="">Pilih</option>
                                                <option value="1">Pria</option>
                                                <option value="2">Wanita</option>
                                            </select>



                                        </div>


                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">
                                                Tanggal Lahir</div>

                                            <input
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                type="date"
                                                value={tanggalLahir}
                                                onChange={(e) => setTanggalLahir(e.target.value)}
                                                require
                                            />
                                        </div>


                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">
                                                Status Pernikahan</div>
                                            <select name="status NIkah"
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                value={statusKawin}
                                                onChange={(e) => setStatusKawin(e.target.value)}
                                            >
                                                <option value="">Pilih</option>
                                                <option value="1">Lajang</option>
                                                <option value="2">Menikah</option>
                                                <option value="3">Duda</option>
                                                <option value="4">Janda</option>
                                            </select>


                                            {/* <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">Tipe Unit</div>
                                            <select
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                value={selectedOptionTipeRumah}
                                                onChange={handleOptionChangeTipeRumah}
                                                required
                                            >
                                                <option value="">Pilih Tipe</option>
                                                {optionTipeRumah.map(option3 => (
                                                    <option key={option3.id} value={option3.id}>
                                                        {option3.nama}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">Harga Rumah</div>
                                            <input
                                                id="hargaRumah"
                                                type="text"
                                                placeholder="Rp XX.XXX.XXX.XXX,-"
                                                value={formatter.format(hargaRumah)}
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                disabled
                                            />
                                        </div>

                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">Nama Pengembang</div>
                                            <input
                                                id="namaPengembang"
                                                type="text"
                                                placeholder="PT. Pengembang"
                                                value={namaPengembang}
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                disabled
                                            />
                                        </div>

                                        <div className="igahp-form-item">
                                            <div className="igahp-m-regular neutral-90-text">Nama Perumahan</div>
                                            <input
                                                id="namaPerumahan"
                                                type="text"
                                                placeholder="Cinta Raya Abadi"
                                                value={namaPerumahan}
                                                className="form-border-rad-12 igahp-m-regular neutral-90-text"
                                                disabled
                                            />
                                        </div> */}


                                        </div>
                                    </div>

                                    <div className="igahp-form-item" id="location-form">
                                        <div className="igahp-m-regular neutral-90-text">
                                            Wilayah</div>

                                        <div className="row">
                                            <LocationSelect id="locationSelect1"
                                                villageCode={selectedVillage}
                                                onVillageChange={handleVillageChange} />
                                        </div>

                                    </div>
                                    <button className="btn-green-solid-pressed" type="submit">
                                        <div className="igahp-s-semi-bold white-text">Kirim</div>
                                        <img src="ic_message.svg" alt="Send" />
                                    </button>


                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalMinat;
