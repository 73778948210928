import { useEffect, useContext } from 'react'
import React from 'react'
import Login  from './Login'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const Logout = () => {
  const { logoutContext } = useContext(AuthContext);
  const navigate = useNavigate();


  useEffect(() => {
    logoutContext();
    navigate('/login')
  }, []);

  return;
}

export default Logout
