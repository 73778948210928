import React from 'react'
import Footer from '../Footer'
import { useEffect } from 'react';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import { useState } from 'react';

import DemoMapIndonesiaHighChart from './DemoMapIndonesiaHighChart';


const BankDashboard = () => {

    const [lokasiGps, setLokasiGps] = useState({});

    useEffect(() => {
        // const script = document.createElement('script');
        // script.src = "/js/bankdashboard.js"
        // script.async = true;
        // document.body.appendChild(script);


        // setLokasiGps({ lat: -6.266007, lng: 106.747293 });

        // return () => { script.remove(); }

    }, []);


    return (
        <>

            {/* <IndonesiaMap /> */}

            {/* <DemoMapIndonesiaHighChart/> */}

            {/* <MapComponent/> */}

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2 mt-1">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">DASHBOARD <b>BANK</b></h1>
                            </div>{/* /.col */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Dashboard</li>
                                </ol>
                            </div>
                            {/* /.content-card-body */}
                            {/* /.content-card-body */}
                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Content Header (Page header) */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* <div class="card-header">
                              <h3 class="card-title">TABEL REALISASI</h3>
                          </div> */}
                                {/* /card */}
                                <div className="card card-outline card-success">
                                    <div className="card-header ui-sortable-handle" style={{ cursor: 'move' }}>
                                        <div className="card-title">
                                            <h3 className="card-title">
                                                Peta Persebaran Rumah</h3>
                                        </div>
                                        <div className="card-tools">
                                            <ul className="nav nav-pills ml-auto">
                                                <li className="nav-item"><a className="nav-link active btn-default" href="#maps-canvas" data-toggle="tab">Canvas Maps
                                                </a></li>
                                                <li className="nav-item"><a className="nav-link btn-default" href="#maps-google" data-toggle="tab">
                                                    Google Maps</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="active tab-pane" id="maps-canvas">
                                                <div id="containerHighChart" style={{ overflow: 'auto' }}>
                                                    <div dir="ltr" className="highcharts-container" style={{ position: 'relative', overflow: 'hidden', width: 1550, height: 800, textAlign: 'left', lineHeight: 'normal', zIndex: 0, WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', userSelect: 'none', touchAction: 'manipulation', outline: 'none' }}>
                                                        <DemoMapIndonesiaHighChart />
                                                    </div>                                                    
                                                </div>                                                
                                            </div>
                                            
                                            {/* /.tab-pane */}
                                            <div className="tab-pane" id="maps-google">
                                                <div id="containerMapGoogle" data-highcharts-chart={0} style={{ overflow: 'auto' }}>
                                                    <div id="highcharts-8613zum-0" dir="ltr" className="highcharts-container" style={{ position: 'relative', overflow: 'hidden', width: 1550, height: 800, textAlign: 'left', lineHeight: 'normal', zIndex: 0, WebkitTapHighlightColor: 'rgba(0, 0, 0, 0)', userSelect: 'none', touchAction: 'manipulation', outline: 'none' }}>
                                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16328040.9491453!2d108.8514212402305!3d-2.393286107448966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2c4c07d7496404b7%3A0xe37b4de71badf485!2sIndonesia!5e0!3m2!1sen!2sid!4v1656923211387!5m2!1sen!2sid" width={1550} height={800} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* /.card-body */}
                                    {/* /.card */}
                                    {/* /.row */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                <br />
                {/* /.content-tabel */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card-header ui-sortable-handle" style={{ cursor: 'move' }}>
                            <div className="card-title">
                                <h4 className="text-muted fw-light">Data <b>Demand</b></h4>
                            </div>
                            <div className="card-tools">
                                <ul className="nav nav-pills ml-auto">
                                    <li className="nav-item"><a className="nav-link active btn-default" href="#rumahsubsidi-hijau" data-toggle="tab">Subsidi
                                        Hijau</a></li>
                                    <li className="nav-item"><a className="nav-link btn-default" href="#rumahsubsidi-konven" data-toggle="tab">
                                        Komersil Hijau</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="tab-content">
                                <div className="active tab-pane" id="rumahsubsidi-hijau">
                                    {/* row card baris 1 */}
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>TOTAL PENGEMBANG </p>
                                                    <h1 className="text"><b>100</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH PENGEMBANG MENGAJUKAN RUMAH HIJAU </p>
                                                    <h1 className="text"><b>20</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH PENGEMBANG DIPROSES</p>
                                                    <h1 className="text"><b>20</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH PENGEMBANG DISETUJUI</p>
                                                    <h1 className="text"><b>20</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="card card-outline card-success">
                                                <div className="card-header">
                                                    <h3 className="card-title">PROGRESS PEMBANGUNAN</h3>
                                                </div>
                                                <div className="card-body p-0">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: 10 }}>#</th>
                                                                <th>Nama Pengembang</th>
                                                                <th>Progress</th>
                                                                <th>Label</th>
                                                                <th>Total Unit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1.</td>
                                                                <td>Purimega Saranaland</td>
                                                                <td>
                                                                    <div className="progress progress-xs">
                                                                        <div className="progress-bar bg-success" style={{ width: '100%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-success">100%</span></td>
                                                                <td>100</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2.</td>
                                                                <td>Prima Permata Utama</td>
                                                                <td>
                                                                    <div className="progress progress-xs">
                                                                        <div className="progress-bar bg-warning" style={{ width: '80%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-warning">80%</span></td>
                                                                <td>80</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3.</td>
                                                                <td>Permata Graha HIjau</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-warning" style={{ width: '60%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-warning">60%</span></td>
                                                                <td>60</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4.</td>
                                                                <td>Alam Karya Indah</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-warning" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-warning">50%</span></td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5.</td>
                                                                <td>Surya Indah Hijau</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-info" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-info">40%</span></td>
                                                                <td>40</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6.</td>
                                                                <td>Green Permata Cahaya</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-info" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-info">30%</span></td>
                                                                <td>30</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7.</td>
                                                                <td>Karya Utama Hijau</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-info" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-info">20%</span></td>
                                                                <td>20</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="card-footer clearfix">
                                                    <div className="card-tools">
                                                        <ul className="pagination pagination-sm float-right ml-2">
                                                            <a href="#" className="btn btn-sm btn-secondary float-right">View All</a>
                                                        </ul>
                                                        <ul className="pagination pagination-sm float-right">
                                                            <li className="page-item"><a className="page-link" href="#">«</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">»</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h1 className="text"><b>100</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Berminat</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h1 className="text"><b>80</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Sedang Proses</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h1 className="text"><b>20</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Disetujui</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h1 className="text"><b>0</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Tidak Disetujui</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    {/* /.post statistik */}
                                    <div className="row">
                                        {/* Left col */}
                                        <section className="col-lg-6 connectedSortable">
                                            {/* Custom tabs (Charts with tabs)*/}
                                            <div className="card card-outline card-success">
                                                <div className="card-header">
                                                    <h3 className="card-title">Monitoring Minat Pembiayaan</h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-minus" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="chart">
                                                        <canvas id="stackedBarChart" style={{ minHeight: 330, height: 330, maxHeight: 330, maxWidth: '100%', display: 'block', boxSizing: 'border-box', width: 1146 }} width={1146} height={330} />
                                                    </div>
                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                        </section>
                                        <section className="col-lg-6 connectedSortable">
                                            {/* Custom tabs (Charts with tabs)*/}
                                            <div className="card card-success card-outline">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        Monitoring Kebutuhan Rumah</h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-minus" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="chartjs-size-monitor">
                                                        <div className="chartjs-size-monitor-expand">
                                                            <div className />
                                                        </div>
                                                        <div className="chartjs-size-monitor-shrink">
                                                            <div className />
                                                        </div>
                                                    </div>
                                                    <canvas id="pieChart" style={{ minHeight: 330, height: 330, maxHeight: 330, maxWidth: '100%', display: 'block', width: 330 }} width={388} height={250} className="chartjs-render-monitor" />
                                                </div>
                                                {/* /.d-flex */}
                                            </div>
                                        </section>
                                        {/* Main content */}
                                    </div>
                                    <br />
                                    <div className="row mb-2 mt-1">
                                        <div className="col-sm-6">
                                            <h4 className="text-muted fw-light">Data <b>Transaksi</b></h4>
                                        </div>{/* /.col */}
                                        {/* /.content-card-body */}
                                        {/* /.content-card-body */}
                                    </div>{/* /.row */}
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH BERKAS DALAM PROSES</p>
                                                    <h1 className="text"><b>5 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 500.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH BERKAS SELESAI PROSES</p>
                                                    <h1 className="text"><b>10 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 1.000.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH UNIT DALAM PROSES</p>
                                                    <h1 className="text"><b>5 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 500.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-success">
                                                <div className="inner">
                                                    <p>JUMLAH UNIT SELESAI PROSES</p>
                                                    <h1 className="text"><b>5 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 500.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>KETERSEDIAAN DANA TAHUN 2024</b></h5>
                                                    <small>Nilai Rupiah</small>
                                                    <h1 className="text"><b>RP. 100.000.000.000</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-donate" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>KETERSEDIAAN DANA BULAN INI (MARET)</b></h5>
                                                    <small>Nilai Rupiah</small>
                                                    <h1 className="text"><b>RP. 100.000.000.000</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-donate" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>TARGET UNIT TAHUN 2024</b></h5>
                                                    <h1 className="text"><b>200 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 200.000.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-home" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>REALISASI UNIT TAHUN 2024</b></h5>
                                                    <h1 className="text"><b>180 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 180.000.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-home" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    <br />
                                    {/* /.content-header */}
                                </div>
                                {/* /.tab-pane */}
                                <div className="tab-pane" id="rumahsubsidi-konven">
                                    {/* row card baris 2 */}
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>TOTAL PENGEMBANG </p>
                                                    <h1 className="text"><b>100</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH PENGEMBANG MENGAJUKAN RUMAH SUBSIDI KONVEN </p>
                                                    <h1 className="text"><b>20</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH PENGEMBANG DIPROSES</p>
                                                    <h1 className="text"><b>20</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH PENGEMBANG DISETUJUI</p>
                                                    <h1 className="text"><b>20</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-building" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="card card-outline card-lightblue">
                                                <div className="card-header">
                                                    <h3 className="card-title">PROGRESS PEMBANGUNAN</h3>
                                                </div>
                                                <div className="card-body p-0">
                                                    <table className="table">
                                                        <thead>
                                                            <tr>
                                                                <th style={{ width: 10 }}>#</th>
                                                                <th>Nama Pengembang</th>
                                                                <th>Progress</th>
                                                                <th>Label</th>
                                                                <th>Total Unit</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>1.</td>
                                                                <td>Purimega Saranaland</td>
                                                                <td>
                                                                    <div className="progress progress-xs">
                                                                        <div className="progress-bar bg-success" style={{ width: '100%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-success">100%</span></td>
                                                                <td>100</td>
                                                            </tr>
                                                            <tr>
                                                                <td>2.</td>
                                                                <td>Prima Permata Utama</td>
                                                                <td>
                                                                    <div className="progress progress-xs">
                                                                        <div className="progress-bar bg-warning" style={{ width: '80%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-warning">80%</span></td>
                                                                <td>80</td>
                                                            </tr>
                                                            <tr>
                                                                <td>3.</td>
                                                                <td>Permata Graha HIjau</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-warning" style={{ width: '60%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-warning">60%</span></td>
                                                                <td>60</td>
                                                            </tr>
                                                            <tr>
                                                                <td>4.</td>
                                                                <td>Alam Karya Indah</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-warning" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-warning">50%</span></td>
                                                                <td>50</td>
                                                            </tr>
                                                            <tr>
                                                                <td>5.</td>
                                                                <td>Surya Indah Hijau</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-info" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-info">40%</span></td>
                                                                <td>40</td>
                                                            </tr>
                                                            <tr>
                                                                <td>6.</td>
                                                                <td>Green Permata Cahaya</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-info" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-info">30%</span></td>
                                                                <td>30</td>
                                                            </tr>
                                                            <tr>
                                                                <td>7.</td>
                                                                <td>Karya Utama Hijau</td>
                                                                <td>
                                                                    <div className="progress progress-xs progress-striped active">
                                                                        <div className="progress-bar bg-info" style={{ width: '50%' }} />
                                                                    </div>
                                                                </td>
                                                                <td><span className="badge bg-info">20%</span></td>
                                                                <td>20</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="card-footer clearfix">
                                                    <div className="card-tools">
                                                        <ul className="pagination pagination-sm float-right ml-2">
                                                            <a href="#" className="btn btn-sm btn-secondary float-right">View All</a>
                                                        </ul>
                                                        <ul className="pagination pagination-sm float-right">
                                                            <li className="page-item"><a className="page-link" href="#">«</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">1</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">2</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">3</a></li>
                                                            <li className="page-item"><a className="page-link" href="#">»</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h1 className="text"><b>100</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Berminat</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h1 className="text"><b>80</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Sedang Proses</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h1 className="text"><b>20</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Disetujui</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h1 className="text"><b>0</b></h1>
                                                    <h6>JUMLAH</h6>
                                                    <p>Debitur Tidak Disetujui</p>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-users" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    {/* /.post statistik */}
                                    <div className="row">
                                        {/* Left col */}
                                        <section className="col-lg-6 connectedSortable">
                                            {/* Custom tabs (Charts with tabs)*/}
                                            <div className="card card-outline card-lightblue">
                                                <div className="card-header">
                                                    <h3 className="card-title">Monitoring Minat Pembiayaan</h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-minus" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="chart">
                                                        <canvas id="stackedBarChart" style={{ minHeight: 330, height: 330, maxHeight: 330, maxWidth: '100%', display: 'block', boxSizing: 'border-box', width: 1146 }} width={1146} height={330} />
                                                    </div>
                                                </div>
                                                {/* /.card-body */}
                                            </div>
                                        </section>
                                        <section className="col-lg-6 connectedSortable">
                                            {/* Custom tabs (Charts with tabs)*/}
                                            <div className="card card-lightblue card-outline">
                                                <div className="card-header">
                                                    <h3 className="card-title">
                                                        Monitoring Kebutuhan Rumah</h3>
                                                    <div className="card-tools">
                                                        <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                            <i className="fas fa-minus" />
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="chartjs-size-monitor">
                                                        <div className="chartjs-size-monitor-expand">
                                                            <div className />
                                                        </div>
                                                        <div className="chartjs-size-monitor-shrink">
                                                            <div className />
                                                        </div>
                                                    </div>
                                                    <canvas id="pieChart" style={{ minHeight: 330, height: 330, maxHeight: 330, maxWidth: '100%', display: 'block', width: 330 }} width={388} height={250} className="chartjs-render-monitor" />
                                                </div>
                                                {/* /.d-flex */}
                                            </div>
                                        </section>
                                        {/* Main content */}
                                    </div>
                                    <br />
                                    <div className="row mb-2 mt-1">
                                        <div className="col-sm-6">
                                            <h4 className="text-muted fw-light">Data <b>Transaksi</b></h4>
                                        </div>{/* /.col */}
                                        {/* /.content-card-body */}
                                        {/* /.content-card-body */}
                                    </div>{/* /.row */}
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH BERKAS DALAM PROSES</p>
                                                    <h1 className="text"><b>5 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 500.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH BERKAS SELESAI PROSES</p>
                                                    <h1 className="text"><b>10 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 1.000.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH UNIT DALAM PROSES</p>
                                                    <h1 className="text"><b>5 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 500.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <p>JUMLAH UNIT SELESAI PROSES</p>
                                                    <h1 className="text"><b>5 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 500.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-file-alt" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>KETERSEDIAAN DANA TAHUN 2024</b></h5>
                                                    <small>Nilai Rupiah</small>
                                                    <h1 className="text"><b>RP. 100.000.000.000</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-donate" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-info">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>KETERSEDIAAN DANA BULAN INI (MARET)</b></h5>
                                                    <small>Nilai Rupiah</small>
                                                    <h1 className="text"><b>RP. 100.000.000.000</b></h1>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-donate" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    <div className="row">
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>TARGET UNIT TAHUN 2024</b></h5>
                                                    <h1 className="text"><b>200 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 200.000.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-home" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                        <div className="col-md">
                                            {/* small box */}
                                            <div className="small-box bg-lightblue">
                                                <div className="inner">
                                                    <h5 className="text mb-4"><b>REALISASI UNIT TAHUN 2024</b></h5>
                                                    <h1 className="text"><b>180 </b></h1>
                                                    <small>Nilai Rupiah</small>
                                                    <h5 className="text"><b>Rp. 180.000.000.000</b></h5>
                                                </div>
                                                <div className="icon">
                                                    <i className="fas fa-home" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* ./col */}
                                    </div>
                                    <br />
                                </div>
                            </div>
                            {/* /.tab-content */}
                        </div>{/* /.card-body */}
                    </div>{/* /.row */}
                </div>
                {/* /.content-tabel */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row mb-2 mt-1">
                            <div className="col-sm-6">
                                <h4 className="text-muted fw-light">Data <b>TABEL</b></h4>
                            </div>{/* /.col */}
                            {/* /.content-card-body */}
                            {/* /.content-card-body */}
                        </div>{/* /.row */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-outline card-success">
                                    <div className="card-header">
                                        <div className="row">
                                            <div className="col-md-3">
                                                {/* Date range */}
                                                <div className="form-group">
                                                    <label>Pilih Tahun</label>
                                                    <select id="select-tahun" className="custom-select form-control select2-hidden-accessible" data-select2-id="select-tahun" tabIndex={-1} aria-hidden="true">
                                                        <option>2022</option>
                                                        <option>2021</option>
                                                        <option>2020</option>
                                                        <option>2019</option>
                                                        <option>2018</option>
                                                    </select>
                                                    {/* /.input group */}
                                                </div>
                                                {/* /.form group */}
                                            </div>
                                            <div className="col-md-3">
                                                {/* Date range */}
                                                <div className="form-group">
                                                    <label>Provinsi</label>
                                                    <select id="select-status" className="custom-select form-control select2-hidden-accessible" data-select2-id="select-status" tabIndex={-1} aria-hidden="true">
                                                        <option>Aceh</option>
                                                        <option>Bali</option>
                                                        <option>Bengkulu</option>
                                                        <option>Banten</option>
                                                        <option>DI Yogyakarta</option>
                                                        <option>DKI Jakarta</option>
                                                        <option>Gorontalo</option>
                                                        <option>Jambi</option>
                                                        <option>Jawa Barat</option>
                                                        <option>Jawa Timur</option>
                                                        <option>Jawa Tengah</option>
                                                        <option>Kepulauan Riau</option>
                                                        <option>Kepulauan Bank Belitung</option>
                                                        <option>Kalimantan Timur</option>
                                                        <option>Kalimantan Utara</option>
                                                        <option>Kalimantan Selatan</option>
                                                        <option>Kalimantan Barat</option>
                                                        <option>Lampung</option>
                                                        <option>Maluku</option>
                                                        <option>Maluku Utara</option>
                                                        <option>Nusa Tenggara Barat</option>
                                                        <option>Nusa Tenggara Timur</option>
                                                        <option>Papua</option>
                                                        <option>Papua Barat</option>
                                                        <option>Riau</option>
                                                        <option>Sulawesi Selatan</option>
                                                        <option>Sulawesi Barat</option>
                                                        <option>Sulawesi Tengah</option>
                                                        <option>Sulawesi Utara</option>
                                                        <option>Sulawesi Tenggara</option>
                                                        <option>Sumatera Barat</option>
                                                        <option>Sumatera Selatan</option>
                                                        <option>Sumatera Utara</option>
                                                    </select>
                                                    {/* /.form group */}
                                                </div>
                                                {/* /.col */}
                                            </div>
                                            {/* /.row */}
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <br />
                                                    <button className="btn btn-success" id="btn-download-excel" style={{ marginTop: 7 }}>
                                                        Submit
                                                    </button>
                                                    {/* <button class="btn btn-secondary" href="#" id="#" style="margin-top: 7px;">Download CSV Semua Data

                                                      </button> */}
                                                    {/* /.input group */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    {/* <div class="card-header">
                                      <h3 class="card-title">TABEL REALISASI</h3>
                                  </div> */}
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <table id="example1" className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>NO</th>
                                                    <th>PENGEMBANG</th>
                                                    <th>TARGET (UNIT)</th>
                                                    <th>REALISASI (UNIT)</th>
                                                    <th>% (UNIT)</th>
                                                    <th>TARGET (RUPIAH)</th>
                                                    <th>REALISASI (RUPIAH)</th>
                                                    <th>% (RUPIAH)</th>
                                                    <th>SISA TARGET (UNIT)</th>
                                                    <th>SISA TARGET (RUPIAH)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>3</td>
                                                    <td><b>PURIMEGA SARANALAND</b></td>
                                                    <td>1000</td>
                                                    <td>600</td>
                                                    <td>6%</td>
                                                    <td>16.000.000.000.000</td>
                                                    <td>600.000.000.000</td>
                                                    <td>66%</td>
                                                    <td>66.000</td>
                                                    <td>10.000.000.000</td>
                                                </tr>
                                                <tr>
                                                    <td>2</td>
                                                    <td><b>PERMATA GRAHA HIJAU</b></td>
                                                    <td>1000</td>
                                                    <td>600</td>
                                                    <td>6%</td>
                                                    <td>16.000.000.000.000</td>
                                                    <td>600.000.000.000</td>
                                                    <td>66%</td>
                                                    <td>66.000</td>
                                                    <td>10.000.000.000</td>
                                                </tr>
                                                <tr>
                                                    <td>1</td>
                                                    <td><b>ALAM KARYA GRAHA</b></td>
                                                    <td>1000</td>
                                                    <td>600</td>
                                                    <td>6%</td>
                                                    <td>16.000.000.000.000</td>
                                                    <td>600.000.000.000</td>
                                                    <td>66%</td>
                                                    <td>66.000</td>
                                                    <td>10.000.000.000</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                </section>
                {/* /.content-tabel */}
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}




        </>
    )
}

export default BankDashboard
