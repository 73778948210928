import React, { useEffect, useState, useContext } from 'react';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { AuthContext } from "../../../AuthContext";
import { Modal, Button, Form, Tabs, Tab } from 'react-bootstrap';
import exportCSV from 'json-to-csv-export';

import LocationSelect from '../../shared/LocationSelect'


const IgahpDataQuesionerTable2 = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [totalRows, setTotalRows] = useState(0);
    const [searchQuery, setSearchQuery] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [editData, setEditData] = useState({ id: '', nik: '', namaLengkap: '', handphone: '' });
    const [activeTab, setActiveTab] = useState('first');

    const [selectedVillage, setSelectedVillage] = useState('');

    const { user } = useContext(AuthContext);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
        setEditData(prevData => ({
            ...prevData,
            kodeWilayah: villageId
        }));

    };

    const statusPerkawinanMapping = {
        1: 'Belum Kawin',
        2: 'Kawin',
        3: 'Cerai Hidup',
        4: 'Cerai Mati',
    };

    const columns = [
        {
            name: 'No.',
            selector: (row, index) => (page - 1) * perPage + index + 1,
            sortable: true,
            width: '65px', // Set a reasonable width for small columns
        },
        {
            name: 'Nama Lengkap',
            selector: row => row.namaLengkap,
            sortable: true,
            wrap: true, // Wrap text in case it overflows
        },
        {
            name: 'NIK',
            selector: row => {
                const nik = row.nik;
                return nik ? nik.slice(0, 12) + nik.slice(12).replace(/\d/g, '*') : '';
            },
            sortable: true,
            wrap: true,
        },
        {
            name: 'Jenis Kelamin',
            selector: row => row.jenisKelamin == '1' ? 'Pria' : row.jenisKelamin == '2' ? 'Wanita' : 'Unknown',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Tanggal Lahir',
            selector: row => new Date(row.tanggalLahir).toLocaleDateString('en-CA'), // Format yyyy-MM-dd
            sortable: true,
            wrap: true,
        },
        {
            name: 'Status Perkawinan',
            selector: row => statusPerkawinanMapping[row.statusKawin] || 'Tidak Diketahui',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Tanggungan Jiwa',
            selector: row => row.tanggunganJiwa,
            sortable: true,
            wrap: true,
        },
        // {
        //     name: 'Handphone',
        //     selector: row => row.handphone,
        //     sortable: true,
        //     wrap: true,
        // },
        {
            name: 'Actions',
            cell: (row) => (
                <div>
                    <button
                        className="btn btn-primary btn-sm"
                        onClick={() => handleEdit(row)}>
                        Edit
                    </button>
                </div>
            ),
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            width: '100px', // You can also define fixed widths for specific columns
        },
    ];


    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/igahp/quesioner/data-quesioner-page2`, {
                params: {
                    page: page - 1,
                    size: perPage,
                    search: searchQuery,
                },
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                }
            });
            setData(response.data.content);
            setTotalRows(response.data.totalElements);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }
    };

    const fetchAllData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/igahp/quesioner/data-quesioner-page2-all`, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                }
            });
            setLoading(false);
            return response.data;
        } catch (error) {
            console.error('Error fetching all data:', error);
            setLoading(false);
            return [];
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, perPage, searchQuery]);

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        setPage(1);
    };

    const handleEdit = (row) => {
        const formattedTanggalLahir = new Date(row.tanggalLahir).toISOString().split('T')[0];

        setSelectedVillage(row.kodewilayah);
        setEditData(
            {
                id: row.id,
                namaDesa: row.namaDesa,
                rw: row.rw,
                rt: row.rt,
                alamatKtp: row.alamatKtp,
                alamatDomisili: row.alamatDomisili,
                nik: row.nik,
                namaLengkap: row.namaLengkap,
                nomorHandphone: row.nomorHandphone,
                nomorKk: row.nomorKk,
                jenisKelamin: row.jenisKelamin,
                tanggalLahir: formattedTanggalLahir,
                statusKawin: row.statusKawin,
                agama: row.agama,
                pendidikan: row.pendidikan,
                pekerjaan: row.pekerjaan,
                memilikiTabungan: row.memilikiTabungan,
                statusKepemilikanRumah: row.statusKepemilikanRumah,
                buktiKepemilikanRumah: row.buktiKepemilikanRumah,
                jumlahPenghuni: row.jumlahPenghuni,
                luasTanah: row.luasTanah,
                luasBangunan: row.luasBangunan,
                jenisAtap: row.jenisAtap,
                jenisDinding: row.jenisDinding,
                jenisLantai: row.jenisLantai,
                penerangan: row.penerangan,
                airMinum: row.airMinum,
                jenisKloset: row.jenisKloset,
                bbMemasak: row.bbMemasak,
                layakKonstruksi: row.layakKonstruksi,
                layakDensitas: row.layakDensitas,
                layakAirminum: row.layakAirminum,
                layakSanitasi: row.layakSanitasi,
                asetLahanlain: row.asetLahanlain,
                asetRumahlain: row.asetRumahlain,
                minatProgrampembiayaan: row.minatProgrampembiayaan,
                jenisPeminatanrumah: row.jenisPeminatanrumah,
                programPerumahan: row.programPerumahan,
                pilihanBankpelaksana: row.pilihanBankpelaksana,
                besaranCicilan: row.besaranCicilan,
                rencanaProgram: row.rencanaProgram,
                angkutanUmum: row.angkutanUmum,
                terminal: row.terminal,
                stasiun: row.stasiun,
                pasar: row.pasar,
                bank: row.bank,
                gerbangTol: row.gerbangTol,
                spbu: row.spbu,
                tk: row.tk,
                sd: row.sd,
                sltp: row.sltp,
                slta: row.slta,
                universitas: row.universitas,
                pendidikanLainnya: row.pendidikanLainnya,
                masjidMusholla: row.masjidMusholla,
                gereja: row.gereja,
                vihara: row.vihara,
                klenteng: row.klenteng,
                tempatIbadahLainnya: row.tempatIbadahLainnya,
                terjadiGenanganAir: row.terjadiGenanganAir,
                terjadiBanjir: row.terjadiBanjir,
                terjadiPutingBeliung: row.terjadiPutingBeliung,
                terjadiKeretakanTanah: row.terjadiKeretakanTanah,
                terjadiLongsor: row.terjadiLongsor,
                terjadiGempaBumi: row.terjadiGempaBumi

            }
        );


        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleModalSave = async () => {

        try {
            await axios.put(`${apiUrl}/api/igahp/quesioner/updateSemua/${editData.id}`, editData, {
                headers: {
                    Authorization: `Bearer ${user.accessToken}`,
                }
            });
            console.log('Data updated successfully:', editData);
            setShowModal(false);
            fetchData();  // Refresh data after saving

        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, type, checked, value } = e.target;

        setEditData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };


    const handleExportCSV = async () => {
        const allData = await fetchAllData();
        const headers = ['No.', 'Nama Lengkap', 'NIK', 'Jenis Kelamin', 'Tanggungan Jiwa', 'Handphone'];
        const itemsFormatted = allData.map((item, index) => ({
            'No.': index + 1,
            'Nama Lengkap': item.namaLengkap,
            'NIK': item.nik,
            'Jenis Kelamin': item.jenisKelamin,
            'Tanggungan Jiwa': item.tanggunganJiwa,
            'Handphone': item.handphone,
        }));

        exportCSV({ data: itemsFormatted, filename: 'pendataan_awal_bgh_all', headers });
    };

    return (
        <>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>List Pendataan Awal BGH</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">List Pendataan Awal</li>
                                </ol>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <input
                                    type="text"
                                    placeholder="Search..."
                                    className="form-control"
                                    value={searchQuery}
                                    onChange={handleSearch}
                                />
                            </div>
                            <div className="col-sm-6 text-right">
                                <button
                                    className="btn btn-success btn-sm"
                                    onClick={handleExportCSV}>
                                    Export CSV
                                </button>
                            </div>

                        </div>

                        <div>
                            <DataTable
                                columns={columns}
                                data={data}
                                progressPending={loading}
                                pagination
                                paginationServer
                                paginationTotalRows={totalRows}
                                onChangePage={page => setPage(page)}
                                onChangeRowsPerPage={perPage => setPerPage(perPage)}
                                responsive // Makes table responsive
                                dense // Decreases the row height for better fit
                            />
                        </div>
                    </div>
                </section>
            </div>

            <Modal show={showModal} onHide={handleModalClose} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="edit-data-tabs"
                        activeKey={activeTab}
                        onSelect={(k) => setActiveTab(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="first" title="Wilayah">
                            <Form>

                                <div className="form-group">
                                    <label>Kelurahan</label>
                                    <div className="row">
                                        <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                                    </div>
                                </div>


                                <Form.Group controlId="formNmDesa">
                                    <Form.Label>Nama Desa</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="namaDesa"
                                        value={editData.namaDesa}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNmRt">
                                    <Form.Label>RT</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="rt"
                                        value={editData.rt}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formNmRw">
                                    <Form.Label>RW</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="rw"
                                        value={editData.rw}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                <Form.Group controlId="formAlamatKtp">
                                    <Form.Label>Alamat Sesuai Ktp</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="alamatKtp"
                                        value={editData.alamatKtp}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formAlamatDomisili">
                                    <Form.Label>Alamat Domisili</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="alamatDomisili"
                                        value={editData.alamatDomisili}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                            </Form>
                        </Tab>
                        <Tab eventKey="second" title="Demografi">
                            <Form>
                                <Form.Group controlId="formNama">
                                    <Form.Label>Nama Lengkap</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="namaLengkap"
                                        value={editData.namaLengkap}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>


                                {/* <Form.Group controlId="formNik">
                                    <Form.Label>Nik</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nik"
                                        value={editData.nik}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group> */}

                                <Form.Group controlId="formNik">
                                    <Form.Label>Nik</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nik"
                                        value={`${editData.nik.slice(0, 12)}****`}
                                        readOnly
                                    />
                                </Form.Group>


                                <Form.Group controlId="formKk">
                                    <Form.Label>Nomor KK</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomorKk"
                                        value={editData.nomorKk}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formHp">
                                    <Form.Label>Nomor Hp</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="nomorHandphone"
                                        value={editData.nomorHandphone}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formJenisKelamin">
                                    <Form.Label>Jenis Kelamin</Form.Label>
                                    <Form.Select
                                        name="jenisKelamin"
                                        value={editData.jenisKelamin}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Jenis Kelamin</option>
                                        <option value="1">Laki-laki</option>
                                        <option value="2">Perempuan</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formTanggalLahir">
                                    <Form.Label>Tanggal Lahir</Form.Label>
                                    <Form.Control
                                        type="date"
                                        name="tanggalLahir"
                                        value={editData.tanggalLahir}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>


                                {/* Status Perkawinan */}
                                <Form.Group controlId="formStatusPerkawinan">
                                    <Form.Label>Status Perkawinan</Form.Label>
                                    <Form.Select
                                        name="statusKawin"
                                        value={editData.statusKawin}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Status Perkawinan</option>
                                        <option value="1">Belum Kawin</option>
                                        <option value="2">Kawin</option>
                                        <option value="3">Cerai Hidup</option>
                                        <option value="4">Cerai Mati</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Agama */}
                                <Form.Group controlId="formAgama">
                                    <Form.Label>Agama</Form.Label>
                                    <Form.Select
                                        name="agama"
                                        value={editData.agama}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Agama</option>
                                        <option value="1">Islam</option>
                                        <option value="2">Kristen</option>
                                        <option value="3">Katolik</option>
                                        <option value="4">Hindu</option>
                                        <option value="5">Buddha</option>
                                        <option value="6">Konghucu</option>
                                        <option value="7">Penghayat Kepercayaan</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formPendidikan">
                                    <Form.Label>Pendidikan</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="pendidikan"
                                        value={editData.pendidikan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Pendidikan</option>
                                        <option value="1">Tidak/Belum Sekolah</option>
                                        <option value="2">Tidak Tamat SD/Sederajat</option>
                                        <option value="3">Masih SD/Sederajat</option>
                                        <option value="4">Tamat SD/Sederajat</option>
                                        <option value="5">Masih SLTP/Sederajat</option>
                                        <option value="6">Tamat SLTP/Sederajat</option>
                                        <option value="7">Masih SLTA/Sederajat</option>
                                        <option value="8">Tamat SLTA/Sederajat</option>
                                        <option value="9">Masih PT/Sederajat</option>
                                        <option value="10">Tamat PT/Sederajat</option>
                                    </Form.Control>
                                </Form.Group>


                                <Form.Group controlId="formPekerjaan">
                                    <Form.Label>Pekerjaan (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="pekerjaan"
                                        value={editData.pekerjaan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Pekerjaan</option>
                                        <option value="1">1. Tidak/Belum Bekerja</option>
                                        <option value="2">2. Petani</option>
                                        <option value="3">3. Nelayan</option>
                                        <option value="4">4. Pedagang</option>
                                        <option value="5">5. Pejabat Negara</option>
                                        <option value="6">6. PNS/TNI/Polri</option>
                                        <option value="7">7. Pegawai Swasta</option>
                                        <option value="8">8. Wiraswasta</option>
                                        <option value="9">9. Pensiunan</option>
                                        <option value="10">10. Pekerja Lepas</option>
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group controlId="formRekeningTabungan">
                                    <Form.Label>Memiliki Rekening Tabungan (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="memilikiTabungan"
                                        value={editData.memilikiTabungan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Tab>

                        <Tab eventKey="third" title="Kepemilikan Rumah">
                            <Form>
                                {/* Status Kepemilikan Bangunan */}
                                <Form.Group controlId="formStatusKepemilikan">
                                    <Form.Label>Status Kepemilikan Bangunan tempat tinggal yang ditempati (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="statusKepemilikanRumah"
                                        value={editData.statusKepemilikanRumah}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Milik Sendiri</option>
                                        <option value="2">2. Kontrak/Sewa</option>
                                        <option value="3">3. Bebas Sewa</option>
                                        <option value="4">4. Menumpang</option>
                                        <option value="5">5. Dinas</option>
                                        <option value="6">6. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Bukti Kepemilikan */}
                                <Form.Group controlId="formJenisBuktiKepemilikan">
                                    <Form.Label>(Jika Ya) Apa jenis bukti kepemilikan tanah bangunan tempat tinggal ini (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="buktiKepemilikanRumah"
                                        value={editData.buktiKepemilikanRumah}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. SHM atas Nama Anggota Keluarga</option>
                                        <option value="2">2. SHM bukan a.n Anggota Keluarga dengan perjanjian pemanfaatan tertulis</option>
                                        <option value="3">3. SHM bukan a.n Anggota Keluarga tanpa perjanjian pemanfaatan tertulis</option>
                                        <option value="4">4. Sertifikat selain SHM (SHGB, SHSRS)</option>
                                        <option value="5">5. Surat Bukti Lainnya (Girik, Letter C, dll)</option>
                                        <option value="6">6. Tidak Punya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jumlah Penghuni */}
                                <Form.Group controlId="formJumlahPenghuni">
                                    <Form.Label>Jumlah Penghuni (masukkan jumlah)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="jumlahPenghuni"
                                        value={editData.jumlahPenghuni}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Luas Tanah */}
                                <Form.Group controlId="formLuasTanah">
                                    <Form.Label>Luas Tanah (m2)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="luasTanah"
                                        value={editData.luasTanah}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Luas Bangunan */}
                                <Form.Group controlId="formLuasBangunan">
                                    <Form.Label>Luas Bangunan Rumah (m2)</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="luasBangunan"
                                        value={editData.luasBangunan}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Jenis Atap */}
                                <Form.Group controlId="formJenisAtap">
                                    <Form.Label>Jenis Atap (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="jenisAtap"
                                        value={editData.jenisAtap}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Beton</option>
                                        <option value="2">2. Genteng</option>
                                        <option value="3">3. Asbes/Seng</option>
                                        <option value="4">4. Kayu/Sirap</option>
                                        <option value="5">5. Bambu</option>
                                        <option value="6">6. Jerami/ijuk/rumbia/daun-daunan</option>
                                        <option value="7">7. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Dinding */}
                                <Form.Group controlId="formJenisDinding">
                                    <Form.Label>Jenis Dinding (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="jenisDinding"
                                        value={editData.jenisDinding}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Tembok</option>
                                        <option value="2">2. Kayu/Papan</option>
                                        <option value="3">3. Seng</option>
                                        <option value="4">4. Bambu</option>
                                        <option value="5">5. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Lantai */}
                                <Form.Group controlId="formJenisLantai">
                                    <Form.Label>Jenis Lantai (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="jenisLantai"
                                        value={editData.jenisLantai}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Keramik/Granit/Marmer/Ubin/Tegel/Teraso</option>
                                        <option value="2">2. Semen</option>
                                        <option value="3">3. Kayu/Papan</option>
                                        <option value="4">4. Bambu</option>
                                        <option value="5">5. Tanah</option>
                                        <option value="6">6. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Penerangan */}
                                <Form.Group controlId="formPenerangan">
                                    <Form.Label>Penerangan (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="penerangan"
                                        value={editData.penerangan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Listrik Pribadi 900 Watt</option>
                                        <option value="2">2. Listrik Pribadi diatas 900 Watt</option>
                                        <option value="3">3. Genset/Solar Cell</option>
                                        <option value="4">4. Listrik Bersama</option>
                                        <option value="5">5. Non Listrik</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Air Minum */}
                                <Form.Group controlId="formAirMinum">
                                    <Form.Label>Air Minum (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="airMinum"
                                        value={editData.airMinum}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Air Kemasan/Isi Ulang</option>
                                        <option value="2">2. Ledeng/PAM</option>
                                        <option value="3">3. Sumur BOR</option>
                                        <option value="4">4. Sumur Terlindungi</option>
                                        <option value="5">5. Sumur Tidak Terlindungi</option>
                                        <option value="6">6. Air Permukaan (Sungai, Danau, dll)</option>
                                        <option value="7">7. Air Hujan</option>
                                        <option value="8">8. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Kloset */}
                                <Form.Group controlId="formJenisKloset">
                                    <Form.Label>Jenis Kloset (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="jenisKloset"
                                        value={editData.jenisKloset}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya dengan Septic Tank</option>
                                        <option value="2">2. Ya tanpa Septic Tank</option>
                                        <option value="3">3. Tidak Jamban Umum/Bersama</option>
                                        <option value="4">4. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Bahan Bakar Memasak */}
                                <Form.Group controlId="formBahanBakar">
                                    <Form.Label>Bahan Bakar Memasak (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="bbMemasak"
                                        value={editData.bbMemasak}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Listrik/Gas</option>
                                        <option value="2">2. Minyak Tanah</option>
                                        <option value="3">3. Arang/Kayu</option>
                                        <option value="4">4. Lainnya</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Kelayakan Konstruksi */}
                                <Form.Group controlId="formKelayakanKonstruksi">
                                    <Form.Label>Kelayakan Konstruksi (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="layakKonstruksi"
                                        value={editData.layakKonstruksi}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Kelayakan Kepadatan Ruang */}
                                <Form.Group controlId="formKepadatanRuang">
                                    <Form.Label>Kelayakan Kepadatan Ruang (Densitas) (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="layakDensitas"
                                        value={editData.layakDensitas}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Kelayakan Air Minum */}
                                <Form.Group controlId="formKelayakanAirMinum">
                                    <Form.Label>Kelayakan Air Minum (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="layakAirminum"
                                        value={editData.layakAirminum}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Kelayakan Sanitasi */}
                                <Form.Group controlId="formKelayakanSanitasi">
                                    <Form.Label>Kelayakan Sanitasi (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="layakSanitasi"
                                        value={editData.layakSanitasi}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Memiliki Aset Lahan di Lokasi Lain */}
                                <Form.Group controlId="formAsetLahan">
                                    <Form.Label>Memiliki Aset Lahan di Lokasi Lain (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="asetLahanlain"
                                        value={editData.asetLahanlain}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Memiliki Rumah di Lokasi Lain */}
                                <Form.Group controlId="formRumahLain">
                                    <Form.Label>Memiliki Rumah di Lokasi Lain (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="asetRumahlain"
                                        value={editData.asetRumahlain}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>
                        </Tab>

                        <Tab eventKey="fourth" title="Peminatan Program">

                            <Form>
                                {/* Minat Program Pembiayaan Perumahan */}
                                <Form.Group controlId="formMinatPembiayaan">
                                    <Form.Label>Minat Program Pembiayaan Perumahan (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="minatProgrampembiayaan"
                                        value={editData.minatProgrampembiayaan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Jenis Peminatan Rumah */}
                                <Form.Group controlId="formJenisPeminatanRumah">
                                    <Form.Label>Jenis Peminatan Rumah (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="jenisPeminatanrumah"
                                        value={editData.jenisPeminatanrumah}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Milik</option>
                                        <option value="2">2. Sewa</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Pilih Program Perumahan */}
                                <Form.Group controlId="formPilihProgramPerumahan">
                                    <Form.Label>Pilih Program Perumahan (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="programPerumahan"
                                        value={editData.programPerumahan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Pembiayaan Pemilikan Rumah</option>
                                        <option value="2">2. Pembiayaan Pembangunan Rumah</option>
                                        <option value="3">3. Pembiayaan Perbaikan Rumah</option>
                                        <option value="4">4. Sewa Hunian</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Pilih Bank Pelaksana */}
                                <Form.Group controlId="formPilihBankPelaksana">
                                    <Form.Label>Pilih Bank Pelaksana (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="pilihanBankpelaksana"
                                        value={editData.pilihanBankpelaksana}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Bank BTN</option>
                                        <option value="2">2. Bank BRI / Lainnya sebutkan</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Kesediaan Besar Cicilan */}
                                <Form.Group controlId="formCicilan">
                                    <Form.Label>Kesediaan Besar Cicilan yang diinginkan (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="besaranCicilan"
                                        value={editData.besaranCicilan}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. &lt; 500rb</option>
                                        <option value="2">2. 500 ribu - 750 ribu</option>
                                        <option value="3">3. 750 ribu - 1 juta</option>
                                        <option value="4">4. &gt; 1 juta</option>
                                    </Form.Select>
                                </Form.Group>

                                {/* Rencana Program */}
                                <Form.Group controlId="formRencanaProgram">
                                    <Form.Label>Rencana Program (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="rencanaProgram"
                                        value={editData.rencanaProgram}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. 2025</option>
                                        <option value="2">2. 2026</option>
                                        <option value="3">3. 2027</option>
                                        <option value="4">4. Belum Tahu Kapan</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>


                        </Tab>
                        <Tab eventKey="fifth" title="Kawasan Iklim">

                            <Form>
                                {/* Sarana Transportasi Umum */}
                                <Form.Group controlId="formSaranaTransportasi">
                                    <Form.Label>Sarana Transportasi</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Angkutan Umum"
                                        name="angkutanUmum"
                                        checked={editData.angkutanUmum}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Terminal"
                                        name="terminal"
                                        checked={editData.terminal}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Stasiun"
                                        name="stasiun"
                                        checked={editData.stasiun}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Pasar"
                                        name="pasar"
                                        checked={editData.pasar}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Bank"
                                        name="bank"
                                        checked={editData.bank}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Gerbang Tol"
                                        name="gerbangTol"
                                        checked={editData.gerbangTol}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SPBU"
                                        name="spbu"
                                        checked={editData.spbu}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Sarana Pendidikan */}
                                <Form.Group controlId="formSaranaPendidikan">
                                    <Form.Label>Sarana Pendidikan</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="TK"
                                        name="tk"
                                        checked={editData.tk}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SD"
                                        name="sd"
                                        checked={editData.sd}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SLTP"
                                        name="sltp"
                                        checked={editData.sltp}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="SLTA"
                                        name="slta"
                                        checked={editData.slta}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="universitas"
                                        name="universitas"
                                        checked={editData.universitas}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Lainnya"
                                        name="pendidikanLainnya"
                                        checked={editData.pendidikanLainnya}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Sarana Tempat Ibadah */}
                                <Form.Group controlId="formSaranaIbadah">
                                    <Form.Label>Sarana Tempat Ibadah</Form.Label>
                                    <Form.Check
                                        type="checkbox"
                                        label="Masjid/Musholla"
                                        name="masjidMusholla"
                                        checked={editData.masjidMusholla}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Gereja"
                                        name="gereja"
                                        checked={editData.gereja}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Vihara"
                                        name="vihara"
                                        checked={editData.vihara}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Klenteng"
                                        name="klenteng"
                                        checked={editData.klenteng}
                                        onChange={handleInputChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label="Lainnya"
                                        name="tempatIbadahLainnya"
                                        checked={editData.tempatIbadahLainnya}
                                        onChange={handleInputChange}
                                    />
                                </Form.Group>

                                {/* Kondisi Iklim dalam 3 Tahun Terakhir */}
                                <Form.Group controlId="formGenanganAir">
                                    <Form.Label>Apakah pernah terjadi Genangan Air (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="terjadiGenanganAir"
                                        value={editData.terjadiGenanganAir}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formBanjir">
                                    <Form.Label>Apakah pernah terjadi Banjir (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="terjadiBanjir"
                                        value={editData.terjadiBanjir}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formPutingBeliung">
                                    <Form.Label>Apakah pernah terjadi Angin Puting Beliung (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="terjadiPutingBeliung"
                                        value={editData.terjadiPutingBeliung}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formKeretakanTanah">
                                    <Form.Label>Apakah pernah terjadi Keretakan Tanah (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="terjadiKeretakanTanah"
                                        value={editData.terjadiKeretakanTanah}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formLongsor">
                                    <Form.Label>Apakah pernah terjadi Longsor (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="terjadiLongsor"
                                        value={editData.terjadiLongsor}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>

                                <Form.Group controlId="formGempaBumi">
                                    <Form.Label>Apakah pernah terjadi Gempa Bumi (isikan kode)</Form.Label>
                                    <Form.Select
                                        name="terjadiGempaBumi"
                                        value={editData.terjadiGempaBumi}
                                        onChange={handleInputChange}
                                    >
                                        <option value="">Pilih Opsi</option>
                                        <option value="1">1. Ya</option>
                                        <option value="2">2. Tidak</option>
                                    </Form.Select>
                                </Form.Group>
                            </Form>


                        </Tab>
                    </Tabs>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleModalSave}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default IgahpDataQuesionerTable2;
