import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import MenuPerumahanDetail from '../MenuPerumahanDetail'
import { useHistory } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// import SidebarPengembang from './SidebarPengembang';
import jwt_decode from 'jwt-decode'

import axios from 'axios';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import { AuthContext } from '../../../AuthContext';
import { useContext } from 'react';
import LocationSelect from '../../shared/LocationSelect';

import Swal from 'sweetalert2';




const ModalKantorPemasaran = ({ onClose, dataPemasaran }) => {


    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        
    };


    const [isChecked, setIsChecked] = useState(false);

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000
    });



    const [selectedVillage, setSelectedVillage] = useState('');


    const [formData, setFormData] = useState({
        alamat: '',
        nomor: '',
        kodeWilayah: '',
        noTelp: '',
        email: '',
        website: '',
        fax: '',
        isKantorUtama: false,
    });

    const handleVillageChange = (villageId) => {
        setSelectedVillage(villageId);
        setFormData({ ...formData, kodeWilayah: villageId });
    };

    useEffect(() => {
        const handleEscapeKey = (event) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };
        document.addEventListener('keydown', handleEscapeKey);
        return () => {
            document.removeEventListener('keydown', handleEscapeKey);
        };
    }, [onClose]);


    const [isButtonProcess, setIsButtonProcess] = useState(false);


    const handleClose = () => {
        onClose();
    };

    const formatter = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        currencyDisplay: 'symbol',
        maximumFractionDigits: 0,
    });

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    const [isTokenExpired, setIsTokenExpired] = useState(false);


    let refreshToken = null;

    const navigate = useNavigate();

    const { user, setUser } = useContext(AuthContext);


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    useEffect(() => {

        (async () => {


            // if (user.pemasaranrumah_state='edit') {
                setSelectedVillage(user.pemasaranrumah_wilayah)
                formData.alamat = user.pemasaranrumah_alamat
                formData.email = user.pemasaranrumah_email
                formData.noTelp = user.pemasaranrumah_notelp;
                formData.nomor = user.pemasaranrumah_nomor;
                formData.website = user.pemasaranrumah_website;
                setIsChecked(user.pemasaranrumah_kantorutama);
            // }

        })();

    }, []);


    const handleSubmit = async (e) => {


        setIsButtonProcess(true);

        e.preventDefault();

        let tambahData = false;
        if (user.pemasaranrumah_state == 'edit') {
            tambahData = false;
        } else {
            tambahData = true;
        }
        console.log('isChecked submit')
        console.log(isChecked)
        // Prepare the data to be sent to the API
        const requestData = {
            id: user.pemasaranrumah_id,
            alamat: formData.alamat,
            nomor: formData.nomor,
            kodeWilayah: formData.kodeWilayah,
            noTelp: formData.noTelp,
            email: formData.email,
            website: formData.website,
            fax: formData.fax,
            kantorUtama: isChecked,
            perumahan_id: user.perumahan_id,
            tambahData: tambahData
        };



        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }


        console.log(requestData);
        // Send formData to API
        await fetch(apiUrl + '/api/igahp/pengembang/lokasi/simpanKantorPemasaran', {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
            }
        })
            .then(response => response.json())
            .then(data => {
                // Handle API response
                // console.log(data);
                // alert(data.status);

                Toast.fire({
                    icon: 'success',
                    title: data.status
                });

                setIsButtonProcess(false);
                // navigate('/igahp/pengembang/PengembangKantorPemasaran');
            })
            .catch(error => {
                // Handle error

                setIsButtonProcess(false);
                console.error(error);

                Toast.fire({
                    icon: 'error',
                    title: error
                });
            });



    };


    return (
        <>


            <div className="modal fade" id="ModalKantorPemasaran" tabIndex={-1} role="dialog"
                aria-labelledby="ModalKantorPemasaranLabel" aria-hidden="true" data-backdrop="static">

                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Input Data</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="modal-body">
                                <div className="form-group">
                                    <h6 className="m-0">Kantor Pemasaran </h6>
                                    <p className="text-muted mb-0"><small>Isi data tambah kantor pemasaran</small></p>
                                </div>


                                <div className="form-group">
                                    {/* <label htmlFor="exampleInputKelurahan">Kelurahan</label> */}
                                    <label>Lokasi Kantor</label>
                                    <div className="row">
                                        <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                                    </div>
                                    {/* <input type="text" name="kelurahan" className="form-control" id="exampleInputKelurahan" /> */}
                                    {/* <input
                                                    type="text"
                                                    className="form-control"
                                                    id="kodeWilayah"
                                                    name="kodeWilayah"
                                                    value={formData.kodeWilayah}
                                                    onChange={handleChange}
                                                    // required
                                                /> */}
                                </div>

                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">No. Chat WA (untuk calon debitur)</label>
                                            {/* <input type="number" className="form-control" id="settings" placeholder="021-88xxxxx" /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="noTelp"
                                                name="noTelp"
                                                value={formData.noTelp}
                                                onChange={handleChange}
                                                maxLength={15}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Email</label>
                                            {/* <input type="text" className="form-control" id="settings" placeholder="onegreen@mail.com" /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Website</label>
                                            {/* <input type="name" className="form-control" id="settings" placeholder="www.indonesiahijau.id" /> */}

                                            <input
                                                type="text"
                                                className="form-control"
                                                id="website"
                                                name="website"
                                                value={formData.website}
                                                onChange={handleChange}
                                                required
                                            />
                                            <label>
                                                <input
                                                    type="checkbox"
                                                    checked={isChecked}
                                                    onChange={handleCheckboxChange}
                                                    style={{ marginRight: '10px' }} // You can adjust the margin as needed
                                                />
                                                Kantor Utama</label>



                                        </div>
                                    </div>



                                </div>

                                <div className="row">
                                    <div className="col-sm-9">
                                        <div className="form-group">
                                            <label>Alamat Kantor</label>
                                            {/* <textarea className="form-control" rows={3} placeholder="Masukan Alamat Lengkap Kantor" defaultValue={""} /> */}
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="alamat"
                                                name="alamat"
                                                value={formData.alamat}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-3">
                                        <div className="form-group">
                                            <label htmlFor="dafaultLabel" className="text-gray-dark">Nomor Kantor</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="nomor"
                                                name="nomor"
                                                value={formData.nomor}
                                                onChange={handleChange}
                                                required
                                            />
                                            {/* <div className="input-group">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text">No.</span>
                                                </div>
                                                <input type="text" className="form-control" id="settings" placeholder={'00'} />
                                            </div> */}
                                        </div>
                                    </div>
                                </div>



                            </div>

                            <div class="modal-footer justify-content-end align-content-between">

                                <button type="submit" disabled={isButtonProcess} className="btn btn-success">Simpan</button>
                            </div>

                        </form>


                    </div>
                </div>

            </div>
        </>
    )
}

export default ModalKantorPemasaran
