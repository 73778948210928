import React from 'react'
import BankDashboard from './BankDashboard'

const BankContentDepan = () => {
  return (
    <>
      <BankDashboard />
    </>
  )
}

export default BankContentDepan
