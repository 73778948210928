
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; 
import NavBarAtas from './NavBarAtas';

const EmailVerification = () => {
    const [kode, setKode] = useState('');
    const [status, setStatus] = useState('');
    
    const navigate = useNavigate()
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        setKode(searchParams.get('kode'));
        const kod = searchParams.get('kode');
        const activationEndpoint = apiUrl + '/api/auth/aktivasi?kode=' + kod;

        const activateUser = async () => {
            try {
                const response = await fetch(activationEndpoint);
                if (response.ok) {
                    const data = await response.json();
                    setStatus('Activation successful');
                    setTimeout(() => {
                        navigate('/login');
                    }, 3000);
                } else {
                    setStatus('Activation failed');
                    setTimeout(() => {
                        navigate('/register');
                    }, 30000);
                }
            } catch (error) {
                setStatus('Error during activation');
                setTimeout(() => {
                    navigate('/register');
                }, 30000);
            }
        };

        activateUser();

        return () => { }; // No need to clear the timeout here

    }, []);

    require("./../../css/common.css");

    return (
        <>            
            <NavBarAtas  showButton={false} />

            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div className="lockscreen-wrapper">
                <div className="lockscreen-footer text-center">
                    <h4>Email Verification</h4>
                    <h1>{status}</h1>
                    Copyright © 2023 <b><a href="https://indonesiahijau.id" className="text-black">Indonesia Hijau</a></b><br />
                    All rights reserved
                </div>
            </div>
        </>
    )
}

export default EmailVerification
