import React from 'react'
import Footer from '../Footer'
import { AuthContext } from '../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';

const ManajemenPenugasan = () => {

    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [options, setOptions] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedRoleName, setSelectedRoleName] = useState('');

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    // useEffect(() => {
    //   fetchData();  
    // }, []);



    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');
            // fetchDataRoles();
            fetchData();
        }
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);


    const fetchDataRoles = () => {
        // Replace 'API_URL' with your actual API endpoint
        fetch(apiUrl+'/api/public/getAllRoles')
            .then(response => response.json())
            .then(data => setOptions(data))
            .catch(error => console.error('Error fetching data:', error));
    };


    const fetchData = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl+'/api/igahp/getAllPenugasan', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const handleAddUsers = () => {
        // user['pemasaranrumah_state'] = 'add';
        // user['pemasaranrumah_id'] = null
        // loginContext(user);

        navigate(`/igahp/admin/ManajemenUsersTambah`);
    };



    const handleSelectRoleChange = event => {
        setSelectedRole(event.target.value);
        setSelectedRoleName(event.target.options[event.target.selectedIndex].text);
        // console.log('Selected role:', selectedRole);
        // console.log('Selected role name:', event.target.options[event.target.selectedIndex].text);
    };

    const handleSubmitAdd = event => {
        event.preventDefault();
        // Handle form submission or any other logic here

        if (selectedRole === '') {
            alert('Pilih Role terlebih dahulu...');
            return;
        }
        user['rolestate'] = 'add';
        user['roleid'] = selectedRole;
        user['rolename'] = selectedRoleName;
        loginContext(user);

        navigate(`/igahp/admin/ManajemenUsersTambah`);
        // console.log('Selected option:', selectedRole);
    };




    const handleApproveClick = async (id) => {
        const confirmApprove = window.confirm("Are you sure you want to Approve this user?");


        if (confirmApprove) {


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }

            doUpdateStatus(id, '1')


        } else {

        }
    }

    const doUpdateStatus = async (id, status) => {

        const requestBody = {
            user_id: id,
            status: status
        };


        try {
            await fetch(apiUrl+'/api/igahp/updateStatusUser', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),

            })
                .then(response => response.json())
                .then(data => {

                    alert(data.message);
                    fetchData();
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert(data.status);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }

    }

    const handleDeniedClick = async (id) => {
        const confirmApprove = window.confirm("Are you sure you want to Non-Aktif this user?");


        if (confirmApprove) {


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }



        } else {

        }
    }

    const handleAddPenugasan = () => {
        // user['pemasaranrumah_state'] = 'add';
        // user['pemasaranrumah_id'] = null
        // loginContext(user);

        navigate(`/igahp/admin/ManajemenPenugasanTambah`);
    };

    let sequenceNumber = 1;

    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Manajemen Penugasan</h1>                                
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Manajemen Penugasan</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">


                        <div className="row">

                            {isLoading ?
                                <p>Loading Data...</p> :

                                <>
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="card-header">
                                                {/* <h3 className="card-title">Penugasan </h3> */}
                                                <button type="submit" onClick={handleAddPenugasan} className="btn btn-primary">Tambah Penugasan</button>
                                            </div>
                                            <div className="card-body">
                                                <table id="example1" className="table table-bordered table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>NO</th>
                                                            <th>Pengembang</th>
                                                            <th>Perumahan</th>
                                                            <th>Tipe</th>
                                                            <th>Blok</th>
                                                            <th>No Rumah</th>
                                                            <th>Id Rumah</th>
                                                            <th>Petugas</th>
                                                            <th>Email Petugas</th>
                                                            <th>Aksi</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {data.map((item) => (
                                                            <tr key={item.idPenugasan}>
                                                                {/* <td>{item.idPenugasan}</td> */}
                                                                <td>{sequenceNumber++}</td>
                                                                <td>{item.namaPengembang}</td>
                                                                <td>{item.namaPerumahan}</td>
                                                                <td>{item.tipeRumah}</td>
                                                                <td>{item.namaBlok}</td>
                                                                <td>{item.nomorRumah}</td>
                                                                <td>{item.idRumahTapak}</td>
                                                                <td>{item.petugasUser}</td>
                                                                <td>{item.petugasEmail}</td>
                                                                
                                                                <td>

                                                                    {/* <button type="button"
                                                                        className="btn btn-danger float-right"
                                                                        onClick={() => handleDeniedClick(item.id)}
                                                                    >Denied</button>

                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary float-right"
                                                                        style={{ marginRight: 5, marginLeft: 5 }}
                                                                        onClick={() => handleApproveClick(item.id)}
                                                                    >Approve</button> */}


                                                                    <button type="button"
                                                                        className="btn btn-danger float-right"
                                                                        onClick={() => handleDeniedClick(item.id)}
                                                                    >Hapus </button>


                                                                </td>

                                                            

                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </>

                            }


                        </div>



                        {/* <div className="row">                            
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#activity" data-toggle="tab">Activity</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content">
                                            <div className="active tab-pane" id="activity">                                                
                                            </div>
                                            <div className="tab-pane" id="timeline">
                                            </div>                                            
                                            <div className="tab-pane" id="settings">
                                            </div>                                            
                                        </div>                                        
                                    </div>
                                </div>                                
                            </div>                            
                        </div> */}



                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            {/* <Footer /> */}
        </>
    )
}

export default ManajemenPenugasan
