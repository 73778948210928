import React from 'react'

import LocationSelect from '../../shared/LocationSelect'
import { useState } from 'react'
import { useRef } from 'react'
import jwt_decode from 'jwt-decode'
import axios from 'axios';
import { AuthContext } from '../../../AuthContext'
import { useContext } from 'react'
import { refreshTokenRequest } from '../../refreshTokenRequest'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react'
import InputMask from 'react-input-mask';
import Swal from 'sweetalert2';

const ModalProfile = ({ onClose }) => {


  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };
    document.addEventListener('keydown', handleEscapeKey);
    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [onClose]);



  const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  const [modalVisible, setModalVisible] = useState(false);

  const [namaDirektur, setNamaDirektur] = useState('');
  const [namaPerusahaan, setNamaPerusahaan] = useState('');
  const [npwpPerusahaan, setNpwpPerusahaan] = useState('');
  const [emailPerusahaan, setEmailPerusahaan] = useState('');
  const [websitePerusahaan, setWebsitePerusahaan] = useState('');
  const [alamatPerusahaan, setAlamatPerusahaan] = useState('');
  const [kodePos, setKodePos] = useState('');
  const [rt, setRt] = useState('');
  const [rw, setRw] = useState('');
  const [npwp, setNpwp] = useState('');
  const [telpKantor, setTelpKantor] = useState('');
  const [telpDirektur, setTelpDirektur] = useState('');
  const [namaPicPemohon, setNamaPicPemohon] = useState('');
  const [telpPicPemohon, setTelpPicPemohon] = useState('');
  const [dokAktePendirian, setDokAktePendirian] = useState(null);
  const [dokNib, setDokNib] = useState(null);
  const [dokNpwp, setDokNpwp] = useState(null);

  let refreshToken = null;

  const apiUrl = process.env.REACT_APP_API_ENDPOINT;


  const handleVillageChange = (villageId) => {
    setSelectedVillage(villageId);
  };


  // const handleOptionAsosiasiChange = event => {
  //   setSelectedAsosiasiOption(event.target.value);
  // };

  const [wilayah, setWilayah] = useState('');
  const { user, setUser } = useContext(AuthContext);
  const [selectedVillage, setSelectedVillage] = useState('');
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [selectedAsosiasiOption, setSelectedAsosiasiOption] = useState('');

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {

    (async () => {
      // await fetchOptionsFromApi();

      await fetchOptionsFromApi()
        .then(data => setOptions(data))
        .catch(error => console.error('Error fetching options:', error));

      await fetchData();



      setIsLoading(false);
      setIsTokenExpired(false);
    })();

  }, []);


  const fetchOptionsFromApi = async () => {
    try {
      const response = await fetch(apiUrl + '/api/public/getAllAsosiasi');
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error('Failed to fetch options from API');
    }
  };


  const handleOptionAsosiasiChange = event => {
    setSelectedAsosiasiOption(event.target.value);
  };

  const fetchData = async () => {

    let decodedToken = jwt_decode(user.accessToken);
    console.log("Decoded Token", decodedToken);
    let currentDate = new Date();
    // JWT exp is in seconds
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      console.log("Token expired.");
      try {
        const paramRefreshToken = {
          refreshToken: user.refreshToken,
          csrfToken: user.csrfToken,
        };
        refreshToken = await refreshTokenRequest(paramRefreshToken);
        // refreshToken = await refreshTokenRequest();
      } catch (error) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      if (refreshToken.statusCode === 403) {
        console.error('Expired. Please sign in.');
        navigate('/login');
      }
      console.log(refreshToken);
      user['accessToken'] = refreshToken.accessToken;
      setIsTokenExpired(true);
    } else {
      console.log("Valid token");
      // result = true;
    }
    try {
      console.log('user id ' + user.id);
      console.log('pengembang id ' + user.pengembang_id);
      const response = await axios.get(apiUrl + '/api/igahp/pengembangProfile/' + user.pengembang_id, {
        headers: {
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
        }
      });
      // await Delay(5000);

      console.log(response.data);
      setData(response.data);


      setNamaDirektur(response.data.namaDirektur);
      setNamaPerusahaan(response.data.pengembang_nama);
      setNpwpPerusahaan(response.data.npwp);
      setEmailPerusahaan(response.data.email);
      setNamaPicPemohon(response.data.pic);
      setTelpPicPemohon(response.data.picTelp);
      setTelpKantor(response.data.telp);
      setKodePos(response.data.kodePos);
      setSelectedVillage(response.data.wilayah);
      setSelectedAsosiasiOption(response.data.asosiasiId);
      setAlamatPerusahaan(response.data.alamat);

      setDokAktePendirian(response.data.dokAktePendirian)
      setDokNib(response.data.dokNib)

      setIsLoading(false);
      setIsTokenExpired(false);
    } catch (error) {
      console.error('Error get api data...');
      navigate('/login');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // alert('simpan')

    const formData = new FormData();

    formData.append('id', user.pengembang_id);

    formData.append('userId', user.id);
    formData.append('namaDirektur', namaDirektur);
    formData.append('namaPerusahaan', namaPerusahaan);
    formData.append('npwpPerusahaan', npwpPerusahaan);
    formData.append('emailPerusahaan', emailPerusahaan);
    formData.append('websitePerusahaan', websitePerusahaan);
    formData.append('alamatPerusahaan', alamatPerusahaan);
    formData.append('asosiasiId', selectedAsosiasiOption);
    formData.append('kodePos', kodePos);
    formData.append('rt', rt);
    formData.append('rw', rw);
    formData.append('telpKantor', telpKantor);
    formData.append('telpDirektur', telpDirektur);
    formData.append('namaPicPemohon', namaPicPemohon);
    formData.append('telpPicPemohon', telpPicPemohon);
    formData.append('kodeWilayah', selectedVillage);
    formData.append('dokAktePendirian', dokAktePendirian);
    formData.append('dokNib', dokNib);
    formData.append('dokNpwp', dokNpwp);


    const sUrl = apiUrl + '/api/igahp/editPengembangProfile';

    // console.log(sUrl);
    try {
      await fetch(sUrl, {
        credentials: 'include', //getcookies
        method: 'POST',
        // body: JSON.stringify(formData),
        body: formData,
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
          'X-XSRF-TOKEN': user.csrfToken
        },
      })
        .then(response => response.json())
        .then(data => {
          // Handle API response
          // console.log(data);
          // alert(data.status);


          Toast.fire({
            icon: 'success',
            title: data.status
          });

          // setModalVisible(false);

          // navigate("/igahp/pengembang/PengembangProfile");

        })
        .catch(error => {
          // Handle error
          // console.error(error);

          Toast.fire({
            icon: 'error',
            title: error
          });

          // alert(error);
        });

    } catch (error) {
      console.error('An error occurred:', error);

    }

    

  };

  const handleClose = () => {
    // Lakukan apa pun yang diperlukan sebelum menutup modal
    // ...
    // Panggil fungsi onClose untuk memberi tahu parent bahwa modal ditutup
    onClose();
  };


  const handleFileChangeNib = (e) => {
    const file = e.target.files[0];
    //ukuran tidakboleh lebih dari 5 mb
    if (file && file.size > 10 * 1024 * 1024) {    
      alert('Ukuran file NIB tidak boleh lebih dari 5MB');
      e.target.value = null; // Clear the file input
    } else {
      setDokNib(file);
    }
  };


  const handleFileChangeAkta = (e) => {
    const file = e.target.files[0];
    //ukuran tidakboleh lebih dari 5 mb
    if (file && file.size > 10 * 1024 * 1024) {
      alert('Ukuran file AKTA tidak boleh lebih dari 5MB');
      e.target.value = null; // Clear the file input
    } else {
      setDokAktePendirian(file);
    }
  };
  return (

    <>


      <div className="modal fade" id="ModalPermohonanBgh" tabIndex={-1} role="dialog" 
        aria-labelledby="ModalPermohonanBghLabel" aria-hidden="true" data-backdrop="static">
    

     
      
      {/* <div className={`modal fade ${modalVisible ? 'show' : ''}`}
        id="ModalPermohonanBgh"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ModalPermohonanBgh"
        aria-hidden={!modalVisible}
      > */}

        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Input Data</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="modal-body">

                {/* Main content */}
                <section className="content">
                  <div className="container-fluid">

                    <div className="row">
                      <div className="col-md-12">

                        <div className="card card-primary">
                          <div className="card-header">
                            <h3 className="card-title">Profile Data Pengembang</h3>
                          </div>
                          <div className="card-body">


                            <div className="row">

                              <div className="col-md-6">

                                <div className="form-group">
                                  <label>Nama Direktur</label>
                                  <input type="text" className="form-control"
                                    value={namaDirektur} onChange={(e) => setNamaDirektur(e.target.value)}
                                    placeholder='Cth. Budiyanto'
                                  />
                                </div>

                                <div className="form-group">
                                  <label>Nama Perusahaan</label>
                                  <input type="text" className="form-control"
                                    value={namaPerusahaan} onChange={(e) => setNamaPerusahaan(e.target.value)}
                                    placeholder='PT. XXXXXX'
                                  />
                                </div>

                              </div>
                              <div className="col-md-6">
                                <div className="form-group">
                                  <label>Alamat Kantor</label>
                                  <textarea className="form-control" rows={3} placeholder='Cth. Jl. Limau 1 No 22 RT.XX RW.XX' defaultValue={""}
                                    value={alamatPerusahaan} onChange={(e) => setAlamatPerusahaan(e.target.value)}
                                    
                                  />
                                </div>

                              </div>

                            </div>

                            <div className="row">

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Email Perusahaan</label>
                                  <input type="text" className="form-control"
                                    value={emailPerusahaan} onChange={(e) => setEmailPerusahaan(e.target.value)}
                                    placeholder='pengembang@mail.com'
                                  />
                                </div>

                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Telp Kantor</label>
                                  <input type="text" className="form-control"
                                    value={telpKantor} onChange={(e) => setTelpKantor(e.target.value)}
                                    placeholder='021-XXXXXX'
                                    maxLength={15}
                                  />
                                </div>
                              </div>


                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label>Kode Pos</label>
                                  <input type="text" className="form-control"
                                    value={kodePos} onChange={(e) => setKodePos(e.target.value)}
                                    placeholder='00000'
                                    maxLength={5}
                                  />
                                </div>
                              </div>

                            </div>

                            <div className="row">

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>NPWP</label>
                                  
                                  {/* <input type="text" className="form-control"
                                    value={npwpPerusahaan} onChange={(e) => setNpwpPerusahaan(e.target.value)}
                                    placeholder='00.000.000.0-000.000'
                                  /> */}

                                  <InputMask className="form-control"
                                    mask="99.999.999.9-999.999"
                                    maskChar="_"
                                    value={npwpPerusahaan}
                                    onChange={(e) => setNpwpPerusahaan(e.target.value)}
                                  />


                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Asosiasi Pengembang</label>
                                  <select className="form-control" value={selectedAsosiasiOption} onChange={handleOptionAsosiasiChange}>
                                    <option value="">Select an option</option>
                                    {options.map(option => (
                                      <option key={option.id} value={option.id}>
                                        {option.nama}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>Nama Admin Pengembang</label>
                                  <input type="text" className="form-control"
                                    value={namaPicPemohon} onChange={(e) => setNamaPicPemohon(e.target.value)}
                                    placeholder='Cth. Susi'
                                  />
                                </div>
                              </div>

                              <div className="col-sm-3">
                                <div className="form-group">
                                  <label>No. Handphone Admin</label>
                                  <input type="text" className="form-control"
                                    value={telpPicPemohon} onChange={(e) => setTelpPicPemohon(e.target.value)}
                                    placeholder='08XXXXXX'
                                    maxLength={15}
                                  />
                                </div>                                
                              </div>

                              
                            </div>



                          </div>
                        </div>

                      </div>
                    </div>


                    
                    <br />

                    <div className="form-group">
                      <label>Lokasi Kantor Pengembang</label>
                      <div className="row">
                        <LocationSelect id="locationSelect1" villageCode={selectedVillage} onVillageChange={handleVillageChange} />
                      </div>
                    </div>

                    <br />

                    <div className="card card-success">
                      <div className="card-header">
                        <h3 className="card-title">Dokumen</h3>
                      </div>

                      <div className="card-body">


                        <div className="form-group">
                          <div className="row">

                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Akta Pendirian Perusahaan</label>
                                <input
                                  className="form-control form-control"
                                  // data-pristine-required-message="Jpg Siteplan  latar tidak boleh kosong" required
                                  type="file"
                                  accept=".pdf"
                                  
                                  onChange={handleFileChangeAkta}
                                  
                                  require
                                />
                                <small>Format Dokumen berbentuk .pdf  </small><br></br>
                                {/* <small><i>{dokAktePendirian}</i></small> */}
                              </div>
                            </div>

                            <div className="col-sm-6">

                              <div className="form-group">
                                <label htmlFor="exampleInputFile">Nomor Induk Berusaha</label>
                                <div className="mb-6">
                                  <input
                                    className="form-control form-control"
                                    // data-pristine-required-message="Jpg Siteplan  latar tidak boleh kosong" required
                                    type="file"
                                    accept=".pdf"
                                    // onChange={(e) => setDokNib(e.target.files[0])}
                                    onChange={handleFileChangeNib}
                                    // value={dokNib}
                                    require
                                  />
                                  <small>Format Dokumen berbentuk .pdf  </small><br></br>
                                  {/* <small><i>{dokNib}</i></small> */}
                                </div>
                              </div>
                            </div>


                          </div>
                        </div>


                      </div>
                    </div>


                  </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}

              </div>

              <div className="modal-footer justify-content-between">
                {/* <button type="button" className="btn btn-default" data-dismiss="modal">Tutup</button> */}
                <button type="submit" className="btn btn-success">Simpan</button>
              </div>
            </form>

          </div>

        </div>

      </div>

    </>


  )
}

export default ModalProfile
