import React from 'react'

const ModalPermohonan = () => {
  return (

    <>

      <div className="modal fade"
        id="ModalPermohonanBgh"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="ModalPermohonanBgh"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Form Permohonan BGH</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">

              {/* Main content */}
              <section className="content">
                <div className="container-fluid">
                  <div className="row">
                    {/* left column */}
                    <div className="col-md-6">
                      {/* general form elements */}
                      <div className="card card-primary">
                        <div className="card-header">
                          <h3 className="card-title">Data Bangunan</h3>
                        </div>
                        {/* /.card-header */}
                        {/* form start */}
                        <form>
                          <div className="card-body">
                            <div className="form-group">
                              {/* <label htmlFor="exampleInputEmail1">Nama Gedung</label> */}
                              <h5>Nama Bangunan</h5>
                              <input type="email" className="form-control" id="exampleInputEmail1" placeholder="Enter email" />
                            </div>



                            <h5>Klas Bangunan</h5>
                            <div className="form-group">
                              {/* <label htmlFor="exampleSelectBorder">Bottom Border only <code>.form-control-border</code></label> */}
                              <select className="custom-select form-control-border" id="exampleSelectBorder">
                                <option>Klas 1a</option>
                                <option>Klas 4</option>
                                <option>Klas 5</option>
                                <option>Klas 6</option>
                                <option>Klas 7</option>
                                <option>Klas 8</option>
                                <option>Klas 9a</option>
                                <option>Klas 9b</option>
                              </select>
                            </div>


                            <div className="form-group">
                              <div className="row">
                                <div className="col-sm-6">
                                  {/* text input */}
                                  <div className="form-group">
                                    <h5>Luas Bangunan</h5>
                                    <input type="text" className="form-control" placeholder="" />
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <h5>Lantai</h5>
                                    <input type="text" className="form-control" placeholder="" />
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div className="form-group">
                              {/* <label htmlFor="exampleInputEmail1">Nama Gedung</label> */}
                              <h5>Kategory BGH</h5>
                              <input type="email" className="form-control" id="exampleInputEmail1" placeholder="" />
                            </div>

                          </div>
                          {/* /.card-body */}
                          {/* <div className="card-footer">
                            <button type="submit" className="btn btn-primary">Submit</button>
                          </div> */}
                        </form>
                      </div>



                      {/* /.card */}
                      {/* general form elements */}
                      <div className="card card-warning">
                        <div className="card-header">
                          <h3 className="card-title">Data Penyedia Jasa</h3>
                        </div>
                        {/* /.card-header */}
                        <div className="card-body">

                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                {/* text input */}
                                <div className="form-group">
                                  <h5>Nama Penyedia</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h5>Alamat</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                {/* text input */}
                                <div className="form-group">
                                  <h5>No KTP</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h5>No HP</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                            </div>
                          </div>


                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                {/* text input */}
                                <div className="form-group">
                                  <h5>Upload SKA/SKK/Sertifikat BGH</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h5>Nomor SKA/SKK/Sertifikat BGH</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                        {/* /.card-body */}
                      </div>
                      
                    </div>
                    {/*/.col (left) */}
                    {/* right column */}
                    <div className="col-md-6">
                      {/* Form Element sizes */}
                      <div className="card card-success">
                        <div className="card-header">
                          <h3 className="card-title">Data Pemilik</h3>
                        </div>
                        <div className="card-body">



                          <h5>Status Kepemilikan</h5>
                          <div className="form-group">
                            {/* <label htmlFor="exampleSelectBorder">Bottom Border only <code>.form-control-border</code></label> */}
                            <select className="custom-select form-control-border" id="exampleSelectBorder">
                              <option>PT</option>
                              <option>CV</option>
                              <option>Yayasan</option>
                            </select>
                          </div>



                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                {/* text input */}
                                <div className="form-group">
                                  <h5>Nama</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h5>No Kitas</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                            </div>
                          </div>




                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                {/* text input */}
                                <div className="form-group">
                                  <h5>Email</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h5>No Hp</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                            </div>
                          </div>



                          <div className="form-group">
                            <div className="row">
                              <div className="col-sm-6">
                                {/* text input */}
                                <div className="form-group">
                                  <h5>Alamat</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <h5>Nama Badan Usaha</h5>
                                  <input type="text" className="form-control" placeholder="" />
                                </div>
                              </div>
                            </div>
                          </div>

                        
                        </div>
                        {/* /.card-body */}
                      </div>


{/*                       
                      <div className="card card-danger">
                        <div className="card-header">
                          <h3 className="card-title">Different Width</h3>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            <div className="col-3">
                              <input type="text" className="form-control" placeholder=".col-3" />
                            </div>
                            <div className="col-4">
                              <input type="text" className="form-control" placeholder=".col-4" />
                            </div>
                            <div className="col-5">
                              <input type="text" className="form-control" placeholder=".col-5" />
                            </div>
                          </div>
                        </div>                        
                      </div>
                       */}
                      
                    </div>
                    {/*/.col (right) */}
                  </div>
                  {/* /.row */}
                </div>{/* /.container-fluid */}
              </section>
              {/* /.content */}

            </div>

            <div className="modal-footer justify-content-between">
              <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
              <button type="button" className="btn btn-primary">Save changes</button>
            </div>
          </div>
          {/* /.modal-content */}
        </div>
        {/* /.modal-dialog */}
      </div>

    </>


  )
}

export default ModalPermohonan
