import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartComponentApi = (props) => {
    const chartRef = useRef(null);

    console.log(props)

    const labels = props.data.map(item => item.label);
    const dataValues = props.data.map(item => item.value);


    useEffect(() => {
        console.log('masuk chart comp');

        if (chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            new Chart(ctx, {
                // type: 'line', // You can change this to 'bar', 'pie', etc. for different types of charts
                type: props.tipeChart, // You can change this to 'bar', 'pie', etc. for different types of charts

                data: {
                    // labels: ['Acer', 'Hp', 'Dell', 'Lenovo', 'MacBook'],
                    labels : labels,
                    datasets: [
                        {
                                
                            label: props.label,
                            // data: [10, 20, 15, 30, 25],
                            data: dataValues,
                            // backgroundColor: 'rgba(75, 192, 192, 0.2)', // Fill color
                            backgroundColor: ['#f56954', '#00a65a', '#f39c12', '#00c0ef', '#3c8dbc', '#d2d6de'],
                            borderColor: 'rgba(75, 192, 192, 1)', // Border color
                            borderWidth: 1, // Border width
                        },
                    ],
                },
                
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                },
            });
        }
    }, []);

    return (
        <div className="chart tab-pane" id="sales-chart" style={{ position: 'relative', height: 300 }}>
            <canvas ref={chartRef} id="sales-chart-canvas" height={300} style={{ height: 300 }} />
        </div>
    );
};

export default ChartComponentApi;
