import React from 'react'

const ContentPengembang = () => {
  return (
    <div>
      AAAAAAAAAA
    </div>
  )
}

export default ContentPengembang
