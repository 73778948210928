import React from 'react'
import Footer from '../../Footer'
import { useState } from 'react';
import { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../../AuthContext';
import { refreshTokenRequest } from '../../refreshTokenRequest';
import axios from 'axios';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


const ManajemenPenugasanTambah = () => {

    const [selectedItems, setSelectedItems] = useState([]);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { user, setUser } = useContext(AuthContext);
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const [optionsPengembang, setOptionsPengembang] = useState([]);
    const [selectedOptionPengembang, setSelectedOptionPengembang] = useState('');
    const [optionsPerumahan, setOptionsPerumahan] = useState([]);
    const [selectedOptionPerumahan, setSelectedOptionPerumahan] = useState('');
    const [optionsUnitRumah, setOptionsUnitRumah] = useState([]);
    const [selectedOptionUnitRumah, setSelectedOptionUnitRumah] = useState('');

    const [selectedNomorPengajuanBgh, setSelectedNomorPengajuanBgh] = useState('');

    const [konstruksiChecked, setKonstruksiChecked] = useState(true);
    const [bangunanHijauChecked, setBangunanHijauChecked] = useState(true);

    const apiUrl = process.env.REACT_APP_API_ENDPOINT;

    const handleOptionChangePengembang = event => {
        setSelectedOptionPengembang(event.target.value);
    };


    const handleOptionChangeNomorPengajuanBgh = event => {
        setSelectedNomorPengajuanBgh(event.target.value);
    };

    const [optionsNomorPengajuanBgh, setOptionsNomorPengajuanBgh] = useState([]);


    const handleOptionChangePerumahan = event => {
        setSelectedOptionPerumahan(event.target.value);
    };


    const handleOptionChangeUnitRumah = event => {
        setSelectedOptionUnitRumah(event.target.value);
        console.log(event.target.value);
    };
    let refreshToken = null;

    const navigate = useNavigate()

    useEffect(() => {

        const script2 = document.createElement('script');
        script2.src = "/js/toast.js";
        script2.async = true;
        document.body.appendChild(script2);


        // Fetch options from API and update state
        fetchOptionsFromApiPengembang()
            .then(data => setOptionsPengembang(data))
            .catch(error => console.error('Error fetching options:', error));

        return () => { script2.remove(); }
    }, []);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);

        if (isLoading || isTokenExpired) {
            console.log('Use effetc load fetchdata calling.....');            
             fetchDataUser();
        }
        return () => { script.remove(); }

    }, [isLoading, isTokenExpired]);



    useEffect(() => {
        fetchOptionsFromApiPerumahan()
            .then(data => setOptionsPerumahan(data))
            .catch(error => console.error('Error fetching options:', error));

    }, [selectedOptionPengembang]);


    useEffect(() => {
       
        fetchOptionsFromUnitRumah()
            .then(data => setOptionsUnitRumah(data))
            .catch(error => console.error('Ersror fetching options:', error));

    }, [selectedOptionPerumahan]);



    useEffect(() => {

        fetchOptionsNomorPengajuanBgh()
            .then(data => setOptionsNomorPengajuanBgh(data))
            .catch(error => console.error('Ersror fetching options:', error));

    }, [selectedOptionPerumahan]);

    

    const fetchOptionsFromApiPengembang = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }
        try {
            const response = await axios.get(apiUrl+'/api/igahp/getAllPengembang', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            console.log('getAllPengembang ...');
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchOptionsFromApiPerumahan = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        var idPengembang = (selectedOptionPengembang === '') ? 1 : selectedOptionPengembang;

        try {
            const response = await axios.get(apiUrl+'/api/igahp/lokasibypengembangid/' + idPengembang, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };



    const fetchDataUser = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl+'/api/igahp/getAllUsers', {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log('Hasil getAllUser dari form ManajemenPenugasanTambah')
            console.log(response.data);
            setData(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };


    const fetchOptionsNomorPengajuanBgh = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        var idLokasi = (selectedOptionPerumahan === '') ? 1 : selectedOptionPerumahan;

        try {
            const response = await axios.get(apiUrl + '/api/igahp/getListPermohonanBgh/' + idLokasi, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            console.log('respon api nomor permohonan bgh untuk id lokasi/perumahan = ' + idLokasi);
            console.log(response.data)
            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };

    const fetchOptionsFromUnitRumah = async () => {
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();'
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        var idLokasi = (selectedOptionPerumahan === '') ? 1 : selectedOptionPerumahan;

        try {
            const response = await axios.get(apiUrl+'/api/igahp/getUnitByIdLokasi/' + idLokasi, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });

            console.log('respon api unit rumah untuk id lokasi/perumahan = ' + idLokasi);
            console.log(response.data)
            return response.data;
        } catch (error) {
            // console.error('Error get api data...');
            // navigate('/login');
        }
    };


    const handleCheckboxChangePenugasan = (event) => {
        const { name, checked } = event.target;

        // if (name === 'konstruksi') {
        //     setKonstruksiChecked(checked);
        // } else if (name === 'bangunanHijau') {
        //     setBangunanHijauChecked(checked);
        // }

        setBangunanHijauChecked(checked);
    };

    function handleCheckboxChangeUserSelect(event, itemId) {
        const isChecked = event.target.checked;
        console.log(`Checkbox for item with ID ${itemId} is ${isChecked ? 'checked' : 'unchecked'}`);
        // You can perform any additional actions based on the checkbox state here
    }


    function getCheckedItemsFromDataTable() {
        const table = document.getElementById('example1'); // Replace with the actual table ID
        const checkboxElements = table.querySelectorAll('tbody input[type="checkbox"]');
        const checkedItems = [];

        checkboxElements.forEach((checkbox) => {
            if (checkbox.checked) {
                const row = checkbox.closest('tr');
                const id = row.querySelector('td:first-child').textContent; // Assuming ID is in the first cell
                checkedItems.push(id);
            }
        });

        return checkedItems;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();



        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        const checkedItemIDs = getCheckedItemsFromDataTable();
        console.log('total dipilih = ' + checkedItemIDs.length);
        console.log('Checked item IDs:', checkedItemIDs);

        var kodePenugasan = 0;
        if (konstruksiChecked && bangunanHijauChecked) {
            kodePenugasan = 3;
            // alert('dipilih 22 nya');
        } else if (konstruksiChecked) {
            kodePenugasan = 1;
            // alert('konstruksi aja yang dipilh');
        } else if (bangunanHijauChecked) {
            kodePenugasan = 2;
            // alert('bgh aja yang dipilh');
        } else {
             alert('tak ada satupun jenis penugasan yang dipilih');
             return;
        }

        if (checkedItemIDs.length === 0) {
            alert('Petugas belum dipilih');
            return;
        }

        if (checkedItemIDs.length>1) {
            alert('Petugas hanya bisa dipilih 1 saja');
            return;
        }

        //----------------- mulai handel form ---------------------------------------------------------------------------------------
        const formData = new FormData();
        // formData.append('id', user['blokrumah_id']);
        // formData.append('id', user.perumahan_id);
        // formData.append('is_tambah_data', isTambahData);

        // formData.append('kodePenugasan', kodePenugasan);
        formData.append('kodePenugasan', 2); ///hardcode 2 aja menjadi penilaian hijau
        formData.append('userId', checkedItemIDs[0]);
        formData.append("idRumahTapak", selectedOptionUnitRumah);
        formData.append("idRumahSusun", '0');
        formData.append("idNomorPengajuanBgh", selectedNomorPengajuanBgh);


        for (var key of formData.entries()) {
            // console.log(key[0] + ', ' + key[1]);
        }


        try {
            await fetch(apiUrl+'/api/igahp/simpanPenugasan', {
                credentials: 'include', //getcookies
                method: 'POST',
                // body: JSON.stringify(formData),
                body: formData,
                headers: {
                    // 'Content-Type': 'application/json',
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'X-XSRF-TOKEN': user.csrfToken
                },
            })
                .then(response => response.json())
                .then(data => {
                    // Handle API response
                    // console.log(data);

                    alert(data.status);

                    navigate('/igahp/admin/ManajemenPenugasan');
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert(error);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }

        
      
    }

    let sequenceNumber = 1;

    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Penugasan Baru</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Penugasan</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">

                        <div className="row">
                            {/* left column */}
                            <div className="col-md-12">
                                {/* jquery validation */}
                                <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Form <small></small></h3>
                                    </div>
                                    {/* /.card-header */}
                                    {/* form start */}
                                    <form onSubmit={handleSubmit}>
                                        <div className="card-body">


                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Pilih Pengembang</label>
                                                        <select className="form-control" value={selectedOptionPengembang} onChange={handleOptionChangePengembang}>
                                                            <option value="">Select an option</option>
                                                            {optionsPengembang.map(option => (
                                                                <option key={option.id} value={option.id}>
                                                                    {option.nama}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Pilih Perumahan</label>
                                                        <select className="form-control" value={selectedOptionPerumahan} onChange={handleOptionChangePerumahan}>
                                                            <option value="">Select an option</option>
                                                            {optionsPerumahan.map(option2 => (
                                                                <option key={option2.id} value={option2.id}>
                                                                    {option2.nama_perumahan}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Pilih Nomor Pengajuan BGH</label>
                                                        <select className="form-control" value={selectedNomorPengajuanBgh} onChange={handleOptionChangeNomorPengajuanBgh}>
                                                            <option value="">Select an option</option>
                                                            {optionsNomorPengajuanBgh.map(option4 => (
                                                                <option key={option4.id} value={option4.id}>
                                                                    {option4.nomorPermohonan}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Pilih Unit Rumah</label>
                                                        <select className="form-control" value={selectedOptionUnitRumah} onChange={handleOptionChangeUnitRumah}>
                                                            <option value="">Select an option</option>
                                                            {optionsUnitRumah.map(option3 => (
                                                                <option key={option3.id} value={option3.id}>
                                                                    {option3.id_rumah}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <div className="col-sm-12">
                                                    <div className="form-group">
                                                        <label>Jenis Penugasan</label>
                                                        {/* <div className="form-check">
                                                            
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="konstruksi"
                                                                checked={konstruksiChecked}
                                                                onChange={handleCheckboxChangePenugasan}
                                                            />
                                                            <label className="form-check-label">Konstruksi</label>
                                                        </div> */}
                                                        <div className="form-check">
                                                            {/* <input className="form-check-input" type="checkbox" defaultChecked /> */}
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                name="bangunanHijau"
                                                                checked={bangunanHijauChecked}
                                                                onChange={handleCheckboxChangePenugasan}
                                                            />
                                                            <label className="form-check-label">Bangunan Hijau</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

.                                            <div className="form-group">

                                                
                                                {isLoading ?
                                                    <p>Loading Data...</p> :

                                                    <>
                                                        <br/>
                                                        <div className="col-sm-12">
                                                            <label>Pilih Petugas</label>
                                                            <div className="card">
                                                                {/* <div class="card-header">
                                                                    <h3 class="card-title">List User </h3>
                                                                </div> */}
                                                                <div className="card-body">
                                                                    <table id="example1" className="table table-bordered table-striped">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>NO</th>
                                                                                <th>USERNAME</th>
                                                                                <th>EMAIL</th>
                                                                                <th>STATUS</th>
                                                                                <th>ROLE</th>
                                                                                <th>CHOICE</th>

                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>

                                                                            {data.map((item) => (
                                                                                <tr key={item.id}>
                                                                                    {/* <td>{item.id}</td> */}
                                                                                    <td>{sequenceNumber++}</td>
                                                                                    <td>{item.username}</td>
                                                                                    <td>{item.email}</td>
                                                                                    <td>{item.status}</td>
                                                                                    <td>{item.roles[0].name}</td>
                                                                                    <td className='text-center'>
                                                                                        <input 
                                                                                            className="form-check-input" 
                                                                                            type="checkbox"  
                                                                                            // checked={selectedItems.includes(item.id)}
                                                                                            onChange={(e) => handleCheckboxChangeUserSelect(e, item.id)}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            ))}

                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                            </div>

                                        </div>
                                        {/* /.card-body */}
                                        <div className="card-footer">
                                            <button type="submit" className="btn btn-primary">Submit</button>
                                        
                                        
                                            {/* <button type="button" class="btn btn-success toastrDefaultSuccess">
                                                Launch Success Toast
                                            </button> */}

                                        </div>
                                    </form>
                                </div>
                                {/* /.card */}
                            </div>
                            {/*/.col (left) */}
                            {/* right column */}
                            <div className="col-md-6">
                            </div>
                            {/*/.col (right) */}
                        </div>

                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



            <Footer />
        </>
    )
}

export default ManajemenPenugasanTambah
