import React from 'react'

const TentangKami = () => {

    require("./../../css/common.css");

    return (
        <><div>
            <div className="top-navbar">
                <section className="content-left">
                    <div className="content-phone">
                        <img src="ic_phone.svg" alt="Phone" />
                        <div className="phone-number one-green-s-regular">+62 821 3320 2930</div>
                    </div>
                    <div className="content-email">
                        <img src="ic_email.svg" alt="Email" />
                        <div className="email one-green-s-regular">connect@igahp.id</div>
                    </div>
                </section>
                <section className="content-right">
                    <div className="content-instagram">
                        <img src="ic_instagram.svg" alt="Instagram" />
                    </div>
                    <div className="content-linkedin">
                        <img src="ic_linkedin.svg" alt="Linked In" />
                    </div>
                </section>
            </div>
            <nav>
                <div className="bottom-navbar">
                    <div className="bottom-navbar-left">
                        <a href="index.html"><img src="ic_logo_one_green.svg" alt="One Green" /></a>
                    </div>
                    <div className="bottom-navbar-right">
                        <ul>
                            <li>
                                <a href="/RumahHijau" className="one-green-s-regular">Rumah Hijau</a>
                            </li>
                            <li>
                                <a href="/TentangKami" className="one-green-s-regular">Tentang Kami</a>
                            </li>
                            <li>
                                <a href="#" className="one-green-s-regular">Berita</a>
                            </li>
                            <li>
                                <a href="#" className="one-green-s-regular">Bantuan</a>
                            </li>
                            <li>
                                <a href="/login" className="one-green-s-semibold">
                                    <div className="btn-green-border">
                                        Login ke Portal
                                        <img src="ic_arrow_right.svg" alt />
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
            <section className="section-hero-image">
                <div className="hero-image">
                    <div className="content-background-right">
                        <div className="about-us-title">
                            <p className="white-text one-green-highlight-bold">Komitmen Kami untuk Masa</p>
                            <p className="blue-text one-green-highlight-bold">Depan Berkelanjutan</p>
                        </div>
                    </div>
                </div>
                <div className="content-background-hero-image">
                    <img src="image_about_us.png" alt />
                </div>
            </section>
            <section className="wrapper-content">
                <div className="wrapper-tab">
                    <a href="#about-us">
                        <div className="tab tab-selected">
                            Tentang Kami
                        </div>
                    </a>
                    <a href="#">
                        <div className="tab">
                            Visi &amp; Misi
                        </div>
                    </a>
                    <a href="#">
                        <div className="tab">
                            Tim Kami
                        </div>
                    </a>
                </div>
                <div className="content-about-us" id="about-us">
                    <h3>Tentang Kami</h3>
                    <p className="one-green-m-regular neutral-90-text">Melihat perkembangan jaman saat ini dimana masyarakat
                        membutuhkan aplikasi yang mempermudah kinerja serta
                        mempercepat proses Pengajuan serta Penilaian Sertifikasi. Aplikasi yang saling terhubung antar
                        stakeholders (Pemerintah, Bank dan Pengembang) berbasis web based dan mobile apps. Maka kami
                        berinisiatif menciptakan OneGreen</p>
                </div>
                <div className="content-visi-misi" id="visi-misi">
                    <div className="content-visi">
                        <div className="image-visi">
                            <img src="bg_visi.png" alt="Visi" />
                        </div>
                        <div className="text-visi">
                            <p className="one-green-m-regular black-text">Menciptakan dunia di mana setiap rumah adalah teladan
                                dalam keberlanjutan. Kami bermimpi tentang
                                sebuah masa depan di mana rumah-rumah bukan hanya tempat tinggal, tetapi juga ekosistem yang
                                berkontribusi positif pada lingkungan sekitarnya. Kami berkomitmen untuk terus mengembangkan
                                teknologi dan praktik pembangunan ramah lingkungan, serta memberikan solusi perumahan inovatif
                                yang memungkinkan setiap individu untuk hidup seimbang dengan alam. Dengan visi ini, kami
                                berusaha untuk memimpin perubahan dalam industri konstruksi menuju lingkungan yang lebih hijau,
                                lebih sehat, dan lebih berkelanjutan.</p>
                        </div>
                    </div>
                    <div className="content-misi">
                        <div className="text-misi">
                            <p className="one-green-m-regular black-text">Menciptakan dunia di mana setiap rumah adalah teladan
                                dalam keberlanjutan. Kami bermimpi tentang
                                sebuah masa depan di mana rumah-rumah bukan hanya tempat tinggal, tetapi juga ekosistem yang
                                berkontribusi positif pada lingkungan sekitarnya. Kami berkomitmen untuk terus mengembangkan
                                teknologi dan praktik pembangunan ramah lingkungan, serta memberikan solusi perumahan inovatif
                                yang memungkinkan setiap individu untuk hidup seimbang dengan alam. Dengan visi ini, kami
                                berusaha untuk memimpin perubahan dalam industri konstruksi menuju lingkungan yang lebih hijau,
                                lebih sehat, dan lebih berkelanjutan.</p>
                        </div>
                        <div className="image-misi">
                            <img src="bg_misi.png" alt="Visi" />
                        </div>
                    </div>
                </div>
                <div className="content-our-team" id="our-team">
                    <div className="title-our-team">
                        <h3>Tim Kami</h3>
                        <div className="selected-line">
                            <div className="left-line" />
                            <div className="mid-line" />
                            <div className="right-line" />
                        </div>
                    </div>
                    <div className="team-photo">
                        <div className="wrapper-profile">
                            <div className="photo-profile">
                                <img src="cto_photo.png" alt />
                            </div>
                            <div className="profile-name">
                                <p className="one-green-l-medium black-text">Moch. Ihsan</p>
                            </div>
                            <div className="profile-job-position">
                                <p className="one-green-s-regular neutral-80-text">Chief Operation Officer</p>
                            </div>
                        </div>
                        <div className="wrapper-profile">
                            <div className="photo-profile">
                                <img src="ceo_photo.png" alt />
                            </div>
                            <div className="profile-name">
                                <p className="one-green-l-medium black-text">Wenrizal</p>
                            </div>
                            <div className="profile-job-position">
                                <p className="one-green-s-regular neutral-80-text">Chief Executive Officer</p>
                            </div>
                        </div>
                        <div className="wrapper-profile">
                            <div className="photo-profile">
                                <img src="cfo_photo.png" alt />
                            </div>
                            <div className="profile-name">
                                <p className="one-green-l-medium black-text">Purwita Arifin</p>
                            </div>
                            <div className="profile-job-position">
                                <p className="one-green-s-regular neutral-80-text">Chief Finance Office</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer>
                <div className="container">
                    <div className="wrapper-location">
                        <img src="ic_logo_one_green_white.svg" alt="One Green" />
                        <p className="white-text one-green-s-regular">Jl. Limau I No.18, RT.4/RW.3, Kramat Pela, Kec. Kby. Baru,
                            Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12130</p>
                    </div>
                    <div className="wrapper-contact-us">
                        <div className="one-green-s-semibold white-text">Kontak Kami</div>
                        <p className="one-green-s-regular white-text">No. Telepon : (021) 38250051</p>
                        <p className="one-green-s-regular white-text">Email : onegreenid.co.id</p>
                        <p className="one-green-s-regular white-text">Jam Operasional : 08.30 - 17.00 WIB</p>
                    </div>
                    <div className="wrapper-about-us">
                        <div className="one-green-s-semibold white-text">Tentang Kami</div>
                        <p>
                            <a href="#" className="one-green-s-regular white-text">Cerita One Green</a>
                        </p>
                        <p>
                            <a href="#" className="one-green-s-regular white-text">Visi Misi</a>
                        </p>
                        <p>
                            <a href="#" className="one-green-s-regular white-text">Tim Kami</a>
                        </p>
                        <p>
                            <a href="#" className="one-green-s-regular white-text">Penghargaan</a>
                        </p>
                    </div>
                </div>
            </footer>
        </div>

        </>
    )
}

export default TentangKami
