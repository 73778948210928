import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import { useEffect } from 'react';

const MapContainer = (props) => {
    const mapStyles = {
        width: '98%',
        height: '600px',
    };

    const { google } = props;


    useEffect(() => {
        // console.log('map ke panggil...')   
    });


    const handleMapContainerReady = (mapProps, map) => {
        // Callback function when the map is ready
        // console.log('Map is ready');
        // You can perform any additional actions here
        // or call a function passed as a prop to the MapContainer component
        // to notify the parent component that the map is ready.
        // Example: props.onMapReady()x
        props.onMapContainerReady(true);
    };


    return (
        <Map
                        google={google}
                        zoom={14}
                        style={mapStyles}
                        initialCenter={props.gps}
                        center={props.gps}
                        onReady={handleMapContainerReady} // Call the callback when the map is ready
                    >
            <Marker position={props.gps} />
        </Map>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyAxq_gqzoRsDNxU3FD1r0hgHtM8Pjxa3uU&callback', // replace with your Google Maps API key
})(MapContainer);
