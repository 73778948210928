// PrivateRoute.js
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Cookies from 'js-cookie';

const PrivateRoute = ({ children }) => {
    const { isAuthenticated } = useContext(AuthContext);

    if (isAuthenticated || Cookies.get('isAuthenticated') === 'true') {
        return <>{children}</>;
    } else {
        return <Navigate to="/login" replace />;
    }
};

export default PrivateRoute;