import React from 'react'
// import BankDashboard from './SekBghDashboard'
import SekBghDashboard from './SekBghDashboard'

const SekBghContentDepan = () => {
  return (
    <>
      <SekBghDashboard />
    </>
  )
}

export default SekBghContentDepan
