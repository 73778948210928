import React from 'react'
import Footer from '../Footer'
import { useEffect } from 'react';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import { useState } from 'react';

const PemohonDashboard = () => {

    const [lokasiGps, setLokasiGps] = useState({});

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/bankdashboard.js"
        script.async = true;
        document.body.appendChild(script);


        setLokasiGps({ lat: -6.266007, lng: 106.747293 });

        return () => { script.remove(); }

    }, []);

    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };

    return (
        <>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0">Dashboard Pemohon BGH</h1>
                            </div>{/* /.col */}

                        </div>{/* /.row */}
                    </div>{/* /.container-fluid */}
                </div>
                {/* /.content-header */}
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Small boxes (Stat box) */}

                        <div className="row">
                            <div className="col-lg-6 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>1000</h3>
                                        <p>Total Lokasi Terdaftar</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-6 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        {/* <h3>53<sup style={{ fontSize: 20 }}>%</sup></h3> */}
                                        <h3>5000</h3>
                                        <p>Total Unit Perumahan</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>

                            {/* ./col */}
                        </div>


                        <div className="row">
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-info">
                                    <div className="inner">
                                        <h3>500</h3>
                                        <p>Antrian Penilaian Rumah Hijau</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-bag" />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-success">
                                    <div className="inner">
                                        {/* <h3>53<sup style={{ fontSize: 20 }}>%</sup></h3> */}
                                        <h3>1000</h3>
                                        <p>Proses Penugasan Penilaian</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-stats-bars" />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-warning">
                                    <div className="inner">
                                        <h3>1000</h3>
                                        <p>Proses Penilaian Asesor</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-person-add" />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                            <div className="col-lg-3 col-6">
                                {/* small box */}
                                <div className="small-box bg-danger">
                                    <div className="inner">
                                        <h3>65</h3>
                                        <p>Lolos Penilaian Asesor</p>
                                    </div>
                                    <div className="icon">
                                        <i className="ion ion-pie-graph" />
                                    </div>
                                    <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right" /></a>
                                </div>
                            </div>
                            {/* ./col */}
                        </div>
                        
                        <div className="row">
                            <div className="col-md-6">
                                {/* AREA CHART */}
                                {/* <div className="card card-primary">
                                    <div className="card-header">
                                        <h3 className="card-title">Area Chart</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="chart">
                                            <canvas id="areaChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                        </div>
                                    </div>                                    
                                </div> */}
                                {/* /.card */}
                                {/* DONUT CHART */}
                                <div className="card card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title">Yang sudah terverifikasi dan belum</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <canvas id="donutChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                                {/* PIE CHART */}
                                {/* <div className="card card-danger">
                                    <div className="card-header">
                                        <h3 className="card-title">Pie Chart</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <canvas id="pieChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                    </div>                                
                                </div>
                                 */}
                                {/* /.card */}
                            </div>
                            {/* /.col (LEFT) */}
                            <div className="col-md-6">
                                {/* LINE CHART */}
                                {/* <div className="card card-info">
                                    <div className="card-header">
                                        <h3 className="card-title">Line Chart</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="chart">
                                            <canvas id="lineChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                        </div>
                                    </div>                                    
                                </div> */}
                                {/* /.card */}
                                {/* BAR CHART */}
                                <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Total perumahan rumah hijau per provinsi</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="chart">
                                            <canvas id="barChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                                {/* STACKED BAR CHART */}
                                {/* <div className="card card-success">
                                    <div className="card-header">
                                        <h3 className="card-title">Stacked Bar Chart</h3>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="chart">
                                            <canvas id="stackedBarChart" style={{ minHeight: 250, height: 250, maxHeight: 250, maxWidth: '100%' }} />
                                        </div>
                                    </div>                                    
                                </div> */}
                                {/* /.card */}
                            </div>
                            {/* /.col (RIGHT) */}
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12">

                                <h4>Peta Sebaran Rumah Hijau</h4>
                                {/* <small>Koordinat lokasi anda akan muncul setelah anda memasukan foto lokasi yang valid.</small> */}

                                <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">

                                    <>
                                        <MapContainer
                                            gps={lokasiGps}
                                            onMapContainerReady={handleMapContainerReady}
                                        />
                                    </>

                                </CustomIframe>


                            </div>
                        </div>


                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



        </>
    )
}

export default PemohonDashboard
