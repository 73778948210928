import React from 'react'
import Footer from '../Footer'
import { AuthContext } from '../../AuthContext';
import { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode'
import { refreshTokenRequest } from '../refreshTokenRequest';
import axios from 'axios';
import CustomIframe from '../CustomIFrame';
import MapContainer from './LokasiPerumahan/MapContainer';

import './PDFViewer.css';

import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import '@react-pdf-viewer/core/lib/styles/index.css'
import ModalTangguhkanLokasi from './childs/ModalTangguhkanLokasi';

const ManajemenLokasiApproval = () => {


    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(true);
    const [isLoadingPemasaran, setIsLoadingPemasaran] = useState(true);
    const [data, setData] = useState([]);
    const [dataPemasaran, setDataPemasaran] = useState([]);
    const [dataProfile, setDataProfile] = useState({});
    const [dataFile, setDataFile] = useState({});
    const [lokasiGps, setLokasiGps] = useState({});
    const [pdfUrl, setPdfUrl] = useState('');
    const [sitePlanUrl, setSitePlanUrl] = useState('');
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [sitePlanLatarUrl, setSitePlanLatarUrl] = useState('');
    const [isLoadingHistory, setIsLoadingHistory] = useState(true);
    const [isModal, setIsModal] = useState(false);
    const apiUrl = process.env.REACT_APP_API_ENDPOINT;


    useEffect(() => {

        if (isLoading || isTokenExpired) {
                     fetchData();
        }

        if (isLoadingPemasaran || isTokenExpired) {
            fetchDataPemasaran();
        }


    }, [isLoading, isTokenExpired, isLoadingPemasaran]);

    const handleMapContainerReady = () => {
        // setGpsLoaded(true);
        console.log('MapContainer finished rendering');
    };


    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    const fetchData = async () => {

       
        let decodedToken = jwt_decode(user.accessToken);
        // console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }



        try {
            const response = await axios.get('${apiUrl}/api/igahp/lokasi/detail/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log(response.data);
            // setData(response.data);
            setDataProfile(response.data);
            setIsLoading(false);
            setIsTokenExpired(false);
            setPdfUrl(apiUrl+'/api/public/displaypdf/' + response.data.dokumen_imb);
            setSitePlanUrl(apiUrl+'/api/public/displaypdf/' + response.data.siteplan_ttd);
            setSitePlanLatarUrl(apiUrl+'/api/public/displayimage/' + response.data.siteplanLatar)

            setLokasiGps({ lat: response.data.latitude, lng: response.data.longitude });
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }


    };


    const handleApproveClick = async (id) => {
        const confirmApprove = window.confirm("Are you sure you want to Approve this Lokasi Perumahan?");


        if (confirmApprove) {


            let decodedToken = jwt_decode(user.accessToken);
            console.log("Decoded Token", decodedToken);
            let currentDate = new Date();
            // JWT exp is in seconds
            if (decodedToken.exp * 1000 < currentDate.getTime()) {
                console.log("Token expired.");
                try {
                    const paramRefreshToken = {
                        refreshToken: user.refreshToken,
                        csrfToken: user.csrfToken,
                    };
                    refreshToken = await refreshTokenRequest(paramRefreshToken);
                    // refreshToken = await refreshTokenRequest();
                } catch (error) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                if (refreshToken.statusCode === 403) {
                    console.error('Expired. Please sign in.');
                    navigate('/login');
                }
                console.log(refreshToken);
                user['accessToken'] = refreshToken.accessToken;
                setIsTokenExpired(true);
            } else {
                console.log("Valid token");
                // result = true;
            }

             doUpdateStatus(id, '1')


        } else {

        }
    }



    const doUpdateStatus = async (id, status) => {

        const requestBody = {
            id_lokasi: id,
            userId: user.id,
            status: status
        };


        try {
            await fetch(apiUrl+'/api/igahp/updateStatusLokasi', {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),

            })
                .then(response => response.json())
                .then(data => {

                    alert(data.message);
                    fetchData();
                    // handleNilaiBalikan();
                })
                .catch(error => {
                    // Handle error
                    console.error(error);
                    alert(data.status);
                });

        } catch (error) {
            console.error('An error occurred:', error);
        }

    }

    const fetchDataPemasaran = async () => {

        let decodedToken = jwt_decode(user.accessToken);
        console.log("Decoded Token", decodedToken);
        let currentDate = new Date();
        // JWT exp is in seconds
        if (decodedToken.exp * 1000 < currentDate.getTime()) {
            console.log("Token expired.");
            try {
                const paramRefreshToken = {
                    refreshToken: user.refreshToken,
                    csrfToken: user.csrfToken,
                };
                refreshToken = await refreshTokenRequest(paramRefreshToken);
                // refreshToken = await refreshTokenRequest();
            } catch (error) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            if (refreshToken.statusCode === 403) {
                console.error('Expired. Please sign in.');
                navigate('/login');
            }
            console.log(refreshToken);
            user['accessToken'] = refreshToken.accessToken;
            setIsTokenExpired(true);
        } else {
            console.log("Valid token");
            // result = true;
        }

        try {
            const response = await axios.get(apiUrl+'/api/igahp/getPemasaranRumah/' + user.perumahan_id, {
                headers: {
                    Authorization: `Bearer ${refreshToken?.accessToken || user.accessToken}`
                }
            });
            // await Delay(5000);
            console.log('respon api kantor pemasaran...');
            console.log(response.data);
            setDataPemasaran(response.data);
            setIsLoadingPemasaran(false);
            setIsTokenExpired(false);
        } catch (error) {
            console.error('Error get api data...');
            navigate('/login');
        }
    };

    const handleIsModal = (item, event) => {

        isModal ? setIsModal(false) : setIsModal(true);
        // setItemData(dataProfile);

    }

    const newPlugin = defaultLayoutPlugin();


    const handleNilaiBalikan = (nilaiBalikan) => {
        // setIsLoading(nilaiBalikan);
    };

    let sequenceNumber = 1;

    return (
        <>

            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Pengajuan Approval Lokasi</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Pengajuan Approval Lokasi</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">

                            {/* /.col */}
                            <div className="col-md-12">



                                {/* Profile Image */}
                                <div className="card card-primary card-outline">
                                    <div className="card-body box-profile">

                                        <div className="d-flex justify-content-between align-items-center">
                                            <h1>{dataProfile.nama_perumahan}</h1>
                                            <div className="btn-group">
                                                <button 
                                                    className="btn btn-primary"
                                                    onClick={() => handleApproveClick(user.perumahan_id)}
                                                    >Verifikasi Lokasi</button>
                                                <div className="mx-2"></div>


                                                {/* <button className="btn btn-danger">Tangguhkan Lokasi</button> */}


                                                <button
                                                    type="button" className="btn btn-info"
                                                    id="tangguhkanModals" data-toggle="modal"
                                                    data-target="#ModalTangguhkanLokasi"
                                                    className="btn btn-danger float-left"

                                                    onClick={(event) => {
                                                        handleIsModal("tambah-data", event)
                                                    }}

                                                >Tangguhkan Lokasi
                                                </button>


                                                <ModalTangguhkanLokasi
                                                    sendModal={isModal}
                                                    // sendItem={itemData}
                                                    penampungBalikan={handleNilaiBalikan}
                                                />


                                            </div>
                                        </div>
                                        {/* <h1>{dataProfile.nama_perumahan}</h1> */}


                                        <strong><i className="fas fa-map-marker-alt mr-1" /> Alamat</strong>
                                        <h5>{dataProfile.info_kelurahan}</h5>
                                        <br />

                                        <strong><i className="far fa-file-alt mr-1" /> Notes</strong>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card">

                                                    {/* /.card-header */}
                                                    <div className="card-body p-0">
                                                        <table className="table table-sm">
                                                            <thead>
                                                                {/* <tr>
                                                                
                                                                <th>Task</th>
                                                                <th>Progress</th>
                                                                <th>Label</th>
                                                            </tr> */}
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Jenis Perumahan</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {dataProfile.jenis_perumahan === 0 ? 'rumah tapak' : 'rumah susun'}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>IMB/PBG</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {dataProfile.imb}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Nik Pemilik IMB/PBG</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {dataProfile.pemilik_imb}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Tanggal IMB/PBG</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {dataProfile.tgl_imb}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* /.card-body */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="card">

                                                    {/* /.card-header */}
                                                    <div className="card-body p-0">
                                                        <table className="table table-sm">
                                                            <thead>
                                                                {/* <tr>
                                                                
                                                                <th>Task</th>
                                                                <th>Progress</th>
                                                                <th>Label</th>
                                                            </tr> */}
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Kecamatan IMB/PBG</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        {dataProfile.infoKecamatan}
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Dokumen IMB/PBG</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <a href={'${apiUrl}/api/public/displaypdf/' + dataProfile.dokumen_imb} target="_blank" >Lihat File</a>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Foto Gambar Kerja Siteplan</td>
                                                                    <td>:</td>
                                                                    <td>
                                                                        <a href={'${apiUrl}/api/public/displaypdf/' + dataProfile.siteplan_ttd} target="_blank" >Lihat File</a>
                                                                    </td>

                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    {/* /.card-body */}
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}

                                <div className="card">
                                    <div className="card-header p-2">
                                        <ul className="nav nav-pills">
                                            <li className="nav-item"><a className="nav-link active" href="#perizinan" data-toggle="tab">Perizinan</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#fotolokasi" data-toggle="tab">Photo Lokasi</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#dokumenimb" data-toggle="tab">Dokumen IMB/PBG</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#siteplanimb" data-toggle="tab">Siteplan IMB/PBG</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#kantorpemasaran" data-toggle="tab">Kantor Pemasaran</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#bandingkansiteplan" data-toggle="tab">Bandingkan Siteplan</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#teswarnai" data-toggle="tab">Tes warnai siteplan</a></li>
                                            <li className="nav-item"><a className="nav-link" href="#historypengajuan" data-toggle="tab">History Pengajuan</a></li>
                                        </ul>
                                    </div>{/* /.card-header */}
                                    <div className="card-body">
                                        <div className="tab-content">

                                            <div className="active tab-pane" id="perizinan">


                                                <div className="invoice p-3 mb-3">
                                                    {/* title row */}
                                                    <div className="row">
                                                        <div className="col-12">
                                                            {/* <h4>
                                                                <i className="fas fa-globe" /> AdminLTE, Inc.
                                                                <small className="float-right">Date: 2/10/2014</small>
                                                            </h4> */}
                                                        </div>
                                                        {/* /.col */}
                                                    </div>
                                                    {/* info row */}
                                                    <div className="row invoice-info">
                                                        <div className="col-sm-4 invoice-col">
                                                            <strong> Diajukan oleh</strong>
                                                            <address>
                                                                {dataProfile.idPengajuan}  (ID Pengajuan)<br />
                                                                {dataProfile.nikPengaju} (Nomor KTP)<br />
                                                                {dataProfile.namaPengaju} (Nama Lengkap)<br />
                                                                {dataProfile.telpPengaju} (No Ponsel)<br />
                                                                {dataProfile.kelurahanPengaju} (Alamat)
                                                            </address>
                                                        </div>
                                                        {/* /.col */}
                                                        <div className="col-sm-4 invoice-col">
                                                            <strong>Atas nama</strong>
                                                            <address>
                                                                {dataProfile.pengembangNama} (Nama Pengembang)<br />
                                                                {dataProfile.pengembangNpwp} (NPWP)<br />
                                                                {dataProfile.asosiasiNama} (Asosiasi Pengembang)<br />
                                                                {dataProfile.pengembangAlamat} (Alamat Pengembang)<br />
                                                            </address>
                                                        </div>
                                                        {/* /.col */}
                                                        <div className="col-sm-4 invoice-col">
                                                            <strong>Untuk Perumahan</strong>
                                                            <address>
                                                                {dataProfile.nama_perumahan}  (Nama Perumahan)<br />
                                                                {(dataProfile.jenis_perumahan == '0') ? (
                                                                    'Rumah Tapak ') : ('Rumah Susun'
                                                                )}	 (Jenis Perumahan)<br />
                                                                {dataProfile.info_kelurahan} (Alamat Perumahan)<br />
                                                                {lokasiGps.lng}, {lokasiGps.lat} (koordinat)
                                                            </address>
                                                        </div>
                                                        {/* /.col */}
                                                    </div>


                                                </div>


                                                <h4>Peta Lokasi Perumahan</h4>
                                                <small>Koordinat lokasi anda akan muncul setelah anda memasukan foto lokasi yang valid.</small>
                                                <CustomIframe width="100%" height="500px" frameBorder="0" scrolling="no">
                                                    <>
                                                        <MapContainer
                                                            gps={lokasiGps}
                                                            onMapContainerReady={handleMapContainerReady}
                                                        />
                                                    </>
                                                </CustomIframe>
                                            </div>


                                            <div className="tab-pane" id="fotolokasi">
                                                <div className="card card-primary card-outline">
                                                    <div className="card-body box-profile">
                                                        {/* <div className="text-center">
                                            <img className="profile-user-img img-fluid img-circle" src="../../dist/img/user4-128x128.jpg" alt="User profile picture" />
                                        </div> */}
                                                        <h4>Foto Lokasi Perumahan</h4>
                                                        <small>Lokasi Harus Difoto Menggunakan Smartphone Dalam Kondisi GPS/Lokasi Aktif.</small>

                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h3 className="card-title">
                                                                            <i className="fas fa-text-width" />
                                                                            {/* Unordered List */}
                                                                        </h3>
                                                                    </div>
                                                                    {/* /.card-header */}
                                                                    <div className="card-body">
                                                                        <div>
                                                                            <img src={'${apiUrl}/api/public/displayimage/' + dataProfile.foto_gerbang} className="card-img-top card-img" alt="Image" />
                                                                            <div className="card-body">
                                                                                <h5 className="card-title">Foto Gerbang</h5>
                                                                                {/* <p className="card-text">This is a card with a full-fill image.</p> */}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    {/* /.card-body */}
                                                                </div>
                                                                {/* /.card */}
                                                            </div>
                                                            {/* ./col */}
                                                            <div className="col-md-4">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h3 className="card-title">
                                                                            <i className="fas fa-text-width" />
                                                                            {/* Ordered Lists */}
                                                                        </h3>
                                                                    </div>
                                                                    {/* /.card-header */}
                                                                    <div className="card-body">
                                                                        <div>
                                                                            <img src={'${apiUrl}/api/public/displayimage/' + dataProfile.foto_tengah} className="card-img-top card-img" alt="Image" />
                                                                            <div className="card-body">
                                                                                <h5 className="card-title">Foto Tengah</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                {/* /.card */}
                                                            </div>
                                                            {/* ./col */}
                                                            <div className="col-md-4">
                                                                <div className="card">
                                                                    <div className="card-header">
                                                                        <h3 className="card-title">
                                                                            <i className="fas fa-text-width" />
                                                                            {/* Unstyled List */}
                                                                        </h3>
                                                                    </div>
                                                                    {/* /.card-header */}
                                                                    <div className="card-body">
                                                                        <div>
                                                                            <img src={'${apiUrl}/api/public/displayimage/' + dataProfile.foto_contoh} className="card-img-top card-img" alt="Image" />
                                                                            <div className="card-body">
                                                                                <h5 className="card-title">Foto Contoh</h5>
                                                                                {/* <p className="card-text">This is a card with a full-fill image.</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* /.card-body */}
                                                                </div>
                                                                {/* /.card */}
                                                            </div>
                                                            {/* ./col */}
                                                        </div>



                                                    </div>
                                                    {/* /.card-body */}
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="dokumenimb">
                                                <div className="invoice p-3 mb-3">
                                                    {/* title row */}
                                                    <div className="row">
                                                        <div className="col-12 d-flex justify-content-center">
                                                            <h4>
                                                                <i className="" />Cek Dokumen IMB
                                                            </h4>
                                                        </div>
                                                        {/* /.col */}
                                                    </div>
                                                    {/* info row */}
                                                    <div className="row invoice-info">
                                                        <div className="col-md-6 text-right">

                                                            <address>
                                                                <strong>Nama Pengembang</strong><br />
                                                                NIK/ NPWP Pemilik IMB<br />
                                                                Nomor IMB<br />
                                                                Tanggal Terbit<br />

                                                            </address>
                                                        </div>
                                                        {/* /.col */}
                                                        <div className="col-md-6 text-left">

                                                            <address>
                                                                <strong> {dataProfile.pengembangNama}</strong><br />
                                                                {dataProfile.pemilik_imb}<br />
                                                                {dataProfile.imb}<br />
                                                                {dataProfile.tgl_imb}<br />

                                                            </address>
                                                        </div>

                                                    </div>
                                                    <div className="pdf-container">                                                        
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">                                                           
                                                            <Viewer fileUrl={pdfUrl} plugins={[newPlugin]} />                                                           
                                                        </Worker>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="siteplanimb">
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <h4>
                                                            <i className="" />Cek Siteplan 
                                                        </h4>
                                                    </div>
                                                    <div className="pdf-container">
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                            <Viewer fileUrl={sitePlanUrl} plugins={[newPlugin]} />
                                                        </Worker>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="tab-pane" id="kantorpemasaran">
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <h4>
                                                            <i className="" />Kantor Pemasaran
                                                        </h4>
                                                    </div>

                                                </div>
                                                <div className="row">

                                                    {isLoadingPemasaran ?
                                                        <p>Loading Data Pemasaran...</p> :

                                                        <div className="card">

                                                            {/* /.card-header */}
                                                            <div className="card-body">
                                                                <table id="example1" className="table table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>NO</th>
                                                                            <th>ALAMAT</th>
                                                                            <th>EMAIL</th>
                                                                            <th>TELP</th>
                                                                            <th>KANTOR UTAMA</th>
                                                                            <th>WEB</th>
                                                                            
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {dataPemasaran.map((item) => (
                                                                            <tr key={item.id}>

                                                                                <td>{sequenceNumber++}</td>
                                                                                <td>{item.alamat}</td>
                                                                                <td>{item.email}</td>
                                                                                <td>{item.noTelp}</td>
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={item.kantorUtama}
                                                                                    // onChange={() => handleCheckboxChange(item.id)}
                                                                                    />
                                                                                </td>
                                                                                <td>{item.website}</td>

                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }


                                                </div>

                                            </div>

                                            <div className="tab-pane" id="bandingkansiteplan">

                                                <div className="col-12 d-flex justify-content-center">
                                                    <h4>
                                                        <i className="" />Bandingkan Siteplan
                                                    </h4>
                                                </div>

                                                <div className="row">
                                                    <div className="col-6 d-flex justify-content-center">
                                                        <h5>Siteplan IMB</h5>
                                                    </div>
                                                    <div className="col-6 d-flex justify-content-center">
                                                        <h5>Siteplan Latar</h5>
                                                    </div>

                                                    

                                                </div>
                                                <div className="row">
                                                                                                       
                                                    <div className="col-6 d-flex justify-content-center">
                                                        
                                                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                            <Viewer fileUrl={sitePlanUrl} plugins={[newPlugin]} />
                                                        </Worker>                                                        
                                                    </div>


                                                    
                                                    <div className="col-6 d-flex justify-content-center">
                                                        <img src={'${apiUrl}/api/public/displayimage/' + dataProfile.siteplanLatar} className="card-img-top card-img" alt="Image" />
                                                        {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                                                            <Viewer fileUrl={sitePlanUrl} plugins={[newPlugin]} />
                                                        </Worker> */}
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="tab-pane" id="teswarnai">
                                            </div>

                                            
                                            <div className="tab-pane" id="historypengajuan">
                                                <div className="row">
                                                    <div className="col-12 d-flex justify-content-center">
                                                        <h4>
                                                            <i className="" />History Pengajuan
                                                        </h4>
                                                    </div>

                                                </div>
                                                <div className="row">

                                                    {isLoadingPemasaran ?
                                                        <p>Loading Data History...</p> :

                                                        <div className="card">

                                                            {/* /.card-header */}
                                                            <div className="card-body">
                                                                <table id="example1" className="table table-bordered table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th>NO</th>
                                                                            <th>Diajukan Oleh</th>
                                                                            <th>Alamat</th>
                                                                            <th>Tanggal</th>
                                                                            <th>Petugas yang cek</th>
                                                                            <th>Hasil Pengecekan</th>

                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>

                                                                        {dataPemasaran.map((item) => (
                                                                            <tr key={item.id}>
                                                                                {/* <td>{item.id}</td> */}
                                                                                <td>{sequenceNumber++}</td>
                                                                                <td>{item.alamat}</td>
                                                                                <td>{item.email}</td>
                                                                                <td>{item.noTelp}</td>
                                                                                <td>
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={item.kantorUtama}
                                                                                    // onChange={() => handleCheckboxChange(item.id)}
                                                                                    />
                                                                                </td>
                                                                                <td>{item.website}</td>

                                                                            </tr>
                                                                        ))}

                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    }


                                                </div>

                                            </div>


                                        </div>
                                        {/* /.tab-content */}
                                    </div>{/* /.card-body */}
                                </div>
                                {/* /.card */}


                            </div>
                            {/* /.col */}
                        </div>

                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}

            {/* <Footer /> */}
        </>
    )
}

export default ManajemenLokasiApproval
