import React from 'react'

import Navbar from './Navbar';
import SideBarAdmin from './SideBarAdmin';

import {
    BrowserRouter,
    Router,
    Routes,
    Route,
    Link,
    Outlet,
} from "react-router-dom";

// import Badge from "../additional/Bagde";
import { useContext } from "react";
import { AuthContext } from "../../AuthContext";

// import Navbar from "./Navbar";.
// import $ from "jquery";
import { useEffect } from "react";
import Footer from './Footer';

const MenuAdmin = ({ children }) => {

    const { user, setUser } = useContext(AuthContext);

    useEffect(() => {
        const script = document.createElement('script');
        script.src = "/js/content.js";
        script.async = true;
        document.body.appendChild(script);


        return () => { script.remove(); }
    }, []);


    return (
        // <div>
        //     <AdminHeader/>            
        // </div>

        <>

            <div className="hold-transition sidebar-mini layout-fixed">

                <div className="wrapper">

                    <Navbar />

                    <SideBarAdmin />

                    <Outlet />

                    <Footer />

                </div>

            </div>
        </>
    )
}

export default MenuAdmin
