import React from 'react'

import { useEffect } from 'react';
import Footer from '../Footer';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import jwt_decode from 'jwt-decode'
import { useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { refreshTokenRequest } from '../refreshTokenRequest';
import { Link, useNavigate } from 'react-router-dom';
import LocationSelect from '../shared/LocationSelect';
import axios from 'axios';
import KantorPemasaran from './childs/KantorPemasaran'
import MenuPerumahanDetail from './MenuPerumahanDetail';
import CustomIframe from '../CustomIFrame';
import MapContainer from '../igahp_admin/LokasiPerumahan/MapContainer';
import InnerHTML from 'dangerously-set-html-content';

const PengembangLokasiSitePlan = () => {

    const { user, setUser } = useContext(AuthContext);
    let refreshToken = null;
    const [isTokenExpired, setIsTokenExpired] = useState(false);
    const navigate = useNavigate()
    const { loginContext } = useContext(AuthContext);
    const script = document.createElement('script');

    // const window = (new JSDOM('')).window
    // const DOMPurify = createDOMPurify(window)

    useEffect(() => {
        script.src = "/js/mysiteplan.js";
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);




    const rawHtml2 = `

                    <!-- Button to trigger the modal -->
                    
                    <!-- Modal -->
                    <div class="modal fade" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"    aria-hidden="true">
                        <div class="modal-dialog " role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Unit Detail

                            <button onclick="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            
                            </div>
                            <div class="modal-body">
                            <!-- Form inside the modal -->
                            <form>

                            <!--
                                <div class="form-group">
                                    <label for="selectOption">Jenis Rumah:</label>
                                    <select class="form-control" id="selectJenisRumah">
                                        <option value="">Pilih Jenis Rumah...</option>
                                        <option value="1">Deret Tengah</option>
                                        <option value="2">Deret Pojok</option>
                                        
                                    </select>
                                </div>
                                -->

                                <div class="form-group">
                                    <label for="selectOption">Status Kavling:</label>
                                    <select class="form-control" id="selectStatusRumah">
                                        <option value="">Pilih Status...</option>
                                        <option value="perencanaan-hijau">Unit Perencanaan Sertifikasi Subsidi Hijau</option>
                                        <option value="pembangunan-hijau">Unit Pembangunan Subsidi Hijau</option>
                                        <option value="sertifikasi-hijau">Unit Sertifikasi Subsidi Hijau</option>
                                        <option value="subsidi">Unit Subsidi</option>
                                        <option value="terjual">Unit Subsidi Terjual</option>
                                        <option value="komersil">Unit Komersial</option>
                                        <option value="komersil-terjual">Unit Komersial Terjual</option>
                                    </select>
                                </div>
                                    
                                <div class="form-group">
                                <label for="tipeRumah">Tipe Rumah</label>
                                <select id="tipeRumahSelect" class="form-control">
                                    </select>
                                </div>
                            
                                <div class="form-group">
                                <label for="blok">Blok Rumah</label>             
                                    <select id="blokSelect" class="form-control">
                                    </select>
                                </div>
                            
                                
                                <div class="form-group">
                                <label for="email">Nomor Rumah</label>
                                <input type="text" class="form-control" id="no_rumah" placeholder="">
                                </div>
                            
                                <!-- Add more form fields as needed -->
                            </form>
                            </div>
                            <div class="modal-footer">
                            <button  onclick="closeModal()" class="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button"  onclick="simpanPolygon2()" class="btn btn-primary">Simpan</button>
                            <button id = "btnPindahPolygon" type="button"  onclick="pindahPolygon()" class="btn btn-warning">Pindahkan</button>
                            <button id = "btnHapusPolygon" type="button"  onclick="hapusPolygonBro()" class="btn btn-danger">Hapus</button>
                            </div>
                        </div>
                        </div>
                    </div>


                   <style>
                            /* Add your CSS styles here */
                            td {
                                text-align: center;
                                vertical-align: middle;
                            }
                            #stage {
                                width: 1570px;
                                height: 1000px;
                                float: left;
                                background: #d  dd;
                                transition: transform 0.5s; /* Adding a smooth transition */
                            }
                    </style>


                    <div className="card-header">
                        <div class="card-info">
                            
                            <button onclick="rotateStage()" class="btn btn-primary">Rotate</button>                                  
                            <button onclick="warnaiBro()" class="btn btn-info">Tampilkan Warna</button>
                            <button onclick="resetPolygon()" class="btn btn-warning">Sembunyikan Warna</button>
                            
                        </div>             

                        <div class="card-info">
                            <small>Gunakan mouse untuk geser / zoom in dan zoom out gambar siteplan</small>
                            
                        </div>                              
                    </div>




                      <div class="card">
                            <div class="card-body">

                                <div>
                                <tr>                                    
                                    <table>
                                    
                                        <td>
                                            <div style="position: relative; width: 100%; height: 100%">
                                                <!--<div id="stage" style="width: 1570px; height: 1000px; float: left; background: #ddd"> -->    
                                                <div id="stage" style="width: 1570px; height: 1000px; float: left; background: #ddd"> 
                                                </div>
                                                <div id="labelEl" style="position:absolute; visibility: hidden; top: 0px; left: 0px;  background: #fff; border: 1px solid #dedede; border-radius: 5px; padding:10px" />
                                            </div>
                                        </td>
                                    </tr>
                                    </table>
                                </div>
                            </div>
                        </div>                         
    
                    
                 

    `;


    return (
        <>
            {/* Main Sidebar Container */}
            {/* <aside className="main-sidebar sidebar-dark-primary elevation-4"> */}
            <aside className="main-sidebar sidebar-light-success elevation-4">

                <MenuPerumahanDetail />
            </aside>


            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">

                        <div className="row mb-4 mt-2">
                            <div className="col-sm-6">
                                <h1 className="text-muted fw-light">PEWARNAAN<b> SITEPLAN</b></h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active">Siteplan</li>
                                </ol>
                            </div>
                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">

                        <div className="row">



                            <InnerHTML html={rawHtml2} />

                            <input type="hidden" name="perumahan_id" id="perumahan_id" value={user.perumahan_id}></input>





                        </div>
                    </div>{/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div>
            {/* /.content-wrapper */}



        </>
    )
}

export default PengembangLokasiSitePlan
